export const url = {

  // User
  emailOtp : "/otp/email",
  emailVerify : "/otp/email/verify",
  checkUserEmail: "/users/email",
  getRegisterOtp : "/users/create/otp", 
  resendOtp : "/otp/resend", 
  getAuthToken: "/auth/token",

  // Signin
  getSignInOtp : "/otp/login",
  login : "/auth/login", 

  // DeleteAccount
  deleteAccount: "/users",

  // LookUp
  getBusinessServiceTypes: "/business/lookup/business-types",
  getBusinessLocations: "/business/lookup/locations/{typeId}",

  // Signup
  registerBusiness : "/business",
  insertBusinessInformation : "/business/{businessId}",
  updateBusinessInformation: "/business/configure",
  gstVerify : "/business/{businessId}/gst/verify",
  avatar : "/business/owner/image",
  businessVerify : "/business/verify",
  ownerImage : "/business/{businessId}/owner/image",
  getAllBusinessServices : "/business-supplier/businessTypes",
  uploadBankDocument : "/business/bank/document",

  // Business
  getBusinessInfo: "/{domain}/info",
  postBusinessInfo: "/{domain}/info",


  // Operating Days
  getAllOperatingDays: "/business/lookup/schedule",
  getSupplierOperatingDays: "/business/config/schedule",
  addSupplierOperatingDays : "/business/config/schedule",

  // Order Settings
  getAllOrderSettings: "/business/lookup/order/default",
  getSupplierOrderSettings : "/business/config/order",
  addSupplierOrderSettings : "/business/config/order",
  updateSupplierOrderSettings : "/business/config/order",
  

  // Reports
  emailOrderList: "supplier/reports/orders-by-status/email",
  downloadOrderList: "supplier/reports/orders-by-status/download",
  emailDailyCollectionReport: "supplier/reports/daily-collection-report/email",
  downloadDailyCollectionReport: "supplier/reports/daily-collection-report/download",
  emailSummaryReport: "supplier/reports/summary-report/email",
  downloadSummaryReport: "supplier/reports/summary-report/download",

  getDocument: '/documents/{documentId}',

  // Inventory
  getSupplierMenuCategoryAndItems: "/kitchen-inventory/{businessId}/menuCategoryAndItems",
  addSupplierInventory: "/kitchen-inventory/{businessId}",
  updateSupplierInventory: "/kitchen-inventory/{businessId}",
  getSupplierItems: "/kitchen-inventory/{businessId}/inventories",
  getSupplierItemsDetails: "/kitchen-inventory/{businessInfoId}/inventory/{inventoryId}",


  // Categories
  getAllCategories: "/{domain}/categories/all",
  getSupplierCategories: "/{domain}/categories/assigned",
  addSupplierCategories: "/{domain}/category",
  updateSupplierCategories: "/{domain}/category",
  getSupplierCategoryDetails: "/{domain}/category/assigned/{inventoryGroupId}",
  deleteSupplierCategory: "/{domain}/category/{inventoryGroupId}",

  // Inventories
  getMenuCategoryAndItems: "/{domain}-inventories/category-and-items",
  addInventory: "/{domain}-inventories",
  getItems: "/{domain}-inventories",
  getInventoriesLookup: "/{domain}-inventories/lookup",
  getItemsDetails: "/{domain}-inventories/{inventoryId}",
  updateInventory: "{domain}-inventories/{inventoryId}",
  inventoryImage: "{domain}-inventories/image",
  deleteInventory: "/{domain}-inventories/{inventoryId}",

  //Dashboard
  getOrders: "/order/supplier/",
  searchOrders: "/order/supplier/search/",
  getOrderDetails: "/order/supplier/details/{orderId}",
  changeOrderStatus: "/order/supplier/{type}",

  updatePoojaDate: "/order/supplier/order/date",
  uploadPoojaImage: "/orders/document/{orderInfoId}"

}
