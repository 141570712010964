/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"

import { 
    LandingScreen,
    BusinessDetails,
    VerifyMobileNumber,
    BusinessInformation,
    BusinessInformationVerification,
    ChooseYourBusiness,
    MobileNumber,
    
 } from "../screens"
import { color } from "../theme";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type DashboardParamList = {
    landing: undefined
    chooseyourbusiness: undefined
    businessdetails: undefined
    businessinformation: undefined
    businessinformationverification: undefined
    mobilenumber: undefined
    verifymobilenumber: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<DashboardParamList>()


const config = {
  animation: 'spring',
  config: {
    stiffness: 1000,
    damping: 100,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};


export function SignupNavigator() {
  return (
    <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          // gestureDirection: 'horizontal',
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          transitionSpec: {
            open: config,
            close: config
          }
      }}
    >
      {/* <Stack.Screen name="landing" component={LandingScreen} options={{title: ""}} /> */}
      <Stack.Screen name="chooseyourbusiness" component={ChooseYourBusiness} options={{title: "Choose Your Business"}}/>
      <Stack.Screen name="businessdetails" component={BusinessDetails} options={{title: "Enter Your Business Details"}}/>
      <Stack.Screen name="businessinformation" component={BusinessInformation} options={{title: "Business Information"}}/>
      <Stack.Screen name="businessinformationverification" component={BusinessInformationVerification} options={{title: "Business Information Verification"}}/>
      <Stack.Screen name="mobilenumber" component={MobileNumber} options={{title: "Enter Mobile Number"}}/>
      <Stack.Screen name="verifymobilenumber" component={VerifyMobileNumber} options={{title: "Verify Mobile Number"}}/>

    </Stack.Navigator>
  )
}



/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = []
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
