import React, { FunctionComponent as Component, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { TextField, Button, Screen } from '../../components';
import { View, Text, ViewStyle, ScrollView } from "react-native";
import { color } from "../../theme";
import { ApiSignup, ApiBusinessServices } from "../../services/api";
import { useStores } from "../../models";
import { useNavigation } from "@react-navigation/native";
import { save } from "../../utils/storage";
import { aadhaarValidate } from "../../utils/validation/aadhaar-validation";
import { panValidate } from "../../utils/validation/pan-validation";
import { DocumentUpload } from "../../components/document-upload/document-upload";
import { OtpModal } from "../profile-screen/components/otp-modal/otp-modal";

export const BusinessInformationVerification: Component = observer(function BusinessInformationVerification(props) {

    var businessTypeId = props['route'].params.data

    // navigation
    const navigation = useNavigation()

    // Stores
    const { userStore, loaderStore, businessDetailsStore, domainStore } = useStores()

    const [showError, setShowError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")

    

    useEffect(()=>{
        loaderStore.hideLoader()
        businessDetailsStore.updateConfirmBankAccountNumber("")
    }, [])

        
    const nextScreen = async () =>  {
        await save('businessStatus', "VerificationPending")
        userStore.setBusinessStatus("VerificationPending")
        navigation.navigate("welcome")
    }

    const insertBusinessInformationApi = ()=> {
        businessDetailsStore.updateBusinessVerified("VerificationPending")
        checkForMandatoryFields()
    }

    const checkForMandatoryFields = ()=> {
        setShowError(true)
        const success = businessDetailsStore.mandatoryCheckAuthInfo()
        const successAccountNumber = businessDetailsStore.businessInfo.bankDetails.bankAccountNumber.trim() == 
                                businessDetailsStore.businessInfo.confirmAccountNumber.trim() ? true: false
        // const aadhaarValidation = aadhaarValidate(businessDetailsStore.businessInfo.aadharHash)
        // const panValidation = panValidate(businessDetailsStore.businessInfo.panCardHash)

        const panValidation = businessDetailsStore.businessInfo.panCardHash && businessDetailsStore.businessInfo.panCardHash.trim().length ? 
        panValidate(businessDetailsStore.businessInfo.panCardHash): true
     
        if(success && panValidation && successAccountNumber){

            setErrorMessage(null)
            const api = new ApiBusinessServices()
            businessDetailsStore.updateAadharHash("XXXXXXXXXXXX")
            var data = businessDetailsStore.getBusinessPostModel()
            api.addBusinessInfo(data, domainStore.domainName[0]).then(res => responseWork(res))
        } else {

            !successAccountNumber ? setErrorMessage("Account number does not match") : !panValidation ? setErrorMessage("Enter a valid Pan Number") : setErrorMessage("Error Updating")

            // !aadhaarValidation && !panValidation ? setErrorMessage("Please enter a valid Aadhaar and Pan Number") : !aadhaarValidation ?
            //     setErrorMessage("Enter a valid Aadhaar Number") :  setErrorMessage("Enter a valid Pan Number")
        }
    }
 

    const responseWork = (res) => {
        if (res["kind"] == "ok") {
            nextScreen()
        } else {

            setErrorMessage("Email already exist")
        }
    }



    const uploadDocument = (imageUrl) => {

        const api = new ApiSignup()
        api.uploadBankDocument(imageUrl).then(res => {
          if(res.kind == "ok"){
            businessDetailsStore.updateBankDocumentId(res.data['id'])
          }
        })
    }


    return (

        <Screen preset="fixed" headerTitle="Business Information" showHeader={true} showBackButton={true} loader={loaderStore.loader}>

                <View style={CONTAINER}>
                    <ScrollView style={{ flex: 1, width: "100%" }}>
                        <View style={BOTTOM_CARD}>

                            <View style={{ width: "90%" }}>

                                <OtpModal target={businessDetailsStore.businessInfo.email} required={true} showError={showError} targetType="email" isVerified={businessDetailsStore.businessInfo.emailVerified}
                                    showLoader={x=> x == true ? loaderStore.showLoader() : loaderStore.hideLoader()} ></OtpModal>


                                {/* <TextField label='Aadhaar Number' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.aadharHash} getText={text => businessDetailsStore.updateAadharHash(text)} keyboardType="numeric" maxLength={12}></TextField> */}
                                <TextField label='PAN Card Number' defaultValue={businessDetailsStore.businessInfo.panCardHash} getText={text => businessDetailsStore.updatePanCardHash(text)}></TextField>
                                <TextField label='Registration Number' defaultValue={businessDetailsStore.businessInfo.registrationNumber} getText={text => businessDetailsStore.updateRegistrationNumber(text)}></TextField>

                                
                                <Text style={{fontSize: 16, marginTop: 20, color: color.grey, marginLeft: 8, marginBottom: 10}}>Bank Details</Text>
                                <TextField label='Account Number' secureTextEntry={true} required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.bankDetails.bankAccountNumber} getText={text => businessDetailsStore.updateBankAccountNumber(text)} keyboardType="numeric"></TextField>
                                <TextField label='Confirm Account Number' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.confirmAccountNumber} getText={text => businessDetailsStore.updateConfirmBankAccountNumber(text)} keyboardType="numeric"></TextField>
                                <TextField label='IFSC Code' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.bankDetails.bankingIfsCode} getText={text => businessDetailsStore.updateBankingIfsCode(text)}></TextField>
                                <TextField label='Account Holder Name' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.bankDetails.accountHolderName} getText={text => businessDetailsStore.updateAccountHolderName(text)}></TextField>
                                <DocumentUpload required={true} showError={showError}  getDocument={x=> {uploadDocument(x)}} removeDocument={x=>{businessDetailsStore.updateBankDocumentId(null)}}></DocumentUpload>



                                <View style={{marginVertical: 20, alignItems: "center"}}>

                                    <View style={{marginBottom: 10}}>
                                        { !businessDetailsStore.businessInfo.emailVerified ? <Text style={{fontSize: 13, color: color.ananthamOrange, marginBottom: 5, textAlign: 'center'}}>Email not verified</Text> : null}
                                        { errorMessage ? <Text style={{color: color.ananthamOrange, fontSize: 13, textAlign: 'center'}}>{errorMessage}</Text> : <View></View> }
                                    </View>

                                    <Button
                                        preset="primary"
                                        style={{marginHorizontal: 50}}
                                        text="Submit For Verification"
                                        onPress={insertBusinessInformationApi}/>
                                </View>

                            </View>
                        </View>

                    </ScrollView>

                </View>

        </Screen>
    )
    
    
})



const BOTTOM_CARD: ViewStyle = {
    width: "100%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingVertical: 30,
    backgroundColor: color.white,
    justifyContent: "space-around",
    alignItems: "center",
    bottom: 0,
    marginTop: 20,
}

const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
}