import { types } from "mobx-state-tree"


const Inventory = types
.model({
    amount: types.optional(types.number, 0),
    discountAmount: types.optional(types.number, 0),
    avatarUri: types.maybeNull(types.string),
    category: types.optional(types.string, ""),
    inventoryId: types.optional(types.number, 0),
    isPublished: types.optional(types.boolean, false),
    name: types.optional(types.string, ""),
    isDeleted: types.optional(types.boolean, false)
})
.actions(self => ({
}))


export const InventoryModel = types
.model()
.props({
    inventories: types.optional(types.array(Inventory), []),
})
.actions(self=>({
    setInventory(inventories){
        self.inventories = inventories
    },
    updateInventory(inventory, categoryName){
        self.inventories.map(x=>{
            if(x.inventoryId == inventory.id){
                x.amount = inventory.rate
                x.discountAmount = inventory.discountAmount
                x.name = inventory.name
                x.avatarUri = inventory.avatarUri
                x.isPublished = inventory.showToCustomer
                x.category = categoryName
            }
        })
    },
    addInventory(inventory, categoryName, inventoryId){
        self.inventories.push({
            amount: inventory.rate,
            discountAmount: inventory.discountAmount,
            name: inventory.name,
            avatarUri: inventory.avatarUri,
            isPublished: inventory.showToCustomer,
            category: categoryName,
            inventoryId: inventoryId
        })
    },
    deleteInventory(inventoryId){
        self.inventories.map(x=>{
            if(x.inventoryId == inventoryId){
                x.isPublished = false
                x.isDeleted = true
            }
        })
    },
}))

const Days = types
.model({
    Monday: types.optional(types.boolean, false),
    Tuesday: types.optional(types.boolean, false),
    Wednesday: types.optional(types.boolean, false),
    Thursday: types.optional(types.boolean, false),
    Friday: types.optional(types.boolean, false),
    Saturday: types.optional(types.boolean, false),
    Sunday: types.optional(types.boolean, false),
})

const CustomeDetails = types
.model()
.props({
    FieldName: types.maybeNull(types.optional(types.string, "")),
    FieldType: types.maybeNull(types.optional(types.string, "")),
    IsMandatory: types.maybeNull( types.optional(types.boolean, false)),
    IsDisabled: types.maybeNull( types.optional(types.boolean, false)),
    DefaultValue: types.maybeNull(types.optional(types.string, ""))
})

export const InventoryViewModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    inventoryId: types.optional(types.number, 0),
    categoryId: types.optional(types.number, 0),
    name: types.maybeNull(types.optional(types.string, "")),
    shortName: types.optional(types.string, ""),
    avatarUri: types.maybeNull(types.optional(types.string, "")),
    description: types.optional(types.string, ""),
    availability: types.optional(types.array(types.string), []),
    foodType: types.optional(types.enumeration("FoodType", ["Veg", "NonVeg"]), "Veg"),
    rate: types.optional(types.number, 0),
    discountAmount: types.optional(types.number, 0),
    weight: types.optional(types.number, 0),
    packingTime: types.maybeNull(types.optional(types.number, 0)),
    showToCustomer: types.optional(types.boolean, false),
    availablityBoolean: types.optional(Days, {}),
    availablityIds: types.optional(types.array(types.number), []),
    customDetails: types.maybeNull(types.optional(types.array(CustomeDetails), [])),
    useGroupCapacity: types.optional(types.boolean, false),
    capacity: types.optional(types.number, 0),
    isDeliveryFeeApplicable: types.optional(types.boolean, false),
    isEnquireAndPay: types.optional(types.boolean, false),
})
.actions(self=>({
    create(inventory){
        self.id = inventory.id
        // self.inventoryId = inventory.menuId
        // self.inventoryId = inventory.poojaId
        self.name = inventory.name
        self.categoryId = inventory.categoryId
        self.shortName = inventory.shortName
        self.avatarUri = inventory.avatarUri
        self.description = inventory.description
        self.availability = inventory.availability
        self.foodType = inventory.foodType
        self.rate = inventory.rate
        self.discountAmount = inventory.discountAmount
        self.weight = inventory.weight
        self.packingTime = inventory.packingTime
        self.showToCustomer = inventory.showToCustomer
        self.availablityBoolean = inventory.availablityBoolean
        self.availablityIds = inventory.availablityIds
        self.customDetails = inventory.customDetails
        self.useGroupCapacity = inventory.useGroupCapacity
        self.capacity = inventory.capacity
        self.isDeliveryFeeApplicable = inventory.isDeliveryFeeApplicable
        self.isEnquireAndPay = inventory.isEnquireAndPay
    },
    updateId(id){
        self.id = id
    },
    updateCategoryId(id){
        self.categoryId = id
    },
    updateInventoryId(inventoryId){
        self.inventoryId = inventoryId
    },
    updateName(name){
        self.name = name
        self.shortName = name
    },
    updateShortName(name){
        self.shortName = name
    },
    updateAvatarUri(uri){
        self.avatarUri = uri
    },
    updateDescription(description){
        self.description = description
    },
    updateAvailability(availability){
        self.availability = availability
    },
    updateAvailabilityBooleans(value){
        self.availablityBoolean = value
    },
    updateAvailabilityIds(ids){
        self.availablityIds = ids
    },
    updateFoodType(type){
        self.foodType = type
    },
    updateRate(rate){
        self.rate = rate
    },
    updateDiscountRate(discountAmount){
        self.discountAmount = discountAmount
    },
    updateWeight(weight){
        self.weight = weight
    },
    updatePackingTime(time){
        self.packingTime = time
    },
    toggleShowToCustomer(){
        self.showToCustomer = !self.showToCustomer
    },
    toggleUseGroupCapacity(){
        self.useGroupCapacity = !self.useGroupCapacity
    },
    updateCapacity(capacity){
        self.capacity = capacity
    },
    updateCustomDetails(customDetails){
        self.customDetails = JSON.parse(JSON.stringify(customDetails))
    },
    toggleIsDeliveryFeeApplicable(){
        self.isDeliveryFeeApplicable = !self.isDeliveryFeeApplicable
    },
    toggleIsEnquireAndPay(){
        self.isEnquireAndPay = !self.isEnquireAndPay
    },
    addFieldToCustomDetails(customField) {
        self.customDetails.push(customField)
    },
    editFieldToCustomDetails(customField) {
        self.customDetails.forEach(cf => {
            if(cf.FieldName == customField.FieldName) {
                cf.FieldType = customField.FieldType
                cf.IsDisabled = customField.IsDisabled
                cf.IsMandatory = customField.IsMandatory
                cf.DefaultValue = customField.DefaultValue
            }
        })
    },
    removeFieldFromCustomDetails(customField) {
        var removedIds = []
        self.customDetails.map((cf, index)=>{
            if(cf.FieldName == customField.FieldName){
                removedIds.push(index)
            }
        })
        for (var i = removedIds.length -1; i >= 0; i--){
            self.customDetails.splice(removedIds[i],1);
        }
    },
    reset(){
        self.id = 0
        self.inventoryId = 0
        self.categoryId = 0
        self.shortName = ""
        self.name = null
        self.avatarUri = null
        self.description = ""
        self.availability.clear()
        self.foodType = "Veg"
        self.rate = 0
        self.discountAmount = 0
        self.weight = 0
        self.packingTime = 0
        self.showToCustomer = false
        self.availablityBoolean.Monday = false
        self.availablityBoolean.Tuesday = false
        self.availablityBoolean.Wednesday = false
        self.availablityBoolean.Thursday = false
        self.availablityBoolean.Friday = false
        self.availablityBoolean.Saturday = false
        self.availablityBoolean.Sunday = false
        self.useGroupCapacity = true
        self.capacity = 0
        self.customDetails ? self.customDetails.clear() : null
        self.availablityIds.clear()
        self.isDeliveryFeeApplicable = false
        self.isEnquireAndPay = false
    },
    checkMandatory(){
        if(self.categoryId && self.name && self.rate > 0){
            if(self.useGroupCapacity){
                return true
            } else {
                return self.capacity > 0 ? true : false
            }
        } else {
            return false
        }
    }
}))
.views(self=>({
    getAddModel(){
        return {
            id: null,
            categoryId: self.categoryId,
            name: self.name,
            shortName: self.shortName,
            avatarUri: self.avatarUri,
            description: self.description,
            availability: self.availablityIds,
            rate: self.rate,
            discountAmount: self.discountAmount,
            showToCustomer: self.showToCustomer,
            customDetails: self.customDetails,
            useGroupCapacity: self.useGroupCapacity,
            capacity: self.capacity,
            isDeliveryFeeApplicable: self.isDeliveryFeeApplicable,
            weight: self.weight,
            foodType: self.foodType,
            packingTime: self.packingTime,
            isEnquireAndPay: self.isEnquireAndPay
        }
    },
    getUpdateModel(){
        return {
            id: self.id,
            categoryId: self.categoryId,
            name: self.name,
            shortName: self.shortName,
            poojaId: self.inventoryId,
            avatarUri: self.avatarUri,
            description: self.description,
            availability: self.availablityIds,
            rate: self.rate,
            discountAmount: self.discountAmount,
            showToCustomer: self.showToCustomer,
            customDetails: self.customDetails,
            useGroupCapacity: self.useGroupCapacity,
            capacity: self.capacity,
            isDeliveryFeeApplicable: self.isDeliveryFeeApplicable,
            weight: self.weight,
            foodType: self.foodType,
            packingTime: self.packingTime,
            isEnquireAndPay: self.isEnquireAndPay
          }
    }
}))



