import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Picker, StyleSheet } from "react-native"
import { DropdownProps } from "./dropdown.props"
// import { Icon } from "../icon/icon"
import { spacing, color, styles } from "../../theme"
import { Layout, Select, SelectItem, IndexPath } from "@ui-kitten/components";
import { Text } from "../text/text"


// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}


/**
 * Dropdown that appears on many screens. Will hold navigation buttons and screen title.
 */
export const Dropdown: Component<DropdownProps> = props => {
  const {
    dropdownList,
    placeholder,
    title,
    titleStyle,
    required,
    showError,
    getSelectedItem,
    defaultValue
  } = props


  const [selectedIndex, setSelectedIndex] = React.useState();


 

  const [list, setList] = React.useState([]);
  const [value, setValue] = React.useState(null);

  // if(selectedIndex){
  //   setValue(dropdownList[selectedIndex.row]['name']);
  // }

  useEffect(()=>{
    setList(dropdownList)
  }, [])

  useEffect(()=>{
    if(defaultValue){
      dropdownList.map((item, index) =>{
        if(defaultValue == item.id){
          setSelectedIndex(new IndexPath(index))
          setValue(item['name'])
        }
      })
    } else {
      setValue(null)
      setSelectedIndex(0)
    }
  }, [defaultValue])



  useEffect(()=>{
    if(selectedIndex){
      setValue(dropdownList[selectedIndex.row]['name']);
    }  
  }, [selectedIndex])

  return (
    <View style={{...ROOT}}>
      


        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

            <View style={{flexDirection: 'row'}}>
              <Text preset="fieldLabel"  text={title}  style={{...label_lg, ...titleStyle}} />
              { required ?
                <View>
                  <Text style={{color: color.ananthamOrange}}> *</Text>
                </View> : <View></View>
              }
            </View>

            { required && showError &&  (selectedIndex == undefined || selectedIndex == 0)?
              <View>
                <Text style={{...label_error}}>Required</Text>
              </View> : <View></View>
            }

          </View>


          <Layout level='1'>
            <Select
              selectedIndex={selectedIndex}
              placeholder={placeholder}
              value={value}
              onSelect={index => {setSelectedIndex(index); getSelectedItem(dropdownList[index['row']])}}>
              {dropdownList.map((r, index) => <SelectItem key={index} title={r.name}/>)}    
            </Select>

          </Layout>
        
    </View>
  )
}

const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}

const label_error: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}


