import React, { FunctionComponent as Component, useEffect } from 'react';
import { View, ViewStyle, BackHandler, Platform } from 'react-native';
import { observer } from 'mobx-react-lite';
import { color, styles } from "../../theme"
import { TextField, Button, Screen, MapPicker, Text, Dropdown } from '../../components';
import { useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { ApiSignup, ApiBusinessServices } from '../../services/api';
import { load, save } from '../../utils/storage';
import { useStores } from '../../models';
import { ImageUpload } from '../../components/image-upload/image-upload';
import { getBusinessName } from "../../utils/business-info"
import GMapComponent from "../../components/map-picker/gmap-picker"


export const BusinessInformation: Component = observer(function BusinessInformation() {

    const navigation = useNavigation()
    
    const { businessDetailsStore, businessLocationsStore, domainStore } = useStores()

    const [showError, setShowError] = React.useState(false)
    const [showButton, setShowButton] = React.useState(false)
    const [businessTypeId, setBusinessTypeId] = React.useState(0);

    
    useEffect(() => {
        const backAction = () => {       
            navigation.goBack()
            return true
        };
    
        const backHandler = BackHandler.addEventListener(
          "hardwareBackPress",
          backAction
        );
        return () => backHandler.remove();
    }, []);


    useEffect(() => {
        getBusinessType()
        getBusinessLocations()
    }, [])


    const getBusinessLocations = async () =>  {
        const api = new ApiBusinessServices()
        api.getBusinessLocations(4).then(async res => {
          await businessLocationsStore.create(res['data'])
        })
      }

    const getBusinessType = async () => {
        var businessTypeId = await load("businessTypeId")
        getAppTitles(businessTypeId)
        setBusinessTypeId(businessTypeId)
        businessDetailsStore.updateBusinessAppName(getBusinessName(businessTypeId))
        getBusinessInfo()
    }

    const getAppTitles = async (businessTypeId) => {
        domainStore.create(businessTypeId)
    }
    

    const getBusinessInfo = ()=> {
        const api = new ApiBusinessServices()
        api.getBusinessInfo(domainStore.domainName[0]).then(res => {
            businessDetailsStore.createBusinessInfo(res['data'])
            setShowButton(true)
        })
      }



    const nextScreen = async ()=> {
        await save('businessStatus', "PendingDetails")
        checkForMandatoryFields()
    }

    const checkForMandatoryFields = ()=> {
        setShowError(true)
        const success = businessDetailsStore.mandatoryCheckBusinessInfo()
        
        if(success){
            navigation.navigate("businessinformationverification", {data: businessTypeId})
        }
    }


    // api calls

    const uploadImage = (imageUrl) => {
        const api = new ApiSignup()
        api.ownerImage(imageUrl).then(res => {
          if(res.kind == "ok"){
            businessDetailsStore.updateBusinessAvatarUri(res.data['url'])
          }
        })
    }

    const handleAddressChange = (address, addressDetails, latitude, longitude) => {
        const selectedLoc = {
            latitude: latitude,
            longitude: longitude
        };
        businessDetailsStore.updateAddressLaneOne(address)
        businessDetailsStore.updateLatLng(selectedLoc)
        businessDetailsStore.updateZipCode(addressDetails.pincode)
    }

    return (

        <Screen preset="fixedHeight" headerTitle="Business Information" showHeader={true} showBackButton={true}>

            <View style={CONTAINER}>

                <ScrollView style={{ flex: 1, width: "100%" }}>
                    <View style={BOTTOM_CARD}>

                        <View style={{ width: "90%" }}>
                            <TextField label={domainStore.businessName + " Name"} required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.businessName} getText={text => businessDetailsStore.updateBusinessName(text)}></TextField>
                            <TextField label='Description' multiline={true} style={{maxHeight: 100}} defaultValue={businessDetailsStore.businessInfo.businessDescription} getText={text => businessDetailsStore.updateBusinessDescription(text)}></TextField>

                            <Text style={{fontSize: 14, color: color.dim, marginTop: 10, marginLeft: 8}}>Administrator Name</Text>
                            <View style={{flexDirection: 'row'}}>
                                <View style={{flexGrow:1, minWidth:"50%", maxWidth: "50%"}}>
                                <TextField label="First Name" required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.firstName}  getText={text => businessDetailsStore.updateFirstName(text)} titleStyle={styles.label_sm}></TextField> 
                                </View>
                                <View style={{flexGrow:1, minWidth: "50%", maxWidth: "50%"}}>
                                    <TextField label="Last Name" required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.lastName} getText={text => businessDetailsStore.updateLastName(text)}  titleStyle={styles.label_sm}></TextField> 
                                </View>
                            </View>

                            <Text style={{...styles.label_lg, marginTop: 10, marginLeft: 8}}>{domainStore.businessName + " Image"}</Text>

                            <ImageUpload defaultImageUrl={businessDetailsStore.businessInfo.businessAvatarUri} getImage={x=> {uploadImage(x)}}></ImageUpload>


                            <TextField label='Business Type'   disabled={true} defaultValue={domainStore.businessName}></TextField>
                            <TextField required={true} showError={showError} label={domainStore.businessName + ' Location'}  defaultValue={businessDetailsStore.businessInfo.businessLocation} getText={text => businessDetailsStore.updateBusinessLocation(text)}></TextField>

                            {/* <TextField label='Operational Location' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.operationLocationName}></TextField> */}
                            <Dropdown dropdownList={businessLocationsStore.businessLocations} defaultValue={businessDetailsStore.businessInfo.operationLocationId} required={true} showError={showError} title="Operational Location" getSelectedItem={item => businessDetailsStore.updateOperationLocation(item)}></Dropdown>
                            
                            {Platform.OS != "web" ?
                                <MapPicker required={true} showError={showError} getLatLng={latLng => businessDetailsStore.updateLatLng(latLng)} getAddress={address=> businessDetailsStore.updateAddressLaneOne(address)}></MapPicker>
                                : <GMapComponent onAddressChange={handleAddressChange} selectedAddress={businessDetailsStore.businessInfo} />}

                            <TextField label='Address 1' multiline={true} style={{ maxHeight: 100 }} defaultValue={businessDetailsStore.businessInfo.addressLaneOne} required={true} showError={showError} getText={text => businessDetailsStore.updateAddressLaneOne(text)}></TextField>
                            <TextField label='Address 2'  multiline={true} style={{maxHeight: 100}} getText={text => businessDetailsStore.updateAddressLaneTwo(text)}></TextField>
                            <TextField label='Landmark' getText={text => businessDetailsStore.updateLandmark(text)}></TextField>
                            {/* <TextField label='Street Address' getText={text => onChangeText(text, "")}></TextField> */}
                            <TextField label='ZIP Code' required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.zipCode} getText={text => businessDetailsStore.updateZipCode(text)} keyboardType="numeric" autoComplete="postal-code"></TextField>
                            
                            <TextField label='Website' getText={text => businessDetailsStore.updateWebsite(text)} keyboardType="url"></TextField>

                            {/* <TextField label='City' getText={text => businessDetailsStore.updateDistrict(text)}></TextField> */}
                            {/* <TextField label='District' required={true} showError={showError}  getText={text => businessDetailsStore.updateDistrict(text)}></TextField> */}
                            {/* <TextField label='District'   disabled={true} defaultValue={businessDetailsStore.businessInfo.operationLocationName}></TextField> */}

                        </View>

                        { showButton ? <Button
                            preset="primary"
                            style={{ marginVertical: 20 }}
                            text="Continue"
                            onPress={() => nextScreen()}
                        /> : null }
                    </View>
                </ScrollView>

            </View>
        </Screen>
    );
    


})


const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
}

const BOTTOM_CARD: ViewStyle = {
    width: "100%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingVertical: 30,
    backgroundColor: color.white,
    justifyContent: "space-around",
    alignItems: "center",
    bottom: 0,
    marginTop: 20,
}