/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TabBarIcon from "../components/tabbar-icon/tabbar-icon";


import { 
  WelcomeScreen, 
  ConfigurationsScreen,
  ProfileScreen,
  DashboardScreen,
  } from "../screens"

import { DashboardNavigator } from "./dashboard-navigator";
import { ConfigurationsNavigator } from "./configurations-navigator";
import { ProfileNavigator } from "./profile-navigator";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type PrimaryParamList = {
  welcome: undefined
  home: undefined
  configurationStack: undefined
  dashboardStack: undefined
  profileStack: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<PrimaryParamList>()
const Tab = createBottomTabNavigator();


export function PrimaryNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  
      }}
    >

    <Stack.Screen name="home" component={Home} />
    <Stack.Screen name="welcome" component={WelcomeScreen} options={{title: "Welcome"}}/>
    <Stack.Screen name="configurationStack" component={ConfigurationsNavigator} />
    <Stack.Screen name="dashboardStack" component={DashboardNavigator} />
    <Stack.Screen name="profileStack" component={ProfileNavigator} />

    </Stack.Navigator>
  )
}


function Home() {
  return (
      <Tab.Navigator 
        tabBarOptions={{
            style: {
              borderTopWidth: 0,
              paddingTop: 3,
              paddingBottom: 4,
              height: 60,
              shadowColor: '#000',
              shadowOpacity: 0.1,
              shadowRadius: 20,
              borderTopLeftRadius:20,
              borderTopRightRadius: 20,
              backgroundColor: '#fff',
              shadowOffset: { width: 0, height: 0 }
            },
            keyboardHidesTabBar: true,
          }}
          initialRouteName='dashboard'
          >

          <Tab.Screen 
            name="configurations" 
            component={ConfigurationsScreen} 
            options={{
            title: 'Configure',
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="config"/>,
            tabBarVisible: true
          }}
        />

        <Tab.Screen 
          name="dashboard" 
          component={DashboardScreen}
          options={{
            title: 'Home',
            tabBarVisible: true,
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="dashboard" />
          }}
        />
        <Tab.Screen 
          name="profile" 
          component={ProfileScreen}   
          options={{
            title: 'Profile',
            tabBarVisible: true,
            tabBarIcon: ({ focused }) => <TabBarIcon focused={focused} name="profile"
             />
          }}
        />

      </Tab.Navigator>
  );
}












/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["welcome", "dashboard", "signin", "home"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
