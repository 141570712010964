import React, { FunctionComponent as Component } from "react"
import { View, TouchableOpacity } from 'react-native';
import {  Text } from "@ui-kitten/components";
import { color, spacing } from "../../../../theme";
import { CustomFieldsProps } from "./custom-fields.props";
import { Icon } from "../../../../components";
import { observer } from "mobx-react-lite";

const CustomFields: Component<CustomFieldsProps> = props => {
    const {
        style,
        customDetails,
        action,
    } = props

    const getTypeColor = (fieldName)=> {
        if(fieldName == 'Number') {
            return color.purpleStatus
        } else if(fieldName == 'Scanner') {
            return color.yellowStatus
        } else {
            return color.grey
        }
    }

    return (
        <View style={style}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10}}>
                <Text style={{fontWeight: 'bold'}}>
                    Custom Fields
                </Text>
                <TouchableOpacity onPress={()=> action(
                        {"FieldName": "", "FieldType": "", "IsDisabled": false, "IsMandatory": false, "DefaultValue": ""}, 'add'
                )}>
                    <Text style={{color: color.white, marginLeft: 5, fontSize: 12, backgroundColor: color.link, 
                        borderRadius: 10, paddingHorizontal: 10, paddingVertical: 2}}>+ Add New</Text>
                </TouchableOpacity>
            </View>
            { customDetails && customDetails.length ?
                <View>
                    {  customDetails.map((item, index) => 
                            <View style ={{flexDirection: 'column', paddingHorizontal: spacing.small}}>
                                <View style={{marginVertical: 5, flexDirection: 'row', justifyContent: 'space-between'}}>
                                    <View style={{flexDirection: 'row'}}>
                                        <Text style={{'fontWeight': 'bold'}}>{'Field: '}</Text>
                                        <Text>{item['FieldName']}</Text>
                                        <Text style={{
                                            backgroundColor: getTypeColor(item['FieldType']), color: color.white,
                                            marginLeft: 5,
                                            paddingVertical: 2, paddingHorizontal: 10, borderRadius: 10, fontSize: 12}}>
                                            {item['FieldType']}
                                        </Text>
                                    </View>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <TouchableOpacity onPress={()=> action(item, 'edit')}>
                                            <Text style={{fontWeight: 'bold', color: color.ananthamBlue}}>Edit</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity onPress={()=> action(item, 'delete')}>
                                            <Icon style={{'marginLeft': 10, 'height': 16}} icon="delete_icon" ></Icon>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        )
                    }
                </View> : 
                <Text style={{color: color.grey}}>No custom fields added</Text> 
            }
        </View>
    );
};


export default observer(CustomFields);
