import React, { FunctionComponent as Component, useEffect } from "react"
import { View, Text, TouchableOpacity, StyleSheet, TextStyle, ViewStyle } from "react-native"
import { color, styles } from "../../../../theme";
import { OtpModalProps } from "./otp-modal.props";
import { TextField, Button, Icon } from "../../../../components";
import { useStores } from "../../../../models";
import { ApiSignin, ApiSignup } from "../../../../services/api";
import { Modal, Card } from "@ui-kitten/components";

 

const BACKDROP: ViewStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

const CENTER: ViewStyle = {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  }
  

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const OtpModal: Component<OtpModalProps> = props => {
    
    const {
        targetType,
        target,
        display = false,
        showError, 
        required,
        showLoader,
    } = props
    
    const [ modalVisible, setModalVisibility ] = React.useState(false);
    const { businessDetailsStore } = useStores()
    const [ resendMessage, setResendMessage ] = React.useState(null)
    const [ resendStatus, setResendStatus ] = React.useState(false)
    const [ errorMessage, setErrorMessage ] = React.useState("")


    useEffect(() => {
        setModalVisibility(display)
    }, [display]);


    function resendOtp(){
        var data = {
            otpReferenceId: businessDetailsStore.businessInfo.otpReferenceId,
            target: businessDetailsStore.businessInfo.email,
            targetType: 'email',
            action: "verify"
        }

        const api = new ApiSignin()
        api.resendOtp(data).then(res => {
            if(res.kind == "ok"){
                businessDetailsStore.updateOtpReferenceId(res['data'])
                setResendMessage("OTP sent successfully")
                setResendStatus(true)
            } else {
                setResendMessage("Failed to resend OTP")
                setResendStatus(false)
            }
        })
    }


    const emailOtpApi = ()=> {
        const api = new ApiSignup()

        showLoader(true)
        const email = businessDetailsStore.businessInfo.email
        api.checkUserEmail(email).then(res => {
            
            if('data' in res){
                if(res['data']['isUserExists']){
                    setErrorMessage("Email already exist")
                } else  {
                    api.emailOtp(email).then(res => {
                        if (res["kind"] == "ok") {
                            businessDetailsStore.updateOtpReferenceId(res['data']['otpReferenceId'])
                            setModalVisibility(true)
                            setErrorMessage(null)
                        } else if (res['statusCode'] == 500){
                            setErrorMessage(res['message'])
                        }
                    })
                }
                showLoader(false)
            } else{
                setErrorMessage("Enter a valid email")
                showLoader(false)
            }
        })
    }



    // function emailOtpApi() {
    //     const api = new ApiSignup()
    //     showLoader(true)
    //     const email = target
    //     api.emailOtp(email).then(res => {
    //         loaderStore.hideLoader()
    //         showLoader(false)
    //         if (res["kind"] == "ok") {
    //             businessDetailsStore.updateOtpReferenceId(res['data']['otpReferenceId'])
    //             setModalVisibility(true)
    //         }
    //     })
    // }


    const emailVerifyApi = () => {
        const api = new ApiSignup()
        api.emailVerify(businessDetailsStore.getEmailVerifyModel()).then(res => {
            if (res["kind"] == "ok") {
                setModalVisibility(false)
                businessDetailsStore.updateEmailVerified(true)
            } else {
                setResendMessage("OTP not valid")
                setResendStatus(false)        
            }
        })
    }





  return (
    <View>

        <View style={{ flexDirection: "row", flexWrap: "nowrap", alignItems: "center" }}>
            <View style={{ flex: 1 }}>
                <TextField required={required} showError={showError} label={targetType.charAt(0).toUpperCase() + targetType.slice(1, targetType.length)} disabled={businessDetailsStore.businessInfo.emailVerified} defaultValue={target} getText={text => businessDetailsStore.updateEmail(text)} ></TextField>
            </View>

            <TouchableOpacity style={{top: 8}} onPress={() => {!businessDetailsStore.businessInfo.emailVerified ? emailOtpApi() : null}}>
                <Text style={{color: color.ananthamBlue}}>{ !businessDetailsStore.businessInfo.emailVerified ? 'Verify' : 'Verified'}</Text>
            </TouchableOpacity>
        </View>
        { errorMessage ? <Text style={{fontSize: 11, color: resendStatus ? color.green: color.ananthamOrange, marginBottom: 5, textAlign: 'center'}}>{errorMessage}</Text> : <View></View>}

        <Modal visible={modalVisible}
                backdropStyle={BACKDROP}
                onBackdropPress={() => setModalVisibility(false)}
                style={{padding: 20}}>
                
                <Card>

                    <View style={CENTER}>

                        <TouchableOpacity style={{ width: "100%", alignItems: "flex-end", paddingBottom: 10 }} onPress={() => setModalVisibility(false)}>
                            <Icon icon="close"></Icon>
                        </TouchableOpacity>
                        <Text style={{ textAlign: 'center', paddingHorizontal: 25, lineHeight: 25 }}>Please enter the 4-digit OTP sent to your email</Text>
                        <View style={{ width: "50%" }}>
                            <TextField
                                placeholder='OTP'
                                getText={text => businessDetailsStore.updateOtp(text)}
                                keyboardType='numeric'
                            >
                            </TextField>
                            <View style={{ paddingVertical: 20 }}>
                                <Text style={styles.sub_title}>
                                    Didn’t receive the OTP?
                                </Text>
                                <TouchableOpacity
                                    onPress={resendOtp}>
                                    <Text style={{fontSize: 13, alignSelf: 'center', color: color.ananthamBlue, marginTop: 3}}>
                                        RESEND OTP
                                    </Text>    
                                </TouchableOpacity>

                                { resendMessage ?
                                    <View style={{alignSelf: 'center', marginTop: 10}}>
                                        <Text style={{fontSize: 13, color: resendStatus ? color.green: color.ananthamOrange}}>{resendMessage}</Text>    
                                    </View> : <View></View>
                                }
                            </View>
                        </View>
                        <Button
                            preset="primary"
                            text="Verify"
                            onPress={() => emailVerifyApi()}
                        />

                        <Text style={{marginTop: 10, color: color.grey, fontSize: 12}}>
                            Please check Spam or Promotions folders also.  
                        </Text>
                    </View>
                </Card>

        </Modal>

    </View>
    
  )
}

