import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"

import { LandingScreen } from "../screens"
import { SignupNavigator } from "./signup-navigator";
import { SigninNavigator } from "./signin-navigator";


export type AutheticationParamList = {
    landing: undefined
    signup: undefined
    signin: undefined
}

const Stack = createStackNavigator<AutheticationParamList>()


const config = {
  animation: 'spring',
  config: {
    stiffness: 850,
    damping: 100,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};


export function AutheticationNavigator() {
    return (
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            gestureEnabled: true,
            // gestureDirection: 'horizontal',
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            transitionSpec: {
              open: config,
              close: config
            }
          }}
        >

          <Stack.Screen name="landing" component={LandingScreen} options={{title: "Ellam for Enterprises"}}/>
          <Stack.Screen name="signup" component={SignupNavigator} options={{title: "SignUp"}}/>
          <Stack.Screen name="signin" component={SigninNavigator} options={{title: "SignIn"}}/>
    
        </Stack.Navigator>
      )
}

const exitRoutes = ["welcome", "signin"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
