import React, { FunctionComponent as Component, useEffect } from "react"
import { View, Modal, ViewStyle, TextStyle, Image } from 'react-native';
import { color, styles } from "../../theme";
import { UpdatePopupProps } from "./update-popup.props";
import { Button, Icon, Text } from "..";


const FULL: ViewStyle = {
    backgroundColor: color.white,
    marginHorizontal: 50,
    borderRadius: 10,
    height: 200,
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5
  }
  
const FULL_OUTER: ViewStyle = {
    flex: 1,
    backgroundColor: "#0D1F3C80",
    justifyContent: 'center'
}


const CENTER: ViewStyle = {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  }
  
  
  const LIGHTTEXT: TextStyle = {
    color: color.dim,
    fontSize: 13,
    marginHorizontal: 7,
    marginVertical: 1
  }

export const UpdatePopup: Component<UpdatePopupProps> = props => {
  const {
    message,
    display,
    update,
  } = props


  const [modalVisible, setModalVisibility] = React.useState(false);

  useEffect(() => {
    setModalVisibility(display)
  }, [display]);


  return (
    <View style={{margin: 10}}> 

      <Modal
        visible={modalVisible}
        animationType={'none'}
        transparent={true}
        >
          
          <View style={FULL_OUTER}>
            
            <View style={FULL}>

                <View style={CENTER}>
                  
                    <Icon icon={"update_icon"} style={{width:80, height:80, position: 'absolute', top: -65, alignSelf: 'center'}}></Icon>
                    

                    <View style={{marginTop: 25, alignSelf: 'center'}}>

                      <Text preset="bold" style={{fontSize: 18, textAlign: 'center', alignSelf: 'center', marginBottom: 15}}>
                        Update Application
                      </Text>

                      <Text style={[LIGHTTEXT, {textAlign: 'center', marginBottom: 15}]}>{message}</Text>
                    
                      <View style={[LIGHTTEXT, {flexDirection: 'row', alignSelf: 'center'}]}>
                        <Button
                          preset="primary"
                          style={{borderRadius: 20, width: 125}}
                          text="Update"
                          onPress={update}
                          />
                      </View>

                  </View>

                </View>

            </View>

          </View>

      </Modal>

    </View>
  );
};


