import { businessServices_Model, businessLocations_Model } from "../../models/signup-model"
import { GeneralApiProblem, getGeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { Api } from "../api"
import { url } from "../api-list";
import { load, save } from "../../../utils/storage";


type businessServices_Res = { kind: "ok"; data: businessServices_Model } | GeneralApiProblem
type businessLocations_Res = { kind: "ok"; data: businessLocations_Model } | GeneralApiProblem

export class ApiBusinessServices {

  async getBusinessTypes(): Promise<businessServices_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    // make the api call
    const response: ApiResponse<any> = await apiService.get(url.getBusinessServiceTypes)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      // if (response.data['statusCode'] = 200) {
      // }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getBusinessLocations(typeId): Promise<businessLocations_Res> {
    
    // make the api call
    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    const apiUrl = url.getBusinessLocations.replace("{typeId}", typeId)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      // if (response.data['statusCode'] = 200) {
      // }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getBusinessStatus() {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call
    const apiUrl = url.businessVerify

    const response: ApiResponse<any> = await apiService.get(apiUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      let resData = response.data
      if(resData){
        await save("businessStatus", resData['status'])
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getBusinessInfo(domain) {

    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.getBusinessInfo.replace("{domain}", domain)

    const response: ApiResponse<any> = await apiService.get(apiUrl)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async addBusinessInfo(param, domain) {

    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.postBusinessInfo.replace("{domain}", domain)


    const response: ApiResponse<any> = await apiService.post(apiUrl, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      let resData = response.data
      if(resData){
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async updateBusinessInfo(data) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.put(url.updateBusinessInformation, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      let resData = response.data
      if(resData){
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}