import { types } from "mobx-state-tree"


const CustomeDetails = types
.model()
.props({
    FieldName: types.maybeNull(types.optional(types.string, "")),
    FieldType: types.maybeNull(types.optional(types.string, "")),
    IsMandatory: types.maybeNull( types.optional(types.boolean, false)),
    IsDisabled: types.maybeNull( types.optional(types.boolean, false)),
    DefaultValue: types.maybeNull(types.optional(types.string, ""))
})

const Category = types
.model({
    id: types.maybeNull(types.number),
    groupName: types.maybeNull(types.string),
    isActive: types.maybeNull(types.boolean),
    inventoryGroupId: types.maybeNull(types.number),
    shortName: types.maybeNull(types.string),
    customDetails: types.maybeNull(types.optional(types.array(CustomeDetails), [])),
    isDeleted: types.optional(types.boolean, false)
})
.actions(self => ({
    setId(id) {
        self.id = id
    },
    toggleActive(isActive) {
        self.isActive = !self.isActive
    }
}))


export const CategoryModel = types
.model()
.props({
    categories: types.optional(types.array(Category), []),
})
.actions(self=>({
    addCategories(categories){
        this.reset()
        categories.map(x=>{
                self.categories.push(x)
        })
    },
    reset(){
        self.categories.clear()
    },
    updateCategory(category){

        self.categories.map(x=>{
            if(x.id == category.inventoryGroupId){
                x.groupName = category.categoryName
                x.isActive = category.showToCustomer
            } 
        })
    },
    addCategory(category, inventoryGroupId){

        var data ={
            id: inventoryGroupId,
            groupName: category.categoryName,
            isActive: category.showToCustomer,
            inventoryGroupId: inventoryGroupId,
            shortName: category.categoryName,
            customDetails: category.customDetails
        }


        self.categories.push(JSON.parse(JSON.stringify(data)))

    },
    getCustomFieldsByName(name){
        var customDetails = []
        self.categories.map(x=>{
            if(x.groupName == name){
                customDetails = x.customDetails
            }
        })

        return customDetails
    },
    deleteCategory(inventoryGroupId){
        self.categories.map(x=>{
            if(x.id == inventoryGroupId){
                x.isDeleted = true
                x.isActive = false
            } 
        })
    },
}))



export const CategoryViewModel = types
.model()
.props({
    categoryId: types.maybeNull(types.optional(types.string, "")),
    categoryName: types.maybeNull(types.optional(types.string, "")),
    capacity: types.maybeNull(types.optional(types.number, 0)),
    endTime: types.maybeNull(types.optional(types.string,"")),
    startTime: types.maybeNull(types.optional(types.string,"")),
    inventoryGroupId: types.maybeNull(types.optional(types.string,"")),
    showToCustomer: types.optional(types.boolean, false),
    customDetails: types.maybeNull(types.optional(types.array(CustomeDetails), []))
})
.actions(self=>({
    create(category){
        self.categoryId = category.categoryId
        self.categoryName = category.categoryName
        self.capacity = category.capacity
        self.endTime = category.endTime
        self.startTime = category.startTime
        self.inventoryGroupId = category.inventoryGroupId
        self.showToCustomer = category.showToCustomer
        self.customDetails = category.customDetails
    },
    updateCategoryId(name){
        self.categoryId = name
    },
    updateCategoryName(name){
        self.categoryName = name
    },
    updateCapacity(capacity){
        self.capacity = capacity
    },
    updateEndTime(endTime){
        self.endTime = endTime
    },
    updateStartTime(startTime){
        self.startTime = startTime
    },
    updateInventoryGroupId(id){
        self.inventoryGroupId = id
    },
    toggleShowToCustomer(){
        self.showToCustomer = !self.showToCustomer
    },
    updateCustomDetails(customDetails){
        self.customDetails = JSON.parse(JSON.stringify(customDetails))
    },
    addFieldToCustomDetails(customField) {
        self.customDetails.push(customField)
    },
    editFieldToCustomDetails(customField) {
        self.customDetails.forEach(cf => {
            if(cf.FieldName == customField.FieldName) {
                cf.FieldType = customField.FieldType
                cf.IsDisabled = customField.IsDisabled
                cf.IsMandatory = customField.IsMandatory
                cf.DefaultValue = customField.DefaultValue
            }
        })
    },
    removeFieldFromCustomDetails(customField) {
        var removedIds = []
        self.customDetails.map((cf, index)=>{
            if(cf.FieldName == customField.FieldName){
                removedIds.push(index)
            }
        })
        for (var i = removedIds.length -1; i >= 0; i--){
            self.customDetails.splice(removedIds[i],1);
        }
    },
    reset(){
        self.capacity = 0
        self.endTime = ""
        self.startTime = ""
        self.inventoryGroupId = ""
        self.showToCustomer = false
        self.customDetails ? self.customDetails.clear() : null
    },
    checkMandatory(){
        if(self.capacity > 0 && self.categoryName){
            return true
        } else {
            return false
        }
    }
}))
.views(self=>({
    getAddModel(){
        return {
            categoryId: self.categoryId,
            categoryName: self.categoryName,
            capacity: self.capacity,
            endTime: self.endTime,
            startTime: self.startTime,
            showToCustomer: self.showToCustomer,
            inventoryGroupId: null,
            customDetails: self.customDetails
        }
    },
    getUpdateModel(){
        return {
            categoryId: self.categoryId,
            categoryName: self.categoryName,
            capacity: self.capacity,
            endTime: self.endTime,
            startTime: self.startTime,
            showToCustomer: self.showToCustomer,
            inventoryGroupId: self.inventoryGroupId,
            customDetails: self.customDetails
        }
    }
}))


