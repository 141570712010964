import * as React from "react"
import { KeyboardAvoidingView, Platform, ScrollView, StatusBar, View, Image, TextStyle, ViewStyle, StyleSheet, Dimensions } from "react-native"
import { useSafeArea, SafeAreaView } from "react-native-safe-area-context"
import { ScreenProps } from "./screen.props"
import { isNonScrolling, offsets, presets } from "./screen.presets"
import { Text } from "../text/text";
import { color } from "../../theme";
import { Icon } from "../icon/icon";
import { useNavigation } from "@react-navigation/core";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useEffect, useState } from "react"
import { OverflowMenu, MenuItem } from "@ui-kitten/components";
import { Ionicons } from "@expo/vector-icons";


const isIos = Platform.OS === "ios"
const preloader = require('../../../assets/preloader.gif')

const headerContainerStyle: ViewStyle = { backgroundColor: color.background, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}
const headerBackStyle: TextStyle = {paddingLeft: 15, alignContent: 'flex-start'}
const headerTitleStyle: TextStyle = { color: color.black, fontSize: 20, fontWeight: 'bold', alignSelf: 'center', paddingRight: 15}


function ScreenWithoutScrolling(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background }
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const [visible, setVisible] = React.useState(false);
  const renderToggleButton = () => (
    <TouchableOpacity style={{width: 20, alignSelf: 'center'}} onPress={() => setVisible(true)}>
      <Ionicons name="ellipsis-vertical-outline" size={24} color="black" />
    </TouchableOpacity>
  );

  const renderIcon = (icon) => (
    <Ionicons name={icon} size={24} color={color.primary} />
  );
  
  const navigation = useNavigation()
  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >


    <View style={{height: Dimensions.get('window').height - 60}}>

      <View>
        { isIos ?
          <View style={{paddingTop: 20, backgroundColor: color.background}}>
              <StatusBar barStyle={props.statusBar || "dark-content"} /> 
          </View> :
          <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor={color.background}/> 
        } 
      </View>


      { props.showHeader ? 
        <View style={{marginVertical: 20}}>
            <View style={headerContainerStyle}>
                  
              { props.showBackButton && Platform.OS != "web" ? 
                  <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                    <Icon icon="back" style={{width:50, height:30}}></Icon>
                  </TouchableOpacity> 
                : <View style={{marginLeft: 15, width: 50}}></View>
              }
              <Text style={headerTitleStyle}>
                  {props.headerTitle}
              </Text>

              <View style={{width: 50, height: 30, alignSelf: 'center', alignContent: 'center', justifyContent: 'center'}}>
                { props.showMore ? 
                
                <OverflowMenu
                  anchor={renderToggleButton}
                  visible={visible}
                  placement="bottom start"
                  onSelect={(index)=> {props.onMoreSelect(index); setVisible(false)}}
                  onBackdropPress={() => setVisible(false)}>
                  <MenuItem title='Email' accessoryRight={()=>renderIcon('ios-mail')}/>
                  <MenuItem title='Download' accessoryRight={()=>renderIcon('md-download')}/>
                </OverflowMenu> : null} 
              </View>
            
            </View> 
            { props.subTitle ?
              <Text style={{textAlign: 'center', marginTop: 15, paddingHorizontal: 20}}>
              {props.subTitle}
              </Text> : null
            }
        </View> : <View></View>
      }

      <View style={[preset.outer, style, insetStyle]}>{props.children}
      </View>

      </View>
    </KeyboardAvoidingView>
  )
}



function ScreenWithoutScrollingFixed(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background }
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const [visible, setVisible] = React.useState(false);
  const renderToggleButton = () => (
    <TouchableOpacity style={{width: 20, alignSelf: 'center'}} onPress={() => setVisible(true)}>
      <Ionicons name="ellipsis-vertical-outline" size={24} color="black" />
    </TouchableOpacity>
  );

  const renderIcon = (icon) => (
    <Ionicons name={icon} size={24} color={color.primary} />
  );
  
  const navigation = useNavigation()
  return (
    
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

      <View style={{height: Dimensions.get('window').height}}>

        <View>
          { isIos ?
            <View style={{paddingTop: 20, backgroundColor: color.background}}>
                <StatusBar barStyle={props.statusBar || "dark-content"} /> 
            </View> :
            <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor={color.background}/> 
          } 
        </View>


        { props.showHeader ? 
          <View style={{marginVertical: 20}}>
              <View style={headerContainerStyle}>
                    
                { props.showBackButton && Platform.OS != "web" ? 
                    <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                      <Icon icon="back" style={{width:50, height:30}}></Icon>
                    </TouchableOpacity> 
                : <View style={{marginLeft: 15, width: 50}}></View>
              }
                <Text style={headerTitleStyle}>
                    {props.headerTitle}
                </Text>

                <View style={{width: 50, height: 30, alignSelf: 'center', alignContent: 'center', justifyContent: 'center'}}>
                  { props.showMore ? 
                    <OverflowMenu
                      anchor={renderToggleButton}
                      visible={visible}
                      placement="bottom start"
                      onSelect={(index)=> {props.onMoreSelect(index); setVisible(false)}}
                      onBackdropPress={() => setVisible(false)}>
                      <MenuItem title='Email' accessoryRight={()=>renderIcon('ios-mail')}/>
                      <MenuItem title='Download' accessoryRight={()=>renderIcon('md-download')}/>
                    </OverflowMenu> : null} 
                </View>
              
              </View> 
              { props.subTitle ?
                <Text style={{textAlign: 'center', marginTop: 15, paddingHorizontal: 20}}>
                {props.subTitle}
                </Text> : null
              }
          </View> : <View></View>
        }


        <View style={[preset.outer, style, insetStyle]}>{props.children}</View>

      </View>
    </KeyboardAvoidingView>
  )
}



function ScreenWithoutScrollingMobile(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background }
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const [visible, setVisible] = React.useState(false);
  const renderToggleButton = () => (
    <TouchableOpacity style={{width: 20, alignSelf: 'center'}} onPress={() => setVisible(true)}>
      <Ionicons name="ellipsis-vertical-outline" size={24} color="black" />
    </TouchableOpacity>
  );

  const renderIcon = (icon) => (
    <Ionicons name={icon} size={24} color={color.primary} />
  );
  
  const navigation = useNavigation()
  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >


      <View>
        { isIos ?
          <View style={{paddingTop: 20, backgroundColor: color.background}}>
              <StatusBar barStyle={props.statusBar || "dark-content"} /> 
          </View> :
          <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor={color.background}/> 
        } 
      </View>


      { props.showHeader ? 
        <View style={{marginVertical: 20}}>
            <View style={headerContainerStyle}>
                  
              { props.showBackButton && Platform.OS != "web" ? 
                  <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                    <Icon icon="back" style={{width:50, height:30}}></Icon>
                  </TouchableOpacity> 
                : <View style={{marginLeft: 15, width: 50}}></View>
              }
              <Text style={headerTitleStyle}>
                  {props.headerTitle}
              </Text>

              <View style={{width: 50, height: 30, alignSelf: 'center', alignContent: 'center', justifyContent: 'center'}}>
                { props.showMore ? 
                
                  <OverflowMenu
                    anchor={renderToggleButton}
                    visible={visible}
                    placement="bottom start"
                    onSelect={(index)=> {props.onMoreSelect(index); setVisible(false)}}
                    onBackdropPress={() => setVisible(false)}>
                    <MenuItem title='Email' accessoryRight={()=>renderIcon('ios-mail')}/>
                    <MenuItem title='Download' accessoryRight={()=>renderIcon('md-download')}/>
                  </OverflowMenu> : null} 
              </View>
            
            </View> 
            { props.subTitle ?
              <Text style={{textAlign: 'center', marginTop: 15, paddingHorizontal: 20}}>
              {props.subTitle}
              </Text> : null
            }
        </View> : <View></View>
      }

      <View style={[preset.outer, style, insetStyle]}>{props.children}
      </View>

    </KeyboardAvoidingView>
  )
}


function ScreenWithScrolling(props: ScreenProps) {
  const insets = useSafeArea()
  const preset = presets.scroll
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : { backgroundColor: color.background}
  const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const navigation = useNavigation()

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : null}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >

      <View>
        { isIos ?
            <View style={{paddingTop: 20, backgroundColor: color.background}}>
                <StatusBar barStyle={props.statusBar || "dark-content"} /> 
            </View> :
                <StatusBar barStyle={props.statusBar || "dark-content"} backgroundColor={color.background}/> 
          } 
      </View>


      { props.showHeader ? 
        <View style={{marginTop: 20, marginBottom: 10}}>
          <View style={headerContainerStyle}>
                  
              { props.showBackButton && Platform.OS != "web" ? 
                  <TouchableOpacity style={headerBackStyle} onPress={() => navigation.goBack()}>
                    <Icon icon="back" style={{width:30, height:30}}></Icon>
                  </TouchableOpacity> 
                : <View style={{marginLeft: 15, width: 50}}></View>
              }
              <Text style={headerTitleStyle}>
                  {props.headerTitle}
              </Text>

              <View style={{width: 30, height: 30}}></View>
            
            </View> 
            
            { props.subTitle ?
              <Text style={{textAlign: 'center', marginTop: 15, paddingHorizontal: 20}}>
                    {props.subTitle}
              </Text> : null
            }
        </View> : <View></View>
      }

      <View style={[preset.outer, backgroundStyle, insetStyle]}>
        <ScrollView
          style={[preset.outer, backgroundStyle]}
          contentContainerStyle={[preset.inner, style]}
        >
          {props.children}

        </ScrollView>
      </View>


    </KeyboardAvoidingView>
  )
}







function ScreenWithBoth(props: ScreenProps) {
  if (isNonScrolling(props.preset)) {
    if(Platform.OS != "web"){
      return <ScreenWithoutScrollingMobile {...props} />
    } else{
      return <ScreenWithoutScrolling {...props} />
    }
  } else if(props.preset == "fixedHeight"){
    if(Platform.OS != "web"){
      return <ScreenWithoutScrollingMobile {...props} />
    } else{
      return <ScreenWithoutScrollingFixed {...props} />
    }
  } 
  else {
    return <ScreenWithScrolling {...props} />
  }
}



function LoaderScreen(loader) {

  useEffect(()=>{
    setIsShowingLoader(loader)
  }, [loader])

  const [isShowingLoader, setIsShowingLoader] = useState(false);

  if (isShowingLoader) {
    return(
    <View style={{width: "100%", height: "100%", alignItems: "center", justifyContent: "center", backgroundColor: '#0D1F3C80'}}>
      <Image style={styles.loaderIcon} source={preloader}/>
    </View>
    );
  } else {
    return null;
  }
}

/**
 * The starting component on every screen in the app.
 *
 * @param props The screen props
 */
export function Screen(props: ScreenProps) {
  return( 
    <View style={{height: "100%"}}>

      <ScreenWithBoth {...props} />
      { props.loader ?
      <View style={{position: "absolute", height: "100%", width: "100%"}}>{LoaderScreen(props.loader)}</View> : 
      <View></View>
      }
      
    </View>
  )  
}

const styles = StyleSheet.create({
  loaderIcon: {
    width: 50,
    height: 50,
  },
});