import React, { FunctionComponent as Component, useEffect } from "react"
import { TextStyle, View, ViewStyle, ScrollView, TouchableOpacity, Share, Linking, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Text, Button } from "../../../components"
import { color } from "../../../theme"
import { Ionicons } from "@expo/vector-icons";
import { ApiOrders } from "../../../services/api/api-methods/api-orders";
import { useStores } from "../../../models";
import moment from "moment";
import { useNavigation } from "@react-navigation/core";
import { Toast } from "../../../components/toast/toast";
import { OrderUpdateCard } from "../components/order-update-card/order-update-card";
import { ImageUpload } from "../../../components/image-upload/image-upload";

const TITLE: TextStyle = {
    flex: 1,
    color: color.grey
}

const CONTENT: TextStyle = {
    flex: 1,
    fontWeight: '500'
}

const SUMMARY: ViewStyle = {
    flexDirection: 'row', 
    padding: 3
}


export const OrderDetailsScreen: Component = observer(function OrderDetailsScreen(props) {

    const navigation = useNavigation()
    const { orderDetailsStore, orderListStore, loaderStore, domainStore } = useStores()

    var orderId = props['route'].params['orderId']
    const [errorMessage, setErrorMessage] = React.useState("")

    const [showToast, setShowToast] = React.useState(false)
    const [toastMessage, setToastMessage] = React.useState("")
    const [toastType, setToastType] = React.useState('success')
  
    const [showUpdateCard, setShowUpdateCard] = React.useState(false)
    const [orderItemDetails, setOrderItemDetails] = React.useState(null)
    const [navBack, setNavBack] = React.useState(false)


    useEffect(()=>{
        getOrderDetails(orderId)
      }, [])

    const onSharePress = async () => {
    const gmapURL = "\n http://maps.google.com/maps?q=loc:" + orderDetailsStore.latitude + "," + orderDetailsStore.longitude + " ";
    const fullAddress = 
    (orderDetailsStore.userName ? orderDetailsStore.userName + " " : "") +
    (orderDetailsStore.userContact ? orderDetailsStore.userContact + "\n" : "") +
    (orderDetailsStore.addressLine1 ? orderDetailsStore.addressLine1 + ", " : "") +
    (orderDetailsStore.locality ? orderDetailsStore.locality + ", " : "") +
    (orderDetailsStore.landmark ? orderDetailsStore.landmark + ", " : "") +
    (orderDetailsStore.city ? orderDetailsStore.city + ", " : "") +
    (orderDetailsStore.state ? orderDetailsStore.state + ", " : "") +
    (orderDetailsStore.pinCode ? orderDetailsStore.pinCode + " " : "")

    const orderDetails = "Order# " + orderDetailsStore.orderRefNumber + "\n" + 
                            "Order Date " + moment(orderDetailsStore.date).format('DD MMM, YYYY hh:mm A') + "\n\n" 

    const shareOptions = {
        title: 'Location',
        message: orderDetails + fullAddress + gmapURL, // Note that according to the documentation at least one of "message" or "url" fields is required
        // url: gmapURL,
        subject: 'location'
        };

    // Share all
    if(Platform.OS != "web"){
        Share.share(shareOptions);
    } else {
        var canOpen = await Linking.canOpenURL('whatsapp://send?text=' + fullAddress + gmapURL)
        if(canOpen){
            await Linking.openURL('whatsapp://send?text=' + fullAddress + gmapURL);
        }
    }

    // Whatsapp Share
    // // Linking.openURL('whatsapp://send?text=msg&phone=91' + this.state.mobile_no);
    }
    
    const getOrderDetails = (orderId) => {
        const api = new ApiOrders()
        loaderStore.showLoader()
        setTimeout(() => {
            loaderStore.hideLoader()
        }, 7000);
        api.getOrderDetails(orderId).then(async res => {
            if(res['data']){
                orderDetailsStore.create(res['data'])
            }
            loaderStore.hideLoader()
        })
    }

    const uploadPoojaImage = (imageUrl) => {

        const api = new ApiOrders()
        api.uploadPoojaImage(imageUrl, orderDetailsStore.id).then(res => {
    
            if(res.kind == "ok"){
                orderDetailsStore.updateOrderDocumentUrl(res['data']['url'])
                setToastMessage("Successfully uploaded the image")
                setToastType('success')
            } else{
                orderDetailsStore.updateOrderDocumentUrl(orderDetailsStore.orderDocumenturl)
                setToastMessage("Failed to upload the image")
                setToastType('error')
            }
            setShowToast(true)
        })
    }

    const changeOrderStatusApi = (type) => {
        const api = new ApiOrders()
        const data = {
            orderIds: [orderId]
        }

        var orderAction = ''
        if(type == 'Pending'){
            orderAction = 'accept'
        } else if(type == 'PendingDelivery'){
            orderAction = 'complete'
        }  

        if(orderListStore.checkForTodaysOrder([orderId])){
            api.orderAction(data, orderAction).then(res => {

                if(res.kind == "ok"){
                    if(type == 'Pending'){
                        orderListStore.removePendingOrderIds([orderId])
                        setToastMessage("Successfully accepted the order")
                    } else if(type == 'PendingDelivery'){
                        orderListStore.removeAcceptedOrderIds([orderId])       
                        setToastMessage("Successfully completed the order")         
                    }  
                    setToastType('success')
                    setShowToast(true)
                    setNavBack(true)      
                    // navigation.goBack()
                } else {
                    setToastMessage("Failed to update the order")
                    setToastType('error')
                    setShowToast(true)
                    setNavBack(false)      
                }
            })

        } else {
            setErrorMessage(domainStore.inventoryName[0] + " with upcoming dates cannot be completed")
        }
    }


    const updatePoojaDate = (data) => {
        setNavBack(false)      
        loaderStore.showLoader()
        const api = new ApiOrders()
        api.updatePoojaDate(data).then(res => {
            loaderStore.hideLoader()
            if(res.kind == "ok"){
                setToastType('success')
                setToastMessage("Successfully updated the " + domainStore.inventoryName[3] + " date")   
                orderDetailsStore.updatePoojaDate(data.orderItemInfoId, data.orderdate)

                // orderListStore.updatePoojaDate(data.orderItemInfoId, data.orderdate)
                orderListStore.updateShowAllAccepted(true)
                getScheduledOrders()
                setShowToast(true)
            } else {
                setToastType('error')
                setToastMessage("Failed to update the " + domainStore.inventoryName[3] + " date")         
                setShowToast(true)
            }
        })
    }

    const getScheduledOrders = () => {
        const api = new ApiOrders()
        api.getPendingOrders('pendingDelivery').then(res => {
          orderListStore.createConfirmedList(res['data'])
        })
    }


    const StatusBadge = props => {

        var status = orderDetailsStore.status == "PendingDelivery" ? "Accepted" 
        : orderDetailsStore.status == "Pending" ? "Payment Complete"
        : orderDetailsStore.status == "PendingApproval" ? "Pending Approval" 
        : orderDetailsStore.status == "PendingPayment" ? "Pending Payment"
        : "Completed"

        if(status == 'Payment Complete'){
            return <Text style={{backgroundColor: color.blueStatusBg, color: color.blueStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
        } else if (status == "Accepted") {
            return <Text style={{backgroundColor: color.yellowStatusBg, color: color.yellowStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
        } else if (status == "Completed") {
            return <Text style={{backgroundColor: color.greenStatusBg, color: color.greenStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
        } else if (status == "Pending Approval") {
            return <Text style={{backgroundColor: color.redStatusBg, color: color.redStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
        } else {
            return <Text style={{backgroundColor: color.purpleStatusBg, color: color.purpleStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
        }
    }
    
    const DeliveryBadge = props => {

        if(props.type == 'RequireDelivery'){
            return <Text style={{backgroundColor: '#FFD9CD', color: '#F54237', borderRadius: 10, fontSize: 12, fontWeight: '700', padding: 2,  ...props.style}}>{"  Delivery  "}</Text>;
        } else if (props.type == "SelfCollection") {
            return <Text style={{backgroundColor: '#FFD9CD', color: '#F54237', borderRadius: 10, fontSize: 12, fontWeight: '700', padding: 2,  ...props.style}}>{"  Pick Up  "}</Text>;
        } else {
            return <View></View>
        }
    }

    const updateOrderItemStatus = (itemId, status) => {
        if(orderDetailsStore.status == "PendingApproval") {
            orderDetailsStore.updateOrderItemsStatus(itemId, status)
        }
    }

    const updatePendingStatusApi = () => {
        const api = new ApiOrders()
        let acceptedOrderIds = []
        let rejectedOrderIds = []

        orderDetailsStore.items.map(item => {
            if(item.enquiryStatus == 'Approved') {
                acceptedOrderIds.push(item.id)
            }
            if (item.enquiryStatus == 'Rejected') {
                rejectedOrderIds.push(item.id)
            }
        })

        const data = {
            orderInfoId: orderId,
            acceptedOrderIds: acceptedOrderIds,
            rejectedOrderIds: rejectedOrderIds
        }

        if(orderDetailsStore.items.length == (acceptedOrderIds.length + rejectedOrderIds.length)){
            api.approveOrderItems(data).then(res => {
                if(res.kind == "ok"){
                    orderListStore.removePendingApprovalOrderIds([orderId])
                    setToastType('success')
                    setShowToast(true)
                    setNavBack(true)      
                } else {
                    setToastMessage("Failed to update the pending order item")
                    setToastType('error')
                    setShowToast(true)
                    setNavBack(false)      
                }
            })
        } else {
            setErrorMessage("Please approve or reject the order items")
        }
    }

    return (
      <Screen preset="fixedHeight" headerTitle={domainStore.inventoryName[3] + " Details"} showHeader={true} showBackButton={true} loader={loaderStore.loader}>

        { showToast ?
            <Toast 
                display={showToast} 
                close={()=>{navBack? navigation.goBack() : null;setShowToast(false)}} 
                message={toastMessage}
                type={toastType}
            ></Toast> : null
        }

        { showUpdateCard ?
            <OrderUpdateCard 
                itemDetails={orderItemDetails}
                display={showUpdateCard}
                confirm={(data)=> {updatePoojaDate(data); setShowUpdateCard(false)}}
                cancel={()=>setShowUpdateCard(false)} 
            ></OrderUpdateCard> : null 
        }


        <View style={{backgroundColor: color.white, borderTopLeftRadius: 20, borderTopRightRadius: 20, marginTop: 10, flex: 1}}>
            { !loaderStore.loader ?
                <ScrollView>

                    <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15, marginTop: 20, padding: 10, borderRadius: 20}}>
                        <Text style={{paddingTop: 5, marginBottom:10, fontWeight: 'bold', alignSelf: 'center'}}>Summary</Text>
                        <View style={SUMMARY}>
                                <Text style={TITLE}>Receipt #</Text>
                                <Text style={CONTENT}>{orderDetailsStore.orderRefNumber}</Text>
                        </View>
                        <View style={SUMMARY}>
                                <Text style={TITLE}>Order Date</Text>
                                <Text style={CONTENT}>{moment(orderDetailsStore.date).format('DD MMM, YYYY hh:mm A')}</Text>
                        </View>
                        <View style={SUMMARY}>
                                <Text style={TITLE}>Status</Text>
                                <View style={{flex: 1}}>
                                    <StatusBadge style={{  alignSelf: 'flex-start', paddingHorizontal: 8}} ></StatusBadge>
                                </View>
                        </View>
                        {/* <View style={SUMMARY}>
                                <Text style={titleStyle}>Order Delivery Status</Text>
                                <Text style={contentStyle}>Delivery not applicable</Text>
                        </View> */}
                        <View style={SUMMARY}>
                                <Text style={TITLE}>{domainStore.inventoryName[5] + " Name"}</Text>
                                <Text style={CONTENT}>{orderDetailsStore.userName}</Text>
                        </View>
                        <View style={SUMMARY}>
                                <Text style={TITLE}>Contact Number</Text>
                                <Text style={CONTENT}>{orderDetailsStore.userContact}</Text>
                        </View>
                        {/* <View style={summaryView}>
                                <Text style={titleStyle}>Total Items</Text>
                                <Text style={contentStyle}>{orderDetailsStore.itemsCount}</Text>
                        </View>
                        <View style={summaryView}>
                                <Text style={titleStyle}>Total Amount</Text>
                                <Text style={[contentStyle, {fontWeight: 'bold'}]}>₹ {orderDetailsStore.amount}</Text>
                        </View> */}
                        <View style={SUMMARY}>
                                <Text style={TITLE}>Payment Status</Text>
                                <View style={{flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                                    <Text style={[{color: orderDetailsStore.paymentStatus == 'Completed' ? color.green: color.red, 
                                        marginRight: 5, fontWeight: 'bold'}]}>
                                        {orderDetailsStore.paymentStatus.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                    </Text>
                                    <Ionicons name="md-checkmark-circle-outline" size={16} 
                                    color={orderDetailsStore.paymentStatus == 'Completed' ? color.green: color.red} />
                                </View>
                        </View>
                    </View>


                    <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15, padding: 10, borderRadius: 20}}>
                        <Text style={{paddingTop: 5, marginBottom:10, fontWeight: 'bold', alignSelf: 'center'}}>{domainStore.inventoryName[3] + " Details"}</Text>
                        
                        <View>
                            { orderDetailsStore.items.map((item, index)=> 
                            <View key={index} style={{padding: 3}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{fontWeight: 'bold'}}>{item.name}</Text>
                                        <Text style={{fontWeight: 'bold'}}>₹ {item.amount}</Text>
                                    </View>
                                    <View>
                                        <Text style={{paddingBottom: 3}}>Category: {item.categoryName}</Text>
                                    </View>
                                    { item.customValues && item.customValues.map((customFields, index)=>
                                        <View key={index} style={{flexDirection: 'row'}}>
                                            <Text>{customFields.fieldName}: </Text>
                                            <Text>{customFields.fieldValue}</Text>
                                        </View>   
                                    )}

                                    { item.deliveryType != "NoDelivery" ? 
                                        <DeliveryBadge type={item.deliveryType} style={{  alignSelf: 'flex-start', paddingHorizontal: 8, marginVertical: 5}} ></DeliveryBadge>: null
                                    }


                                    <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <View style={{flexDirection: 'row'}}>
                                            <Text>{domainStore.inventoryName[3] + " Date:"}</Text>
                                            <Text>{moment(item.date).format('DD MMM, YYYY')}</Text>
                                        </View>
                                        { orderDetailsStore.status == "PendingDelivery" && item.enquiryStatus != 'Rejected' ? 
                                            <TouchableOpacity onPress={()=> {
                                                    setOrderItemDetails(item); 
                                                    setShowUpdateCard(true)}
                                                }>
                                                <Text style={{color: color.link, fontWeight: 'bold'}}>Change</Text>
                                            </TouchableOpacity> : null
                                        }
                                    </View>

                                    { item.isEnquireAndPay ?
                                        <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 5}}>
                                            <Button
                                                preset={item.enquiryStatus == "Approved"? "approve_selected" : "approve_unselected"}
                                                style={{margin: 10, alignSelf: 'center'}}
                                                text="Approve"
                                                onPress={()=>updateOrderItemStatus(item.id, 'Approved')}/> 
                                            <Button
                                                preset={item.enquiryStatus == "Rejected"? "reject_selected" : "reject_unselected"}
                                                style={{margin: 10, alignSelf: 'center'}}
                                                text="Reject"
                                                onPress={()=>updateOrderItemStatus(item.id, 'Rejected')}/> 

                                        </View> : null 
                                    }

                                    { index < orderDetailsStore.items.length -1  ?
                                        <View style={{width: '100%', height: 1, backgroundColor: color.white, marginTop: 10, marginBottom:0}}></View>
                                        : <View></View>
                                    }
                                </View>
                                
                            )}
                        </View>

                    </View>


                    { orderDetailsStore.addressLine1 ?
                        <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15,  padding: 10, borderRadius: 20}}>
                        
                        <Text style={{paddingTop: 5, marginBottom:10, fontWeight: 'bold', alignSelf: 'center'}}>Delivery Details</Text>
                        <View style={{padding: 3}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    {/* <Text style={{paddingVertical: 2}}>Payment Reference</Text> */}
                                    <Text>{orderDetailsStore.addressLine1}</Text>
                                </View>

                                { orderDetailsStore.city ?
                                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{paddingVertical: 2}}>City</Text>
                                        <Text>{orderDetailsStore.city}</Text>
                                    </View> : null
                                }

                                { orderDetailsStore.landmark ?
                                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{paddingVertical: 2}}>Landmark</Text>
                                        <Text>{orderDetailsStore.landmark}</Text>
                                    </View> : null
                                }

                                { orderDetailsStore.pinCode ?
                                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{paddingVertical: 2}}>Pincode</Text>
                                        <Text>{orderDetailsStore.pinCode}</Text>
                                    </View> : null
                                }

                                { orderDetailsStore.state ?
                                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{paddingVertical: 2}}>State</Text>
                                        <Text>{orderDetailsStore.state}</Text>
                                    </View> : null
                                }

                                <View style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"flex-end", marginTop: 3}}>
                                    <TouchableOpacity 
                                        style={{display:"flex", flexDirection:"row"}}
                                        onPress={onSharePress}
                                        >
                                        <Ionicons name="location-outline" size={20} color={color.primary}/>
                                        <Text style={{color:color.primary}}>Share Location</Text>

                                    </TouchableOpacity>
                                </View>

                        </View>

                        </View> : null
                    }

                    <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15,  padding: 10, borderRadius: 20}}>
                    <Text style={{paddingTop: 5, marginBottom:10, fontWeight: 'bold', alignSelf: 'center'}}>Items and Total</Text>
                    <View style={{padding: 3}}>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Text style={{paddingVertical: 2}}>Items</Text>
                                <Text>{orderDetailsStore.itemsCount}</Text>
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Text style={{paddingVertical: 2}}>Item Total</Text>
                                <Text>₹ {orderDetailsStore.amount - orderDetailsStore.deliveryCharge}</Text>
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Text style={{paddingVertical: 2}}>Delivery Fee</Text>
                                <Text>₹ {orderDetailsStore.deliveryCharge}</Text>
                            </View>
                            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Text style={{fontWeight: 'bold', paddingVertical: 2}}>Total Amount</Text>
                                <Text style={{fontWeight: 'bold'}}>₹ {orderDetailsStore.amount}</Text>
                            </View>
                    </View>

                    </View>


                    <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15,  padding: 10, borderRadius: 20}}>
                    
                        <Text style={{paddingTop: 5, marginBottom:10, fontWeight: 'bold', alignSelf: 'center'}}>Payment Details</Text>
                        <View style={{padding: 3}}>
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Text style={{paddingVertical: 2}}>Payment Reference</Text>
                                    <Text>{orderDetailsStore.payment.referenceNumber}</Text>
                                </View>
                                    {/* <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <Text style={{paddingVertical: 2}}>Payment Amount</Text>
                                        <Text>₹ {orderDetailsStore.payment.amount}</Text>
                                    </View> */}
                                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Text style={{paddingVertical: 2}}>Payment Mode</Text>
                                    <Text>Online Payment</Text>
                                </View>
                                {/* <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Text style={{paddingVertical: 2}}>Payment Status</Text>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Text style={[{color: color.green, marginRight: 5, fontWeight: 'bold'}]}>Paid</Text>
                                        <Ionicons name="md-checkmark-circle-outline" size={16} color={color.green} />
                                    </View>
                                </View> */}
                        </View>

                    </View>

                    { orderDetailsStore.status == 'Completed' || orderDetailsStore.status == 'PendingDelivery' ?
                        <View style={{backgroundColor: color.background, marginHorizontal: 15, marginBottom: 15,  padding: 10, borderRadius: 20}}>
                            <ImageUpload defaultImageUrl={orderDetailsStore.orderDocumenturl} getImage={x=> {uploadPoojaImage(x)}}></ImageUpload> 
                        </View>: 
                        null
                    }


                    { orderDetailsStore.status == 'Pending' ||  orderDetailsStore.status == 'PendingDelivery' 
                        || orderDetailsStore.status == 'PendingApproval' ?
                        
                        <View style={{flexDirection: 'column', justifyContent: 'center',  marginBottom: 10}}>        

                            { errorMessage ? 
                                <Text style= {{textAlign: 'center', fontSize: 12, color: color.red}}>
                                    {errorMessage}
                                </Text> : null
                            }

                            <Button
                                preset="primary"
                                style={{margin: 10, alignSelf: 'center'}}
                                text={orderDetailsStore.status == 'PendingApproval'? "Approve" : "Pending" ? "Accept" : "Complete"}
                                onPress={()=>orderDetailsStore.status == 'PendingApproval'? updatePendingStatusApi() 
                                    : changeOrderStatusApi(orderDetailsStore.status)}/> 

                        </View> : null
                    }

                </ScrollView>
                : null 
            }
        </View>

      </Screen>
  )
})
