import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, StyleSheet, TouchableOpacity } from "react-native"
import { spacing, color, styles as mainStyles } from "../../theme"
import DatePicker from 'react-native-datepicker'
import { Text } from "../text/text"
import moment from 'moment';
import { TopTabProps } from "./top-tab.props";

// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall,
  flexDirection: 'row',
  justifyContent: 'space-around',
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const TopTab: Component<TopTabProps> = props => {
  const {
    defaultTab = 0,
    labels,
    onClick
  } = props


  const [selectedTab, setSelectedTab] = React.useState(0)
    
  useEffect(()=>{
    setSelectedTab(defaultTab)
  }, [])
  

  const onTabClicked = (index)=>{
    setSelectedTab(index)
    onClick(index)
  }

  return (
    <View style={{ ...ROOT }}>
        {labels.map((item, index)=>
            <TouchableOpacity key={index} onPress={()=>onTabClicked(index)} style={{flexGrow: 1}}>
                <View style={{flexDirection: 'row', alignSelf: 'center', paddingBottom: 10}}>
                  <Text style={{marginHorizontal: 5}}>{item}</Text>
                  {/* <Text>3</Text> */}
                </View>
                <View style={{
                    height: 4, 
                    backgroundColor: index==selectedTab ? color.primary: '#E0E9F8', 
                    flexGrow: 1, 
                    // borderRadius: index==selectedTab ? 10: 0
                    }}></View>
            </TouchableOpacity>
        )}
    </View>
    
  )
}


