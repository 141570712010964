import { ViewStyle, TextStyle } from "react-native"
import { color, spacing } from "../../theme"

/**
 * All text will start off looking like this.
 */
const BASE_VIEW: ViewStyle = {
  paddingVertical: spacing.xSmall,
  paddingHorizontal: spacing.xSmall,
  borderRadius: 23,
  justifyContent: "center",
  alignItems: "center",
  height: 46,
  minWidth: 200
}

const BASE_TEXT: TextStyle = {
  paddingHorizontal: spacing.small,
}

const BASE_VIEW_SMALL: ViewStyle = {
  paddingVertical: spacing.xSmall,
  paddingHorizontal: spacing.xSmall,
  borderRadius: 20,
  justifyContent: "center",
  alignItems: "center",
  height: 40,
  minWidth: 100
}


/**
 * All the variations of text styling within the app.
 *
 * You want to customize these to whatever you need in your app.
 */
export const viewPresets = {
  /**
   * A smaller piece of secondard information.
   */
  primary: { ...BASE_VIEW, backgroundColor: color.primary } as ViewStyle,

  primary_sm: { ...BASE_VIEW_SMALL, backgroundColor: color.primary } as ViewStyle,

  secondary: { ...BASE_VIEW, backgroundColor: color.transparent, borderWidth: 1, borderColor: color.primary} as ViewStyle,

  secondary_sm: { ...BASE_VIEW_SMALL, backgroundColor: color.transparent, borderWidth: 1, borderColor: color.primary} as ViewStyle,

  approve_selected: { ...BASE_VIEW_SMALL, backgroundColor: color.green, borderWidth: 1, borderColor: color.green} as ViewStyle,

  approve_unselected: { ...BASE_VIEW_SMALL, backgroundColor: color.transparent, borderWidth: 1, borderColor: color.green} as ViewStyle,

  reject_selected: { ...BASE_VIEW_SMALL, backgroundColor: color.red, borderWidth: 1, borderColor: color.red} as ViewStyle,

  reject_unselected: { ...BASE_VIEW_SMALL, backgroundColor: color.transparent, borderWidth: 1, borderColor: color.red} as ViewStyle,

  /**
   * A button without extras.
   */
  link: {
    ...BASE_VIEW,
    paddingHorizontal: 0,
    paddingVertical: 0,
    alignItems: "flex-start",
  } as ViewStyle,


}

export const textPresets = {
  
  primary: { ...BASE_TEXT, fontSize: 17, color: color.palette.white } as TextStyle,

  primary_sm: { ...BASE_TEXT, fontSize: 15, color: color.palette.white } as TextStyle,

  secondary: { ...BASE_TEXT, fontSize: 17, color: color.primary} as TextStyle,

  secondary_sm: { ...BASE_TEXT, fontSize: 15, color: color.primary} as TextStyle,

  approve_selected: { ...BASE_TEXT, fontSize: 15, color: color.white} as TextStyle,

  approve_unselected: { ...BASE_TEXT, fontSize: 15, color: color.green} as TextStyle,

  reject_selected: { ...BASE_TEXT, fontSize: 15, color: color.white} as TextStyle,

  reject_unselected: { ...BASE_TEXT, fontSize: 15, color: color.red} as TextStyle,


  link: {
    ...BASE_TEXT,
    color: color.text,
    paddingHorizontal: 0,
    paddingVertical: 0,
  } as TextStyle,
}

/**
 * A list of preset names.
 */
export type ButtonPresetNames = keyof typeof viewPresets
