import React, { FunctionComponent as Component, useEffect } from "react"
import { Image, View, ViewStyle, Dimensions, Keyboard, Platform, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField } from "../../components"
import { color } from "../../theme"
import { ApiSignup, ApiSignin } from "../../services/api"
import { useStores } from "../../models";
import { mobileValidate } from "../../utils/validation/mobile-validation";
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
const appConfig = require('../../../app.json');

export const MobileNumber: Component = observer(function MobileNumber() {

    const { registerBusinessStore, loaderStore, userStore } = useStores()
    const [showError, setShowError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("Enter a valid phone number")
    const [isKeyboardView, setIsKeyboardView] = React.useState(false)

    // load image
    const img = require('../../../assets/mobilenumber.png')

    // navigations
    const navigation = useNavigation()
    const nextScreen = () => navigation.navigate("verifymobilenumber")


    useEffect(()=>{
        loaderStore.hideLoader()
        registerBusinessStore.updatePhoneNumber(null)
        if(Platform.OS != 'web'){
            registerForPushNotificationsAsync()
        } 
    }, [])

    // API calls
    const sendOtp = () => {

        if(mobileValidate(registerBusinessStore.phoneNumber)){
            setShowError(false)
            loaderStore.showLoader()
            if (userStore.isSignUp) {
                registerOtpApi()
            } else {
                signInOtp()
            }
        } else {
            setShowError(true)
        }
    }
    
    const registerOtpApi = () => {
        const api = new ApiSignup()
        api.getRegisterOtp(registerBusinessStore.phoneNumber).then(res => { 
            loaderStore.hideLoader()
            responseWork(res)
        })
    }

    const signInOtp = () => {
        const api = new ApiSignin()
        api.getSignInOtp(registerBusinessStore.phoneNumber).then(res => {
            loaderStore.hideLoader()
            if(res.kind == "ok"){
                responseWork(res)
            } else {
                setShowError(true)
                setErrorMessage(res.data['message'])
            }
        })
    }

    const responseWork = (res) => {

        if (res["kind"] == "ok") {
            registerBusinessStore.updateOtpReferenceId(res["data"]["otpReferenceId"])
            nextScreen()
        } else {
            setErrorMessage(res['data']['message'])
            setShowError(true)
        }
    }


    useEffect(() => {
        let keyboardDidShow = Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
        let keyboardDidHide = Keyboard.addListener("keyboardDidHide", _keyboardDidHide);
    
        // cleanup function
        return () => {
          keyboardDidShow?.remove()
          keyboardDidHide?.remove()
          // Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
          // Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
        };
      }, []);
  
    const _keyboardDidShow = () => {
      setIsKeyboardView(true)
    };
  
    const _keyboardDidHide = () => {
      setIsKeyboardView(false)
    };


    const registerForPushNotificationsAsync = async () => {

        if (Platform.OS === 'android') {
            Notifications.setNotificationChannelAsync('default', {
              name: 'default',
              importance: Notifications.AndroidImportance.MAX,
              vibrationPattern: [0, 250, 250, 250],
              lightColor: '#FF231F7C',
            });
        }

        if (Device.isDevice) {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
              const { status } = await Notifications.requestPermissionsAsync();
              finalStatus = status;
            }
            if (finalStatus !== 'granted') {
              console.log('Failed to get push token for push notification!');
              return;
            }
            const projectId = appConfig?.expo?.extra?.eas?.projectId;
            await Notifications.getExpoPushTokenAsync({projectId}).then(token=> {
                registerBusinessStore.updatePushToken(token.data)
                registerBusinessStore.updateDeviceOs(Platform.OS)
            })
            .catch(err=>{console.log(err)});

        } else {
            console.log('Must use physical device for Push Notifications');
        }
    };
    

    return (
        <Screen preset="fixedHeight" headerTitle="Enter Mobile Number"  showHeader={true} showBackButton={true} loader={loaderStore.loader}>
            <View style={CONTAINER}>
                
                <View>
                </View>
                
                <Image source={img} style = {{
                        width: isKeyboardView && Dimensions.get('screen').height < 1000  ? 150: 330, 
                        height: isKeyboardView && Dimensions.get('screen').height < 1000 ? 100 : 230, margin: 20
                    }}
                ></Image>
                
                <View style={BOTTOM_CARD}>
                    <View style={{ width: "90%" }}>

                        <TextField
                            placeholder='Mobile Number'
                            getText={text => registerBusinessStore.updatePhoneNumber(text)}
                            keyboardType='phone-pad'>
                        </TextField>

                        { showError ?
                        <Text style={{color: color.ananthamOrange, alignSelf: 'center', fontSize: 13}}>{errorMessage}</Text> : <View></View>
                        }

                        <View style={{ paddingVertical: 20 }}>
                            <Text style={SUB_TITLE}>
                                We will send a One Time Passsword (OTP) to your Phone Number
                            </Text>
                        </View>
                    </View>
                    <Button
                        preset="primary"
                        style={{ marginVertical: 20}}
                        text="Send OTP"
                        onPress={sendOtp}
                    />
                </View>
                
            </View>
        </Screen>
    )
})


const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    justifyContent: "space-between",
    alignItems: "center",
}


const BOTTOM_CARD: ViewStyle = {
    width: "100%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingTop: 0,
    paddingBottom: 30,
    justifyContent: "space-around",
    alignItems: "center",
    bottom: 0
}



const SUB_TITLE: TextStyle = {
    width: "100%",
    color: color.text,
    textAlign: "center"
}

