import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

import { color, spacing } from '../theme';

export const styles = StyleSheet.create({
  title_container:{
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.black,
    paddingTop: 40,
    paddingVertical: 30,  
    width: "100%",
  },
  title_primary: {
    width: "100%",
    color: color.black,
    fontSize: 28,
    textAlign: "center",
    fontWeight: "bold" 
  },
  title_secondary: {
    width: "100%",
    color: color.white,
    fontSize: 26,
    textAlign: "center",
    fontWeight: "bold" 
  },
  sub_title: {
    width: "100%",
    color: color.text,
    textAlign: "center"
  } as TextStyle,

  bottom_card: {
    width: screenWidth,
    borderTopLeftRadius: spacing.xxLarge,
    borderTopRightRadius: spacing.xxLarge,
    backgroundColor: color.white,
    justifyContent: "space-around",
    padding: spacing.medium,
    alignItems: "center",
    bottom: 0,
  } as ViewStyle,
  
  TextInput: {
    height: 40, 
    fontSize: 24,
    borderColor: '#CFD2D8', 
    borderBottomWidth: 1,
    width: "90%"
  },
  label_lg: {
    fontSize: 14,
    color: color.dim,
    marginBottom: spacing.xSmall
  },
  label_sm: {
    fontSize: spacing.small,
    color: color.dim,
    marginBottom: spacing.xSmall
  },
  label_error: {
    fontSize: spacing.small,
    color: color.ananthamOrange,
    marginBottom: spacing.xSmall
  },
  label_link: {
    fontSize: spacing.small,
    color: color.primary,
    marginBottom: spacing.xSmall
  },
})
