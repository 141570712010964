import React, { FunctionComponent as Component, useEffect } from "react"
import { observer } from "mobx-react-lite"

import { ScrollView, View, ViewStyle, Platform } from 'react-native';
import { spacing, color } from "../../../theme";
import { Screen, Switch, TextField, Button, Dropdown, DayPicker, Text } from "../../../components";

import { ApiInventoryConfig } from "../../../services/api/api-methods/api-inventory-config";
import { ApiOperatingDaysConfig } from "../../../services/api/api-methods/api-operaring-days-config";
import { useStores } from "../../../models";
import { PopupMessage } from "../../../components/popup-message/popup-message";
import { TextFieldAndDropdown } from "../../../components/textfield-and-dropdown/textfield-and-dropdown";
import { ImageUpload } from "../../../components/image-upload/image-upload";
import { CustomFieldsPopup } from "../components/custom-fields-popup/custom-fields-popup";
import CustomFields from "../components/custom-fields/custom-fields";

var defaultsDays = {
  "Monday": false,
  "Tuesday": false,
  "Wednesday": false,
  "Thursday": false,
  "Friday": false,
  "Saturday": false,
  "Sunday": false,
}


export const InventoryDetailScreen: Component = observer(function AddInventoryScreen(props) {
  
  var inventoryData = props['route'].params

  const  {inventoryStore, inventoryViewStore, loaderStore, categoryInventoryLookupModel, operatingDaysStore, domainStore} = useStores()

  const [allOperatingDays, setAllOperatingDays] = React.useState({}) 
  const [showMessage, setShowMessage] = React.useState(false)
  const [showError, setShowError] = React.useState(false)

  const [responseStatus, setResponseStatus] = React.useState(false)

  const [newEntry, setNewEnty] = React.useState(false)
  const [popupMessage, setPopupMessage] = React.useState("")

  const [showCustomFieldPopup, setCustomFieldPopup] = React.useState({show: false, mode: null})
  const [customField, setCustomField] = React.useState()


  useEffect(()=>{
    getSupplierOperatingDays()
    getInventoryLookup()
    getOperatingDays()
  }, [])



  const getOperatingDays = () =>{
    const api = new ApiOperatingDaysConfig()
    api.getAllOperatingDays().then(res => {
      'statusCode' in res['data'] ?  delete res['data']['statusCode'] : null
      setAllOperatingDays(res['data'])
      fetchData(res['data'])
    })
  }

  const getSupplierOperatingDays = () => {
    const api = new ApiOperatingDaysConfig()
    api.getSupplierOperatingDays().then(res => {
      if(res.kind == "ok"){
        'statusCode' in res['data'] ?  delete res['data']['statusCode'] : null
        operatingDaysStore.updateSupplierDays(res['data'])
      }
    })
}


  const fetchData = (allOperatingDaysLoc) => {
    if(inventoryData['type'] == "update" && inventoryData["item"]["inventoryId"]){
      setNewEnty(false)
      const api = new ApiInventoryConfig()

      api.getItemsDetails(inventoryData["item"]["inventoryId"], domainStore.domainName[1]).then(res => {
        
        var results = res['data']

        categoryInventoryLookupModel.updateCategory(results['categoryId'])

        defaultsDays = {
          "Monday": false,
          "Tuesday": false,
          "Wednesday": false,
          "Thursday": false,
          "Friday": false,
          "Saturday": false,
          "Sunday": false,
        }

        var availability = []
        results['availability'].map((day)=>{
          defaultsDays[day] = true
          availability.push(Number(allOperatingDaysLoc[day]))
        })

        results['name'] = inventoryData["item"]["name"]
        results['shortName'] = inventoryData["item"]["name"]
        results['availablityBoolean'] = defaultsDays
        results['availablityIds'] = availability

        inventoryViewStore.create(results)

      })
    } else{
      setNewEnty(true)
    }
  }

  const onChangeDay = (days) => {
    var availability = []
    Object.keys(days).map((x)=>{
      if(days[x]==true){
        availability.push(Number(allOperatingDays[x]))
      }
    })
    inventoryViewStore.updateAvailabilityIds(availability)
    inventoryViewStore.updateAvailabilityBooleans(days)
  }


  const getInventoryLookup = () => {
    const api = new ApiInventoryConfig()
    api.getInventoriesLookup(domainStore.domainName[1]).then(res => {
      categoryInventoryLookupModel.addInvetories(res['data'])
    })
  }

  const saveData = () => {
    if(inventoryViewStore.checkMandatory()){
      loaderStore.showLoader()
      if(newEntry){
        addSupplierInventory(inventoryViewStore.getAddModel())
      } else {
        updateSupplierInventory(inventoryViewStore.getUpdateModel())
      }
    } else {
      setShowError(true)
    }
  }
  
  const addSupplierInventory = (inventory) => {

    const api = new ApiInventoryConfig()
    api.addInventory(inventory, domainStore.domainName[1]).then(res => {
      loaderStore.hideLoader()

      if(res.kind == "ok"){
        var category = categoryInventoryLookupModel.getCategory(inventory.categoryId)
        inventoryStore.addInventory(inventory, category['name'], res['data'])
        setResponseStatus(true)
        setPopupMessage("Successfully configured " + domainStore.inventoryName[3])
        setShowMessage(true)
      } else {
        setResponseStatus(false)
        setPopupMessage(domainStore.inventoryName[3] + " already exist")
        setShowMessage(true)
      }
    })
  }
  
  const updateSupplierInventory = (inventory) => {

    const api = new ApiInventoryConfig()
    api.updateInventory(inventory, domainStore.domainName[1]).then(res => {
        loaderStore.hideLoader()

        if(res.kind == "ok"){
          var category = categoryInventoryLookupModel.getCategory(inventory.categoryId)
          inventoryStore.updateInventory(inventory, category['name'])
          setResponseStatus(true)
          setPopupMessage("Successfully configured " + domainStore.inventoryName[3])
          setShowMessage(true)
        } else {
          setResponseStatus(false)
          setPopupMessage("Failed to update " + domainStore.inventoryName[3])
          setShowMessage(true)
        }
    })
  }


  const uploadImage = (imageUrl) => {
    const api = new ApiInventoryConfig()
    api.addInventoyImage(imageUrl, domainStore.domainName[1]).then(res => {
      if(res.kind == "ok"){
        inventoryViewStore.updateAvatarUri(res['data']['url'])
      }
    })
  }


  const deleteInventory = () => {
    const api = new ApiInventoryConfig()
    api.deleteInventory(inventoryViewStore.id, domainStore.domainName[0]).then(res => {
        loaderStore.hideLoader()
        if(res.kind == "ok"){
            setResponseStatus(res.data)
            if(res.data) {
              inventoryStore.deleteInventory(inventoryViewStore.inventoryId)
              setPopupMessage("Successfully deleted " + domainStore.inventoryName[3])
              setShowMessage(true)
            } else {
              setPopupMessage("Failed to delete the " + domainStore.inventoryName[3])
              setShowMessage(true)
            }
        } else {
          setResponseStatus(false)
          setPopupMessage("Failed to delete the " + domainStore.inventoryName[3])
          setShowMessage(true)
        }
    })
  }

  const updateCustomFields = (item, mode) => {
    if(mode == 'delete') {
      inventoryViewStore.removeFieldFromCustomDetails(item)
    } else {
      setCustomField(item)
      setCustomFieldPopup({show: true, mode})
    }
  }
  
  return (
      <Screen preset="fixedHeight" headerTitle={domainStore.inventoryName[3] + " Details"} 
              showHeader={true} subTitle={"Configure New " + domainStore.inventoryName[3]} 
              showBackButton={true} loader={loaderStore.loader}> 

        <View style={CONTAINER}>
            
            <PopupMessage 
              display={showMessage} 
              close={()=> { 
                setShowMessage(false);
                if(newEntry){
                  inventoryViewStore.reset();
                  setNewEnty(true)
                }
              }} 
              message={popupMessage}
              closeNavigationPath={{stack: 'home', screen: 'configurations'}}
              actionText="Add New"
              addAction={()=> {
                setShowMessage(false);
                // if(newEntry){
                  inventoryViewStore.reset();
                  setNewEnty(true)
                // }
              }}
              type={responseStatus ? "success": "error"}
            ></PopupMessage> 

            <CustomFieldsPopup
                mode={showCustomFieldPopup.mode}
                display={showCustomFieldPopup.show}
                close={()=>setCustomFieldPopup({show: false, mode: null})} 
                save={(data, mode)=> {
                  if(mode == 'add'){
                    inventoryViewStore.addFieldToCustomDetails(data)
                  } else {
                    inventoryViewStore.editFieldToCustomDetails(data)
                  }
                }}
                customDetails={customField}
            ></CustomFieldsPopup>

            <View style={BOTTOM_CARD}>

              <ScrollView style={SCROLL_VIEW}>

                  <Dropdown 
                      required={true} showError={showError}
                      defaultValue={inventoryViewStore.categoryId} 
                      dropdownList={newEntry ? categoryInventoryLookupModel.selectedCategories.filter(cat=>cat.isActive) :
                            categoryInventoryLookupModel.selectedCategories} 
                      title="Category" 
                      getSelectedItem={item => {
                        inventoryViewStore.updateCategoryId(item['id']);
                        inventoryViewStore.updateInventoryId(0);
                        categoryInventoryLookupModel.updateCategory(item['id'])
                        inventoryViewStore.updateCustomDetails(item['customDetails'])
                      }}
                  ></Dropdown>

                  <TextFieldAndDropdown title={domainStore.inventoryName[3]}  disabled={ newEntry ? false: true }  
                      type={ newEntry ? "both" : "textfield"} required={true} showError={showError} 
                      defaultValue={inventoryViewStore.name}  
                      dropdownList={categoryInventoryLookupModel.inventories} 
                      getSelectedItem={ item => {
                              if(typeof item == "string") { 
                                inventoryViewStore.updateName(item) 
                              } else {
                                inventoryViewStore.updateName(item['name']); 
                              }
                          }}
                          onChangeType={ item=>{}}
                  ></TextFieldAndDropdown>


                  {/* { domainStore.businessName == "Ellam Stores" ?
                    <Dropdown 
                        required={true} 
                        showError={showError} 
                        defaultValue={inventoryViewStore.foodType} 
                        dropdownList={[{id: "Veg", name: "Veg"},{id: "NonVeg", name: "Non Veg"}]} 
                        title="Food Type" 
                        getSelectedItem={item => {
                          inventoryViewStore.updateFoodType(item['id'])
                        }}
                    ></Dropdown> : null
                  }  */}

                  <TextField 
                    multiline={false} 
                    style={{maxHeight: 100}} 
                    defaultValue={inventoryViewStore.description} 
                    label="Description"  
                    getText={text => inventoryViewStore.updateDescription(text)}>
                  </TextField> 

                  <DayPicker fullDay={Platform.OS == "web" ? true: false} type="small" 
                            operatingDays={JSON.parse(JSON.stringify(operatingDaysStore.supplierDays))} 
                            days={JSON.parse(JSON.stringify(inventoryViewStore.availablityBoolean))} 
                            getDays={text => onChangeDay(text)} title="Availabilty"
                  ></DayPicker>

                  { domainStore.businessName == "Ellam Stores" ||  domainStore.businessName == "Parking" ?
                    <ImageUpload defaultImageUrl={inventoryViewStore.avatarUri} getImage={x=> {uploadImage(x)}}></ImageUpload> : null
                  }

                  <TextField 
                      required={true} 
                      showError={showError} 
                      defaultValue={inventoryViewStore.rate.toString()} 
                      label="Amount (Rs)"  getText={text => 
                      inventoryViewStore.updateRate(Number(text))} 
                      keyboardType="numeric">
                  </TextField> 

                  { domainStore.businessName == "Parking" ?
                  <TextField 
                      showError={showError} 
                      defaultValue={inventoryViewStore.discountAmount.toString()} 
                      label="Discount Amount (Rs)"  getText={text => 
                      inventoryViewStore.updateDiscountRate(Number(text))} 
                      keyboardType="numeric">
                  </TextField>   : null
                  }

                  { domainStore.businessName == "Ellam Stores" ?
                    <TextField 
                      defaultValue={inventoryViewStore.weight ? inventoryViewStore.weight.toString(): "0"} 
                      label="Weight (in Grams)"  
                      getText={text => inventoryViewStore.updateWeight(Number(text))} 
                      keyboardType="numeric">
                    </TextField>  : null
                  }

                  {/* { domainStore.businessName == "Ellam Stores" ?
                    <TextField 
                        defaultValue={inventoryViewStore.packingTime ? inventoryViewStore.packingTime.toString() : "0"} 
                        label="Packing Time (in Mins)"  
                        getText={text => inventoryViewStore.updatePackingTime(Number(text))} 
                        keyboardType="numeric">
                    </TextField> : null
                  } */}


                  <Switch 
                    value={inventoryViewStore.showToCustomer} 
                    onToggle={(value)=>inventoryViewStore.toggleShowToCustomer()} 
                    title={"Show "+ domainStore.inventoryName[3] + " to Customers"} 
                  ></Switch>

                  {/* <TextField multiline={true} defaultValue={inventoryViewStore.rate.toString()} label="Notes"  getText={text => inventoryViewStore.updateRate(Number(text))}></TextField>  */}

                  <Switch 
                    value={inventoryViewStore.useGroupCapacity} 
                    onToggle={(value)=>inventoryViewStore.toggleUseGroupCapacity()} 
                    title="Use Group Capacity"
                  ></Switch>

                  { !inventoryViewStore.useGroupCapacity ? 
                    <TextField 
                        required={true} 
                        showError={showError} 
                        defaultValue={inventoryViewStore.capacity.toString()} 
                        label="Capacity"  
                        getText={text => inventoryViewStore.updateCapacity(Number(text))} 
                        keyboardType="numeric">
                      </TextField> : null
                  }


                  { domainStore.businessName == "Ellam Stores" || domainStore.businessName == "Temple"?
                    <Switch 
                      value={inventoryViewStore.isDeliveryFeeApplicable} 
                      onToggle={(value)=>inventoryViewStore.toggleIsDeliveryFeeApplicable()} 
                      title={"Is delivery applicable to this " + domainStore.inventoryName[3]}
                    ></Switch> : null
                  }

                  <Switch 
                    value={inventoryViewStore.isEnquireAndPay} 
                    onToggle={(value)=>inventoryViewStore.toggleIsEnquireAndPay()} 
                    title={"Is Enquire and Pay"}
                  ></Switch> 

                  <CustomFields style={{padding: spacing.small, marginTop: 20}}
                    customDetails={inventoryViewStore.customDetails} 
                    action={(cf, mode)=> updateCustomFields(cf, mode)}
                  ></CustomFields>

                  <View style={{flexDirection:"row", marginTop: 30, marginBottom: Platform.OS =="web" ? 10: 50, alignSelf: "center"}}>
                    <Button
                      preset="primary_sm"
                      style={{marginRight: 10}}
                      onPress={saveData}
                      text="Save"/>

                    <Button
                      preset="secondary_sm"
                      text="Delete"
                      style={{marginLeft: 10}}
                      onPress={deleteInventory}/>
                  </View>

              </ScrollView>

            </View>

        </View>

      </Screen>
  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}

const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  minHeight: "60%",
  maxHeight: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  bottom: 0,
  marginTop: 0,
}


const SCROLL_VIEW: ViewStyle = {
  padding: spacing.large,
}


