import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { ApiGoogleServices } from '../../services/api/api-methods/google-services';
const { GOOGLE_MAPS_API_KEY_WEB } = require("../../config/env.js");

const containerStyle = {
  height: '400px',
  margin: '10px 10px'
};

const defaultLoc = {
  lat: 37.7749,
  lng: -122.4194
};

function GMapComponent({ onAddressChange, selectedAddress }) {
  const [map, setMap] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(defaultLoc);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [completeAddress, setCompleteAddress] = useState(null);
  const [addressComponents, setAddressComponents] = useState({
    pincode: null,
    locality: null,
    district: null,
    state: null,
    country: null
  });

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  useEffect(() => {
    // Check for location permission and ask if not granted
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      console.log("Permission state:", result.state);
      if (result.state === 'granted') {
        // Permission already granted, get current position
        getCurrentLocation();
      } else if (result.state === 'prompt') {
        // Prompt for permission
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const newPosition = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setCurrentPosition(newPosition);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error) => {
            console.error("Error getting the user's location", error);
            // Fallback to default location if geolocation fails
            setCurrentPosition(defaultLoc);
          }
        );
      } else if (result.state === 'denied') {
        // Permission denied, fallback to default location
        setCurrentPosition(defaultLoc);
      }
      result.onchange = () => {
        if (result.state === 'granted') {
          getCurrentLocation();
        } else {
          setCurrentPosition(defaultLoc);
        }
      };
    });
  }, []);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setCurrentPosition(newPosition);
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        console.error("Error getting the user's location", error);
        // Fallback to default location if geolocation fails
        setCurrentPosition(defaultLoc);
      }
    );
  };

  useEffect(() => {
    if (!selectedAddress.latitude && !selectedAddress.longitude) {
      // Use current position if no selected address
      console.log("No selected address, using current position...");
      getCurrentLocation();
    } else {
      // Set map to selected address coordinates if available
      console.log("Using selected address coordinates:", selectedAddress.latitude, selectedAddress.longitude);
      const newPosition = {
        lat: parseFloat(selectedAddress.latitude),
        lng: parseFloat(selectedAddress.longitude),
      };
      setCurrentPosition(newPosition);
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (map && currentPosition) {
      map.panTo(currentPosition);
      setReverseAddress(currentPosition.lat, currentPosition.lng);
    }
  }, [map, currentPosition]);

  const handleCurrentLocationClick = () => {
    getCurrentLocation();
  };

  const setReverseAddress = (latitude, longitude, updateStore = false) => {
    console.log("setReverseAddress called with:", latitude, longitude);
    const api = new ApiGoogleServices();
    api.getReverseLocationforWEB(latitude, longitude).then(res => {
      console.log("setReverseAddress res.kind:", res.kind);
      if (res.kind === "ok") {
        const addressComponents = getAddressComponents(res.data.results[0].address_components);
        setAddressComponents(addressComponents);
        const address = res.data.results.length && res.data.results[0].formatted_address ? res.data.results[0].formatted_address : "";
        setCompleteAddress(address);
        if (onAddressChange) {
          onAddressChange(address, addressComponents, latitude, longitude);
        }
      }
    }).catch(err => {
      console.error("Error fetching reverse location:", err);
    });
  };

  const getAddressComponents = (address_components) => {
    var pincode = null;
    var locality = null;
    var district = null;
    var state = null;
    var country = null;
    var is_locality_identified = false;

    address_components.forEach((element) => {
      if (element.long_name !== "Unnamed Road" && !is_locality_identified && !element.types.includes("street_number")) {
        locality = element.long_name;
        is_locality_identified = true;
      }
      if (element.types.includes("postal_code")) {
        pincode = element.long_name;
      } else if (element.types.includes("locality")) {
        locality = element.long_name;
      } else if (element.types.includes("administrative_area_level_1")) {
        state = element.long_name;
      } else if (element.types.includes("administrative_area_level_2")) {
        district = element.long_name;
      } else if (element.types.includes("country")) {
        country = element.long_name;
      }
    });

    return {
      pincode: pincode,
      locality: locality,
      district: district,
      state: state,
      country: country
    };
  };

  const addCurrentLocationButton = () => {
    if (!map) return;

    const controlButton = document.createElement('button');
    controlButton.style.backgroundColor = '#fff';
    controlButton.style.border = 'none';
    controlButton.style.padding = '8px';
    controlButton.style.borderRadius = '8%';
    controlButton.style.cursor = 'pointer';
    controlButton.style.margin = '0px 10px';
    controlButton.title = 'Current Location';

    const controlIcon = document.createElement('img');
    controlIcon.src = require('../../../assets/current_loc_btn.png');
    controlIcon.style.width = '24px'; // Adjust size as needed
    controlIcon.style.height = '24px'; // Adjust size as needed
    controlButton.appendChild(controlIcon);

    controlButton.addEventListener('click', handleCurrentLocationClick);

    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlButton);
  };

  const handleMapClick = (event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setCurrentPosition(newPosition);
    if (map) {
      map.panTo(newPosition);
    }
  };

  useEffect(() => {
    if (map) {
      addCurrentLocationButton();
      map.addListener('click', handleMapClick);
    }
    return () => {
      if (map) {
        window.google.maps.event.clearListeners(map, 'click');
      }
    };
  }, [map]);

  return (
    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY_WEB}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={currentPosition}
        zoom={18}
        onLoad={onLoad}
      >
        <Marker position={currentPosition} />
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(GMapComponent);
