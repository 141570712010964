import React, { FunctionComponent as Component, useEffect } from "react"
import { Image, View, ViewStyle, ImageStyle, TouchableOpacity } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Icon } from "../../components"
import { color, spacing, styles } from "../../theme"
import { useStores } from "../../models";
import { ApiBusinessServices } from "../../services/api/api-methods/api-business-services";
import { IconTypes } from "../../components/icon/icons";

export const ChooseYourBusiness: Component = observer(function ChooseYourBusiness() {

    const { businessTypesStore, registerBusinessStore } = useStores()

    const navigation = useNavigation()
    const nextScreen = (typeId) => navigation.navigate("businessdetails", {data: typeId})

    const getIcon = (name) => {
        var serviceName: IconTypes;
        name = name.replace(/\s+/g, '_').replace("&_", '').toLowerCase() + "_logo";
        serviceName = name
        return serviceName
    }

    const setBusiness = (service, clickActive) => {
        if(clickActive) {
            nextScreen(service.id);
            registerBusinessStore.updateBusinessTypeId(service.id)
            registerBusinessStore.updateBusinessTypeName(service.name)
        }
    }

    useEffect(() => {
        businessServicesApi()
    }, [])

    const businessServicesApi = () => {
        const api = new ApiBusinessServices()
        api.getBusinessTypes().then(res => {
            if (res["kind"] == "ok") {
                businessTypesStore.create(res['data'])
            }        
        })
    }


    function visibleBusinessServices(status = 'Visible', clickActive = 0) {
        const sorted = [...businessTypesStore.businessTypes].sort((a, b) => b['priority'] - a['priority'] ? 1:-1);
        return sorted.map((services, i) => {
            if (services.status == status) {
                return (
                    <TouchableOpacity key={i} style={{marginHorizontal: 40, marginVertical: 20}} onPress={()=> setBusiness(services, clickActive)}>
                        <View style={IMAGE_VIEW}>
                            <Icon icon={getIcon(services.name)} style={{width: spacing.xHuge, height: spacing.xHuge}}></Icon>
                        </View>
                        <Text  style={{paddingTop: 10, alignSelf: "center"}}>{services.name}</Text>
                    </TouchableOpacity>
                );
            } else {
                return null
            }
        });
    }


    return (
        <Screen preset="fixedHeight" headerTitle="Choose Your Business" showHeader={true} showBackButton={true}>

            <View style={CONTAINER}>
                <View>
                </View>
                <View style={styles.bottom_card}>
                    <Text preset="default" style={{padding: spacing.large, textAlign: 'center'}}>
                        Register your Business so that Customers can easily find you
                    </Text>
                    <View style={BOTTOM_CONTAINER}>
                        {visibleBusinessServices('Visible', 1)}
                    </View>
                    <View style={{marginTop: spacing.xLarge}}>
                        <Text preset="bold">Coming Soon</Text>
                    </View>
                    <View style={BOTTOM_CONTAINER}>
                        {visibleBusinessServices('ComingSoon', 0)}
                    </View>
                </View>
            </View>
        </Screen>
    )
})


const IMAGE_VIEW: ImageStyle = {
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    justifyContent: "space-between",
    alignItems: "center"
}


const BOTTOM_CONTAINER: ViewStyle = {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: "center",
    width: "100%"
}

