import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, ScrollView } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Screen, ItemCard } from "../../../components"
import { color } from "../../../theme"



export const TaxScreen: Component = observer(function TaxScreen() {

  const navigation = useNavigation()
  const navTaxDetail = () => navigation.navigate('configurationStack', {screen: 'tax_details'})

  return (
    <Screen preset="fixed" headerTitle="Configure Tax Details" showBackButton={true}> 


        <ScrollView style={{marginTop: 20}}>
          <View style={{paddingHorizontal: 20}}>
            <ItemCard cardText={'CGST'} isPublished={true} onCardPress={navTaxDetail}></ItemCard>
            <ItemCard cardText={'SGST'} isPublished={false} onCardPress={navTaxDetail}></ItemCard>
            <ItemCard cardText={'VAT'} isPublished={true} onCardPress={navTaxDetail}></ItemCard>
            <ItemCard cardText={'Flood Cess'} isPublished={false} onCardPress={navTaxDetail}></ItemCard>
            <ItemCard cardText={'Sales Tax'} isPublished={false} onCardPress={navTaxDetail}></ItemCard>
            <ItemCard cardText={'Other Taxes'} isPublished={false} onCardPress={navTaxDetail}></ItemCard>
          </View>
        </ScrollView>


        <View style={BOTTOM_CARD}>
            <View style={{width: "50%", margin:10, alignSelf: "center"}}>

              <Button
                  preset="primary"
                  onPress={navTaxDetail}
                  text="Add"/>

            </View>
        </View>

      </Screen>
  )
})



const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  paddingVertical: 10,
  bottom: 0,
  marginTop: 2,
}