import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, Text } from "react-native"
import { OrderUpdateCardProps } from "./order-update-card.props";
import { color, spacing } from "../../../../theme";
import { Button, TextField } from "../../../../components";
import moment from "moment";
import { DateField } from "../date-field/date-field";
import { Modal, Card } from "@ui-kitten/components";
import { useStores } from "../../../../models";

// static styles
const ROOT: ViewStyle = {
  paddingHorizontal: spacing.xSmall,
}

const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const OrderUpdateCard: Component<OrderUpdateCardProps> = props => {
  const {
    display,
    cancel,
    itemDetails,
    confirm
  } = props

  const { domainStore } = useStores()


  const [modalVisible, setModalVisibility] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [orderDate, setOrderDate] = useState();
  const [message, setMessage] = useState();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setModalVisibility(display)
    setOrderDate(null)
    setShowDetails(true)
  }, [display]);


  function proceedUpdate(){
    if(orderDate){
      setShowDetails(false)
      setShowError(false)
      setMessage(null)
    } else {
      setShowError(true)
    }
  }

  return (
    <View style={{ ...ROOT }}>
            
      <Modal visible={modalVisible}
        backdropStyle={BACKDROP}
        style={{padding: spacing.xxxLarge }}
        onBackdropPress={() => setModalVisibility(false)}>
        
            <Card>

              <View style={{backgroundColor: color.transparent}}></View>

              <View style={{backgroundColor: color.white,  width: "100%"}}>

                
                <View style={{marginHorizontal: 30}}>
                    <Text style={{alignContent: 'stretch', marginTop: 20, marginBottom: 15, 
                                    fontSize: 18, alignSelf: 'center', fontWeight: 'bold'}}>
                      {  "Update " + domainStore.inventoryName[3] + " Date"}  
                    </Text>
                    <Text style={{textAlign: 'center', color: color.grey, fontSize: 13}}>
                      {"Note: On proceeding " + domainStore.inventoryName[3] + " Date for this " + domainStore.inventoryName[3] 
                      + " will be updated and hence the order can only be completed once all " + domainStore.inventoryName[3] 
                      + " items are completed"}
                    </Text>
                </View>

                { itemDetails && showDetails ?
                
                    <View style={{marginHorizontal: 30}}>

                        <View style={{marginTop: 15}}>

                            <View style={{flexDirection: 'row', marginVertical: 2}}>
                                <Text style={{width: "50%", fontWeight: 'bold'}}>{domainStore.inventoryName[3] + " Name"} </Text>
                                <Text>{itemDetails.name}</Text>
                            </View>

                            <View style={{flexDirection: 'row', marginVertical: 2}}>
                                <Text style={{width: "50%", fontWeight: 'bold'}}>{domainStore.inventoryName[3] + " Amount"} </Text>
                                <Text>₹{itemDetails.amount} </Text>
                            </View>

                            { itemDetails.customValues && itemDetails.customValues.map((customFields, index)=>
                                <View key={index} style={{flexDirection: 'row', marginVertical: 2}}>
                                    <Text style={{width: "50%", fontWeight: 'bold'}}>{customFields.fieldName} </Text>
                                    <Text>{customFields.fieldValue}</Text>
                                </View>   
                            )}

                            <View style={{flexDirection: 'row', marginVertical: 2}}>
                                <Text style={{width: "50%", fontWeight: 'bold'}}>{"Current " + domainStore.inventoryName[3] + " Date"} </Text>
                                <Text>{moment(itemDetails.date).format('DD MMM, YYYY')}</Text>
                            </View>

                        </View>

                    </View> : null
                }

                { showDetails ?
                    <View style={{marginHorizontal: 20, marginTop: 15}}>
                        <DateField initialDate={itemDetails.date} required={true} showError={showError}  
                            title={"New " + domainStore.inventoryName[3] + " Date"} getDate={date=> {setOrderDate(date)}}></DateField>
                    </View>
                    :
                    <View style={{marginHorizontal: 20, marginTop: 15}}>
                        <TextField multiline={false} style={{maxHeight: 100}} label={"Remarks to " + domainStore.inventoryName[5]}  
                              getText={text => setMessage(text)}></TextField> 
                    </View>
                }

                <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 15}}>
                  <Button
                      preset="primary_sm"
                      style={{margin: 10, width: 130, height: 46, borderRadius: 23}}
                      text={showDetails ? "Proceed" : "Update"}
                      onPress={()=> {showDetails ? proceedUpdate(): confirm({orderdate: orderDate, message: message, orderItemInfoId: itemDetails.id})}}>
                  </Button>

                  <Button
                      preset="secondary_sm"
                      style={{margin: 10, width: 130, height: 46, borderRadius: 23}}
                      text={showDetails ? "Close" : "Back"}
                      onPress={()=> {showDetails ? cancel(true): setShowDetails(true)}}
                      >
                  </Button>
                </View>

              </View>

            </Card>
        </Modal>

    </View>
    
  )
}

