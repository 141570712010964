import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"
import { url } from "../api-list"


// models

import { load, save } from "../../../utils/storage";



// User signip
  

export class ApiSignin {

  async getSignInOtp(mobileNumber) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const data = {
      phoneNumber: mobileNumber,
      userType: "Business"
    }

    const response: ApiResponse<any> = await apiService.post(url.getSignInOtp, data)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return { kind: "error", data: response.data }
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async resendOtp(data) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const response: ApiResponse<any> = await apiService.post(url.resendOtp, data)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
 
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  
  async login(param) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()

    const response: ApiResponse<any> = await apiService.post(url.login, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data){

        save("isSignedIn", 1)
        save("accessToken", response.data['accessToken'])
        save("refreshToken", response.data['refreshToken'])
        save("deviceId", response.data['deviceId'])
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(response.data['accessToken']);
        save("businessId", decoded['business'])
        save("businessTypeId", decoded['businessType'])
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async deleteAcount() {

    const api = new Api()
    const apiService = await api.setHeader()
    const apiUrl = url.deleteAccount
    const response: ApiResponse<any> = await apiService.delete(apiUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      if (response.data) {
        return { kind: "ok", data: response.data }
      }
    }

    // transform the data into the format we are expecting
    try {

      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
}