import React, { FunctionComponent as Component, useEffect, } from "react"
import { TextStyle, View,  FlatList, TouchableOpacity, ViewStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Icon } from "../../../components"
import { color } from "../../../theme"
import { OrderCard } from "../components/order-card/order-card";
import { useStores } from "../../../models";
import { TopTab } from "../../../components/top-tab/top-tab";
import { DatePickerComponent } from "../../../components/date-picker/date-picker";
import { Toast } from "../../../components/toast/toast";



export const PendingOrdersScreen: Component = observer(function PendingOrdersScreen() {

    const navigation = useNavigation()
    const { orderListStore, loaderStore, domainStore } = useStores()

    const detailsScreen = (id) => navigation.navigate('order_details', {orderId: id})

    const [orders, setOrders] = React.useState([])
    const [status, setStatus] = React.useState('Pending Approval')


    const [showToast, setShowToast] = React.useState(false)
    const [toastMessage, setToastMessage] = React.useState("")
    const [toastType, setToastType] = React.useState('success')

    

    useEffect(()=>{
      loaderStore.hideLoader()
      if(orderListStore.showAllPendingApproval){
        orderListStore.showAllPendingApprovalOrders()
      } else {
        orderListStore.updateFilterPendingApprovalOrderDate(orderListStore.filterDate)
      }
      setOrders(orderListStore.filteredPendingApprovalOrderList)
    }, [])


    const onTabClicked = (index)=>{
      if(index == 0){
        setOrders(JSON.parse(JSON.stringify(orderListStore.filteredPendingApprovalOrderList)))
        setStatus('Pending Approval')
      } else {
        setOrders(JSON.parse(JSON.stringify(orderListStore.filteredPendingPaymentOrderList)))
        setStatus('Pending Payment')
      }
    }

    var renderItem = ({item}) => (<OrderCard status={status} forDelivery={item.delivery} 
        orderDetails={{id: item.orderRefNumber, name: item.userName, itemCount: item.items, amount: item.amount, date: item.date, 
            serviceDate: item.serviceDate, itemDetails: item.itemDetails}} onCardPress={()=>detailsScreen(item.id)}></OrderCard>);
 
    return (
      <Screen preset="fixedHeight" headerTitle={"Pending " + domainStore.inventoryName[0]} showBackButton={true} showHeader={true} showMore={true}>

        <View style={CONTAINER}>

          { showToast ?
            <Toast 
                  display={showToast} 
                  close={()=>setShowToast(false)} 
                  message={toastMessage}
                  type={toastType}
            ></Toast> : null 
        }

          <View style={{marginTop: 10, width: "75%", marginBottom: 10}}>
              <TopTab  labels={['Pending Approval', 'Pending Payment']} defaultTab={0} onClick={(index)=>onTabClicked(index)}></TopTab>
          </View>

          <View style={BOTTOM_CARD}>

              <View style={{marginTop: 15, marginBottom: 10, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'center'}}>

                <TouchableOpacity 
                    onPress={()=>{orderListStore.showAllPendingApprovalOrders(); status == "Pending Approval" ? onTabClicked(0) : onTabClicked(1)}} 
                    style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: orderListStore.showAllPendingApproval ? 
                    color.primary : color.background, width: 150, height: 40, borderRadius: 20}}>
                  <Text style={{textAlign: 'center', color: orderListStore.showAllPendingApproval ? color.white : color.primary}}>All</Text>
                </TouchableOpacity>
                
                <DatePickerComponent 
                    isSelected={orderListStore.showAllAccepted} 
                    initialDate={orderListStore.filterDate} 
                    getDate={(date)=>{
                        orderListStore.updateFilterPendingApprovalOrderDate(date); status == "Pending Approval" ? onTabClicked(0) : onTabClicked(1)
                    }}
                ></DatePickerComponent>
              
              </View>


              <View style={{flex: 1, marginBottom: 10}}>
                { orders.length ?
                    <View style={{flex: 1 }}>
                      <FlatList
                        data={orders}
                        renderItem={renderItem}
                        keyExtractor={item => item.orderRefNumber}
                      />
                    </View>
                  :
                  <View style={{alignSelf: 'center', alignItems: 'center', height: "100%", justifyContent: 'center'}}>
                    <Icon icon={domainStore.imagePrefix + "_inventory"} style={{width: 60, height: 60}}></Icon>
                    <Text style={{padding: 20}}>{"No New " + domainStore.inventoryName[0]}</Text>
                  </View>
                }

              </View>

          </View>
        
        </View>

      </Screen>
  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  backgroundColor: color.background,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  bottom: 0,
  flex: 1
}

