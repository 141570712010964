import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity } from "react-native"
import { HomeCardProps } from "./home-card.props"
import { spacing, color } from "../../../../theme"
import { Icon } from "../../../../components/icon/icon";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing.medium,
    alignItems: "center",
    paddingTop: spacing.large,
    paddingBottom: spacing.large,
}
const CARD_STYLE: ViewStyle = { 
    justifyContent: "center", 
    backgroundColor: color.white,    
    width: 100, 
    height: 100,
    alignItems: "center",
    borderRadius: 20,
    margin: spacing.small,
}
const CARD_TITLE: TextStyle = { 
    color: color.text
}

const CARD_CONTAINER: ViewStyle = {
    alignItems:'flex-end'
}

const INDICATOR_CIRCLE: ViewStyle = {
    width: 33,
    height: 33,
    borderRadius: 33/2,
    alignItems: "center",
    justifyContent: "center",
    position:'absolute',
}

const INDICATOR_TEXT: TextStyle = {
    color: color.white
}


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const HomeCard: Component<HomeCardProps> = props => {
  const {
    cardText,
    icon,
    onCardPress,
    indicatorCount,
    style,
    titleStyle,
    cardTheme
  } = props
  
//   const cardtext = cardText || (cardText && translate(cardText)) || ""

  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity style={{...CARD_CONTAINER}} onPress={onCardPress}>
            <View style={{...CARD_STYLE}}>
                <Icon icon={icon} style={{width: 50, height:50}}></Icon>
            </View>
            { indicatorCount > 0 ?  <View  style={{...INDICATOR_CIRCLE, backgroundColor: color.ananthamOrange}}>
                <Text style={{ ...INDICATOR_TEXT}}>{indicatorCount}</Text>
                </View>: <View></View>
            }
        </TouchableOpacity>
        <Text style= {{...CARD_TITLE, ...titleStyle}}>{cardText}</Text>
    </View>
    
  )
}
