import React from 'react';
import { View, Modal, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { clear } from '../../utils/storage';
import { useStores } from '../../models';
import { ApiSignin } from '../../services/api';

export const DeleteAccountPopUp = ({ setDeletePopup, deletePopUp }) => {
    var store = useStores()
    const { userStore } = useStores()

    const signOut = async () => {
        userStore.setAccessToken(null)
        store.resetStore()
        await clear()
    }
    function handleDeleteAccount(): void {
        const api = new ApiSignin()
        api.deleteAcount().then(res => {
            if (res.kind == "ok") {
                setDeletePopup(false);
                signOut();
            } else {
                // setResendMessage("Failed to resend OTP")
            }
        })
    }

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={deletePopUp}
            onRequestClose={() => setDeletePopup(false)}
        >
            <View style={styles.modalBackground}>
                <View style={styles.modalView}>
                    <Text style={styles.modalText}>Delete Account?</Text>
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity
                            style={[styles.button, styles.cancelButton]}
                            onPress={() => setDeletePopup(false)}
                        >
                            <Text style={styles.buttonText}>Cancel</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.button, styles.deleteButton]}
                            onPress={handleDeleteAccount}
                        >
                            <Text style={styles.buttonText}>Delete</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalBackground: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 35,
        margin: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold'
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
        width: '40%',
        alignItems: 'center',
    },
    cancelButton: {
        backgroundColor: 'lightblue',
    },
    deleteButton: {
        backgroundColor: 'red',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },
});

export default DeleteAccountPopUp;
