import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"

// models
import * as FileSystem from 'expo-file-system';
import { ApiConfig, DEFAULT_API_CONFIG } from "../api-config";
import * as MediaLibrary from 'expo-media-library';

import * as IntentLauncher from 'expo-intent-launcher';
import { Platform } from "react-native";


export class ApiOrders {

    /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }


  async getPendingOrders(status) {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.getOrders + status

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    } else {
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSearchOrders(searchText,limit,offset) {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.searchOrders 
                + searchText 
                + '/' + limit
                + '/' + offset
    

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getOrderDetails(orderId) {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.getOrderDetails.replace("{orderId}", orderId)

    const response: ApiResponse<any> = await apiService.get(apiUrl)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async orderAction(orderIds, type) {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.changeOrderStatus.replace('{type}', type) 

    const response: ApiResponse<any> = await apiService.post(apiUrl, orderIds)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async shareOrder(params) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.emailOrderList, params)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async emailDailyCollectionReport(params) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.emailDailyCollectionReport, params)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async emailSummaryReport(params) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.emailSummaryReport, params)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async downalodOrder(params) {
    const api = new Api()
    var from_date = null
    var to_date = null
    if(params.from != null){
      from_date = new Date(params.from).toISOString()
    }
    if(params.to != null){
      to_date = new Date(params.to).toISOString()
    } 

    var apiUrl = this.config.url + url.downloadOrderList + "?from=" + from_date + "&to=" + to_date + "&status=" + params.status

    var accessToken = "Bearer " + await api.getAccessToken()

    const callback = downloadProgress => {
      const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    };
    
    var timestamp = new Date().getTime();


    if (Platform.OS == "web"){
      
      return fetch(apiUrl,
      { 
              method: "GET",
              headers: { "accept": "*/*", "Content-Type": "application/json",'Authorization': accessToken},
        }).then(response => response.blob()).then(response => {
            var a = document.createElement("a");
            document.body.appendChild(a);

            if(response){

                var url = window.URL.createObjectURL(response);
                a.href = url;
                a.download = 'order_list' + timestamp + '.csv';
                a.click();
                window.URL.revokeObjectURL(url);
                return 'success'

            } else {
              return 'error'
            }

      })
    } else {

      const downloadResumable = FileSystem.createDownloadResumable(
        apiUrl,
        FileSystem.documentDirectory + 'order_list' + timestamp + '.xlsx',
        {
          headers: {
            "accept": "*/*",
            "Authorization": accessToken,
            "Content-Type": "application/json"
          }
        },
        callback
      );

      const { uri, status, headers } = await downloadResumable.downloadAsync();

      if(status == 200){
        FileSystem.getContentUriAsync(uri).then(cUri => {
          IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
            data: cUri,
            flags: 1,
          });
          MediaLibrary.saveToLibraryAsync(uri)
        });
        return 'success'
      } else {
        return 'error'
      }
    }
  
  }


  async downloadDailyCollectionReport(params) {

    const api = new Api()
    var from_date = null
    var to_date = null
    if(params.from != null){
      from_date = new Date(params.from).toISOString()
    }
    if(params.to != null){
      to_date = new Date(params.to).toISOString()
    } 

    var apiUrl = this.config.url + url.downloadDailyCollectionReport + "?from=" + from_date + "&to=" + to_date

    var accessToken = "Bearer " + await api.getAccessToken()

    const callback = downloadProgress => {
      const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    };
    
    var timestamp = new Date().getTime();

    if (Platform.OS == "web"){
      
        return fetch(apiUrl,
        { 
              method: "GET",
              headers: { "accept": "*/*", "Content-Type": "application/json",'Authorization': accessToken},
        }).then(response => response.blob()).then(response => {
            var a = document.createElement("a");
            document.body.appendChild(a);

            if(response){

                var url = window.URL.createObjectURL(response);
                a.href = url;
                a.download = 'daily_collection_report' + timestamp + '.csv';
                a.click();
                window.URL.revokeObjectURL(url);
                return 'success'

            } else {
              return 'error'
            }
  
      })
    } else {

      const downloadResumable = FileSystem.createDownloadResumable(
        apiUrl,
        FileSystem.documentDirectory + 'daily_collection_report' + timestamp + '.xlsx',
        {
          headers: {
            "accept": "*/*",
            "Authorization": accessToken,
            "Content-Type": "application/json"
          }
        },
        callback
      );

      const { uri, status, headers } = await downloadResumable.downloadAsync();

      if(status == 200){
        FileSystem.getContentUriAsync(uri).then(cUri => {
          IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
            data: cUri,
            flags: 1,
          });
          MediaLibrary.saveToLibraryAsync(uri)
          // Linking.openURL(cUri);
        });
        return 'success'
      } else {
        return 'error'
      }
    }
      
  }


  async downloadSummaryReport(params) {

    const api = new Api()
    var from_date = null
    var to_date = null
    if(params.from != null){
      from_date = new Date(params.from).toISOString()
    }
    if(params.to != null){
      to_date = new Date(params.to).toISOString()
    } 

    var apiUrl = this.config.url + url.downloadSummaryReport + "?from=" + from_date + "&to=" + to_date

    var accessToken = "Bearer " + await api.getAccessToken()

    const callback = downloadProgress => {
      const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    };


    var timestamp = new Date().getTime();


    if (Platform.OS == "web"){

      return fetch(apiUrl,
          { 
              method: "GET",
              headers: { "accept": "*/*", "Content-Type": "application/json",'Authorization': accessToken},
              // body:data
      }).then(response => response.blob()).then(response => {
            var a = document.createElement("a");
            document.body.appendChild(a);

            if(response){

                var url = window.URL.createObjectURL(response);
                a.href = url;
                a.download = 'summary_report' + timestamp + '.csv';
                a.click();
                window.URL.revokeObjectURL(url);
                return 'success'

            } else {
              return 'error'
            }
   

      })
    } else {

        const downloadResumable = FileSystem.createDownloadResumable(
          apiUrl,
          FileSystem.documentDirectory + 'summary_report' + timestamp + '.xlsx',
          {
            headers: {
              "accept": "*/*",
              "Authorization": accessToken,
              "Content-Type": "application/json"
            }
          },
          callback
        );
    
        const { uri, status, headers } = await downloadResumable.downloadAsync();
    
        if(status == 200){
          FileSystem.getContentUriAsync(uri).then(cUri => {
            IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
              data: cUri,
              flags: 1,
            });
            MediaLibrary.saveToLibraryAsync(uri)
          });
          return 'success'
        } else {
          return 'error'
        }
    }
  }


  async updatePoojaDate(data) {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.put(url.updatePoojaDate, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async uploadPoojaImage(imageUrl, orderId) {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()

    let body = new FormData();
    if(Platform.OS == "web"){
      const base64Response = await fetch(imageUrl);
      const blob = await base64Response.blob();
      body.append('file', blob);
    } else{
      body.append('file', {uri: imageUrl, name: 'photo.png', type: 'image/png'});
    }

    const apiUrl = url.uploadPoojaImage.replace('{orderInfoId}', orderId)

    const response: ApiResponse<any> = await apiService.put(apiUrl, body)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async approveOrderItems(data) {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.changeOrderStatus.replace('{type}', 'approve') 

    const response: ApiResponse<any> = await apiService.post(apiUrl, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    } else {}

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }
}
