import React, { FunctionComponent as Component } from "react"
import { TextStyle, View, ViewStyle, ScrollView, TouchableOpacity } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, Icon } from "../../../components"
import { color } from "../../../theme"
import { OrderCard } from "../components/order-card/order-card";
import { ApiOrders } from "../../../services/api/api-methods/api-orders";
import { useStores } from "../../../models";
import { BottomCard } from "../../../components/bottom-card/bottom-card";
import { DatePickerComponent } from "../../../components/date-picker/date-picker";
import { Toast } from "../../../components/toast/toast";



export const NewOrdersScreen: Component = observer(function NewOrdersScreen() {

  const navigation = useNavigation()
  const detailsScreen = (id) => navigation.navigate('order_details', {orderId: id})

  const { orderListStore, domainStore } = useStores()

  const [showBottomCard, setShowBottomCard] = React.useState(false)
    
  const [showToast, setShowToast] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState("")
  const [toastType, setToastType] = React.useState('success')

  

  const acceptOrderApi = () => {
    const api = new ApiOrders()
    const data = {
        orderIds: orderListStore.getAcceptOrderIds()
    }
    api.orderAction(data, 'accept').then(res => {

      if(res.kind == 'ok'){
        orderListStore.removePendingOrderIds(orderListStore.getAcceptOrderIds())
        setShowBottomCard(false)
        setToastMessage("Successfully accepted all orders")
        setToastType('success')
        setShowToast(true)
      } else {
        setToastMessage("Failed to accept the orders")
        setToastType('error')
        setShowToast(true)
      }
    })
  }


  return (
    <Screen preset="fixedHeight" headerTitle={"New " + domainStore.inventoryName[0]} showHeader={true} showBackButton={true} >

      <View style={CONTAINER}>

        { showBottomCard ?
          <BottomCard 
              display={showBottomCard} 
              close={()=>setShowBottomCard(false)} 
              proceed={()=>{acceptOrderApi()}}
              orderCount={orderListStore.filteredPendingOrderList.length}
              message="Accept all pending orders"
              subMessage="Note: On proceeding all pending orders will be accepted and moved to scheduled list."
          ></BottomCard>  : null
        }

        { showToast ?
          <Toast 
                display={showToast} 
                close={()=>setShowToast(false)} 
                message={toastMessage}
                type={toastType}
          ></Toast> : null 
        }


          <View style={BOTTOM_CARD}>

              <View style={{marginTop: 15, marginBottom: 10, flexDirection: 'row', alignSelf: 'center', justifyContent: 'center'}}>
                  <View  style={{alignSelf: 'center', justifyContent: 'center', 
                                backgroundColor: orderListStore.showAllPending ? color.primary : color.background, 
                                width: 150, height: 40, borderRadius: 20}}>
                        <TouchableOpacity onPress={()=>orderListStore.showAllPendingOrders()}>
                          <Text style={{textAlign: 'center', color: orderListStore.showAllPending ? color.white : color.primary}}>All</Text>
                        </TouchableOpacity>
                  </View>
                <DatePickerComponent isSelected={orderListStore.showAllPending}  initialDate={orderListStore.filterPendingOrderDate} 
                                    getDate={(date)=>orderListStore.updateFilterPendingOrderDate(date)}></DatePickerComponent>
              </View>

              { orderListStore.filteredPendingOrderList.length ?
                <ScrollView>
                  <View style={CONTAINER}>
                    { orderListStore.filteredPendingOrderList.map((order, index)=>
                      <OrderCard 
                          key={index} 
                          status="New Request" 
                          forDelivery={order.delivery} 
                          orderDetails={{id: order.id, name: order.userName, itemCount: order.items, amount: order.amount, 
                                            date: order.date, serviceDate: order.serviceDate, itemDetails: order.itemDetails}} 
                          onCardPress={()=>detailsScreen(order.id)}
                      ></OrderCard>
                    )}
                  </View>
                </ScrollView> :
                <View style={{alignSelf: 'center', alignItems: 'center', flex: 1, justifyContent: 'center'}}>
                    <Icon icon={domainStore.imagePrefix + "_inventory"} style={{width: 60, height: 60}}></Icon>
                    <Text style={{padding: 20}}>{"No New " + domainStore.inventoryName[0]}</Text>
                </View>
              }


              { orderListStore.filteredPendingOrderList.length ?
                  <View style={{flexDirection: 'row', alignSelf: 'center', marginVertical: 15}}>
                      <Button
                          preset="primary"
                          text="Accept All"
                          onPress={()=>setShowBottomCard(true)}/> 
                              
                  </View>
                  : null
              } 

          </View>
      </View>

    </Screen>
  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  bottom: 0,
}
