import React, { FunctionComponent as Component, useEffect } from "react"
import { Image, View, ViewStyle, TouchableOpacity, Keyboard, Dimensions, TextStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField } from "../../components"
import { color, styles } from "../../theme"
import { ApiSignup, ApiSignin, ApiBusinessServices } from "../../services/api"
import { useStores } from "../../models";
import { save } from "../../utils/storage";

export const VerifyMobileNumber: Component = observer(function VerifyMobileNumber() {

    const { registerBusinessStore, userStore } = useStores()

    // load image
    const img = require('../../../assets/mobilenumber.png')

    const [showError, setShowError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("Enter a valid OTP")
    const [resendMessage, setResendMessage] = React.useState(null)
    const [resendStatus, setResendStatus] = React.useState(false)
    const [isKeyboardView, setIsKeyboardView] = React.useState(false)


    // API calls
    const verifyOtp = () => {
        if (userStore.isSignUp) {
            registerBusinessApi()
        } else {
            loginApi()
        }
    }

    const resendOtp = () => {
        var data = {
            otpReferenceId: registerBusinessStore.otpReferenceId,
            target: registerBusinessStore.phoneNumber,
            targetType: 'sms',
            action: ""
          }
        if (userStore.isSignUp) {
            data.action = 'verify'
        } else {
            data.action = 'sign-in'
        }

        const api = new ApiSignin()
        api.resendOtp(data).then(res => {
            if(res.kind == "ok"){
                registerBusinessStore.updateOtpReferenceId(res['data'])
                setResendMessage("OTP sent successfully")
                setResendStatus(true)
            } else {
                setResendMessage("Failed to resend OTP")
                setResendStatus(false)
            }
        })

    }

    const registerBusinessApi = () => {
        const api = new ApiSignup()

        api.registerBusiness(registerBusinessStore.getRegisterModel()).then(res => {
            
            if(res['data'] && res['data']['authToken']){
                userStore.setBusinessStatus("PendingDetails")
                userStore.setAccessToken(res['data']['authToken'])
                userStore.setRefreshToken(res['data']['refreshToken'])
                userStore.setDeviceId(res['data']['deviceId'])
                save("isFirstTimeUser", true).then(userStore.setFirstTimeUser)

            } else {
                var errorList = res['message']
                if(errorList){
                    setErrorMessage(errorList)
                }
                setShowError(true)
            }
        })
    }

    const loginApi = () => {
        const api = new ApiSignin()
        api.login(registerBusinessStore.getLoginModel()).then(res => {

            if(res['data'] && res['data']['accessToken']){
                userStore.setRefreshToken(res["data"]["refreshToken"])
                userStore.setDeviceId(res["data"]["deviceId"])
                businessStatusApi(res['data']['accessToken'])
            } else {
                var errorList = res['message']
                if(errorList){
                    setErrorMessage(errorList)
                }
                setShowError(true)
            }
        })
    }

    const businessStatusApi = (token) => {
        const api = new ApiBusinessServices()
        api.getBusinessStatus().then(res => {
            if (res["kind"] == "ok") {

                userStore.setBusinessStatus(res["data"]["status"])
                userStore.setAccessToken(token)
    
            } else {
                var errorList = res['message']
                if(errorList){
                    setErrorMessage(errorList)
                }
                setShowError(true)
            }
        })
    }


    useEffect(() => {
        let keyboardDidShow = Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
        let keyboardDidHide = Keyboard.addListener("keyboardDidHide", _keyboardDidHide);
    
        // cleanup function
        return () => {
          keyboardDidShow?.remove()
          keyboardDidHide?.remove()
          // Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
          // Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
        };
      }, []);
  
    const _keyboardDidShow = () => {
      setIsKeyboardView(true)
    };
  
    const _keyboardDidHide = () => {
      setIsKeyboardView(false)
    };



    return (

        <Screen preset="fixedHeight" headerTitle="Verify Mobile Number" showHeader={true} showBackButton={true}>

            <View style={CONTAINER}>
                <View>
                </View>

                <Image source={img} style = {{
                        width: isKeyboardView && Dimensions.get('screen').height < 1000  ? 150: 330, 
                        height: isKeyboardView && Dimensions.get('screen').height < 1000 ? 100 : 230, margin: 20
                    }}
                ></Image>

                <View style={styles.bottom_card}>

                    <View style={{ width: "50%" }}>

                        <Text style={SUB_TITLE}>
                            Verify OTP
                        </Text>

                        <TextField
                            placeholder='OTP'
                            getText={text => registerBusinessStore.updateOtp(text)}
                            keyboardType='numeric'>
                        </TextField>
                        { showError ?
                        <Text style={{color: color.ananthamOrange, alignSelf: 'center', fontSize: 13, textAlign: 'center'}}>{errorMessage}</Text> : <View></View>
                        }

                        <View>

                            { !resendMessage ?
                                <View>
                                    <Text style={SUB_TITLE}>
                                        Didn’t receive the OTP?
                                    </Text>
                                    <TouchableOpacity
                                        onPress={resendOtp}>
                                        <Text style={{fontSize: 13, alignSelf: 'center', color: color.ananthamBlue, marginTop: 3}}>
                                            RESEND OTP
                                        </Text>    
                                    </TouchableOpacity> 
                                </View> : null
                            }

                            { resendMessage ?
                                <View style={{alignSelf: 'center', marginTop: 10}}>
                                    <Text style={{fontSize: 13, color: resendStatus ? color.green: color.ananthamOrange}}>{resendMessage}</Text>    
                                </View> : <View></View>
                            }

                        </View>

                        <Button
                            preset="primary"
                            style={{ marginVertical: 20 }}
                            text="Verify and Proceed"
                            onPress={verifyOtp}
                        />
                    </View>

                  
                </View>
            </View>
        </Screen>
    )
})


const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    justifyContent: "space-between",
    alignItems: "center"
}

const SUB_TITLE: TextStyle = {
    width: "100%",
    color: color.text,
    textAlign: "center"
}
