import { types } from "mobx-state-tree"


const Payment = types
.model({
    amount: types.optional(types.number, 0),
    mode: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    referenceNumber: types.optional(types.string, ""),
    status: types.optional(types.string, ""),
})


const CustomValues = types
.model({
    fieldName: types.optional(types.string, ""),
    fieldValue: types.optional(types.string, "")
})


const Item = types
.model({
        amount: types.optional(types.number, 0),
        customValues: types.maybeNull(types.optional(types.array(CustomValues), [])),
        date: types.optional(types.string, ""),
        id: types.optional(types.number, 0),
        quantity: types.optional(types.number, 0),
        name: types.optional(types.string, ""),
        deliveryType: types.optional(types.string, ""),
        categoryName: types.optional(types.string, ""),
        isEnquireAndPay: types.maybeNull(types.optional(types.boolean, false)),
        enquiryStatus: types.maybeNull(types.optional(types.string, "Pending"))
})


export const OrderDetailsModel = types
.model({
    amount: types.optional(types.number, 0),
    deliveryCharge: types.optional(types.number, 0),
    processingFee: types.optional(types.number, 0),
    date: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    itemsCount: types.optional(types.number, 0),
    orderRefNumber: types.optional(types.string, ""),
    userId: types.optional(types.number, 0),
    userName: types.optional(types.string, ""),
    notes: types.optional(types.string, ""),
    paymentStatus: types.optional(types.string, ""),
    status: types.optional(types.string, ""),
    userContact: types.optional(types.string, ""),
    payment: types.optional(Payment, {}),
    items: types.optional(types.array(Item), []),
    addressLine1: types.optional(types.string, ""),
    addressLine2: types.optional(types.string, ""),
    locality: types.optional(types.string, ""),
    city: types.optional(types.string, ""),
    district: types.optional(types.string, ""),
    country: types.optional(types.string, ""),
    landmark: types.optional(types.string, ""),
    latitude: types.optional(types.string, ""),
    longitude: types.optional(types.string, ""),
    pinCode: types.optional(types.string, ""),
    state: types.optional(types.string, ""),
    orderDocumenturl: types.maybeNull(types.optional(types.string, ""))
})
.actions(self => ({
    create(orderDetail) {
        self.amount = orderDetail.amount
        self.deliveryCharge = orderDetail.deliveryCharge
        self.processingFee = orderDetail.processingFee
        self.date = orderDetail.date
        self.id = orderDetail.id
        self.itemsCount = orderDetail.itemsCount
        self.orderRefNumber = orderDetail.orderRefNumber
        self.userId = orderDetail.userId
        self.userName = orderDetail.userName
        self.notes = orderDetail.notes
        self.paymentStatus = orderDetail.payment.status,
        self.status = orderDetail.status
        self.userContact = orderDetail.userContact
        self.payment = orderDetail.payment
        self.items = orderDetail.items
        self.addressLine1 = orderDetail.addressLine1
        self.addressLine2 = orderDetail.addressLine2
        self.locality = orderDetail.locality
        self.city = orderDetail.city
        self.state = orderDetail.state
        self.district = orderDetail.district
        self.landmark = orderDetail.landmark
        self.latitude = orderDetail.latitude
        self.longitude = orderDetail.longitude
        self.pinCode = orderDetail.pinCode
        self.orderDocumenturl = orderDetail.orderDocumenturl
    }, 
    updatePoojaDate(id, date){
        self.items.map((x, index)=>{
            if(x.id == id){
                x.date = date
            }
        })
    },
    updateOrderDocumentUrl(orderDocumenturl){
        self.orderDocumenturl = orderDocumenturl
    },
    updateOrderItemsStatus(id, status){
        self.items.map(item=>{
            if(item.id == id) {
                item.enquiryStatus = status
            }
        })        
    },
    reset(){
        self.amount = 0
        self.deliveryCharge = 0
        self.processingFee = 0
        self.date = ""
        self.id = 0
        self.itemsCount = 0
        self.orderRefNumber = ""
        self.userId = 0
        self.userName = ""
        self.notes = ""
        self.paymentStatus = "",
        self.status = ""
        self.userContact = ""
        self.payment.amount = 0
        self.payment.id = 0
        self.payment.referenceNumber = ""
        self.payment.status = ""
        self.items.clear()
        self.addressLine1 = ""
        self.addressLine2 = ""
        self.city = ""
        self.landmark = ""
        self.latitude = ""
        self.longitude = ""
        self.pinCode = ""
        self.state = ""
        self.orderDocumenturl = ""
    }
}))
