import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { Api } from "../api"

// models
import { getOperatingDays_Model, addOperatingDays_Model} from "../../models/configurations-model"
import { url } from "../api-list";
import { load } from "../../../utils/storage";


type getAllOperatingDays_Res = { kind: "ok", data: getOperatingDays_Model } | GeneralApiProblem
type getSupplierOperatingDays_Res = { kind: "ok", data: getOperatingDays_Model } | GeneralApiProblem
type addSupplierOperatingDays_Res = { kind: "ok", data: addOperatingDays_Model } | GeneralApiProblem

export class ApiOperatingDaysConfig {


  async getAllOperatingDays(): Promise<getAllOperatingDays_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.get(url.getAllOperatingDays)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSupplierOperatingDays(): Promise<getSupplierOperatingDays_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.get(url.getSupplierOperatingDays)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async addSupplierOperatingDays(days): Promise<addSupplierOperatingDays_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    // make the api call

    const response: ApiResponse<any> = await apiService.post(url.addSupplierOperatingDays, days)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data = true){
      }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}