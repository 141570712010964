import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"

// Models
import { getAllOpenKitchenCategories_Model, getSupplierOpenKitchenCategories_Model, 
  addSupplierOpenKitchenCategory_Model, updateSupplierOpenKitchenCategory_Model, 
  getSupplierOpenKitchenCategoryDetails_Model } from "../../models/configurations-model"


// Categories
type getAllOpenKitchenCategories_Res = { kind: "ok", data: getAllOpenKitchenCategories_Model } | GeneralApiProblem
type getSupplierOpenKitchenCategories_Res = { kind: "ok", data: getSupplierOpenKitchenCategories_Model } | GeneralApiProblem
type addSupplierOpenKitchenCategory_Res = { kind: "ok", data: addSupplierOpenKitchenCategory_Model } | GeneralApiProblem
type updateSupplierOpenKitchenCategory_Res = { kind: "ok", data: updateSupplierOpenKitchenCategory_Model } | GeneralApiProblem
type getSupplierOpenKitchenCategoryDetails_Res = { kind: "ok", data: getSupplierOpenKitchenCategoryDetails_Model } | GeneralApiProblem


export class ApiCategoryConfig {

  async getAllCategories(domain): Promise<getAllOpenKitchenCategories_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    // domain = "worships"
    
    const apiUrl = url.getAllCategories.replace("{domain}", domain)

    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSupplierCategories(domain): Promise<getSupplierOpenKitchenCategories_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.getSupplierCategories.replace("{domain}", domain)


    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
      }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async addSupplierCategory(category, domain): Promise<addSupplierOpenKitchenCategory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    var apiUrl = url.addSupplierCategories.replace("{domain}", domain)
    // make the api call
    const response: ApiResponse<any> = await apiService.post(apiUrl, category)
    
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async updateSupplierCategory(category, domain): Promise<updateSupplierOpenKitchenCategory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()
    var apiUrl = url.updateSupplierCategories.replace("{domain}", domain)

    // make the api call
    const response: ApiResponse<any> = await apiService.put(apiUrl, category)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    } else {
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getSupplierCategoryDetails(inventoryId, domain): Promise<getSupplierOpenKitchenCategoryDetails_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.getSupplierCategoryDetails.replace('{inventoryGroupId}', inventoryId).replace("{domain}", domain)
  
    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    } else {
    }

    // transform the data into the format we are expecting
    try {
      delete response['data']['statusCode']
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async deleteSupplierCategory(categoryId, domain) {

    const api = new Api()
    const apiService = await api.setHeader()
    const apiUrl = url.deleteSupplierCategory.replace('{inventoryGroupId}', categoryId).replace("{domain}", domain)

    // make the api call
    const response: ApiResponse<any> = await apiService.delete(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


}