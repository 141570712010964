import React, { FunctionComponent as Component, useEffect } from "react"
import { Image, View, ViewStyle, ScrollView, Keyboard, TextStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Dropdown, Icon } from "../../components"
import { color, spacing, styles } from "../../theme"
import { useStores } from "../../models/root-store/root-store-context";
import { ApiBusinessServices } from "../../services/api";
import { save } from "../../utils/storage";
import { IconTypes } from "../../components/icon/icons";


export const BusinessDetails: Component = observer(function BusinessDetails(props) {


  var businessTypeId = props['route'].params.data

  // navigations
  const navigation = useNavigation()

  const { businessLocationsStore, registerBusinessStore, loaderStore } = useStores()

  const [showError, setShowError] = React.useState(false)
  const [isKeyboardView, setIsKeyboardView] = React.useState(false)




  useEffect(() => {
    registerBusinessStore.reset()
    businessLocationsApi()
  }, [])


  const getIcon = (name) => {
    var serviceName: IconTypes;
    name = name.replace(/\s+/g, '_').replace("&_", '').toLowerCase() + "_image";
    serviceName = name
    return serviceName
}

  const businessLocationsApi = async () =>  {
    loaderStore.showLoader()
    await save("businessTypeId", businessTypeId)
    const api = new ApiBusinessServices()
    api.getBusinessLocations(4).then(async res => {
      await businessLocationsStore.create(res['data'])
      loaderStore.hideLoader()
    })
  }



  const nextScreen = () => {
    setShowError(true)
    const isComplete = registerBusinessStore.mandatoryCheck()
    if(isComplete){
      navigation.navigate("mobilenumber")
    }
  }


  useEffect(() => {
    let keyboardDidShow = Keyboard.addListener("keyboardDidShow", _keyboardDidShow);
    let keyboardDidHide = Keyboard.addListener("keyboardDidHide", _keyboardDidHide);

    // cleanup function
    return () => {
      keyboardDidShow?.remove()
      keyboardDidHide?.remove()
      // Keyboard.removeListener("keyboardDidShow", _keyboardDidShow);
      // Keyboard.removeListener("keyboardDidHide", _keyboardDidHide);
    };
  }, []);

  const _keyboardDidShow = () => {
    setIsKeyboardView(true)
  };

  const _keyboardDidHide = () => {
    setIsKeyboardView(false)
  };

  const inputFieldForBusinessType = (businessName) => {
      return (
        <View>
          <Text style={{...LABEL_LG, ...MARGIN_HORIZONTAL}}>Administrator Name</Text>
          <View style={{flexDirection: 'row'}}>
            <View style={{flexGrow:1, minWidth:"50%", maxWidth: "50%"}}>
              <TextField label="First Name" required={true} showError={showError}  getText={text => registerBusinessStore.updateFirstName(text)} titleStyle={LABEL_SM}></TextField> 
            </View>
            <View style={{flexGrow:1, minWidth: "50%", maxWidth: "50%"}}>
                <TextField label="Last Name" required={true} showError={showError}  getText={text => registerBusinessStore.updateLastName(text)}  titleStyle={LABEL_SM}></TextField> 
            </View>
          </View>
          <TextField label={businessName + ' Name'} required={true} showError={showError} getText={text => registerBusinessStore.updateBusinessName(text)}></TextField>
          <TextField label={businessName + ' Location'} required={true} showError={showError} getText={text => registerBusinessStore.updateBusinessLocation(text)}></TextField>
          <Dropdown title="Operational Location" dropdownList={businessLocationsStore.businessLocations} required={true} showError={showError} getSelectedItem={item => registerBusinessStore.updateLocationId(item['id'])}></Dropdown>
        </View>
      ) 
  }


  return (
    <Screen preset="fixedHeight" headerTitle="Enter Your Business Details" showHeader={true} showBackButton={true} loader={loaderStore.loader}>
      <View style={CONTAINER}>
          <View style={{ alignItems: 'center', marginBottom: 10, flexGrow: 1, justifyContent: 'center'}}>
            <Icon icon={getIcon(registerBusinessStore.businessTypeName)} style={{ marginTop: 25, marginBottom: 10 , width: isKeyboardView ? 35 :110, height: isKeyboardView? 35 :110}}></Icon>
            <Text style={{fontWeight: 'bold', fontSize: 18}}>
              {registerBusinessStore.businessTypeName}
            </Text>
          </View>
          <View style={styles.bottom_card}>
            <ScrollView>
              {inputFieldForBusinessType(registerBusinessStore.businessTypeName)}
              <View style={{marginVertical: spacing.xLarge, alignSelf: "center"}}>
                <Button
                  preset="primary"
                  text="Continue"
                  onPress={nextScreen}
                />
              </View>
            </ScrollView>
          </View>
      </View>
    </Screen>

  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}

const MARGIN_HORIZONTAL: ViewStyle = {
  marginHorizontal: 6,
  marginVertical: 10
}

const LABEL_LG: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}

const LABEL_SM: TextStyle = {
  fontSize: spacing.small,
  color: color.dim,
  marginBottom: spacing.xSmall
}