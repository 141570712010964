import { types } from "mobx-state-tree"

const ChargeDetails = types
.model()
.props({
    Amount: types.optional(types.number, 0),
    ApplyAs: types.optional(types.string, ""),
    ApplyOn: types.optional(types.string, ""),
    ChargeType: types.optional(types.string, ""),
})

export const OrderSettingsDefaultModel = types
.model()
.props({
    chargeDetails: types.optional(ChargeDetails, {}),
    cancellationBufferTimeMinutes: types.optional(types.number, 0),
    autoRejectionBufferTimeMinutes: types.optional(types.number, 0),
})
.actions(self=>({
    create(settings){
        self.chargeDetails.Amount = settings.chargeDetails.Amount,
        self.chargeDetails.ApplyAs = settings.chargeDetails.ApplyAs,
        self.chargeDetails.ApplyOn = settings.chargeDetails.ApplyOn,
        self.chargeDetails.ChargeType = settings.chargeDetails.ChargeType,
        self.cancellationBufferTimeMinutes =  settings.cancellationBufferTimeMinutes,
        self.autoRejectionBufferTimeMinutes =  settings.autoRejectionBufferTimeMinutes
    },
}))

export const OrderSettingsModel = types
.model()
.props({
    leadTime: types.optional(types.number, 0),
    maximumQuantity: types.optional(types.number, 0),
    minimumQuantity: types.optional(types.number, 0),
    maxDaysForLongTimeOrder: types.optional(types.number, 0),
    isVisibleToCustomer: types.optional(types.boolean, false),
    inHouseDeliveryAvailable: types.optional(types.boolean, false),
    inHouseDeliverySameAsPlatform: types.optional(types.boolean, true),
    chargeType: types.optional(types.string, ""),
    priceApplied: types.optional(types.string, "PerItem"),
    priceAppliedAs: types.optional(types.string, "Amount"),
    deliveryCharge: types.optional(types.number, 0),
    coverage: types.optional(types.string, "All"),
    distanceLimit: types.optional(types.number, 0),
    additionalCost: types.optional(types.number, 0),
    cancellationBufferTimeMinutes: types.optional(types.number, 0),
    autoRejectionBufferTimeMinutes: types.optional(types.number, 0),
    deliveryBoundary: types.optional(types.number, 0),
    isDeliveryToLimitedPincodes: types.optional(types.boolean, false),
    deliverablePincodes: types.maybeNull(types.optional(types.array(types.number), [])),
    minimumAmountForDelivery: types.optional(types.number, 0),
})
.actions(self=>({
    create(settings){
        self.leadTime = settings.leadTime,
        self.maximumQuantity = settings.maximumQuantity,
        self.minimumQuantity = settings.minimumQuantity,
        self.maxDaysForLongTimeOrder = settings.maxDaysForLongTimeOrder,
        self.isVisibleToCustomer = settings.isVisibleToCustomer,
        self.inHouseDeliveryAvailable = settings.inHouseDeliveryAvailable,
        self.inHouseDeliverySameAsPlatform = settings.inHouseDeliverySameAsPlatform
        if(settings.chargeDetails){
            self.priceApplied = settings.chargeDetails.ApplyOn,
            self.priceAppliedAs = settings.chargeDetails.ApplyAs,
            self.deliveryCharge =  settings.chargeDetails.Amount,
            self.coverage = settings.chargeDetails.Coverage
            self.distanceLimit = settings.chargeDetails.DistanceLimit
            self.additionalCost = settings.chargeDetails.AdditionalCost
        }
        self.deliveryBoundary = settings.deliveryBoundary,
        self.isDeliveryToLimitedPincodes = settings.isDeliveryToLimitedPincodes,
        self.deliverablePincodes = settings.deliverablePincodes
        self.minimumAmountForDelivery = settings.minimumAmountForDelivery
    },
    createDefaultSettings(settings){
        self.cancellationBufferTimeMinutes =  settings.cancellationBufferTimeMinutes,
        self.autoRejectionBufferTimeMinutes =  settings.autoRejectionBufferTimeMinutes
    },
    updateLeadTime(leadTime){
        self.leadTime = leadTime
    },
    updateMaximumQuanitity(maximumQuantity){
        self.maximumQuantity = maximumQuantity
    },
    updateMinimumQuanitity(minimumQuantity){
        self.minimumQuantity = minimumQuantity
    },
    updateMaxDaysForLongTimeOrder(maxDaysForLongTimeOrder){
        self.maxDaysForLongTimeOrder = maxDaysForLongTimeOrder
    },
    toggleIsVisible(isVisibleToCustomer){
        self.isVisibleToCustomer = isVisibleToCustomer
    },
    toggleInHouseDeliveryAvailability(inHouseDeliveryAvailable){
        self.inHouseDeliveryAvailable = inHouseDeliveryAvailable
        self.inHouseDeliverySameAsPlatform = true
    },
    toggleInHouseDeliverySameAsPlatform(inHouseDeliverySameAsPlatform){
        self.inHouseDeliverySameAsPlatform = inHouseDeliverySameAsPlatform
        self.deliveryCharge = 0
        self.priceApplied = "PerItem"
        self.priceAppliedAs = "Amount"
        // self.coverage = "All"
        self.distanceLimit = 1
        self.additionalCost = 5
    },
    updateChargeType(chargeType){
        self.chargeType = chargeType
    },
    updatePriceApplied(priceApplied){
        self.priceApplied = priceApplied
    },
    updatePriceAppliedAs(priceAppliedAs){
        self.priceAppliedAs = priceAppliedAs
    },
    updateDeliveryCharges(deliveryCharge){
        self.deliveryCharge = deliveryCharge
    },
    updateCoverage(coverage){
        self.coverage = coverage
    },
    updateDistanceLimit(distanceLimit){
        self.distanceLimit = distanceLimit
    },
    updateAdditionalCost(additionalCost){
        self.additionalCost = additionalCost
    },
    updateCancellationBufferTimeMinutes(cancellationBufferTimeMinutes){
        self.cancellationBufferTimeMinutes = cancellationBufferTimeMinutes
    },
    updateAutoRejectionBufferTimeMinutes(autoRejectionBufferTimeMinutes){
        self.autoRejectionBufferTimeMinutes = autoRejectionBufferTimeMinutes
    },
    updateDeliveryBoundary(deliveryBoundary){
        self.deliveryBoundary = deliveryBoundary
    },
    updateMinimumAmountForDelivery(minimumAmountForDelivery){
        self.minimumAmountForDelivery = minimumAmountForDelivery
    },
    updateIsDeliveryToLimitedPincodes(isDeliveryToLimitedPincodes){
        self.isDeliveryToLimitedPincodes = isDeliveryToLimitedPincodes
    },
    updateDeliverablePincodes(deliverablePincodes){
        self.deliverablePincodes = deliverablePincodes
    },

    reset(){
        self.leadTime = 0
        self.maximumQuantity = 0
        self.minimumQuantity = 0
        self.maxDaysForLongTimeOrder = 0
        self.isVisibleToCustomer = false
        self.inHouseDeliveryAvailable = false
        self.inHouseDeliverySameAsPlatform = true
        self.priceApplied = "PerItem"
        self.priceAppliedAs = "Amount"
        self.deliveryCharge =  0
        self.coverage = "All"
        self.distanceLimit =  0
        self.additionalCost =  0
        self.deliveryBoundary = 0
        self.isDeliveryToLimitedPincodes = false
        self.minimumAmountForDelivery = 0
        self.deliverablePincodes ? self.deliverablePincodes.clear() : null
    },
    checkMandatory(){
        if(self.leadTime > 0 && self.minimumQuantity > 0 && self.maximumQuantity > 0 && self.leadTime 
                    && (!self.isDeliveryToLimitedPincodes || (self.isDeliveryToLimitedPincodes && self.deliverablePincodes && self.deliverablePincodes.length))){
            return true
        } else {
            return false
        }
    }
}))
.views(self=>({
    getUpdateModel(){
        return {
            leadTime: self.leadTime,
            maximumQuantity : self.maximumQuantity,
            minimumQuantity: self.minimumQuantity,
            maxDaysForLongTimeOrder: self.maxDaysForLongTimeOrder,
            isVisibleToCustomer: self.isVisibleToCustomer,
            inHouseDeliveryAvailable: self.inHouseDeliveryAvailable,
            inHouseDeliverySameAsPlatform: self.inHouseDeliverySameAsPlatform,
            chargeType: self.chargeType,
            priceApplied: self.priceApplied,
            priceAppliedAs: self.priceAppliedAs,
            deliveryCharge:  self.deliveryCharge,
            coverage:  self.coverage,
            distanceLimit:  self.distanceLimit,
            additionalCost:  self.additionalCost,
            cancellationBufferTimeMinutes:  self.cancellationBufferTimeMinutes,
            autoRejectionBufferTimeMinutes:  self.autoRejectionBufferTimeMinutes,
            deliveryBoundary:  self.deliveryBoundary,
            isDeliveryToLimitedPincodes:  self.isDeliveryToLimitedPincodes,
            deliverablePincodes:  self.deliverablePincodes,
            minimumAmountForDelivery: self.minimumAmountForDelivery
        }
    },
    getAddModel(){
        return {
            leadTime: self.leadTime,
            maximumQuantity : self.maximumQuantity,
            minimumQuantity: self.minimumQuantity,
            maxDaysForLongTimeOrder: self.maxDaysForLongTimeOrder,
            isVisibleToCustomer: self.isVisibleToCustomer,
            inHouseDeliveryAvailable: self.inHouseDeliveryAvailable,
            inHouseDeliverySameAsPlatform: self.inHouseDeliverySameAsPlatform,
            chargeType: self.chargeType,
            priceApplied: self.priceApplied,
            priceAppliedAs: self.priceAppliedAs,
            deliveryCharge:  self.deliveryCharge,
            coverage:  self.coverage,
            distanceLimit:  self.distanceLimit,
            additionalCost:  self.additionalCost,
            cancellationBufferTimeMinutes:  self.cancellationBufferTimeMinutes,
            autoRejectionBufferTimeMinutes:  self.autoRejectionBufferTimeMinutes,
            deliveryBoundary:  self.deliveryBoundary,
            isDeliveryToLimitedPincodes:  self.isDeliveryToLimitedPincodes,
            deliverablePincodes:  self.deliverablePincodes,
            minimumAmountForDelivery: self.minimumAmountForDelivery
        }
    }
}))








