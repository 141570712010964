import React, { FunctionComponent as Component, useEffect } from "react"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Screen, Text, Button, Icon } from "../../../components"
import { InventoryCard } from "../components/inventory-card/inventory-card";
import { ScrollView } from "react-native-gesture-handler";
import { View, ViewStyle } from "react-native";
import { color } from "../../../theme";
import { ApiInventoryConfig } from "../../../services/api/api-methods/api-inventory-config";
import { useStores } from "../../../models/root-store/root-store-context";
import { ApiCategoryConfig } from "../../../services/api";


export const InventoryScreen: Component = observer(function InventoryScreen() {

  const navigation = useNavigation()
  const navInventoryDetail = (type, item) => navigation.navigate('configurationStack', {screen: 'inventory_detail', params: {type: type, item: item}})
 
  const { inventoryStore, inventoryViewStore, categoryInventoryLookupModel, domainStore } = useStores()


  useEffect(()=>{
    getSupplierItems()
    getSupplierCategories()
  }, [])



  const getSupplierItems = () => {
    const api = new ApiInventoryConfig()
    api.getItems(domainStore.domainName[1]).then(res => {
      inventoryStore.setInventory(res['data'])
    })
  }


  const getSupplierCategories = () => {
    const api = new ApiCategoryConfig()
    api.getSupplierCategories(domainStore.domainName[0]).then(res => {
        var supplierCategories = JSON.parse(JSON.stringify(res['data']))
        categoryInventoryLookupModel.addSelectedCategories(supplierCategories)
    })
  }

  return (
    <Screen preset="fixedHeight" headerTitle={"Configure " + domainStore.inventoryName[0]} showHeader={true} showBackButton={true}> 

        { inventoryStore.inventories.length ?
            <ScrollView style={{marginVertical: 0, paddingHorizontal: 10}}>
              <View>
                    { inventoryStore.inventories.map((item, index) => 
                        <View key={index}>
                            <InventoryCard 
                                  cardText={item['name']} 
                                  icon={domainStore.imagePrefix + "_inventory"} 
                                  image={item.avatarUri}
                                  amount={item['amount']} isPublished={item['isPublished']} 
                                  onCardPress={()=>{inventoryViewStore.reset();navInventoryDetail('update', item)}}>
                              </InventoryCard>
                        </View>
                        )
                    }
                </View>
            </ScrollView> :
            <View style={{flexGrow:1, alignSelf: 'center', justifyContent: 'center'}}>
                <Icon containerStyle={{alignSelf: 'center', marginBottom: 20}} icon={domainStore.imagePrefix + "_inventory"} style={{width: 50, height: 50}}></Icon>
                <Text style={{fontWeight :'bold'}}>{"No " + domainStore.inventoryName[4] + " Added"}</Text>
            </View>
        }

        <View style={BOTTOM_CARD}>
            <View style={{margin:10, alignSelf: "center"}}>

              <Button
                  preset="primary"
                  onPress={()=>{inventoryViewStore.reset();navInventoryDetail('add', 0)}}
                  text={"Add New " + domainStore.inventoryName[3]}/>

            </View>
        </View>

      </Screen>
  )
})



const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  paddingVertical: 10,
  bottom: 0,
  marginTop: 2,
}

