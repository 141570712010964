import { types } from "mobx-state-tree"

export const Loader = types
.model()
.props({
    loader: types.optional(types.boolean, false),
})
.actions(self=>({
    showLoader(){
        self.loader = true
    },
    hideLoader(){
        self.loader = false
    }
}))

