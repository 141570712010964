import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"

// Models
import { getAllOrderSettings_Model, getSupplierOrderSettings_Model, addSupplierOrderSettings_Model, updateSupplierOrderSettings_Model } from "../../models/configurations-model";

// Order Settings
type getAllOrderSettings_Res = { kind: "ok", data: getAllOrderSettings_Model } | GeneralApiProblem
type getSupplierOrderSettings_Res = { kind: "ok", data: getSupplierOrderSettings_Model } | GeneralApiProblem
type addSupplierOrderSettings_Res = { kind: "ok", data: addSupplierOrderSettings_Model } | GeneralApiProblem
type updateSupplierOrderSettings_Res = { kind: "ok", data: updateSupplierOrderSettings_Model } | GeneralApiProblem


export class ApiOrderSettingsConfig {

  async getAllOrderSettings(): Promise<getAllOrderSettings_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.get(url.getAllOrderSettings)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSupplierOrderSettings(): Promise<getSupplierOrderSettings_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.get(url.getSupplierOrderSettings)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
      }
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async addSupplierOrderSettings(data): Promise<addSupplierOrderSettings_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const response: ApiResponse<any> = await apiService.post(url.addSupplierOrderSettings, data)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async updateSupplierOrderSettings(data): Promise<updateSupplierOrderSettings_Res> {

    const api = new Api()
    const apiService = await api.setHeader()


    const response: ApiResponse<any> = await apiService.put(url.updateSupplierOrderSettings, data)
    
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

}