import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, Text, TouchableOpacity } from "react-native"
import { spacing, color } from "../../../../theme"
import { OrderCardProps } from "./order-card.props";
import moment from "moment";
import { Layout, Popover } from '@ui-kitten/components';
import { useStores } from "../../../../models";

 
const CARD_STYLE: ViewStyle = { 
    flexDirection: 'row',
    width: "90%",
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.background,    
    borderRadius: 20,
    margin: spacing.xSmall,
    padding: spacing.xSmall,
    alignSelf: 'center',
}


export const OrderCard: Component<OrderCardProps> = props => {
  const {
    onCardPress,
    style,
    orderDetails,
    status,
    forDelivery
  } = props

  const { domainStore } = useStores()


  const [visible, setVisible] = React.useState(false);
  const [poojaDates, setPoojaDates] = React.useState([]);
  const [deliveryType, setDeliveryType] = React.useState("");


  useEffect(() => {
    getUniqueDates()
  }, [])
  
  function getUniqueDates(){
    var poojaDates = []
    orderDetails.itemDetails.map(x=>{
      poojaDates.push(x.serviceDate)
      if(x.deliveryType != "NoDelivery"){
          setDeliveryType(x.deliveryType)
      }
    })

    let uniquePoojaDates = [...new Set(poojaDates)];
    setPoojaDates(uniquePoojaDates)
  }
  

  const StatusBadge = props => {
    if(status == 'New Request'){
      return <Text style={{textAlign:'center', backgroundColor: color.blueStatusBg, color: color.blueStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{"Payment Complete"}</Text>;
    } else if (status == "Accepted") {
      return <Text style={{width: 90, textAlign:'center', backgroundColor: color.yellowStatusBg, color: color.yellowStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{"Accepted"}</Text>;
    } else if (status == "Completed") {
      return <Text style={{width: 90, textAlign:'center', backgroundColor: color.greenStatusBg, color: color.greenStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{"Completed"}</Text>;
    } else if (status == "Pending Approval") {
      return <Text style={{backgroundColor: color.redStatusBg, color: color.redStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
    } else if (status == "Pending Payment") {
        return <Text style={{backgroundColor: color.purpleStatusBg, color: color.purpleStatus, borderRadius: 10, fontSize: 12,fontWeight: 'bold', padding: 2, ...props.style}}>{status}</Text>;
    } else {
      return <View></View>
    }
  }

  const DeliveryBadge = props => {
    var deliveryStatus = props.children == "RequireDelivery" ? "Delivery" : props.children == "SelfCollection" ? "Pick Up": null
    return <Text style={{width: 90, textAlign:'center', backgroundColor: '#FFD9CD', color: '#F54237', borderRadius: 10, fontSize: 12, fontWeight: '700', padding: 2, ...props.style}}>{deliveryStatus}</Text>;
  }



  const renderToggleButton = () => (
    poojaDates.length > 1 ?
     <TouchableOpacity onPress={() => setVisible(true)}>
        <Text style={{color: color.link, flexWrap:'wrap'}}>{poojaDates.length} {domainStore.inventoryName[3] + " Dates"}</Text>
    </TouchableOpacity> : <View></View>
  );

  return (

    <TouchableOpacity style={{ ...CARD_STYLE, ...style}} onPress={onCardPress}>

        <View style={{flex: 1, flexDirection: 'row', alignItems: 'center'}}>
          
          <View style={{flex: 1, alignItems: 'center', paddingVertical: 10, paddingHorizontal: 10}}>
              <Text style={{fontWeight: 'bold', fontSize: 14, textAlign: 'center'}}> {moment(orderDetails.date).format('DD')} </Text>
              <Text style={{fontSize: 16, color: color.grey, textAlign: 'center'}}> {moment(orderDetails.date).format('MMM')} </Text>
          </View>
          
          <View style={{backgroundColor: color.white, width: 1, height: 75}}></View>
          
          <View style={{flex: 4.8, paddingVertical: 10, paddingHorizontal: 15}}>

              <Text style={{ fontWeight: 'bold'}}>{orderDetails.name}</Text>
              <Text style={{color: color.grey}}>Total Items: {orderDetails.itemDetails.length}</Text>
     
              { poojaDates.length == 1 ? 
                <Text style={{color: color.grey, flexWrap:'wrap'}}>{domainStore.inventoryName[3] + " Date: "} {moment(orderDetails.serviceDate).format('DD MMM YYYY')}</Text>
                : null
              }

              <Popover
                visible={visible}
                anchor={renderToggleButton}
                onBackdropPress={() => setVisible(false)}>
                <Layout style={{padding: 20}}>
                { poojaDates.map((item, index)=>
                    <Text>{moment(item).format('DD MMM YYYY')}</Text>
                )}
                </Layout>
              </Popover> 

              <Text style={{fontWeight: 'bold', fontSize: 13, textAlignVertical: 'center',flexGrow: 1}}>₹{orderDetails.amount.toFixed(2)} </Text>

          </View>


          <View style={{flexDirection: 'column', marginRight: 15}}>
            <StatusBadge style={{alignSelf: 'flex-start', paddingHorizontal: 8, marginVertical: 5}} >{status}</StatusBadge>
            { forDelivery ? <DeliveryBadge style={{alignSelf: 'flex-start', paddingHorizontal: 8, marginVertical: 5}} >{deliveryType}</DeliveryBadge> : null}
          </View>

        </View>

    </TouchableOpacity>    
  )
}



