import * as Network from 'expo-network';

/**
 * Check Network Connection
 *
 * @param server The server which has these creds
 */
export async function getNetworkState(){
    var netState = await Network.getNetworkStateAsync();
    return netState
}