import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TextStyle, TouchableOpacity, Image, Platform } from "react-native"
import { spacing, color } from "../../theme"
import { TimePickerProps } from "./time-picker.props";
import { Text } from "../text/text"
import moment from 'moment';
// import TimeKeeper from 'react-timekeeper';
import { Modal, Datepicker } from "@ui-kitten/components";
import DateTimePicker from '@react-native-community/datetimepicker';

// import TimePicker from 'react-gradient-timepicker'; // or
import { Button } from "../button/button";

// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const TimePickerWidget: Component<TimePickerProps> = props => {
  const {
    title,
    getTime,
    initialTime,
    titleStyle
  } = props



  const [time, setTime] = useState();

  useEffect(()=>{
 
    if(initialTime){
      if(Platform.OS == "web"){
        var time = moment(initialTime, 'HH:mm:ss');      
        setTime(time.format("hh:mm"))
      } else {
        var time = moment(initialTime, 'hh:mm A')
        setTime(new Date(time.toLocaleString()))
      }
    } else {
      setTime(null)
    }
  }, [initialTime])


  const [modalVisible, setModalVisibility] = React.useState(false);


  const onChange = (event) => {
    let selectedTime = event.target.value
    setTime(selectedTime)
    var timeNew = selectedTime + ':00'
    getTime(timeNew)
    // setModalVisibility(false)
  };

  // const onChange = (selectedDate) => {
  //   setTime(selectedDate["formatted12"]);
  //   var dateNew = moment(selectedDate).format('HH:mm:ss')
  //   getTime(dateNew)
  //   setModalVisibility(false)
  // };



  const onChangeMobile = (event, selectedDate) => {
    setModalVisibility(Platform.OS === 'ios');
    setTime(selectedDate);
    getTime(moment(selectedDate).format('HH:mm:ss'))
  }
  

  const showTimepicker = () => {
    setModalVisibility(true);
  };

  let inputCssStyle = {
    "height": "40px",
    "padding": "0px 15px",
    "border-width": "1px",
    "border-radius": "5px",
    "overflow": "hidden",
    "border-color": color.ananthamBlue,
    "border-style": "solid",
    "color": color.grey,
    "font-size": "15px"
  }

  return (
    <View style={{ ...ROOT }}>
        
        <Text text={title}   style={{...label_lg, ...titleStyle}}/>


        <TouchableOpacity onPress={showTimepicker} style={{flexDirection: 'row', backgroundColor: '#f7f9fc', borderColor: '#e4e9f2', 
        borderRadius: 4, borderWidth: 1, paddingVertical: 8}}>
          <Image style={{width: 20, height: 20, marginHorizontal: 15}} source={require('./clock.png')} /> 
          {/* <Text>{time ? time : "Please pick a time"}</Text> */}

          <Text>{time ? Platform.OS == "web" ? moment(time, 'HH:mm').format('hh:mm A') : moment(time).format('hh:mm A') : "Please pick a time"}</Text>

        </TouchableOpacity>
  
        <Modal visible={modalVisible}
                backdropStyle={backdrop}
                onBackdropPress={() => setModalVisibility(false)}>
                
            <View style={{alignItems: "center"}}>
              { Platform.OS == "web" ?
                // <TimeKeeper
                //     time={time}
                //     doneButton={(newTime) => (
                //       <div
                //           style={{ textAlign: 'center', padding: '10px 0' }}
                //           onClick={() => onChange(newTime)}
                //       >
                //           Done
                //       </div>
                //   )}
          
                  // <TimePicker
                  //   time="01:00"
                  //   color1="#9E9E9E"
                  //   headerColor="#347af0"
                  //   placeholder="Start Time"
                  //   onSet={(val) => {
                  //     onChange(val)
                  //     alert('time is ' + JSON.stringify(val));
                  //   }}
                  // />

                  <View style={{paddingVertical:40, paddingHorizontal:60, backgroundColor:'white', borderRadius:15}}>
                    <input 
                      placeholder="Time" 
                      style={inputCssStyle} 
                      type="time" 
                      value={time ? time : moment().format("hh:mm")}
                      onChange={(event)=>onChange(event)}
                      />
                    <View style={{marginTop:20, marginHorizontal:25, alignSelf: "center"}}>
                      <Button
                          preset="primary"
                          text="Close"
                          onPress={()=>setModalVisibility(false)}/>
                    </View>
                  </View>
                :
                <DateTimePicker
                  testID="dateTimePicker"
                  value={time ? time : new Date()}
                  mode='time'
                  is24Hour={false}
                  display="default"
                  onChange={onChangeMobile}
                />
                  }
                </View>
        </Modal>


    </View>
      )
}


const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}


const backdrop: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}







