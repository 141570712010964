import { types } from "mobx-state-tree";

const BusinessModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    iconUri: types.maybeNull(types.optional(types.string, "")),
    status: types.optional(types.string, ""),
    priority: types.optional(types.number, 0),
    deliverySupported: types.optional(types.boolean, false)
})

export const BusinessTypesModel = types
.model()
.props({
    businessTypes: types.optional(types.array(BusinessModel), []),
})
.actions(self=>({
    create(businessTypes){
        self.businessTypes = businessTypes
    },
}))


