export function mobileValidate(number: any){
    try {
        const reg = /^[0]?[6789]\d{9}$/;
        if (reg.test(number) === false || number.length < 10) {
          return false;
        } else {
          return true;
        }
    } catch {
      // not sure why this would fail... even reading the RN docs I'm unclear
      return null
    }
}
  