import { types } from "mobx-state-tree"
import moment from "moment";

const OrderItems = types
.model({
    date: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    serviceDate: types.optional(types.string, ""),
    deliveryType: types.optional(types.string, ""),
    totalAmount: types.optional(types.number, 0),
    isEnquireAndPay: types.optional(types.boolean, false),
    enquiryStatus: types.maybeNull(types.optional(types.string, "")),
})


const Order = types
.model({
    amount: types.optional(types.number, 0),
    date: types.optional(types.string, ""),
    id: types.optional(types.number, 0),
    items: types.optional(types.number, 0),
    orderRefNumber: types.optional(types.string, ""),
    userId: types.optional(types.number, 0),
    userName: types.optional(types.string, ""),
    serviceDate: types.optional(types.string, ""),
    delivery: types.optional(types.boolean, false),
    itemDetails: types.optional(types.array(OrderItems), [])
})



export const OrderListModel = types
.model({
    pendingOrderList: types.optional(types.array(Order), []),
    pendingApprovalOrderList: types.optional(types.array(Order), []),
    pendingPaymentOrderList: types.optional(types.array(Order), []),
    acceptedOrderList: types.optional(types.array(Order), []),
    completedOrderList: types.optional(types.array(Order), []),
    filteredPendingOrderList: types.optional(types.array(Order), []),
    filteredPendingApprovalOrderList: types.optional(types.array(Order), []),
    filteredPendingPaymentOrderList: types.optional(types.array(Order), []),
    filteredAcceptedOrderList: types.optional(types.array(Order), []),
    filteredCompletedOrderList: types.optional(types.array(Order), []),
    searchOrderText: types.optional(types.string, ""),
    searchOrderList: types.optional(types.array(Order), []),
    filterDate: types.optional(types.string, moment().format('YYYY-MM-DD')),
    filterPendingOrderDate: types.optional(types.string, moment().format('YYYY-MM-DD')),
    showAllPending: types.optional(types.boolean, true),
    showAllAccepted: types.optional(types.boolean, true),
    showAllPendingApproval: types.optional(types.boolean, true)
})
.actions(self => ({
    createPendingList(orderList) {
        self.pendingOrderList = orderList
        self.filteredPendingOrderList = orderList
    },
    createPendingApprovalList(orderList) {
        self.pendingApprovalOrderList = orderList
        self.filteredPendingApprovalOrderList = orderList
    },
    createPendingPaymentList(orderList) {
        self.pendingPaymentOrderList = orderList
        self.filteredPendingPaymentOrderList = orderList
    },
    createConfirmedList(orderList) {
        self.acceptedOrderList = orderList
        self.filteredAcceptedOrderList = orderList
    },
    createCompletedList(orderList) {
        self.completedOrderList = orderList
        self.filteredCompletedOrderList = orderList
    },
    updateSearchOrderList(orderList) {
        self.searchOrderList = orderList
    },
    updateSearchOrderText(searchOrderText) {
        self.searchOrderText = searchOrderText
    },
    updateFilterSchedulerOrderDate(date){

        self.showAllAccepted = false
        self.filterDate = date
        
        var filteredAcceptedArray = []
        self.acceptedOrderList.map(order=>{
            for(let i = 0; i < order.itemDetails.length; i++){
                if(moment(order.itemDetails[i].serviceDate).format('YYYY-MM-DD') == date){
                    filteredAcceptedArray.push(order)
                    break;
                }
            }
        })
        self.filteredAcceptedOrderList = JSON.parse(JSON.stringify(filteredAcceptedArray))

        var filteredCompletedArray = []
        self.completedOrderList.map(order=>{
            for(let i = 0; i < order.itemDetails.length; i++){
                if(moment(order.itemDetails[i].serviceDate).format('YYYY-MM-DD') == date){
                    filteredCompletedArray.push(order)
                    break;
                }
            }
        })
        self.filteredCompletedOrderList = JSON.parse(JSON.stringify(filteredCompletedArray))

    },
    updateFilterPendingApprovalOrderDate(date){

        self.showAllPendingApproval = false
        self.filterDate = date
        
        var filteredPendingApprovalArray = []
        self.pendingApprovalOrderList.map(order=>{
            for(let i = 0; i < order.itemDetails.length; i++){
                if(moment(order.itemDetails[i].serviceDate).format('YYYY-MM-DD') == date){
                    filteredPendingApprovalArray.push(order)
                    break;
                }
            }
        })
        self.filteredPendingApprovalOrderList = JSON.parse(JSON.stringify(filteredPendingApprovalArray))

        var filteredPendingPaymentArray = []
        self.pendingPaymentOrderList.map(order=>{
            for(let i = 0; i < order.itemDetails.length; i++){
                if(moment(order.itemDetails[i].serviceDate).format('YYYY-MM-DD') == date){
                    filteredPendingPaymentArray.push(order)
                    break;
                }
            }
        })
        self.filteredPendingPaymentOrderList = JSON.parse(JSON.stringify(filteredPendingPaymentArray))

    },
    updateFilterPendingOrderDate(date){
        self.showAllPending = false
        self.filterPendingOrderDate = date

        var filteredPendingArray = []
        self.pendingOrderList.map(x=>{
            if(moment(x.date).format('YYYY-MM-DD') == date){
                filteredPendingArray.push(x)
            }
        })
        self.filteredPendingOrderList = JSON.parse(JSON.stringify(filteredPendingArray))
    },
    showAllPendingOrders(){
        self.showAllPending = true
        self.filteredPendingOrderList = JSON.parse(JSON.stringify(self.pendingOrderList))
    },
    showAllAcceptedOrders(){
        self.showAllAccepted = true
        self.filteredAcceptedOrderList = JSON.parse(JSON.stringify(self.acceptedOrderList))
        self.filteredCompletedOrderList = JSON.parse(JSON.stringify(self.completedOrderList))
    },
    showAllPendingApprovalOrders(){
        self.showAllPendingApproval = true
        self.filteredPendingApprovalOrderList = JSON.parse(JSON.stringify(self.pendingApprovalOrderList))
        self.filteredPendingPaymentOrderList = JSON.parse(JSON.stringify(self.pendingPaymentOrderList))
    },
    removePendingOrderIds(orderIds){
        var removedFilterIds = []
        self.filteredPendingOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedFilterIds.push(index)
                self.filteredAcceptedOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedFilterIds.length -1; i >= 0; i--){
            self.filteredPendingOrderList.splice(removedFilterIds[i],1);
        }

        var removedIds = []
        self.pendingOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedIds.push(index)
                self.acceptedOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedIds.length -1; i >= 0; i--){
            self.pendingOrderList.splice(removedIds[i],1);
        }
    },
    removeAcceptedOrderIds(orderIds){
        var removedIds = []
        self.acceptedOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedIds.push(index)
                self.completedOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedIds.length -1; i >= 0; i--){
            self.acceptedOrderList.splice(removedIds[i],1);
        }

        var removedFilterIds = []
        self.filteredAcceptedOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedFilterIds.push(index)
                self.filteredCompletedOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedFilterIds.length -1; i >= 0; i--){
            self.filteredAcceptedOrderList.splice(removedFilterIds[i],1);
        }
    },
    removePendingApprovalOrderIds(orderIds){
        var removedIds = []
        self.pendingApprovalOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedIds.push(index)
                self.pendingPaymentOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedIds.length -1; i >= 0; i--){
            self.pendingApprovalOrderList.splice(removedIds[i],1);
        }

        var removedFilterIds = []
        self.filteredPendingApprovalOrderList.map((x, index)=>{
            if(orderIds.includes(x.id)){
                removedFilterIds.push(index)
                self.filteredPendingPaymentOrderList.unshift(JSON.parse(JSON.stringify(x)))
            }
        })

        for (var i = removedFilterIds.length -1; i >= 0; i--){
            self.filteredPendingApprovalOrderList.splice(removedFilterIds[i],1);
        }
    },
    checkForTodaysOrder(orderIds){
        var todaysOrder = true
        self.filteredAcceptedOrderList.map(order=>{
            order.itemDetails.map((item, index)=>{
                if(orderIds.includes(order.id) && moment(item.serviceDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')){
                    todaysOrder  = false
                }
            })
        })  
        return todaysOrder
    },
    updateShowAllAccepted(value){
        self.showAllAccepted = value
    },
    updatePoojaDate(id, date){
        self.acceptedOrderList.map((order, orderIndex)=>{
            order.itemDetails.map((item, itemIndex)=>{
                if(item.id == id){
                    self.acceptedOrderList[orderIndex].itemDetails[itemIndex].serviceDate = date
                    // item.serviceDate == date
                }
            })
        })

        self.filteredAcceptedOrderList.map((order, orderIndex)=>{
            order.itemDetails.map((item, itemIndex)=>{
                if(item.id == id){
                    self.filteredAcceptedOrderList[orderIndex].itemDetails[itemIndex].serviceDate = date
                    // order.serviceDate = date
                }
            })
        })
    }
}))
.views(self=>({
    getAcceptOrderIds(){
        var orderIds =[]
        self.filteredPendingOrderList.map(order=>{
            orderIds.push(order.id)
        })  
        return orderIds
    },
    getCompleteOrderIds(){
        var orderIds =[]
        self.filteredAcceptedOrderList.map(order=>{
            orderIds.push(order.id)
        })  
        return orderIds
    }
}))
