import { types } from "mobx-state-tree";
import { Platform } from "react-native";

export const RegisterBusinessModel = types
.model()
.props({ 
    otp: types.optional(types.string, ""),
    otpReferenceId: types.optional(types.string, ""),
    fullName: types.optional(types.string, ""),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    phoneNumber: types.maybeNull(types.optional(types.string, "")),
    businessName: types.optional(types.string, ""),
    businessLocation: types.optional(types.string, ""),
    businessTypeId: types.optional(types.number, 0),
    businessTypeName: types.optional(types.string, ""),
    locationId: types.optional(types.number, 0),
    deviceOs: types.optional(types.string, Platform.OS),
    pushToken: types.optional(types.string, "")
})
.actions(self=>({
    create(registerBusiness){
        self.otp = registerBusiness.otp
        self.otpReferenceId = registerBusiness.otpReferenceId
        self.fullName = registerBusiness.fullName
        self.phoneNumber = registerBusiness.phoneNumber
        self.businessName = registerBusiness.businessName
        self.businessLocation = registerBusiness.businessLocation
        self.businessTypeId = registerBusiness.businessTypeId
        self.locationId = registerBusiness.locationId
        self.businessTypeName = registerBusiness.businessTypeName
    },
    updateOtp(otp){
        self.otp = otp
    },
    updateOtpReferenceId(otpReferenceId){
        self.otpReferenceId = otpReferenceId
    },
    updateFirstName(firstName){
        self.firstName = firstName
        self.fullName = self.firstName
    },
    updateLastName(lastName){
        self.lastName = lastName
        self.fullName = self.firstName + " " + self.lastName
    },
    updatePhoneNumber(phoneNumber){
        self.phoneNumber = phoneNumber
    },
    updateBusinessName(businessName){
        self.businessName = businessName
    },
    updateBusinessLocation(businessLocation){
        self.businessLocation = businessLocation
    },
    updateBusinessTypeId(businessTypeId){
        self.businessTypeId = businessTypeId
    },
    updateLocationId(locationId){
        self.locationId = locationId
    },
    updateBusinessTypeName(businessTypeName){
        self.businessTypeName = businessTypeName
    },
    updatePushToken(pushToken){
        self.pushToken = pushToken
    },
    updateDeviceOs(deviceOs){
        self.deviceOs = deviceOs
    },
    reset(){
        self.otp = ""
        self.otpReferenceId = ""
        self.fullName = ""
        self.phoneNumber = ""
        self.businessName = ""
        self.businessLocation = ""
        self.locationId = 0
        self.deviceOs = ""
        self.pushToken = "" 
    }
}))
.views(self=>({
    getRegisterModel(){
        return {
            otp: self.otp,
            otpReferenceId: self.otpReferenceId,
            fullName: self.fullName,
            phoneNumber: self.phoneNumber,
            businessName: self.businessName,
            businessLocation: self.businessLocation,
            businessTypeId: self.businessTypeId,
            locationId: self.locationId,
            deviceOs: self.deviceOs,
            pushToken: self.pushToken
        }
    },
    getLoginModel(){
        return {
            otp: self.otp,
            otpRefId: self.otpReferenceId,
            mobileNumber: self.phoneNumber,
            userType: "Business",
            deviceOs: self.deviceOs,
            pushToken: self.pushToken
        }
    },
    mandatoryCheck(){
        if( self.firstName.length &&
            self.lastName.length &&
            self.businessName.length &&
            self.businessLocation.length &&
            self.locationId){
                return true
        } else {
            return false
        }
    }
}))


const IdentityInfo = types
.model()
.props({
    gstNumber: types.optional(types.string, ""),
    licenseNumber: types.optional(types.string, ""),
    licenseValidFrom: types.optional(types.string, ""),
    licenseValidTo: types.optional(types.string, ""),
    gstNumberVerified: types.optional(types.boolean, false),
    licenseVerified: types.optional(types.boolean, false),
    businessCategory: types.optional(types.string, ""),
})


const BankDetails = types
.model()
.props({
    bankAccountNumber: types.optional(types.string, ""),
    bankingIfsCode: types.optional(types.string, ""),
    accountHolderName: types.optional(types.string, ""),
    bankDocumentId: types.maybeNull(types.optional(types.string, "")),
})


const BusinessInfo = types
.model()
.props({
    id: types.maybeNull(types.optional(types.number, 0)),
    businessName: types.optional(types.string, ""),
    businessAppName: types.optional(types.string, ""),
    businessDescription: types.maybeNull(types.optional(types.string, "")),
    website: types.maybeNull(types.optional(types.string, "")),
    firstName: types.optional(types.string, ""),
    middleName: types.maybeNull(types.optional(types.string, "")),
    lastName: types.optional(types.string, ""),
    fullName: types.optional(types.string, ""),
    businessLocation: types.optional(types.string, ""),
    operationLocationId: types.optional(types.number, 0),
    operationLocationName: types.optional(types.string, ""),
    addressLaneOne: types.optional(types.string, ""),
    addressLaneTwo: types.maybeNull(types.optional(types.string, "")),
    streetAddress: types.optional(types.string, ""),
    landmark: types.optional(types.string, ""),
    district: types.optional(types.string, ""),
    zipCode: types.optional(types.string, ""),
    email: types.maybeNull(types.optional(types.string, "")),
    aadharHash: types.maybeNull(types.optional(types.string, "")),
    panCardHash: types.maybeNull((types.optional(types.string, ""))),
    businessMobile: types.optional(types.string, ""),
    aadharVerified: types.optional(types.boolean, false),
    panCardVerified: types.optional(types.boolean, false),
    emailVerified: types.optional(types.boolean, false),
    mobileVerified: types.optional(types.boolean, false),
    businessLocationId: types.optional(types.number, 0),
    businessAvatarUri: types.maybeNull(types.optional(types.string, "")),
    businessVerified: types.optional(types.string, ""),
    latitude: types.optional(types.string, ""),
    longitude: types.optional(types.string, ""),
    otp: types.optional(types.string, ""),
    otpReferenceId: types.optional(types.string, ""),
    confirmAccountNumber: types.optional(types.string, ""),
    bankDetails: types.maybeNull(types.optional(BankDetails, {})),
    registrationNumber: types.maybeNull(types.optional(types.string, ""))

})



export const BusinessDetailsModel = types
.model()
.props({ 
    identityInfo: types.optional(IdentityInfo, {}),
    businessInfo: types.optional(BusinessInfo, {})
})
.actions(self=>({
    create(details){
        this.createIdentityInfo(details.identityInfo)
        this.createBusinessInfo(details.businessInfo)
    },
    createIdentityInfo(identityInfo){
        self.identityInfo.gstNumber = identityInfo.gstNumber
        self.identityInfo.licenseNumber = identityInfo.licenseNumber
        self.identityInfo.licenseValidFrom = identityInfo.licenseValidFrom
        self.identityInfo.licenseValidTo = identityInfo.licenseValidTo
        self.identityInfo.gstNumberVerified = identityInfo.gstNumberVerified
        self.identityInfo.licenseVerified = identityInfo.licenseVerified
        self.identityInfo.businessCategory = identityInfo.businessCategory
    },
    createBusinessInfo(businessInfo){
        self.businessInfo.id = businessInfo.id
        self.businessInfo.businessName = businessInfo.businessName
        self.businessInfo.businessDescription = businessInfo.businessDescription
        self.businessInfo.website = businessInfo.website
        self.businessInfo.firstName = businessInfo.firstName
        self.businessInfo.middleName = businessInfo.middleName
        self.businessInfo.lastName = businessInfo.lastName
        self.businessInfo.fullName = businessInfo.firstName + " " + businessInfo.lastName
        self.businessInfo.businessLocation = businessInfo.businessLocation,
        self.businessInfo.operationLocationId = businessInfo.operationLocationId
        self.businessInfo.operationLocationName = businessInfo.operationLocationName,
        self.businessInfo.businessLocationId = businessInfo.businessLocationId
        self.businessInfo.emailVerified = businessInfo.emailVerified
        self.businessInfo.addressLaneOne = businessInfo.addressLaneOne
        self.businessInfo.addressLaneTwo = businessInfo.addressLaneTwo
        self.businessInfo.landmark = businessInfo.landmark
        self.businessInfo.district = businessInfo.operationLocationName
        self.businessInfo.zipCode = businessInfo.zipCode
        self.businessInfo.email = businessInfo.email
        self.businessInfo.aadharHash = businessInfo.aadharHash
        self.businessInfo.panCardHash = businessInfo.panCardHash
        self.businessInfo.businessMobile = businessInfo.businessMobile
        self.businessInfo.aadharVerified = businessInfo.aadharVerified
        self.businessInfo.panCardVerified = businessInfo.panCardVerified
        self.businessInfo.mobileVerified = businessInfo.mobileVerified
        self.businessInfo.website = businessInfo.website
        self.businessInfo.businessAvatarUri = businessInfo.businessAvatarUri
        self.businessInfo.businessVerified = businessInfo.businessVerified

        self.businessInfo.streetAddress = businessInfo.streetAddress
        self.businessInfo.latitude = businessInfo.latitude
        self.businessInfo.longitude = businessInfo.longitude

        self.businessInfo.bankDetails = businessInfo.bankDetails ? businessInfo.bankDetails : {}
        self.businessInfo.registrationNumber = businessInfo.registrationNumber 
    },
    updateBusinessLocationId(businessLocationId){
        self.businessInfo.businessLocationId = businessLocationId
    },
    updateEmailVerified(emailVerified){
        self.businessInfo.emailVerified = emailVerified
    },
    updateAddressLaneOne(addressLaneOne){
        self.businessInfo.addressLaneOne = addressLaneOne
    },
    updateAddressLaneTwo(addressLaneTwo){
        self.businessInfo.addressLaneTwo = addressLaneTwo
    },
    updateLandmark(landmark){
        self.businessInfo.landmark = landmark
    },
    updateDistrict(district){
        self.businessInfo.district = district
    },
    updateZipCode(zipCode){
        self.businessInfo.zipCode = zipCode
    },
    updateEmail(email){
        self.businessInfo.email = email
        self.businessInfo.emailVerified = false
    },
    updateAadharHash(aadharHash){
        self.businessInfo.aadharHash = aadharHash
    },
    updatePanCardHash(panCardHash){
        self.businessInfo.panCardHash = panCardHash
    },
    updateBusinessMobile(businessMobile){
        self.businessInfo.businessMobile = businessMobile
    },
    updateBusinessName(businessName){
        self.businessInfo.businessName = businessName
    },
    updateBusinessDescription(businessDescription){
        self.businessInfo.businessDescription = businessDescription
    },
    updateWebsite(website){
        self.businessInfo.website = website
    },
    updateFirstName(firstName){
        self.businessInfo.firstName = firstName
    },
    updateLastName(lastName){
        self.businessInfo.lastName = lastName
    },
    updateBusinessLocation(businessLocation){
        self.businessInfo.businessLocation = businessLocation
    },
    updateAadharVerified(aadharVerified){
        self.businessInfo.aadharVerified = aadharVerified
    },
    updatePanCardVerified(panCardVerified){
        self.businessInfo.panCardVerified = panCardVerified
    },
    updateMobileVerified(mobileVerified){
        self.businessInfo.mobileVerified = mobileVerified
    },
    updateOperationLocation(data){
        self.businessInfo.operationLocationId = data['id']
        self.businessInfo.operationLocationName = data['name']
    },
    updateStreetAddress(streetAddress){
        self.businessInfo.streetAddress = streetAddress
    },
    updateLatLng(latlng){
        self.businessInfo.latitude = latlng.latitude.toString()
        self.businessInfo.longitude = latlng.longitude.toString()
    },
    updateBusinessAvatarUri(url){
        self.businessInfo.businessAvatarUri = url
    },
    updateGstNumber(gstNumber){
        self.identityInfo.gstNumber = gstNumber
    },
    updateLicenseNumber(licenseNumber){
        self.identityInfo.licenseNumber = licenseNumber
    },
    updateLicenseValidFrom(gstNumber){
        self.identityInfo.gstNumber = gstNumber
    },
    updateLicenseValidTo(licenseValidTo){
        self.identityInfo.licenseValidTo = licenseValidTo
    },
    updateGstNumberVerified(gstNumberVerified){
        self.identityInfo.gstNumberVerified = gstNumberVerified
    },
    updateLicenseVerified(licenseVerified){
        self.identityInfo.licenseVerified = licenseVerified
    },
    updateBusinessCategory(businessCategory){
        self.identityInfo.businessCategory = businessCategory
    },
    updateOtp(otp){
        self.businessInfo.otp = otp
    },
    updateOtpReferenceId(otpReferenceId){
        self.businessInfo.otpReferenceId = otpReferenceId
    },
    updateAccountHolderName(accountHolderName){
        self.businessInfo.bankDetails.accountHolderName = accountHolderName
    },
    updateBankAccountNumber(bankAccountNumber){
        self.businessInfo.bankDetails.bankAccountNumber = bankAccountNumber
    },
    updateConfirmBankAccountNumber(confirmAccountNumber){
        self.businessInfo.confirmAccountNumber = confirmAccountNumber
    },
    updateBankingIfsCode(bankingIfsCode){
        self.businessInfo.bankDetails.bankingIfsCode = bankingIfsCode
    },
    updateBankDocumentId(bankDocumentId){
        self.businessInfo.bankDetails.bankDocumentId = bankDocumentId
    },
    updateRegistrationNumber(registrationNumber){
        self.businessInfo.registrationNumber = registrationNumber
    },
    updateBusinessVerified(businessVerified){
        self.businessInfo.businessVerified = businessVerified
    },
    updateBusinessAppName(businessName){
        self.businessInfo.businessAppName = businessName
    },
    reset(){
        this.resetIndentityInfo()
        this.resetBusinessInfo()
    },
    resetIndentityInfo(){
        self.identityInfo.gstNumber =  ""
        self.identityInfo.licenseNumber =  ""
        self.identityInfo.licenseValidFrom =  ""
        self.identityInfo.licenseValidTo =  ""
        self.identityInfo.gstNumberVerified =  false
        self.identityInfo.licenseVerified =  false
        self.identityInfo.businessCategory =  ""
    },
    resetBusinessInfo(){
        self.businessInfo.id = 0
        self.businessInfo.businessName = ""
        self.businessInfo.businessDescription = ""
        self.businessInfo.website = ""
        self.businessInfo.firstName = ""
        self.businessInfo.middleName = ""
        self.businessInfo.lastName = ""
        self.businessInfo.fullName = ""
        self.businessInfo.businessLocation = ""
        self.businessInfo.operationLocationId = 0
        self.businessInfo.operationLocationName = ""
        self.businessInfo.businessLocationId =  0
        self.businessInfo.emailVerified =  false
        self.businessInfo.addressLaneOne =  ""
        self.businessInfo.addressLaneTwo =  ""
        self.businessInfo.landmark = "" 
        self.businessInfo.district =  ""
        self.businessInfo.zipCode =  ""
        self.businessInfo.email =  ""
        self.businessInfo.aadharHash =  ""
        self.businessInfo.panCardHash =  ""
        self.businessInfo.businessMobile =  ""
        self.businessInfo.aadharVerified =  false
        self.businessInfo.panCardVerified =  false
        self.businessInfo.mobileVerified =  false
        self.businessInfo.website =  ""
        self.businessInfo.businessVerified = ""
        self.businessInfo.businessAvatarUri = ""
        self.businessInfo.registrationNumber = ""
        self.businessInfo.bankDetails.bankAccountNumber = ""
        self.businessInfo.bankDetails.accountHolderName = ""
        self.businessInfo.bankDetails.bankingIfsCode = ""
        self.businessInfo.confirmAccountNumber = ""
    },
    mandatoryCheckBusinessInfo(){
        if( self.businessInfo.zipCode.length &&
            self.businessInfo.addressLaneOne.length &&
            self.businessInfo.zipCode.length &&
            // self.businessInfo.longitude &&
            // self.businessInfo.latitude &&
            self.businessInfo.firstName.length &&
            self.businessInfo.lastName.length &&
            self.businessInfo.businessName.length &&
            self.businessInfo.businessLocation.length &&
            self.businessInfo.district.length){
                return true
        } else {
            return false
        }
    },
    mandatoryCheckAuthInfo(){
        if(self.businessInfo.email &&
            self.businessInfo.bankDetails.bankAccountNumber &&
            self.businessInfo.bankDetails.bankingIfsCode &&
            self.businessInfo.bankDetails.accountHolderName &&
            self.businessInfo.bankDetails.bankDocumentId &&
            self.businessInfo.emailVerified){
                return true
        } else {
            return false
        }
    }
}))
.views(self=>({
    getBusinessPostModel(){
        return {
                addressLaneOne: self.businessInfo.addressLaneOne,
                addressLaneTwo: self.businessInfo.addressLaneTwo,
                landmark: self.businessInfo.landmark,
                district: self.businessInfo.district,
                zipCode: self.businessInfo.zipCode,
                email: self.businessInfo.email,
                aadharHash: self.businessInfo.aadharHash,
                panCardHash: self.businessInfo.panCardHash,
                registrationNumber: self.businessInfo.registrationNumber,
                businessMobile: self.businessInfo.businessMobile,
                aadharVerified: self.businessInfo.aadharVerified,
                panCardVerified: self.businessInfo.panCardVerified,
                emailVerified: self.businessInfo.emailVerified,
                mobileVerified: self.businessInfo.mobileVerified,
                website: self.businessInfo.website,
                operationLocationId: self.businessInfo.operationLocationId,
                id: self.businessInfo.id,
                businessName: self.businessInfo.businessName,
                businessDescription: self.businessInfo.businessDescription,
                businessLocation: self.businessInfo.businessLocation,
                operationLocationName: self.businessInfo.operationLocationName,
                firstName: self.businessInfo.firstName,
                middleName: self.businessInfo.middleName,
                lastName: self.businessInfo.lastName,
                businessVerified: self.businessInfo.businessVerified,
                businessAvatarUri: self.businessInfo.businessAvatarUri,
                latitude: self.businessInfo.latitude,
                longitude: self.businessInfo.longitude,
                bankDetails: self.businessInfo.bankDetails
        }
    },
    getEmailVerifyModel(){
        return {
            email: self.businessInfo.email,
            otp: self.businessInfo.otp,
            otpReferenceId: self.businessInfo.otpReferenceId
        }
    }
}))


