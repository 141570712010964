import React, { FunctionComponent as Component } from "react"
import { TextStyle, View } from "react-native"
import { observer } from "mobx-react-lite"
import { Text, Screen } from "../../../components"
import { color } from "../../../theme"



export const FeedbacksScreen: Component = observer(function FeedbacksScreen() {

    return (
      <Screen preset="fixed" headerTitle="Feedbacks" showBackButton={true}>

        <View style={CONTAINER}>
            <Text style={TITLE_PRIMARY}>Feedbacks Screen</Text>
        </View>
      </Screen>
  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  justifyContent: "space-evenly",
  alignItems: "center",
}

const TITLE_PRIMARY: TextStyle = {
    paddingTop: 50,
    paddingVertical: 20,  
    width: "100%",
    color: color.black,
    fontSize: 32,
    textAlign: "center",
    fontWeight: "bold" 
}