import { types } from "mobx-state-tree"

export const Toast = types
.model()
.props({
    toast: types.optional(types.boolean, false),
})
.actions(self=>({
    showToast(){
        self.toast = true
    },
    hideToast(){
        self.toast = false
    }
}))

