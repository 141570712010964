import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../../theme"
import { Screen } from "../../../components";
import { ReportCard } from "../components/report-card/report-card";
import { ApiOrders } from "../../../services/api/api-methods/api-orders";
import { Toast } from "../../../components/toast/toast";
import { useStores } from "../../../models";

export const ReportsScreen: Component = observer(function ReportsScreen() {


  const [showToast, setShowToast] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState()
  const [toastType, setToastType] = React.useState('success')

  const { loaderStore, businessDetailsStore } = useStores()
  
  useEffect(()=>{
    loaderStore.hideLoader()
  }, [])


  function emailDailyCollectionReport(data){
    const api = new ApiOrders()
    loaderStore.showLoader()
    api.emailDailyCollectionReport(data).then(res => {
      loaderStore.hideLoader()
      if(res.kind == 'ok'){
        setToastType('success')
        setToastMessage("Successfully shared daily collection report to " + businessDetailsStore.businessInfo.email)
        setShowToast(true)
      } else {
        setToastType('error')
        setToastMessage("Failed to share daily collection report to " + businessDetailsStore.businessInfo.email)
        setShowToast(true)
      }
    })
  }

  function downloadDailyCollectionReport(data){
    const api = new ApiOrders()
    loaderStore.showLoader()
    api.downloadDailyCollectionReport(data).then(res => {
      loaderStore.hideLoader()

      if(res == 'success'){
        setToastType('success')
        setToastMessage("Successfully downloaded daily collection report")
        setShowToast(true)
      } else {
        setToastType('error')
        setToastMessage("Failed to download daily collection report")
        setShowToast(true)
      }
    })
  }

  function emailSummaryReport(data){

    const api = new ApiOrders()
    loaderStore.showLoader()
    api.emailSummaryReport(data).then(res => {
      loaderStore.hideLoader()
      if(res.kind == 'ok'){
        setToastType('success')
        setToastMessage("Successfully shared summary report to " + businessDetailsStore.businessInfo.email)
        setShowToast(true)
      } else {
        setToastType('error')
        setToastMessage("Failed to share summary report to " + businessDetailsStore.businessInfo.email)
        setShowToast(true)
      }
    })
  }



  function downloadSummaryReport(data){

    const api = new ApiOrders()
    loaderStore.showLoader()
    api.downloadSummaryReport(data).then(res => {
      loaderStore.hideLoader()
      if(res == 'success'){
        setToastType('success')
        setToastMessage("Successfully downloaded summary report")
        setShowToast(true)
      } else {
        setToastType('error')
        setToastMessage("Failed to download summary report")
        setShowToast(true)
      }
    })
  }


  


  
  return (
    <Screen preset="fixedHeight" headerTitle="Reports" showHeader={true} showBackButton={true} loader={loaderStore.loader}>


      <View style={bottom_card}>

        { showToast ?
          <Toast 
                display={showToast} 
                close={()=>setShowToast(false)} 
                message={toastMessage}
                type={toastType}>    
          </Toast> : null
        }

          <ReportCard title="Daily Collection Report"  
            subTitle="Report contains orders within the selected date range"
            onEmailShare={(dateRange)=>emailDailyCollectionReport(dateRange)}
            onDownload={(dateRange)=>downloadDailyCollectionReport(dateRange)}>
          </ReportCard>

          <ReportCard title="Summary Report"  
            subTitle="Report contains order summary within the selected date range"
            onEmailShare={(dateRange)=>emailSummaryReport(dateRange)}
            onDownload={(dateRange)=>downloadSummaryReport(dateRange)}>
          </ReportCard>
      </View>  

    </Screen>
  )
})


const bottom_card: ViewStyle = {
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  bottom: 0,
  alignItems: 'center',
  paddingVertical: 10
}