/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { BusinessInfoScreen } from "../screens/profile-screen/business-info-screen/business-info-screen";
import { UserInfoScreen } from "../screens/profile-screen/user-info-screen/user-info-screen";
import { SupportScreen } from "../screens/profile-screen/support-screen/support-screen";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type ProfileParamList = {
    profile: undefined
    business_info: undefined
    user_info: undefined
    legaly: undefined
    support: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<ProfileParamList>()
const Tab = createBottomTabNavigator();

const config = {
  animation: 'spring',
  config: {
    stiffness: 850,
    damping: 100,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

export function ProfileNavigator() {
  return (
    <Stack.Navigator
    screenOptions={{
      headerShown: false,
      gestureEnabled: true,
      // gestureDirection: 'horizontal',
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      transitionSpec: {
        open: config,
        close: config
      }
    }}
    >
      {/* <Stack.Screen name="profile" component={ProfileScreen} /> */}
      <Stack.Screen name="business_info" component={BusinessInfoScreen} options={{title: "Business Information"}}/>
      <Stack.Screen name="user_info" component={UserInfoScreen} options={{title: "User Information"}}/>
      <Stack.Screen name="support" component={SupportScreen} options={{title: "Support"}}/>


    </Stack.Navigator>
  )
}



/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = []
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
