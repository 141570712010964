import React, { FunctionComponent as Component } from "react"
import { View, TextStyle, ViewStyle, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Wallpaper, Icon } from "../../components"
import { color } from "../../theme"
import { useStores } from "../../models";


export const LandingScreen: Component = observer(function LandingScreen() {
  const navigation = useNavigation()

  const  { userStore } = useStores()

  const signIn = ()=> {
    userStore.toggleIsSignUp(false)
    navigation.navigate("signin", {Screen: 'mobilenumber'})
  }

  const signUp = () => {
    userStore.toggleIsSignUp(true)
    navigation.navigate("signup")
  }

  return (

        <View style={CONTAINER}>
          <Wallpaper style={{top:"-50%",position:"absolute"}} preset= {Platform.OS == "web" ? 'stretch' :'contain'} />
          <View></View>
            <View>
                <Text style={WELCOME_TEXT}>
                    Welcome to
                </Text>
                <View style={{alignItems:'center', marginTop: 20}}>
                <Icon icon={'ellam_icon'} style={{width: 100, height: 100}}></Icon>
                </View>
                <Text style={ELLAM_TEXT}>Ellam</Text>
                <Text style={{color: color.ananthamBlue, alignSelf: 'center', fontSize: 12, fontStyle: 'italic'}}>for Enterprises</Text>
            </View>

            <View>
              <Button
                preset="primary"
                style={{marginVertical:10, backgroundColor: color.ananthamBlue}}
                text="Sign Up"
                onPress={signUp}
              />
              <Button
                preset="secondary"
                style={{marginVertical:10, borderColor: color.ananthamBlue}}
                textStyle={{color: color.ananthamBlue}}
                text="Sign In"
                onPress={signIn}
              />
            </View>
        </View>


  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  backgroundColor: color.black,
  justifyContent: "space-evenly",
  alignItems: "center",
}

const WELCOME_TEXT: TextStyle = {
  color: '#FF6300',
  fontSize: 28,
  paddingVertical: 20,
  alignSelf: "center"
}

const ELLAM_TEXT: TextStyle = {
  color: color.ananthamBlue,
  fontSize: 28,
  paddingTop: 20,
  paddingBottom: 0,
  alignSelf: "center",
  fontWeight: 'bold'
}