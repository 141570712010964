import React, { FunctionComponent as Component, useEffect } from "react"
import { Image, ImageStyle, View, ViewStyle, TextStyle, Alert, Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen } from "../../components"
import { color, spacing } from "../../theme"
import { SettingsCard } from "./components/settings-card/settings-card";
import { ScrollView } from "react-native-gesture-handler";
import { clear, load } from "../../utils/storage";
import { useStores } from "../../models";
import { ApiBusinessServices } from "../../services/api";
import { getNetworkState } from "../../utils/network";
import Constants from 'expo-constants';
import { DeleteAccountPopUp } from "../../components/delete-account-popup/delete-account-popup";

export const ProfileScreen: Component = observer(function ProfileScreen() {
  
  const [deletePopUp, setDeletePopup] = React.useState(false)
  
  const navigation = useNavigation()
  var store = useStores()
  const { businessDetailsStore, userStore, businessLocationsStore, networkStore, domainStore } = useStores()


  useEffect(() => {
      checkNetworkState()
      businessLocationsApi()
      getBusinessInfo()
  }, [])

  const navigateTo = (path) => {
    checkNetworkState()
    navigation.navigate('profileStack', {screen: path})
  } 

  const showDeletePopup = () => {
    setDeletePopup(true);
  };

  const signOut = async () => {
    userStore.setAccessToken(null)
    store.resetStore()
    await clear()
  }

  // TODO
  const businessLocationsApi = async () => {
    const api = new ApiBusinessServices()
    api.getBusinessLocations(4).then(async res => {
      businessLocationsStore.create(res['data'])
    })
  }

  const getBusinessInfo = () => {
      const api = new ApiBusinessServices()
      api.getBusinessInfo(domainStore.domainName[0]).then(res => {
        if(res.kind == "ok"){
          businessDetailsStore.createBusinessInfo(res['data'])
        }
      })
  }

  const checkNetworkState = async () => {
    var netState = await getNetworkState()
    networkStore.create(netState)
  }

  return (

    <Screen preset="fixed" headerTitle="Profile" showBackButton={false}>
      <View style={CONTAINER}>
        {deletePopUp ?
          <DeleteAccountPopUp setDeletePopup={setDeletePopup} deletePopUp={deletePopUp} /> : null
        }
            
        <View></View>
          
        <View style={WIDTH_100}>

            <View style={BOTTOM_CARD}>

                <Text style={{marginTop: 60, marginBottom: 20, fontWeight: 'bold'}}>{businessDetailsStore.businessInfo.businessName}</Text>


                <ScrollView style={{width: "100%", paddingHorizontal: 20, marginTop: 20}} contentContainerStyle={{alignItems: 'center'}}>
                  
                  <SettingsCard cardText="User Information" onCardPress={()=> navigateTo('user_info')}></SettingsCard>
                  <SettingsCard cardText="Business Information" onCardPress={()=> navigateTo('business_info')}></SettingsCard>
              {Platform.OS != "web" ? <SettingsCard cardText="Delete Account" onCardPress={() => showDeletePopup()}></SettingsCard> : null}
                  <SettingsCard cardText="Help & Support" onCardPress={()=> navigateTo('support')}></SettingsCard>
              
                  <Text style={{...LABEL_SM, ...MARGIN_TOP}}>Ellam for Enterprises V {Constants.manifest.version}</Text>

                  <View style={{marginBottom: 30, alignSelf: "center"}}>
                    <Button preset="primary" text="Sign Out" onPress={signOut}></Button>
                  </View>

                </ScrollView>

          </View>

          <Image
              style={PROFILE_PIC}
              source={ businessDetailsStore.businessInfo.businessAvatarUri ? 
              {uri: businessDetailsStore.businessInfo.businessAvatarUri}: require('../../../assets/avatar.png')}/>

        </View>
      </View>
    </Screen>
)
})



const CONTAINER: ViewStyle = {
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center"
}



const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  alignItems: 'center',
  bottom: 0,
  marginTop: 60,
}


const PROFILE_PIC: ImageStyle = {
  width: 100,
  height: 100,
  position: "absolute",
  borderRadius: 50,
  marginTop: 10,
}

const WIDTH_100: ViewStyle = {
  width: "100%",
  alignItems: "center",
}

const MARGIN_TOP: ViewStyle = {
  marginTop: 20
}
  
const LABEL_SM: TextStyle = {
  fontSize: spacing.small,
  color: color.dim,
  marginBottom: spacing.xSmall
}

