import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { OperatingDaysModel } from "../stores/configurations-store/operating-days";
import { CategoryModel, CategoryViewModel } from "../stores/configurations-store/category";
import { InventoryModel, InventoryViewModel } from "../stores/configurations-store/inventory";
import { OrderSettingsModel, OrderSettingsDefaultModel } from "../stores/configurations-store/order-settings";
import { BusinessLocationsModel } from "../stores/business-store/business-locations";
import { BusinessTypesModel } from "../stores/business-store/business-types";
import { RegisterBusinessModel, BusinessDetailsModel } from "../stores/business-store/business-details";
import { Loader } from "../stores/shared-store.ts/loader";
import { Toast } from "../stores/shared-store.ts/toast";
import { UserModel } from "../stores/shared-store.ts/access-token";
import { CategoryInventoryLookupModel } from "../stores/configurations-store/configurations-lookup";
import { OrderListModel } from "../stores/orders-store/order-list";
import { OrderDetailsModel } from "../stores/orders-store/order-details";
import { NetworkModel } from "../stores/network-store/network";
import { DomainModel } from "../stores/shared-store.ts/domain";

/**
 * A RootStore model.
 */

//prettier-ignore
export const RootStoreModel = types.model("RootStore").props({

    // Auth Store
    userStore: types.optional(UserModel, {}),

    // Configurations Store
    operatingDaysStore: types.optional(OperatingDaysModel, {}),
    categoriesStore: types.optional(CategoryModel, {}),
    categoryViewStore: types.optional(CategoryViewModel, {}),
    inventoryStore: types.optional(InventoryModel, {}),
    inventoryViewStore: types.optional(InventoryViewModel, {}),
    orderSettingsStore: types.optional(OrderSettingsModel, {}),
    orderSettingsDefaultStore: types.optional(OrderSettingsDefaultModel, {}),
    categoryInventoryLookupModel: types.optional(CategoryInventoryLookupModel, {}),

    

    // SignUp Store
    businessLocationsStore: types.optional(BusinessLocationsModel, {}),
    businessTypesStore: types.optional(BusinessTypesModel, {}),
    registerBusinessStore: types.optional(RegisterBusinessModel, {}),
    
    // Business Details Store
    businessDetailsStore: types.optional(BusinessDetailsModel, {}),

    // Store
    loaderStore: types.optional(Loader, {}),
    toastStore: types.optional(Toast, {}),

    // Orders
    orderListStore: types.optional(OrderListModel, {}),
    orderDetailsStore: types.optional(OrderDetailsModel, {}),

    // Network Store
    networkStore: types.optional(NetworkModel, {}),

    // App Titles
    domainStore: types.optional(DomainModel, {})

})
.actions(self=>({
    resetStore(){
        self.operatingDaysStore.reset()
        self.categoriesStore.reset()
    }
}))
/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}
export interface OperatingDaysStore extends Instance<typeof OperatingDaysModel> {}
export interface CategoryModel extends Instance<typeof CategoryModel> {}
export interface CategoryViewModel extends Instance<typeof CategoryViewModel> {}
export interface InventoryModel extends Instance<typeof InventoryModel> {}
export interface InventoryViewModel extends Instance<typeof InventoryViewModel> {}
export interface OrderSettingsModel extends Instance<typeof OrderSettingsModel> {}
export interface OrderSettingsDefaultModel extends Instance<typeof OrderSettingsDefaultModel> {}
export interface RegisterBusinessModel extends Instance<typeof RegisterBusinessModel> {}
export interface BusinessDetailsModel extends Instance<typeof BusinessDetailsModel> {}
export interface Loader extends Instance<typeof Loader> {}
export interface Toast extends Instance<typeof Toast> {}
export interface UserModel extends Instance<typeof UserModel> {}
export interface CategoryInventoryLookupModel extends Instance<typeof CategoryInventoryLookupModel> {}


/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
