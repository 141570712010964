import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Dimensions } from "react-native"
import { Ionicons } from "@expo/vector-icons";
import { spacing, color } from "../../../../theme";
import { SettingsCardProps } from "./settings-card.props";
import { translate } from "../../../../i18n";

 

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing.tiny,
    padding: spacing.tiny,
    width: "100%"
}

const CARD_STYLE: ViewStyle = { 
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: color.background,    
    borderRadius: 20,
    margin: spacing.tiny,
    padding: spacing.small,
}

const CARD_TITLE: TextStyle = { 
    color: color.text,
    fontSize: spacing.medium,
    marginLeft: spacing.xSmall,
    marginRight: spacing.medium
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const SettingsCard: Component<SettingsCardProps> = props => {
  const {
    cardText,
    onCardPress,
    style,
    titleStyle
  } = props
  
  const cardtext = cardText || (cardText && translate(cardText)) || ""

  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity onPress={onCardPress}>
            <View style={{...CARD_STYLE}}>

                <Text style= {{...CARD_TITLE, ...titleStyle}}>{cardtext}</Text>
                
                <Ionicons
                    name={'ios-arrow-forward'}
                    size={spacing.medium}
                    style={{marginLeft: spacing.small}}
                    color={color.text}
                    />

            </View>
        </TouchableOpacity>
    </View>
    
  )
}
