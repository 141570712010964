export const icons = {

  ellam_icon: require("./ellam.png"),

  back: require("./back-icon.png"),
  bullet: require("./back-icon.png"),
  // new_orders: require("./new-orders.png"),
  reports: require("./reports.png"),

  for_delivery: require("./for-delivery.png"),
  feedbacks: require("./feedbacks.png"),
  scheduled_orders: require("./scheduled-orders.png"),
  // inventory: require("./category.png"),
  // category: require("./category.png"),
  operating_days: require("./operating-days.png"),
  // order_settings: require("./order-settings.png"),
  tax_details: require("./tax-details.png"),

  operating_days2: require("./operating-days-2.png"),
  settings2: require("./settings-2.png"),
  
  prarthana_category: require("./prarthana/category.png"),
  prarthana_inventory: require("./prarthana/inventory.png"),

  open_kitchen_category: require("./open-kitchen/category.png"),
  open_kitchen_inventory: require("./open-kitchen/inventory.png"),

  parking_category: require("./parking/category.png"),
  parking_inventory: require("./parking/inventory.png"),

  breakfast: require("./open-kitchen/breakfast.png"),
  lunch: require("./open-kitchen/lunch.png"),
  dinner: require("./open-kitchen/dinner.png"),

  config_selected: require("./config-selected.png"),
  config_unselected: require("./config-unselected.png"),
  profile_selected: require("./profile-selected.png"),
  profile_unselected: require("./profile-unselected.png"),
  dashboard_selected: require("./dashboard-selected.png"),
  dashboard_unselected: require("./dashboard-unselected.png"),
  ellam_selected: require("./ellam-selected.png"),
  ellam_unselected: require("./ellam-unselected.png"),

  location_marker: require("./location-marker.png"),

  success_icon: require("./success-icon.png"),
  warning_icon: require("./warning-icon.png"),
  error_icon: require("./error-icon.png"),

  close: require("./close-icon.png"),
  wifi_icon: require("./wifi-icon.png"),
  update_icon: require("./update-icon.png"),
  search: require("./search.png"),

  ellam_stores_logo: require("./open-kitchen/logo.png"),
  prarthana_logo: require("./prarthana/logo.png"),
  saloon_spa_logo: require("./saloon-spa/logo.png"),
  community_services_bill_payment_logo: require("./community-services/logo.png"),
  parking_logo: require("./parking/logo.png"),

  prarthana_image: require("./prarthana/image.png"),
  ellam_stores_image: require("./open-kitchen/image.png"),
  parking_image: require("./parking/image.png"),

  pending_icon: require("./pending-icon.png"),

  delete_icon: require("./delete-icon.png"),

}

export type IconTypes = keyof typeof icons
