import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, Text, TouchableOpacity, StyleSheet } from "react-native"
import { spacing, color } from "../../../../theme"
import { ReportCardProps } from "./report-card.props";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import { Calender } from "../../../../components/calender/calender";

 
const CARD_STYLE: ViewStyle = { 
    width: "90%",
    backgroundColor: color.background,    
    borderRadius: 15,
    margin: spacing.xSmall,
    padding: spacing.medium,
}


export const ReportCard: Component<ReportCardProps> = props => {
  const {
    onEmailShare,
    onDownload,
    subTitle,
    style,
    title
  } = props
  
    //   const cardtext = cardText || (cardText && translate(cardText)) || ""

    const [filter, setFilter] = React.useState('All')
    const [filterDate, setFilterDate] = React.useState({from: null, to: null})

    const [showCalender, setShowCalender] = React.useState(false)

    function filterApply(filter, dateRange = null){

        var from = null, to = null
        setFilter(filter)
        if(filter == "All"){
            from = null;
            to = null
        } else if(filter == "Current Week"){         
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6
            
            from = new Date(moment(curr.setDate(first)).format('YYYY-MM-DD'))
            to = new Date(moment(curr.setDate(last)).format('YYYY-MM-DD'))

        } else if(filter == "Current Day"){
            var nextDate = new Date; // get current date
            nextDate.setDate(nextDate.getDate() + 1) // tomorrow
            
            from = new Date(moment().format('YYYY-MM-DD'))
            to = new Date(moment().format('YYYY-MM-DD'))
            // to = new Date(moment(nextDate).format('YYYY-MM-DD'))
        } else if(filter == "Custom"){
            from = dateRange['from']
            to = dateRange['to']
        }
        
        var data = {
            from: from,
            to: to
        }

        setFilterDate(data)
    }


    return (
        <View style={{ ...CARD_STYLE, ...style}} >

                <Calender 
                    display={showCalender}
                    confirm={(dateRange)=> {filterApply('Custom', dateRange); setShowCalender(false)}}
                    cancel={()=>setShowCalender(false)} 
                    >    
                </Calender> 

                <View style={{flexDirection: 'row', flexWrap: 'wrap', marginBottom: 15}}>
                    
                    <TouchableOpacity onPress={()=>filterApply('All')} style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: filter == "All" ? color.primary : color.white, height: 25, paddingHorizontal: 10, marginRight: 5, marginVertical: 5, borderRadius: 20}}>
                        <Text style={{textAlign: 'center', color: filter == "All" ? color.white : color.primary}}>All</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>filterApply('Current Day')} style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: filter == "Current Day" ? color.primary : color.white, height: 25, paddingHorizontal: 10, marginRight: 5, marginVertical: 5, borderRadius: 20}}>
                        <Text style={{textAlign: 'center', color: filter == "Current Day" ? color.white : color.primary}}>Current Day</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>filterApply('Current Week')} style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: filter == "Current Week" ? color.primary : color.white, height: 25, paddingHorizontal: 10, marginRight: 5, marginVertical: 5, borderRadius: 20}}>
                        <Text style={{textAlign: 'center', color: filter == "Current Week" ? color.white : color.primary}}>Current Week</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={()=>{setFilter("Custom");setShowCalender(true)}} style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: filter == "Custom" ? color.primary : color.white, height: 25, paddingHorizontal: 10, marginRight: 5, marginVertical: 5, borderRadius: 20}}>
                        <Text style={{textAlign: 'center', color: filter == "Custom" ? color.white : color.primary}}>Custom</Text>
                    </TouchableOpacity>

                </View>

                <View>
                    <Text style={{fontSize: 14, fontWeight: 'bold'}}>{title}</Text>
                    <Text style={{fontSize: 12, marginVertical: 5}}>{subTitle}</Text>
                </View>

                <View style={{backgroundColor: color.white, width: "100%", height: 1, marginVertical: 10}}></View>
 
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <TouchableOpacity style={{flexDirection: 'row'}} onPress={()=>onEmailShare(filterDate)}>
                        <Ionicons name="ios-mail" size={22} color={color.primary}/>
                        <Text style={{fontSize: 14, fontWeight:'bold', color: color.primary, marginLeft: 5}}>Email  </Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{flexDirection: 'row'}} onPress={()=>onDownload(filterDate)}>
                        <Ionicons name="md-download" size={22} color={color.primary}/>
                        <Text style={{fontSize: 14, fontWeight:'bold', color: color.primary, marginLeft: 5}}>Download  </Text>
                    </TouchableOpacity>
                </View> 

                {/* <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <View>
                        <Text style={{fontSize: 14, fontWeight:'bold', color: color.primary, marginLeft: 5}}>Email</Text>
                    </View>
                    <View>
                        <Text style={{fontSize: 14, fontWeight:'bold', color: color.primary, marginLeft: 5}}>Download  </Text>
                    </View>
                </View> */}
        </View>    
    )
}



