import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TextStyle } from 'react-native';
import {  Card, Text, Modal } from "@ui-kitten/components";
import { color, spacing } from "../../../../theme";
import { CustomFieldsPopupProps } from "./custom-fields-popup.props";
import { Button, Dropdown, Switch, TextField } from "../../../../components";

const CENTER: ViewStyle = {
  flex: 1,
}

const LIGHTTEXT: TextStyle = {
  color: color.dim,
  fontSize: 13,
  marginHorizontal: 7,
  marginVertical: 1
}

const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

export const CustomFieldsPopup: Component<CustomFieldsPopupProps> = props => {
  const {
    mode,
    customDetails,
    display,
    close,
    save,
  } = props

  const [modalVisible, setModalVisibility] = React.useState(false);
  const [customFields, setCustomFields] = React.useState({
    FieldName: null, FieldType: null, IsMandatory: false, IsDisabled: false, DefaultValue: null
  });  
  const [error, setError] = React.useState(null);

  useEffect(() => {
    if(!customDetails) {
      setCustomFields({
        FieldName: null, FieldType: null, IsMandatory: false, IsDisabled: false, DefaultValue: null
      })
    } else {
      setCustomFields({
        FieldName: customDetails['FieldName'], FieldType: customDetails['FieldType'], 
        IsMandatory: customDetails['IsMandatory'], IsDisabled: customDetails['IsDisabled'], DefaultValue: customDetails['DefaultValue']
      })
    }

    setModalVisibility(display)
  }, [display]);

  const getField = (item) => {
    switch (item) {
        case 'FieldName':
            return  <TextField 
                multiline={false} 
                required={true}
                disabled={mode == 'edit' ? true: false}
                style={{maxHeight: 100}} 
                defaultValue={customDetails[item]} 
                label={item}  
                getText={text => setCustomFields(prevState => ({ ...prevState, FieldName: text }))}>
              </TextField> 
        case 'FieldType':
            return <Dropdown 
                required={true} 
                defaultValue={customDetails[item]} 
                dropdownList={[{id: "Text", name: "Text"},{id: "Number", name: "Number"},{
                    id: "Discount", name: "Scanner"}, {id: "Time", name: "Time"}]} 
                title="Field Type" 
                getSelectedItem={item => {
                  setCustomFields(prevState => ({ ...prevState, FieldType: item.id }));
                }}        
            ></Dropdown>
        case 'IsMandatory':
            return <Switch 
                value={customDetails[item]} 
                onToggle={(value) => setCustomFields(prevState => ({ ...prevState, IsMandatory: value }))} 
                title={item} 
            ></Switch>
        case 'IsDisabled':
            return <Switch 
                value={customDetails[item]} 
                onToggle={(value) => setCustomFields(prevState => ({ ...prevState, IsDisabled: value }))} 
                title={item} 
            ></Switch>
        case 'DefaultValue':
            return  <TextField 
                multiline={false} 
                style={{maxHeight: 100}} 
                defaultValue={customDetails[item]} 
                label={item}  
                getText={text => setCustomFields(prevState => ({ ...prevState, DefaultValue: text }))}>
              </TextField> 
        default:
            return true
    }
  }

  const saveCustomFields = ()=> {
    if(customFields['FieldName'] && customFields['FieldType']) {
      setError(null)
      save(customFields, mode)
      close()
    } else {
      setError('Missing mandatory fields')
    }
  }

  return (
    
    <View>

      <Modal visible={modalVisible}
        backdropStyle={BACKDROP}
        onBackdropPress={() => {close();setModalVisibility(false)}}
        style={{padding: 20}}>
        
        <Card>
            <View style={CENTER}>
                { customDetails ?
                    <View>
                        <Text style={{padding: spacing.small, marginTop: 20, marginBottom: 5, fontWeight: 'bold'}}>Custom Fields</Text>
                        {  Object.keys(customDetails).map((item, index) => 
                                <View style ={{flexDirection: 'column', paddingHorizontal: spacing.small}}>
                                    { 
                                        getField(item)
                                    }
                                </View>
                            )
                        }
                    </View> : null
                }
            </View>
          

            <View style={[LIGHTTEXT, {marginTop: 10, alignItems: "center"}]}>
                { error ?
                  <Text style={{fontSize: 12, color: color.error}}>{error}</Text> : null 
                }
                <View style={[LIGHTTEXT, {flexDirection: 'row', justifyContent: "center"}]}>
                  <Button
                      preset="primary_sm"
                      style={{margin: 10}}
                      text="Save"
                      onPress={saveCustomFields}/>
                  <Button
                      preset="reject_selected"
                      style={{margin: 10}}
                      text="Close"
                      onPress={close}/>
                </View>
            </View>
        </Card>
      
      </Modal>

  </View>
  
  );
};