import { types } from "mobx-state-tree"

export const UserModel = types
.model()
.props({
    accessToken: types.maybeNull(types.optional(types.string, "")),
    refreshToken: types.maybeNull(types.optional(types.string, "")),
    deviceId: types.maybeNull(types.optional(types.string, "")),
    businessNumber: types.maybeNull(types.optional(types.string, "")),
    businessTypeId: types.maybeNull(types.optional(types.number, 0)),
    isSignUp: types.optional(types.boolean, false),
    businessStatus: types.optional(types.string, ""),
    isFirstTimeUser: types.maybeNull(types.optional(types.boolean, true))
})
.actions(self=>({
    setAccessToken(accessToken){
        self.accessToken = accessToken
    },
    setRefreshToken(refreshToken){
        self.refreshToken = refreshToken
    },
    setDeviceId(deviceId){
        self.deviceId = deviceId
    },
    toggleIsSignUp(value){
        self.isSignUp = value
    },
    setBusinessStatus(status){
        self.businessStatus = status
    },
    setFirstTimeUser(value){
        self.isFirstTimeUser = Boolean(value)
    }

}))
.views(self=> ({
    getAccessToken(){
        return { accessToken: self.accessToken }
    },
    getRefreshToken(){
        return self.refreshToken
    },
    getIsSignUp(){
        return { isSignUp: self.isSignUp }
    },
    getBusinessStatus(){
        return self.businessStatus
    },
    getFirstTimeUser(vaue){
        return self.isFirstTimeUser
    }

}))

