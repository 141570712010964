/**
 * The root navigator is used to switch between major navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow (which is contained in your PrimaryNavigator) which the user
 * will use once logged in.
 */
import React, { useEffect } from "react"
import { NavigationContainer, NavigationContainerRef } from "@react-navigation/native"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { PrimaryNavigator } from "./primary-navigator"
// import { accessToken } from "./../models/app-model/application_model"
import { AutheticationNavigator } from "./authentication-navigator";
import { load } from "../utils/storage";
import { useStores } from "../models";
import { observer } from "mobx-react-lite"
import { WelcomeNavigator } from "./welcome-navigator";
import { getNetworkState } from "../utils/network";
import { NoInternetScreen } from "../screens/landing-screen/no-internet-screen";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * We recommend using MobX-State-Tree store(s) to handle state rather than navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type RootParamList = {
  primaryStack: undefined
  autheticationStack: undefined
  welcomeStack: undefined
  noInternetScreen:  undefined
}

const Stack = createStackNavigator<RootParamList>()


const RootStack = observer(() => {

  const  {userStore, networkStore} = useStores()

  useEffect(()=>{
    load("accessToken").then(userStore.setAccessToken)
    load("isFirstTimeUser").then(userStore.setFirstTimeUser)
    networkStore.create(getNetwork())

  }, [])

  const getNetwork = async () =>{
    var netState = await getNetworkState()
    return netState
  }

  const config = {
    animation: 'spring',
    config: {
      stiffness: 850,
      damping: 100,
      mass: 3,
      overshootClamping: false,
      restDisplacementThreshold: 0.01,
      restSpeedThreshold: 0.01,
    },
  };

  return (
    <Stack.Navigator
        screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          // gestureDirection: 'horizontal',
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          transitionSpec: {
            open: config,
            close: config
          }
      }}
    >  
        { networkStore.isConnected ?
           userStore.accessToken && userStore.businessStatus == 'Active' && userStore.isFirstTimeUser
            ? 
                      <Stack.Screen
                        name="welcomeStack"
                        component={WelcomeNavigator}
                        options={{headerShown: false}}/> 
                  
            :  
              userStore.accessToken && userStore.businessStatus == 'Active' ? 

                      <Stack.Screen
                        name="primaryStack"
                        component={PrimaryNavigator}
                        options={{headerShown: false}}/>  
              :
                userStore.accessToken && userStore.businessStatus != 'Active' ? 
                
                      <Stack.Screen
                        name="welcomeStack"
                        component={WelcomeNavigator}
                        options={{headerShown: false}}/>
                  :
                      <Stack.Screen
                        name="autheticationStack"
                        component={AutheticationNavigator}
                        options={{headerShown: false}}/>
            : 
            <Stack.Screen
              name="noInternetScreen"
              component={NoInternetScreen}
              options={{headerShown: false}}/>


        }
              
    </Stack.Navigator>
  )
})


const linking = {
  config: {
    primaryStack: {
      path: "",
      screens: {
        configurationStack : {
          path: "configure",
          screens: {
            operating_days: "operating_days"
          }
        },
        profileStack: {
          path: "profile",
          screens: {
            business_info: "business_info"
          }
        },
        dashboardStack: {
          path: "dashboard",
          screens: {
            new_orders: "new_orders"
          }
        },
      }
    }, 
    autheticationStack: {
      path: "auth",
      screens: {
        signup: "signup"
      }
    },
    welcomeStack: {
      path: "welcome",
    },
    NotFound: "404",
  },
};

export const RootNavigator = React.forwardRef<
  NavigationContainerRef,
  Partial<React.ComponentProps<typeof NavigationContainer>>
>((props, ref) => {
  return (
    <NavigationContainer {...props} ref={ref} linking={linking}>
      <RootStack />
    </NavigationContainer>
  )
})

RootNavigator.displayName = "RootNavigator"
