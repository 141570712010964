/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"

import { OperatingDaysScreen, CategoryScreen, InventoryScreen, OrderSettingsScreen, TaxDetailsScreen } from "../screens"
import { color } from "../theme";
import { CategoryDetailScreen } from "../screens/configurations-screen/category-detail-screen/category-detail-screen";
import { InventoryDetailScreen } from "../screens/configurations-screen/inventory-detail-screen/inventory-detail-screen";
import { TaxScreen } from "../screens/configurations-screen/tax-screen/tax-screen";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type ConfigurationsParamList = {
    // configurations: undefined
    operating_days: undefined
    category: undefined
    inventory: undefined
    order_settings: undefined
    tax: undefined
    tax_details: undefined
    category_details: undefined
    inventory_detail: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<ConfigurationsParamList>()


const config = {
  animation: 'spring',
  config: {
    stiffness: 500,
    damping: 100,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};


export function ConfigurationsNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: true,
        // gestureDirection: 'horizontal',
        cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
        transitionSpec: {
          open: config,
          close: config
      }
  }}
    >
      {/* <Stack.Screen name="configurations" component={ConfigurationsScreen} options={{title: "Configurations"}} /> */}
      <Stack.Screen name="operating_days" component={OperatingDaysScreen} options={{title: "Operating Days"}}/>

      <Stack.Screen name="category" component={CategoryScreen} options={{title: "Configure Category"}}/>
      <Stack.Screen name="category_details" component={CategoryDetailScreen} options={{title: "Category Details"}} />

      <Stack.Screen name="inventory" component={InventoryScreen} options={{title: "Inventory"}}/>
      <Stack.Screen name="inventory_detail" component={InventoryDetailScreen} options={{title: "Add Inventory"}}/>

      <Stack.Screen name="order_settings" component={OrderSettingsScreen} options={{title: "Order Settings"}}/>

      <Stack.Screen name="tax" component={TaxScreen} options={{title: "Tax Details"}}/>
      <Stack.Screen name="tax_details" component={TaxDetailsScreen} options={{title: "Tax Details"}}/>

    </Stack.Navigator>
  )
}




/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = []
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
