import React, { FunctionComponent as Component, useEffect } from "react"
import { Text, View, ScrollView, ViewStyle, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Button, TextField, MapPicker, Dropdown } from "../../../components";
import { color } from "../../../theme";
import { useStores } from "../../../models";
import { OtpModal } from "../components/otp-modal/otp-modal";
import { panValidate } from "../../../utils/validation/pan-validation";
import { DocumentPreview } from "../../../components/document-preview/document-preview";

import { Toast } from "../../../components/toast/toast";
import { ApiBusinessServices } from "../../../services/api";
import { load } from "../../../utils/storage";
import GMapComponent from "../../../components/map-picker/gmap-picker"


export const BusinessInfoScreen: Component = observer(function ProfileScreen() {
  
    const { businessDetailsStore, loaderStore, businessLocationsStore, domainStore } = useStores()

    const [showError, setShowError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const [showToast, setShowToast] = React.useState(false)
    const [toastMessage, setToastMessage] = React.useState("")
    const [toastType, setToastType] = React.useState('success')

    useEffect(() => {
        getAppTitles()
        getBusinessInfo()
    }, [])


    const getBusinessInfo = () => {
        const api = new ApiBusinessServices()
        api.getBusinessInfo(domainStore.domainName[0]).then(res => {
            businessDetailsStore.createBusinessInfo(res['data'])
        })
    }

    const getAppTitles = async () => {
        var businessTypeId = await load("businessTypeId")
        domainStore.create(businessTypeId)
    }


    const updateBusinessInfo = () => {
        checkForMandatoryFields()
    }

    const checkForMandatoryFields = () => {

        setShowError(true)

        const success = businessDetailsStore.mandatoryCheckAuthInfo() && businessDetailsStore.mandatoryCheckBusinessInfo()
        // const aadhaarValidation = aadhaarValidate(businessDetailsStore.businessInfo.aadharHash)
        const panValidation = businessDetailsStore.businessInfo.panCardHash && businessDetailsStore.businessInfo.panCardHash.length ? 
                panValidate(businessDetailsStore.businessInfo.panCardHash): true

        if(success && panValidation){
            setErrorMessage(null)
            const api = new ApiBusinessServices()
            var data = businessDetailsStore.getBusinessPostModel()
            loaderStore.showLoader()
            api.updateBusinessInfo(data).then(res => {

                loaderStore.hideLoader()

                if(res.kind == "ok"){
                    setToastMessage("Data updated successfully")
                    setToastType('success')
                    setShowToast(true)
                }
            })
        } else {
            // !aadhaarValidation && !panValidation ? setErrorMessage("Please enter a valid Aadhaar and Pan Number") : !aadhaarValidation ?
            //     setErrorMessage("Please enter a valid Aadhaar Number") : !panValidation ? 
            //     setErrorMessage("Please enter a valid Pan Number") : null

            !panValidation ? setErrorMessage("Enter a valid Pan Number") : setErrorMessage("Error updating temple info")

        }
    }
    const handleAddressChange = (address, addressDetails, latitude, longitude) => {
        const selectedLoc = {
            latitude: latitude,
            longitude: longitude
        };
        if (businessDetailsStore.businessInfo.latitude != latitude || businessDetailsStore.businessInfo.longitude != longitude) {
            businessDetailsStore.updateAddressLaneOne(address)
            businessDetailsStore.updateLatLng(selectedLoc)
            businessDetailsStore.updateZipCode(addressDetails.pincode)
        }
    }
        

  const inputFieldForBusinessType = () => {
      return (
        <View style={{ width: "90%" }}>
            <TextField disabled={true} label={domainStore.businessName + ' Name'}  defaultValue={businessDetailsStore.businessInfo.businessName} getText={text => businessDetailsStore.updateBusinessName(text)}></TextField>
            <TextField disabled={true} label='Business Type'  defaultValue={domainStore.businessName}></TextField>

            <TextField multiline={true} style={{maxHeight: 100}} label={domainStore.businessName + ' Description'}  defaultValue={businessDetailsStore.businessInfo.businessDescription} getText={text => businessDetailsStore.updateBusinessDescription(text)}></TextField>


            <Text style={{fontSize: 14, color: color.dim, marginTop: 10, marginLeft: 8}}>Administrator Name</Text>
            <View style={{flexDirection: 'row'}}>
                <View style={{flexGrow:1, minWidth:"50%", maxWidth: "50%"}}>
                <TextField label="First Name" required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.firstName}  getText={text => businessDetailsStore.updateFirstName(text)} ></TextField> 
                </View>
                <View style={{flexGrow:1, minWidth: "50%", maxWidth: "50%"}}>
                    <TextField label="Last Name" required={true} showError={showError} defaultValue={businessDetailsStore.businessInfo.lastName} getText={text => businessDetailsStore.updateLastName(text)} ></TextField> 
                </View>
            </View>

            <TextField required={true} showError={showError} label={domainStore.businessName + ' Location'}  defaultValue={businessDetailsStore.businessInfo.businessLocation} getText={text => businessDetailsStore.updateBusinessLocation(text)} ></TextField>

            <Dropdown title="Operational Location" dropdownList={businessLocationsStore.businessLocations} defaultValue={businessDetailsStore.businessInfo.operationLocationId} required={true} showError={showError} getSelectedItem={item => businessDetailsStore.updateOperationLocation(item)}></Dropdown>

              { Platform.OS != "web" ?
                <MapPicker required={true} showError={showError} defaultLatitude={businessDetailsStore.businessInfo.latitude} defaultLongitude={businessDetailsStore.businessInfo.longitude}
                        defaultAddress={businessDetailsStore.businessInfo.addressLaneOne} getLatLng={latLng => businessDetailsStore.updateLatLng(latLng)}
                        getAddress={address=> businessDetailsStore.updateAddressLaneOne(address)}></MapPicker>
                  : <GMapComponent onAddressChange={handleAddressChange} selectedAddress={businessDetailsStore.businessInfo} />}
            <TextField required={true} showError={showError} label='Address 1' multiline={true} style={{maxHeight: 100}} getText={text => businessDetailsStore.updateAddressLaneOne(text)} defaultValue={businessDetailsStore.businessInfo.addressLaneOne}></TextField>
            <TextField label='Address 2'  multiline={true}  getText={text => businessDetailsStore.updateAddressLaneTwo(text)} defaultValue={businessDetailsStore.businessInfo.addressLaneTwo}></TextField>
            <TextField label='Landmark'  getText={text => businessDetailsStore.updateLandmark(text)} defaultValue={businessDetailsStore.businessInfo.landmark}></TextField>

            <TextField required={true} showError={showError} label='ZIP Code'  getText={text => businessDetailsStore.updateZipCode(text)} keyboardType="numeric" defaultValue={businessDetailsStore.businessInfo.zipCode}></TextField>
            
            <TextField label='Website'  defaultValue={businessDetailsStore.businessInfo.website} getText={text => businessDetailsStore.updateWebsite(text)}></TextField>

            <OtpModal target={businessDetailsStore.businessInfo.email} targetType="email" isVerified={businessDetailsStore.businessInfo.emailVerified}
                                showLoader={x=> x == true ? loaderStore.showLoader() : loaderStore.hideLoader()}></OtpModal>

            {/* <TextField label='Aadhaar Number' required={true} showError={showError} getText={text => businessDetailsStore.updateAadharHash(text)} defaultValue={businessDetailsStore.businessInfo.aadharHash}></TextField>   */}
            <TextField label='PAN Card Number' showError={showError} getText={text => businessDetailsStore.updatePanCardHash(text)} defaultValue={businessDetailsStore.businessInfo.panCardHash}></TextField>
            <TextField label='Registration Number' defaultValue={businessDetailsStore.businessInfo.registrationNumber} getText={text => businessDetailsStore.updateRegistrationNumber(text)}></TextField>
              
            <Text style={{fontSize: 16, marginTop: 20, color: color.grey, marginLeft: 8, marginBottom: 10}}>Bank Details</Text>
            <TextField disabled={true} label='Account Number' defaultValue={businessDetailsStore.businessInfo.bankDetails.bankAccountNumber} getText={text => businessDetailsStore.updateBankAccountNumber(text)} keyboardType="numeric"></TextField>
            <TextField disabled={true} label='IFSC Code' defaultValue={businessDetailsStore.businessInfo.bankDetails.bankingIfsCode} getText={text => businessDetailsStore.updateBankingIfsCode(text)}></TextField>
            <TextField disabled={true} label='Account Holder Name' defaultValue={businessDetailsStore.businessInfo.bankDetails.accountHolderName} getText={text => businessDetailsStore.updateAccountHolderName(text)}></TextField>

            { businessDetailsStore.businessInfo.bankDetails.bankDocumentId ?  <DocumentPreview label="Document" documentId={businessDetailsStore.businessInfo.bankDetails.bankDocumentId}></DocumentPreview>:null}
            
        
        </View>
      ) 
    }
  




  return (
    <Screen preset="fixedHeight" headerTitle="Business Information" showHeader={true} subTitle="Configure business details" showBackButton={true}>

        { showToast ?
            <Toast 
                    display={showToast} 
                    close={()=>setShowToast(false)} 
                    message={toastMessage}
                    nextNavigationPath={{stack: 'configurationStack', screen: 'order_settings'}}
                    type={toastType}>    
            </Toast> : null
        }

        <View style={CONTAINER}>



            <ScrollView style={{ flex: 1, width: "100%" }}>
                <View style={BOTTOM_CARD}>
                    {inputFieldForBusinessType()}

                    <Text style={{color: color.ananthamOrange, fontSize: 13, marginTop: 5}}>{errorMessage}</Text>
                    <Button
                        preset="primary"
                        style={{ marginVertical: 20 }}
                        text="Update"
                        onPress={() => updateBusinessInfo()}
                    />
                </View>
            </ScrollView>
        </View>
    </Screen>
  )
})

  
  
  
const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.background,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center"
}

const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  paddingVertical: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  alignItems: "center",
  bottom: 0,
  marginTop: 20,
}
