// import React, { useState, useEffect, useCallback, Component } from 'react';
import React, { FunctionComponent as Component, useState, useEffect } from "react"

import {
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Modal,
  Image,
  TextStyle
} from 'react-native';
import MapView from 'react-native-maps';


import { color, spacing } from '../../theme';
import { Text, Button, Icon } from '../../components';
import * as Location from 'expo-location';
import { ApiGoogleServices } from '../../services/api/api-methods/google-services';
import { MapPickerProps } from './map-picker.props';
const { GOOGLE_MAPS_API_KEY } = require("../../config/env")

export const MapPicker: Component<MapPickerProps> = props => {
  const {
    defaultLatitude,
    defaultLongitude,
    defaultAddress,
    getAddress,
    getLatLng,
    required,
    showError,
  } = props

  const [selectedLocation, setSelectedLocation] = useState();

  const [mapRegion, setMapRegion] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [address, setAddress] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [locationModalVisible, setLocationModalVisible] = useState(false);

  useEffect(() => {
      (async () => {
        let { status } = await Location.requestPermissionsAsync();  
        if (status !== 'granted') {
          setErrorMsg('Permission to access location was denied');
        }

        let location = await Location.getLastKnownPositionAsync({});
        // setLocation(location);
        setMapRegion({
          latitude: defaultLatitude ? parseFloat(defaultLatitude) : location.coords.latitude,
          longitude: defaultLongitude? parseFloat(defaultLongitude) : location.coords.longitude,
          latitudeDelta: 0.01,
          longitudeDelta: 0.01
        });

        if(defaultLatitude && defaultLongitude){
          var latitude = parseFloat(defaultLatitude)
          var longitude = parseFloat(defaultLongitude)

          setSelectedLocation({
            latitude: latitude,
            longitude: longitude
          });

          setConfirmed(true)

          setAddress(defaultAddress)
          setImagePreview(latitude, longitude)
         }

      })();
  }, []);

  const checkLocationServicesEnabled = async () => {
    const servicesEnabled = await Location.hasServicesEnabledAsync();
    if (!servicesEnabled) {
      setErrorMsg('Please enable location services and then press OK to continue.');
      setLocationModalVisible(true);
      return false;
    }
    return true;
  };


  const selectLocationHandler = event => {
    getReverseAddress(event)
    setMapRegion({
      latitude: event.nativeEvent.coordinate.latitude,
      longitude: event.nativeEvent.coordinate.longitude,
      latitudeDelta: 0.01,
      longitudeDelta: 0.01
    });
    setSelectedLocation({
      latitude: event.nativeEvent.coordinate.latitude,
      longitude: event.nativeEvent.coordinate.longitude
    });
    setErrorMsg(null)
  };


  const [modalVisible, setModalVisibility] = React.useState(false);

  const submit = event => {
    if(selectedLocation && selectedLocation.latitude && selectedLocation.longitude){

      setConfirmed(true)
      setErrorMsg(null)
      getAddress(address)
      getLatLng(selectedLocation)
      setModalVisibility(false)

      setImagePreview(selectedLocation.latitude, selectedLocation.longitude)

    } else {
      setConfirmed(false)
      setErrorMsg("Please pick a location")
    }
  }


  const setImagePreview = (latitude, longitude)=>{
    let imagePreviewUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
      latitude
    },${
      longitude
    }&zoom=20&size=400x200&maptype=roadmap&markers=color:red%7Clabel:A%7C${
      latitude
    },${longitude}&key=${GOOGLE_MAPS_API_KEY}`;
    setImagePreviewUrl(imagePreviewUrl)
  }

  const onPress = async event => {
    const servicesEnabled = await checkLocationServicesEnabled();
    if (servicesEnabled) {
      setErrorMsg(null);
      setLocationModalVisible(false);
      setModalVisibility(true);
    }
  }





  function getReverseAddress(event) {
    const api = new ApiGoogleServices()
    const latitude = event.latitude
    const longitude = event.longitude
    api.getReverseLocation(latitude, longitude).then(res => {
        if(res.kind == "ok"){
          setAddress(res.data.results[0].formatted_address)
        }
    })
  }




  function getAutoCompleteResults(queryString){
    const api = new ApiGoogleServices()
    api.getAutocompletePlaces(queryString).then(res=>{
    })
  }


  const onRegionChange = region => {
    getReverseAddress(region)
    setMapRegion(region);
    setSelectedLocation({
      latitude: region.latitude,
      longitude: region.longitude
    });
    setErrorMsg(null)
  }


  // getReverseAddress(event)
  // setMapRegion({
  //   latitude: event.nativeEvent.coordinate.latitude,
  //   longitude: event.nativeEvent.coordinate.longitude,
  //   latitudeDelta: 0.01,
  //   longitudeDelta: 0.01
  // });
  // setSelectedLocation({
  //   latitude: event.nativeEvent.coordinate.latitude,
  //   longitude: event.nativeEvent.coordinate.longitude
  // });
  // setErrorMsg(null)

  const marker = require('../../../assets/pin.png')


  return (
    <View style={{margin: 10}}> 

        <TouchableOpacity onPress={onPress}>

        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Icon icon="location_marker"></Icon>
              <Text preset="fieldLabel" text="Pick a location" style={{color: color.ananthamBlue, fontSize: 15, marginVertical: 5, marginLeft:5}} />
              
              { required ?
              <Text style={{color: color.ananthamOrange}}> *</Text> : <View></View>
              }
            </View>

            { required && showError && (!confirmed || (selectedLocation == undefined))?
                <View>
                  <Text style={{...LABEL_ERROR}}>Required</Text>
                </View> : <View></View>
              }

          </View>

          
          <View>
          { selectedLocation && imagePreviewUrl ? 
            <Image style={{width: "100%", height: 150, marginTop: 5, marginBottom: 5}} source={{ uri: imagePreviewUrl }} /> :
            <View></View>
          }
          </View>
       </TouchableOpacity>


        <Modal
            visible={modalVisible}
            animationType={'slide'}
            transparent={false}
            onRequestClose={() => setModalVisibility(false)}>
          
          <View style={{flex: 1}}>

            {/* <AutoComplete list={}></AutoComplete> */}
      
              <MapView
                style={STYLESMAP.map}
                initialRegion={mapRegion}
                showsUserLocation={true}
            maxZoomLevel={20}
            zoomControlEnabled={true}
                onRegionChangeComplete={region => onRegionChange(region)}
              /> 
            <View style={STYLESMAP.markerFixed}>
              <Image style={[STYLESMAP.marker]} source={marker} />
            </View>

              
            <View style={{ margin: 20}}>

                <View style={{ marginBottom: 10, alignSelf: 'center'}}>

                  { address ?
                    <View>
                      <Text style={{textAlign: 'center', margin:5}}>{address}</Text>
                    </View> : 
                    <View>
                        <Text>No Location Selected</Text>
                    </View>
                  }
                </View>


                <View>

                  <View style={{marginBottom: 10, alignSelf: 'center'}}>

                    { errorMsg ?
                      <View>
                        <Text style={{textAlign: 'center', marginTop:5, fontSize: 13, color: color.ananthamOrange}}>{errorMsg}</Text>
                      </View> : null
                    }
                  </View>
                  
                  <View style={{flexDirection: 'row', alignSelf: 'center'}}>

                      <Button
                          preset="primary_sm"
                          style={{marginHorizontal: 5, width: 150, height: 46, borderRadius: 23}}
                          text="Confirm"
                          onPress={submit}>
                      </Button>

                      <Button
                          preset="secondary_sm"
                          style={{marginHorizontal: 5, width: 150, height: 46, borderRadius: 23}}
                          text="Close"
                          onPress={()=> setModalVisibility(false)}>
                      </Button>
                      
                  </View>

                </View>

              </View>

          </View>
          

        </Modal>
      <Modal
        visible={locationModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setLocationModalVisible(false)}
      >
        <View style={STYLESMAP.centeredView}>
          <View style={STYLESMAP.modalView}>
            <Text style={STYLESMAP.modalText}>{errorMsg}</Text>
            <Button
              preset="secondary"
              text="OK"
              onPress={onPress}
            />
          </View>
        </View>
      </Modal>
      </View>
  );
};



const STYLESMAP = StyleSheet.create({
  map: {
    maxHeight: "80%",
    flexGrow: 1,
    width: "100%",
  },
  headerButton: {
    marginHorizontal: 20
  },
  headerButtonText: {
    fontSize: 16,
    color: Platform.OS === 'android' ? 'white' : color.primary
  },
  markerFixed: {
    left: '50%',
    marginLeft: -20,
    marginTop: -40,
    position: 'absolute',
    top: '40%'
  },
  marker: {
    height: 40,
    width: 40
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    color: color.ananthamOrange,
  },
});

const LABEL_ERROR: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}