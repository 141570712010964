import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, TextField } from "../../../components";
import { color } from "../../../theme";
import { ApiSignup } from "../../../services/api";
import { useStores } from "../../../models";
import { ImageUpload } from "../../../components/image-upload/image-upload";




export const UserInfoScreen: Component = observer(function ProfileScreen() {
  

  const { businessDetailsStore, loaderStore } = useStores()


  const uploadImage = (imageUrl) => {
      const api = new ApiSignup()
      api.ownerImage(imageUrl).then(res => {
        if(res.kind == "ok"){
          businessDetailsStore.updateBusinessAvatarUri(res.data['url'])
        }
      })
  }

  return (
    <Screen preset="fixedHeight" headerTitle="User Information" showHeader={true} subTitle="Configure User Details" showBackButton={true} loader={loaderStore.loader}>

            <View style={CONTAINER}>

                <View style={{ flex: 1, justifyContent: "flex-end", height: "100%", width: "100%" }}>
                    <View>
                        <View style={BOTTOM_CARD}>

                            <View style={{ width: "90%" }}>
                              <TextField label='User Full Name' disabled={true} defaultValue={businessDetailsStore.businessInfo.fullName}></TextField>

                              <ImageUpload defaultImageUrl={businessDetailsStore.businessInfo.businessAvatarUri} getImage={x=> {uploadImage(x)}}></ImageUpload>

                            </View>

                        
                        </View>
                    </View>
                </View>

            </View>
    </Screen>
)
})

const CONTAINER: ViewStyle = {
  flex: 1,
  backgroundColor: color.background,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center"
}

const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  paddingVertical: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  alignItems: "center",
  bottom: 0,
  marginTop: 20,
}
  
