import { types } from "mobx-state-tree"


const Days = types
.model({
    Monday: types.optional(types.boolean, false),
    Tuesday: types.optional(types.boolean, false),
    Wednesday: types.optional(types.boolean, false),
    Thursday: types.optional(types.boolean, false),
    Friday: types.optional(types.boolean, false),
    Saturday: types.optional(types.boolean, false),
    Sunday: types.optional(types.boolean, false),
})

const DaysId = types
.model({
    Monday: types.optional(types.number, 2),
    Tuesday: types.optional(types.number, 3),
    Wednesday: types.optional(types.number, 4),
    Thursday: types.optional(types.number, 5),
    Friday: types.optional(types.number, 6),
    Saturday: types.optional(types.number, 7),
    Sunday: types.optional(types.number, 1),
})

export const OperatingDaysModel = types
.model()
.props({
  defaultDays: types.optional(DaysId, {}),
  supplierDays: types.optional(Days, {}),
  selectedDaysId: types.array(types.number)
})
.actions(self=>({
    updateDefaultDays(days){
      self.defaultDays = days
    },
    updateSupplierDays(days){
      self.supplierDays = days
    },
    updateDaysId(idArray){
      self.selectedDaysId = idArray
    },
    reset(){
      self.defaultDays.Monday = 2
      self.defaultDays.Tuesday = 3
      self.defaultDays.Wednesday = 4
      self.defaultDays.Thursday = 5
      self.defaultDays.Friday = 6
      self.defaultDays.Saturday = 7
      self.defaultDays.Sunday = 1
      self.supplierDays.Monday = false
      self.supplierDays.Tuesday = false
      self.supplierDays.Wednesday = false
      self.supplierDays.Thursday = false
      self.supplierDays.Friday = false
      self.supplierDays.Saturday = false
      self.supplierDays.Sunday = false      
      self.selectedDaysId.clear()
    }
}))
.views(self=>({
  getAddModel(){
    return {
      days: self.selectedDaysId
    }
  }
}))