import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TouchableOpacity, Image, TextStyle } from "react-native"
import moment from 'moment';
import {Calendar} from 'react-native-calendars';
import { Modal, Card } from "@ui-kitten/components";
import { Ionicons } from "@expo/vector-icons";
import { DateFieldProps } from "./date-field.props";
import { spacing, color } from "../../../../theme";
import { Text }  from "../../../../components/text/text";

// static styles
const ROOT: ViewStyle = {
  paddingHorizontal: spacing.xSmall,
}

const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

const LABEL_LG: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}

const LABEL_ERROR: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const DateField: Component<DateFieldProps> = props => {
  const {
    title,
    getDate,
    initialDate,
    titleStyle,
    required,
    showError
  } = props


  const [date, setDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [currDate, setCurrDate] = useState();

  useEffect(()=>{
    if(initialDate){
      var dateLoc = moment(initialDate, 'YYYY-MM-DD')
      setDate(dateLoc)
      getDate(moment(initialDate).format('YYYY-MM-DD'))

      var selectedDateObject = {}
      selectedDateObject[moment(initialDate).format('YYYY-MM-DD')] = {selected: true, color: color.primary, textColor: color.white}
      setSelectedDate(selectedDateObject) 
      setCurrDate(moment().format('YYYY-MM-DD'))

    } else {
      setDate(null)
    }
  }, [initialDate])


  const onTimeChange = (time) => {
    setDate(moment(time["dateString"]).format('MMM DD, YYYY'))
    getDate(moment(time["dateString"]).format('YYYY-MM-DD'))


    var selectedDateObject = {}
    selectedDateObject[moment(time["dateString"]).format('YYYY-MM-DD')] = {selected: true, color: color.primary, textColor: color.white}
    setSelectedDate(selectedDateObject) 

    setModalVisibility(false)
  };

  const [modalVisible, setModalVisibility] = useState(false);


  return (
    <View style={{ ...ROOT }}>
        
  
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

          <View style={{flexDirection: 'row'}}>
          <Text text={title}   style={{...LABEL_LG, ...titleStyle}}/>
          { required ?
            <View>
              <Text style={{color: color.ananthamOrange}}> *</Text>
            </View> : null
          }
        </View>

        { required && showError &&  (!date || date == undefined)?
          <View>
            <Text style={{...LABEL_ERROR}}>Required</Text>
          </View> : null
        }

      </View>

      
      <TouchableOpacity onPress={()=> setModalVisibility(!modalVisible)} style={{flexDirection: 'row', backgroundColor: '#f7f9fc', borderColor: '#e4e9f2', 
          borderRadius: 4, borderWidth: 1, paddingVertical: 8}}>
          <Image style={{width: 20, height: 20, marginHorizontal: 15}} source={require('./calender.png')} /> 
          <Text>{ date ? moment(date).format('MMM DD, YYYY'): "Pick a new date"}</Text>
      </TouchableOpacity>


      <Modal visible={modalVisible}
            backdropStyle={BACKDROP}
            onBackdropPress={() => setModalVisibility(false)}>

            <Card>

              <View style={{backgroundColor: color.transparent}}></View>

              <View style={{backgroundColor: color.white, borderTopStartRadius: 15, borderTopEndRadius: 15, width: "100%"}}>

                  <View style={{borderRadius: 10, height: 15}}></View>

                  <Calendar
                    minDate={currDate}
                    markingType={'period'}                        
                    enableSwipeMonths={true}
                    onDayPress={(day) => onTimeChange(day)}
                    markedDates={selectedDate}
                    renderArrow={(direction) => (<Arrow dir={direction}/>)}
                  />

              </View>

            </Card>

         </Modal>
         
    </View>
    
  )
}




const Arrow = (val)=>{
  if(val["dir"] == "left"){
    return <View><Ionicons name="caret-back-outline" size={24} color="black" /></View>
  } else {
    return <View><Ionicons name="caret-forward-outline" size={24} color="black" /></View>
  }
}




