import { types } from "mobx-state-tree"

var businessNames = {
    "2": "Ellam Stores",
    "4": "Temple",
    "6": "Parking"
}
  
var inventoryNames = {
    "2": ["Orders", "Order", "Inventory", "Item", "Items", "Customer"],
    "4": ["Offerings", "Offering", "Offerings", "Offering", "Offerings", "Devotee"],
    "6": ["Orders", "Order", "Inventory", "Item", "Items", "Customer"]
}  

var domainNames = {
    "2": ["open-kitchen", "open-kitchen"],
    "4": ["worship", "worship"],
    "6": ["parking", "parking"]
}

var imagePrefix = {
    "2": "open_kitchen",
    "4": "prarthana",
    "6": "parking"
}
  
 
export const DomainModel = types
.model()
.props({
    businessName: types.maybeNull(types.optional(types.string, "")),
    inventoryName: types.maybeNull(types.optional(types.array(types.string), [])),
    domainName: types.maybeNull(types.optional(types.array(types.string), [])),
    imagePrefix: types.maybeNull(types.optional(types.string, "")),
})
.actions(self=>({
    create(businessTypeId){
        self.businessName = businessNames[businessTypeId]
        self.inventoryName = inventoryNames[businessTypeId]
        self.domainName = domainNames[businessTypeId]
        self.imagePrefix = imagePrefix[businessTypeId]
    },
    updateBusinessName(businessName){
        self.businessName = businessName
    },
    updateInventoryName(inventoryName){
        self.inventoryName = inventoryName
    }
}))

