import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, StyleSheet, Alert, Image, TouchableOpacity, PermissionsAndroid, Platform } from "react-native"
import { spacing, color, styles } from "../../theme"
import { Text } from "../text/text"
import { DocumentPreviewProps } from "./document-preview.props";
import * as FileSystem from 'expo-file-system';
import * as Linking from 'expo-linking';
const { API_URL } = require("../../config/env")

// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const DocumentPreview: Component<DocumentPreviewProps> = props => {
  const {
    documentId,
    label
  } = props


  const [selectedDocument, setSelectedDocument] = React.useState(null);
    

  useEffect(()=>{

    var documentUrl = API_URL + "documents/" + documentId   
    setSelectedDocument(documentUrl)
  }, [])


  const callback = downloadProgress => {
    const progress = downloadProgress.totalBytesWritten / downloadProgress.totalBytesExpectedToWrite;
    // this.setState({
    //   downloadProgress: progress,
    // });
  };

  const downloadResumable = FileSystem.createDownloadResumable(
    'http://techslides.com/demos/sample-videos/small.mp4',
    FileSystem.documentDirectory + 'small.mp4',
    {},
    callback
  );

  async function saveDocument(url){ 

    Linking.openURL(url);
  } 




  return (
    <View style={{ ...ROOT }}>
        

        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

            <View style={{flexDirection: 'row'}}>
            <Text  text={label}  style={{...styles.label_lg}} />
            </View>

        </View>

        { selectedDocument ?    
            <TouchableOpacity onPress={() => saveDocument(selectedDocument)}>
                <Text style={{textDecorationLine: 'underline', color: color.ananthamBlue}}>{selectedDocument}</Text>
            </TouchableOpacity>
            : <View></View>
        }


    </View>
    

  )
}


