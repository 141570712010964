import { types } from "mobx-state-tree";


const CustomeDetails = types
.model()
.props({
    FieldName: types.maybeNull(types.optional(types.string, "")),
    FieldType: types.maybeNull(types.optional(types.string, "")),
    IsMandatory: types.maybeNull( types.optional(types.boolean, false))
})

const categoryModel = types
.model({
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    customDetails: types.maybeNull(types.optional(types.array(CustomeDetails), []))
})

const selectedCategoryModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
    customDetails: types.maybeNull(types.optional(types.array(CustomeDetails), [])),
    isActive: types.maybeNull(types.optional(types.boolean, false))
})

const inventoryModel = types
.model()
.props({
    avatarUri: types.maybeNull(types.optional(types.string, "")),
    description: types.maybeNull(types.optional(types.string, "")),
    shortName: types.maybeNull(types.optional(types.string, "")),
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
})


export const CategoryInventoryLookupModel = types
.model()
.props({
    categories: types.optional(types.array(categoryModel), []),
    inventories: types.optional(types.array(inventoryModel), []),
    selectedCategories: types.optional(types.array(selectedCategoryModel), []),
    selectedCategoryId: types.optional(types.number, 0),
    selectedInventories: types.optional(types.array(inventoryModel), [])
})
.actions(self=>({
    addCategories(categories) {
        self.categories.clear()
        categories.map(x=>{
            var data = {
                id: x.id,
                name: x.groupName,
                customDetails: x.customDetails
            }
            self.categories.push(data)
        })
    } ,
    addInvetories(inventories) {
        self.inventories = inventories
    },
    updateCategory(categoryId){
        self.selectedCategoryId = categoryId
    },
    addSelectedCategories(categories){
        self.selectedCategories.clear()
        categories.map(x=>{
            var data = {
                id: x.id,
                name: x.groupName,
                customDetails: x.customDetails,
                isActive: x.isActive
            }
            self.selectedCategories.push(data)
        })
    }
}))
.views(self=>({
    getInventoryList(categoryId){
        // return self.inventories.get(categoryId)
    },
    getCategory(id){
        return (
            self.categories.filter(x=> x.id == id)
        )
    },
    getInventory(id){
        return(
            self.inventories[id]
        )
    }
}))