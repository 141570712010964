import React, { FunctionComponent as Component, useEffect, } from "react"
import { TextStyle, View,  FlatList, TouchableOpacity, ViewStyle, StyleSheet } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Text, Screen, Icon, Button } from "../../../components"
import { color } from "../../../theme"
import { OrderCard } from "../components/order-card/order-card";
import { useStores } from "../../../models";
import { TopTab } from "../../../components/top-tab/top-tab";
import { ApiOrders } from "../../../services/api/api-methods/api-orders";
import { BottomCard } from "../../../components/bottom-card/bottom-card";
import { DatePickerComponent } from "../../../components/date-picker/date-picker";
import { Toast } from "../../../components/toast/toast";



export const ScheduledOrdersScreen: Component = observer(function ScheduledOrdersScreen() {

    const navigation = useNavigation()
    const { orderListStore, loaderStore, businessDetailsStore, domainStore } = useStores()

    const detailsScreen = (id) => navigation.navigate('order_details', {orderId: id})

    const [orders, setOrders] = React.useState([])
    const [status, setStatus] = React.useState('Accepted')
    const [showBottomCard, setShowBottomCard] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")


    const [showToast, setShowToast] = React.useState(false)
    const [toastMessage, setToastMessage] = React.useState("")
    const [toastType, setToastType] = React.useState('success')
  
    

    useEffect(()=>{
      loaderStore.hideLoader()
      if(orderListStore.showAllAccepted){
        orderListStore.showAllAcceptedOrders()
      } else{
        orderListStore.updateFilterSchedulerOrderDate(orderListStore.filterDate)
      }
      setOrders(orderListStore.filteredAcceptedOrderList)
    }, [])


    const onTabClicked = (index)=>{
      if(index == 0){
        setOrders(JSON.parse(JSON.stringify(orderListStore.filteredAcceptedOrderList)))
        setStatus('Accepted')
      } else {
        setOrders(JSON.parse(JSON.stringify(orderListStore.filteredCompletedOrderList)))
        setStatus('Completed')
      }
    }

    const completeOrderApi = () => {
      const api = new ApiOrders()
      if(orderListStore.checkForTodaysOrder(orderListStore.getCompleteOrderIds())){
        const data = { orderIds: orderListStore.getCompleteOrderIds()}
        api.orderAction(data, 'complete').then(res => {

            if(res.kind == 'ok'){
              orderListStore.removeAcceptedOrderIds(orderListStore.getCompleteOrderIds())
              setShowBottomCard(false)
              setToastMessage("Successfully completed all orders")
              setToastType('success')
              setShowToast(true)
            } else {
              setToastMessage("Failed to complete the orders")
              setToastType('error')
              setShowToast(true)
            }


        })
      } else {
        setErrorMessage(domainStore.inventoryName[0] + " with upcoming dates cannot be completed")
      }
    }


    const shareToMail = (index) => {
      const api = new ApiOrders()
      var data = {
          "status": status == "Accepted" ? "pendingDelivery": "completed",
          "from": !orderListStore.showAllAccepted ? new Date(orderListStore.filterDate) : null,
          "to": !orderListStore.showAllAccepted ? new Date(orderListStore.filterDate) : null
      }
      loaderStore.showLoader()
      if(index.row == 0){
        api.shareOrder(data).then(res => {
            loaderStore.hideLoader()
            if(res.kind == 'ok'){
              setToastType('success')
              setToastMessage("Successfully shared " + status.toLowerCase() + " order list to " + businessDetailsStore.businessInfo.email)
              setShowToast(true)
            } else {
              setToastType('error')
              setToastMessage("Failed to share " + status.toLowerCase() + " order list to " + businessDetailsStore.businessInfo.email)
              setShowToast(true)
            }
        })
      } else {
        api.downalodOrder(data).then(res => {
          loaderStore.hideLoader()
          if(res == 'success'){
            setToastType('success')
            setToastMessage("Successfully downloaded " + status.toLowerCase() + " order list")
            setShowToast(true)
          } else {
            setToastType('error')
            setToastMessage("Failed to download " + status.toLowerCase() + " order list")
            setShowToast(true)
          }
      })
      }
    }
    

    var renderItem = ({item}) => (
      <OrderCard 
        status={status} 
        forDelivery={item.delivery} 
        orderDetails={{
          id: item.orderRefNumber, name: item.userName, itemCount: item.items, amount: item.amount, date: item.date, 
          serviceDate: item.serviceDate, itemDetails: item.itemDetails
        }} 
        onCardPress={()=>detailsScreen(item.id)}></OrderCard>
    );

 
    return (
      <Screen preset="fixedHeight" headerTitle={"Scheduled " + domainStore.inventoryName[0]} showBackButton={true} showHeader={true} showMore={true} onMoreSelect={(x)=>{shareToMail(x)}} loader={loaderStore.loader}>

        <View style={CONTAINER}>

          { showBottomCard ?
            <BottomCard 
              display={showBottomCard} 
              close={()=>setShowBottomCard(false)} 
              proceed={()=>completeOrderApi()}
              orderCount={orderListStore.filteredAcceptedOrderList.length}
              message="Complete all orders"
              subMessage="Note: On proceeding all the accepted orders will be marked complete and moved to completed list."
              errorMessage={errorMessage}></BottomCard>  
            : null 
          }

          { showToast ?
            <Toast 
              display={showToast} 
              close={()=>setShowToast(false)} 
              message={toastMessage}
              type={toastType}></Toast> 
            : null 
          }

          <View style={{marginTop: 10, width: "75%", marginBottom: 10}}>
              <TopTab  labels={['Accepted', 'Completed']} defaultTab={0} onClick={(index)=>onTabClicked(index)}></TopTab>
          </View>

          <View style={BOTTOM_CARD}>

              <View style={{marginTop: 15, marginBottom: 10, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'center'}}>

                <TouchableOpacity 
                    onPress={()=>{orderListStore.showAllAcceptedOrders(); status == "Accepted" ? onTabClicked(0) : onTabClicked(1)}} 
                    style={{alignSelf: 'center', justifyContent: 'center', backgroundColor: orderListStore.showAllAccepted ? color.primary : color.background, width: 150, height: 40, borderRadius: 20}}>
                  <Text style={{textAlign: 'center', color: orderListStore.showAllAccepted ? color.white : color.primary}}>All</Text>
                </TouchableOpacity>
                <DatePickerComponent 
                    isSelected={orderListStore.showAllAccepted} 
                    initialDate={orderListStore.filterDate} 
                    getDate={(date)=>{
                      orderListStore.updateFilterSchedulerOrderDate(date); status == "Accepted" ? onTabClicked(0) : onTabClicked(1)
                    }}
                ></DatePickerComponent>
              
              </View>


              <View style={{flex: 1, marginBottom: 10}}>
                { orders.length ?
                    <View style={{flex: 1 }}>
                      <FlatList
                        data={orders}
                        renderItem={renderItem}
                        keyExtractor={item => item.orderRefNumber}
                      />
                    </View>
                  :
                  <View style={{alignSelf: 'center', alignItems: 'center', height: "100%", justifyContent: 'center'}}>
                    <Icon icon={domainStore.imagePrefix + "_inventory"} style={{width: 60, height: 60}}></Icon>
                    <Text style={{padding: 20}}>{"No New " + domainStore.inventoryName[0]}</Text>
                  </View>
                }

                { orderListStore.filteredAcceptedOrderList.length ?
                  <View style={{flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginTop: 10}}>
                    <Button
                        preset="primary"
                        text="Complete All"
                        onPress={()=>{setErrorMessage(""); setShowBottomCard(true)}}/>
                  </View> : null
                }
              </View>

          </View>
        
        </View>

      </Screen>
  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  backgroundColor: color.background,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  bottom: 0,
  flex: 1
}

