import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TouchableOpacity, TextStyle } from "react-native"
import { TextFieldAndDropdownProps } from "./textfield-and-dropdown.props"
// import { Icon } from "../icon/icon"
import { spacing, color, styles } from "../../theme"
import { Layout, Select, SelectItem, IndexPath, Input } from "@ui-kitten/components";
import { Text } from "../text/text"


// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}


/**
 * Dropdown that appears on many screens. Will hold navigation buttons and screen title.
 */
export const TextFieldAndDropdown: Component<TextFieldAndDropdownProps> = props => {
  const {
    dropdownList,
    placeholder,
    title,
    titleStyle,
    required,
    showError,
    getSelectedItem,
    defaultValue,
    type,
    onChangeType,
    disabled = false
  } = props


  const [selectedIndex, setSelectedIndex] = React.useState();
  const [value, setValue] = React.useState(null);

  const [isTextField, setIsTextField] = React.useState(false);


  useEffect(()=>{
    if(defaultValue){
      // dropdownList.map((item, index) =>{
      //   if(defaultValue == item.id){
      //     setSelectedIndex(new IndexPath(index))
      //     setValue(item['name'])
      //   }
      // })
      setValue(defaultValue)
    } else {
      setValue(null)
      setSelectedIndex(null)
      // setSelectedIndex(0)
    }
  }, [defaultValue])



  useEffect(()=>{
    if(selectedIndex){
      setValue(dropdownList[selectedIndex.row]['name']);
    }  
  }, [selectedIndex])

  return (
    <View style={{...ROOT}}>
      


        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

            <View style={{flexDirection: 'row'}}>
              <Text preset="fieldLabel"  text={title}  style={{...label_lg, ...titleStyle}} />
              { required ?
                <View>
                  <Text style={{color: color.ananthamOrange}}> *</Text>
                </View> : <View></View>
              }
            </View>

            <View style={{flexDirection: 'row'}}>
              { type != 'textfield' ? <TouchableOpacity  onPress={()=> {setIsTextField(!isTextField); onChangeType(isTextField); setSelectedIndex(0)}}>
                {/* <Text style={{...label_link}}> {isTextField ? "Pick from list" : "+ Add New" }</Text> */}
                
                <Text style={{color: color.white, marginLeft: 5, fontSize: 12, backgroundColor: isTextField? color.redStatus : color.link, 
                        borderRadius: 10, paddingHorizontal: 10, paddingVertical: 2}}>
                          {isTextField ? "Pick from list" : "+ Add New" }
                </Text>

              </TouchableOpacity> : null
              }
              { required && showError &&  (value == null)?
                <View style={{marginLeft: 15}}>
                  <Text style={{...label_error}}>Required</Text>
                </View> : <View></View>
              }
            </View>

        </View>

        {
          isTextField || type == 'textfield' ?
          <Input
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onChangeText={nextValue => {setValue(nextValue);getSelectedItem(nextValue)}}
          /> :
            <Select
              selectedIndex={selectedIndex}
              placeholder={placeholder}
              disabled={disabled}
              value={value}
              onSelect={index => {setSelectedIndex(index); getSelectedItem(dropdownList[index['row']])}}>
              {dropdownList.map((r, index) => <SelectItem key={index} title={r.name}/>)}    
            </Select>
        }

        
    </View>
  )
}



const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}

const label_error: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}


const label_link: TextStyle = {
  fontSize: spacing.small,
  color: color.primary,
  marginBottom: spacing.xSmall
}