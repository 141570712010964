import { Ionicons } from '@expo/vector-icons';
import * as React from 'react';
import { Icon } from '..';

export default function TabBarIcon(props) {

  if(props.name == 'dashboard'){

      if(props.focused){
        return(<Icon icon="ellam_selected" style={{height:35,width:35}}/>)
      } else {
        return(<Icon icon="ellam_unselected" style={{height:35,width:35}}/>)
      }

  } else if(props.name == 'config') {

    if(props.focused){
      return(<Icon icon="config_selected"/>)  
    }
    else {
      return(<Icon icon="config_unselected"/>)  
    }

  } else if(props.name == 'profile') {

    if(props.focused){
      return(<Icon icon="profile_selected"/>)  
    }
    else {
      return(<Icon icon="profile_unselected"/>)  
    }

  } else {
    return(<Icon icon="profile_unselected"/>)  
  }


  // return (
  //   // <Ionicons
  //   //   name={props.name}
  //   //   size={30}
  //   //   style={{ marginBottom: -3 }}
  //   //   color={props.focused ? color.primary : color.dim}
  //   // />

    
    
  //   <Icon icon={iconName} />

  // );
}
