import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, ScrollView } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Screen } from "../../components"
import { color } from "../../theme"
import { ConfigCard } from "./components/config-card/config-card";
import { ApiOperatingDaysConfig } from "../../services/api/api-methods/api-operaring-days-config";
import { useStores } from "../../models";
import { getNetworkState } from "../../utils/network";
import { load } from "../../utils/storage";


export const ConfigurationsScreen: Component = observer(function ConfigurationsScreen() {


  const navigation = useNavigation()

  const { operatingDaysStore, networkStore, domainStore } = useStores()



  useEffect(()=>{
    getAppTitles()
    checkNetworkState()
    getOperatingDays()
  }, [])

  const getAppTitles = async () => {
    var businessTypeId = await load("businessTypeId")
    domainStore.create(businessTypeId)
}


  const navigateTo = (path) => {
    checkNetworkState()
    navigation.navigate('configurationStack', {screen: path})
  } 

  function getOperatingDays(){
    const api = new ApiOperatingDaysConfig()
    api.getAllOperatingDays().then(res => {
      'statusCode' in res['data'] ?  delete res['data']['statusCode'] : null
      operatingDaysStore.updateDefaultDays(res['data'])

    })
  }

  const checkNetworkState = async () => {
    var netState = await getNetworkState()
    networkStore.create(netState)
  }
  

  return (
      <Screen preset="fixed" headerTitle="Configurations Screen" showBackButton={false}>
          <View style={card_container}>
            <ScrollView contentContainerStyle={{justifyContent: 'center', alignItems: 'center'}}>
                <ConfigCard cardText="Configure Operating Days" icon="operating_days2" 
                            cardTheme={color.primary} onCardPress={()=> navigateTo('operating_days')}></ConfigCard>
                <ConfigCard cardText="Configure Settings" icon="settings2" 
                            cardTheme={color.primary} onCardPress={()=> navigateTo('order_settings')}></ConfigCard>
                <ConfigCard cardText="Configure Category" icon={domainStore.imagePrefix + "_category"} 
                            cardTheme={color.primary} onCardPress={()=> navigateTo('category')}></ConfigCard>
                <ConfigCard cardText={"Configure " + domainStore.inventoryName[2]} icon={domainStore.imagePrefix + "_inventory"} 
                            cardTheme={color.primary} onCardPress={()=> navigateTo('inventory')}></ConfigCard>
                {/* <ConfigCard cardText="Configure Tax Details" icon="tax_details" 
                            cardTheme={color.primary} onCardPress={()=> navigateTo('scheduled_orders')}></ConfigCard> */}
            </ScrollView>
          </View>
      </Screen>
  )
})


const card_container: ViewStyle = {
  flex: 1,
  flexDirection: 'row',
  justifyContent: "center",
  alignItems: 'center',
}




