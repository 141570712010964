import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, StyleSheet, Alert, Image, TouchableOpacity } from "react-native"
import { spacing, color } from "../../theme"
import { Text } from "../text/text"
import { ImageUploadProps } from "./image-upload.props";
import * as ImagePicker from 'expo-image-picker';

// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ImageUpload: Component<ImageUploadProps> = props => {
  const {
    defaultImageUrl,
    getImage
  } = props


  const [selectedImage, setSelectedImage] = React.useState(null);
  const cross_arrow_white = require('../../../assets/times_white.png')

    
  useEffect(()=>{
      // if(defaultImageUrl){
      setSelectedImage(defaultImageUrl)
      // }
  }, [defaultImageUrl])


  let openImagePickerAsync = async () => {
    let permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
        Alert.alert('Permission to access camera roll is required!');
        return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync();

    if (pickerResult.canceled === true) {
        return;
    }

    setSelectedImage(pickerResult.assets[0].uri); 
    getImage(pickerResult.assets[0].uri)
  };



  return (
    <View style={{ ...ROOT }}>
        
        <Text style={{ color: color.ananthamBlue, paddingBottom: 15 }} onPress={openImagePickerAsync}>+ Upload Image</Text>


        { selectedImage ?    
            <View>
                <View style={{flexDirection: "row", justifyContent: "flex-start", marginBottom: 10}}>
                            <Image
                                source={{ uri: selectedImage }}
                                style={styleSheet.thumbnail}
                            />
                            <TouchableOpacity style={styleSheet.close_btn} onPress={()=>{setSelectedImage(null)}}>
                                <Image source={cross_arrow_white} style={{width:15, height: 15}}/>
                            </TouchableOpacity>
                </View>
            </View>
            : <View></View>
        }
    </View>
    

  )
}





  const styleSheet = StyleSheet.create({
    thumbnail: {
        width: 80,
        height: 80,
        borderRadius: 40,
        resizeMode: "cover"
    },
    close_btn: {
        width: 25,
        height: 25,
        borderRadius: 15,
        backgroundColor: color.primary,
        marginLeft: -20,
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
    }
});