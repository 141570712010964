import { types } from "mobx-state-tree";

const LocationModel = types
.model()
.props({
    id: types.optional(types.number, 0),
    name: types.optional(types.string, ""),
})

export const BusinessLocationsModel = types
.model()
.props({
    businessLocations: types.optional(types.array(LocationModel), [{id: 0, name: ""}]),
})
.actions(self=>({
    create(businessLocations){
        self.businessLocations = businessLocations
    },
}))


