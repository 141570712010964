import React, { FunctionComponent as Component, useEffect, } from "react"
import { View, Image, ViewStyle, ImageStyle, TextStyle, TouchableOpacity } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text } from "../../components"
import { color, spacing } from "../../theme"
import { save, clear } from "../../utils/storage";
import { Ionicons } from "@expo/vector-icons";
import { useStores } from "../../models";
import { ApiBusinessServices } from "../../services/api";

const FULL: ViewStyle = {
  flex: 1,
  backgroundColor: color.background,
  alignItems: "center",
  justifyContent: "space-around",
  paddingTop: 80,
  paddingBottom: 60
}
const CENTER: ViewStyle = {
  flex: 1,
  backgroundColor: color.background,
  alignItems: "center",
  justifyContent: "center",
}

const BOWSER: ImageStyle = {
  alignSelf: "center",
  marginVertical: spacing.large,
  marginRight: 25,
  width: 250,
  height: 250
}

const REJECTED_ICON: ImageStyle = {
  alignSelf: "center",
  marginVertical: spacing.large,
  marginLeft: 25
}

const VERIFIED:  ImageStyle = {
  alignSelf: "center",
  marginVertical: spacing.large,
  width: 250,
  height: 250
}

const LIGHTTEXT: TextStyle = {
  color: '#485068',
  fontSize: 14,
  marginHorizontal: 7,
  marginVertical: 1
}

const TITLE_PRIMARY: TextStyle = {
  width: "100%",
  color: color.black,
  fontSize: 28,
  textAlign: "center",
  fontWeight: "bold" 
}

const SUB_TITLE : TextStyle = {
  width: "100%",
  color: color.text,
  textAlign: "center"
} 


export const WelcomeScreen: Component = observer(function WelcomeScreen() {


  // navigation
  const navigation = useNavigation()

  var store = useStores()
  const {userStore} = useStores()


  useEffect(()=>{
    businessStatusApi()
    save("isFirstTimeUser", true)
    userStore.setFirstTimeUser(true)
    
  },[])

  const businessStatusApi = () => {
    const api = new ApiBusinessServices()
    api.getBusinessStatus().then(async res => {
      if(res.kind == "ok"){
        userStore.setBusinessStatus(res["data"]["status"])
      } else {
        signOut()
      }
    })
  }

  const nextScreen = () => {
    if (userStore.businessStatus == "PendingDetails") {
      navigation.navigate("businessinformation")
    } else {
      navigation.navigate("welcome")
    } 
  }

  const navigateToConfigurations = async ()=> {
    save("isFirstTimeUser", false)
    await userStore.setFirstTimeUser(false)
    navigation.navigate("primaryStack", {screen: 'home', params: {screen: "configurations"}})
  }


  const signOut = async () => {
    userStore.setAccessToken(null)
    store.resetStore()
    await clear()
  }


  if (userStore.businessStatus == "PendingDetails") {
    const imgPath = require("./welcome.png")
    return (
      <View style={FULL}>
        {/* <Wallpaper /> */}
        <Text style={TITLE_PRIMARY}>
          Welcome to Ellam
        </Text>
        <View style={FULL}>
          <View style={{ paddingVertical: 20, display: "flex", alignItems: "center" }}>
            <Text style={SUB_TITLE}>
              To be able to add your services,
            </Text>
            <Text style={SUB_TITLE}>
              please start your profile verification.
            </Text>
          </View>
          <View>
            {/* <Screen style={CONTAINER} preset="scroll" backgroundColor={color.transparent}> */}
            <Image source={imgPath} style={BOWSER} />
            {/* </Screen> */}
          </View>
          <View>
            <Button
              preset="primary"
              text="Start Business Verification"
              onPress={nextScreen}
            />
          </View>
        </View>
        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={signOut}> 
              <Text style={{textDecorationLine: 'underline', color: color.ananthamBlue}}>Sign Out</Text>
            </TouchableOpacity>
            <Text style={{color: color.ananthamBlue}}> from the application</Text>
        </View>
      </View>
    )
  } else if (userStore.businessStatus == "VerificationPending") {
    const imgPath = require("./pending.png")
    return (
      <View style={FULL}>
        
        <Text style={TITLE_PRIMARY}>
          Welcome to Ellam
        </Text>

        <View style={{marginTop: 20, alignItems: 'center'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Ionicons name="ios-help-circle" size={24} color={color.yellow} />
            <Text style={LIGHTTEXT}>Your application is PENDING FOR APPROVAL</Text>
          </View>
         
          <View style={{marginTop: 10, alignItems: 'center'}}>
            <Text style={[LIGHTTEXT, {paddingHorizontal: 20, textAlign: 'center'}]}>You will be able to define catalogue once your profile is verified and Approved.</Text>
            {/* <Text style={LIGHTTEXT}>your profile is verified and Approved.</Text> */}
          </View>
        </View>
        
        <View style={CENTER}>
          <View>
            <Image source={imgPath} style={BOWSER} />
            <View style={{ paddingVertical: 20 }}>
              <Text style={SUB_TITLE}>
                Business Account Verification Pending.
                </Text>
            </View>
          </View>
        </View>


        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={signOut}> 
              <Text style={{textDecorationLine: 'underline', color: color.ananthamBlue}}>Sign Out</Text>
            </TouchableOpacity>
            <Text style={{color: color.ananthamBlue}}> from the application</Text>
        </View>

      </View>
    )
  } else if (userStore.businessStatus == "Active") {
    const imgPath = require("./social.png")
    return (
      <View style={FULL}>
        {/* <Wallpaper /> */}
        <Text style={TITLE_PRIMARY}>
          Welcome to Ellam
        </Text>

        <View style={{marginTop: 20, alignItems: 'center'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Ionicons name="ios-checkmark-circle" size={24} color={color.green} />
            <Text style={LIGHTTEXT}>You have sucessfully verified your details</Text>
          </View>
          
          <View style={{marginTop: 10}}>
            <Text style={LIGHTTEXT}>Now add your configuration so</Text>
            <Text style={LIGHTTEXT}>customers can easily find you</Text>
          </View>
        </View>
        
        <View style={CENTER}>
          <View>
            <Image source={imgPath} style={VERIFIED} />
            <View style={{ paddingVertical: 20 }}>
                <Button
                  preset="primary"
                  text="Add Configurations"
                  onPress={navigateToConfigurations}/>
            </View>
          </View>
        </View>

        {/* <View style={{flexDirection: 'row'}}>
            <Text style={{textDecorationLine: 'underline', color: color.ananthamBlue}}>Sign Out</Text>
            <Text style={{color: color.ananthamBlue}}> from the application</Text>
        </View> */}
      </View>
    )
  } else if (userStore.businessStatus == "Terminated") {
    const imgPath = require("./rejected.png")
    return (
      <View style={FULL}>
        {/* <Wallpaper /> */}
        <Text style={TITLE_PRIMARY}>
          Welcome to Ellam
        </Text>

        <View style={{marginTop: 20, alignItems: 'center'}}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Ionicons name="ios-close-circle" size={24} color={color.red} />
            <Text style={LIGHTTEXT}>Account Rejected</Text>
          </View>
         
          <View style={{marginTop: 10, alignItems: 'center'}}>
            <Text style={LIGHTTEXT}>To be able to add your services,</Text>
            <Text style={LIGHTTEXT}>please contact the admin.</Text>
          </View>
        </View>
        

        <View style={CENTER}>

          <View>
            <Image source={imgPath} style={REJECTED_ICON} />
            <View style={{ paddingVertical: 5 }}>
              <Text style={SUB_TITLE}>
                Some issue happend while verifying
                </Text>
              <Text style={SUB_TITLE}>
                your account
                </Text>
            </View>
            <View style={{ paddingVertical: 20 }}>
              <Text style={SUB_TITLE}>
                You can reach at
                </Text>
              <Text style={SUB_TITLE}>
                info@ellan.in
                </Text>
            </View>
          </View>
        </View>

        <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={signOut}> 
              <Text style={{textDecorationLine: 'underline', color: color.ananthamBlue}}>Sign Out</Text>
            </TouchableOpacity>
            <Text style={{color: color.ananthamBlue}}> from the application</Text>
        </View>

      </View>
    )
  }

})




