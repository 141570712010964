import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, ScrollView } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { ItemCard, Screen, Icon, Text, Button } from "../../../components";
import { ApiCategoryConfig } from "../../../services/api/api-methods/api-category-config";
import { useStores } from "../../../models/root-store/root-store-context";
import { color } from "../../../theme";


export const CategoryScreen: Component = observer(function CategoryScreen() {


    const { categoriesStore, categoryInventoryLookupModel, domainStore } = useStores()



    useEffect(()=>{
        getAllCategories()
        getSupplierCategories()
    }, [])


    const getAllCategories = () => {
        const api = new ApiCategoryConfig()
        api.getAllCategories(domainStore.domainName[0]).then(res => {
            if(res.kind == "ok"){
                var allCategories = JSON.parse(JSON.stringify(res['data']))
                categoryInventoryLookupModel.addCategories(allCategories)
            }
        })
    }


    function getSupplierCategories() {
        const api = new ApiCategoryConfig()
        api.getSupplierCategories(domainStore.domainName[0]).then(res => {
            var supplierCategories = JSON.parse(JSON.stringify(res['data']))
            categoriesStore.addCategories(supplierCategories)
        })
    }
    
    const navigation = useNavigation()
    const navCategoryDetails = (type, data) => navigation.navigate("category_details", {"item": data, "type": type})

    return (
        <Screen preset="fixedHeight" headerTitle="Configure Category" showHeader={true} showBackButton={true}>

                { categoriesStore.categories.length ?
                            <ScrollView style={{marginVertical: 0, paddingHorizontal: 10}}>
                                <View>
                                { categoriesStore.categories.map((cat, index) => 
                                    <View key={index}>
                                        {!cat.isDeleted ?
                                            <ItemCard key={index} cardText={cat.groupName} 
                                                isPublished={'isActive' in cat ? cat.isActive: false} 
                                                onCardPress={() =>navCategoryDetails('update', cat)}
                                            ></ItemCard> 
                                            : null
                                        }
                                    </View>
                                    )
                                }
                                </View>
                            </ScrollView> 
                            :
                            <View style={{flexGrow:1, alignSelf: 'center', justifyContent: 'center'}}>
                                <Icon containerStyle={{alignSelf: 'center', marginBottom: 20}} icon={domainStore.imagePrefix + "_category"} style={{width: 50, height: 50}}></Icon>
                                <Text style={{fontWeight :'bold'}}>No Categories Added</Text>
                            </View>
                }

                <View style={BOTTOM_CARD}>
                    <View style={{margin:10, alignSelf: "center", justifyContent: "center"}}>

                        <Button
                            preset="primary"
                            onPress={()=>{navCategoryDetails('add', 0)}}
                            text="Add New Category"/>

                    </View>
                </View>
        </Screen>
  )
})



const BOTTOM_CARD: ViewStyle = {
    width: "100%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: color.white,
    justifyContent: "space-around",
    paddingVertical: 10,
    bottom: 0,
    marginTop: 2,
  }


  