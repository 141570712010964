/**
 * Get Business Name
 *
 * @param businessId Business Id
 */
export function getBusinessName(businessId) {

  var businessNames = {
    "2": "Open Kitchen",
    "4": "Temple"
  }

  return businessNames[businessId]

}

/**
 * Get Inventory Name
 *
 * @param businessId Business Id
 */
export function getInventoryName(businessId) {

  var inventoryNames = {
    "2": ["Orders", "Order", "Menu", "Item", "Items"],
    "4": ["Offerings", "Offering", "Offerings", "Offering", "Offerings"],
  }

  return inventoryNames[businessId]

}


/**
 * Get Inventory Name
 *
 * @param businessId Business Id
 */
export function getDomainName(businessId) {

  var domainNames = {
    "2": ["open-kitchens", "open-kitchen"],
    "4": ["worships", "worship"],
  }

  return domainNames[businessId]

}