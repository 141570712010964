import React, { FunctionComponent as Component } from "react";
import { View, ViewStyle, TextStyle, TouchableOpacity, Image, Dimensions } from "react-native";
import { spacing, color } from "../../../../theme";
import { translate } from "../../../../i18n";
import { Icon } from "../../../../components/icon/icon";
import { InventoryCardProps } from "./inventory-card.props";
import { Ionicons } from "@expo/vector-icons";
import { Text } from "../../../../components/text/text";

// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing.medium,
    paddingTop: spacing.xSmall,
    paddingBottom: spacing.xSmall,
};

const CARD_STYLE: ViewStyle = {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: color.white,
    borderRadius: 20,
    margin: spacing.tiny,
    paddingHorizontal: spacing.small, // Add padding to prevent content from touching the edges
    paddingVertical: spacing.small, // Add vertical padding for better spacing
    overflow: "hidden", // Prevent overflow
    width: '100%', // Adjust width to fit within the screen
};

const CARD_TITLE_CONTAINER: ViewStyle = {
    flexDirection: "column",
    flexShrink: 1,
    marginLeft: spacing.xSmall,
    marginVertical: 2,
    maxWidth: Dimensions.get("window").width - 160, // Adjust maximum width to prevent overflow
};

const CARD_TITLE: TextStyle = {
    display: 'flex',
    color: color.text,
    fontSize: spacing.large,
    fontWeight: "500",
    flexWrap: "wrap",
    flexShrink: 1,
};

const ICON_STYLE: ViewStyle = {
    justifyContent: "center",
    width: 40, // Ensure a fixed width for consistency
    height: 40, // Ensure a fixed height for consistency
    borderRadius: 20, // Ensure consistent border radius
};

const PRICE_STYLE: TextStyle = {
    color: color.ananthamOrange,
    fontWeight: '400',
    fontSize: spacing.large,
    marginTop: spacing.tiny,
};

const STATUS_ICON_STYLE: ViewStyle = {
    marginHorizontal: spacing.medium,
};

export const InventoryCard: Component<InventoryCardProps> = (props) => {
    const {
        cardText,
        icon,
        image,
        onCardPress,
        style,
        titleStyle,
        isPublished,
        amount,
    } = props;

    const cardtext = cardText || (cardText && translate(cardText)) || "";

    return (
        <View style={{ ...ROOT, ...style }}>
            <TouchableOpacity onPress={onCardPress}>
                <View style={{ ...CARD_STYLE }}>
                    <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
                        <View style={{ ...ICON_STYLE, marginRight: spacing.small }}>
                            {image ? (
                                <Image
                                    source={{ uri: image }}
                                    style={{ width: 40, height: 40, borderRadius: 20 }}
                                />
                            ) : icon ? (
                                <Icon icon={icon} style={{ width: 40, height: 40 }} />
                            ) : (
                                <View style={{ width: 40, height: 40 }} />
                            )}
                        </View>
                        <View style={{ ...CARD_TITLE_CONTAINER }}>
                            <Text style={{ ...CARD_TITLE, ...titleStyle }}>{cardtext}</Text>
                            <Text style={{ ...PRICE_STYLE }}>Rs. {amount}</Text>
                        </View>
                    </View>

                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        {isPublished ? (
                            <Ionicons
                                name="ios-checkmark-circle"
                                size={spacing.xLarge}
                                style={STATUS_ICON_STYLE}
                                color={color.green}
                            />
                        ) : (
                            <Ionicons
                                    name="ios-close-circle"
                                    size={spacing.xLarge}
                                    style={STATUS_ICON_STYLE}
                                    color={color.red}
                                />
                        )}
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    );
};
