import React, { FunctionComponent as Component, useEffect } from "react"
import { ViewStyle, Animated, Easing, TouchableWithoutFeedback, View, TextStyle } from "react-native"
import { color, spacing, styles } from "../../theme"
import { SwitchProps } from "./switch.props"
import { Text } from "../text/text"
import { Toggle } from "@ui-kitten/components";


const ROOT: ViewStyle = {
  padding: spacing.xSmall
}

const labelStyle: TextStyle = {
  fontSize: spacing.small,
  color: color.dim,
  marginBottom: spacing.xSmall
}



export const Switch: Component<SwitchProps> = props => {
  
  var  {
    title,
    titleStyle,
    onToggle,
    value,
  } = props


  const [checked, setChecked] = React.useState(value);
  
  useEffect(()=>{
    setChecked(value)
  }, [value])

  const onCheckedChange = (isChecked) => {
    setChecked(isChecked)
    onToggle(isChecked)
  };

  
  return (
    <View style={{ ...ROOT }}>
      <Text text={title}  style={{...label_lg, ...titleStyle}}/>
      <View style={{flexDirection: 'row'}}>
          <Toggle checked={checked} onChange={onCheckedChange}>
              {}
          </Toggle>
      </View>
    </View>

  )
}


const label_lg: TextStyle = {
  fontSize: 14,
  color: color.dim,
  marginBottom: spacing.xSmall
}