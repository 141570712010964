export const palette = {
  black: "#000000",
  white: "#FFFFFF",
  offWhite: "#E6E6E6",
  yellow: "#FFBE55",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#DD3333",
  blue: "#347AF0",
  cyanBlue: "#009EE0",
  lightBlue: "#EDF1F9",
  red: "#E74949",
  green: "#75BF72",
  orange: "#FF6300",
  grey: '#485068',
  darkBlue: "#E8EFFF",
  linkBlue: "#1FAAFF",

  blueStatusBg: "#D6E5FC",
  blueStatus: "#347BF1",
  yellowStatusBg: "#FFF3CD",
  yellowStatus: "#FFC107",
  greenStatusBg: "#E3F2E3",
  greenStatus: "#75BF72",
  redStatusBg: "#ffe4e6",
  redStatus: "#ba1d23",
  purpleStatusBg: "#ffe8d9",
  purpleStatus: "#b35900"
}
