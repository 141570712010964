import React, { FunctionComponent as Component, useEffect } from "react"
import { View, Text } from "react-native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import { Screen, Icon, Button } from "../../components";
import { useStores } from "../../models";
import { getNetworkState } from "../../utils/network"


export const NoInternetScreen: Component = observer(function NoInternetScreen() {

  const {  networkStore } = useStores()

  useEffect(()=>{
    checkNetworkState()
  }, [])


  const [loading, setLoading] =  React.useState(true)

  async function checkNetworkState(){
    var netState = await getNetworkState()
    networkStore.create(netState)
    if(!netState.isConnected){
      setLoading(false)
    }
  }

  
  return (
    <Screen preset="fixed" headerTitle="Dashboard" showBackButton={false} loader={loading}>

        <View style={{flex:1, alignItems: "center", alignSelf: 'center', justifyContent: 'center'}}>
          
          <Icon icon={"wifi_icon"} style={{width:80, height:80, alignSelf: 'center'}}></Icon>
          
          <Text style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center', marginVertical: 10}}>     No Internet Connection     </Text>

          <Text style={{textAlign: 'center', marginHorizontal: 20}}>Something went wrong. Please check you network connection.</Text>

          <Button onPress={()=> checkNetworkState()} 
            text={"RETRY"} style={{width: 100, backgroundColor: color.primary, marginTop: 30, height: 38}} 
            textStyle={{fontSize: 14, letterSpacing: 0.5}}>
          </Button>

        </View> 

    </Screen>
  )
})


