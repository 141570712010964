import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, ScrollView, TextStyle, Platform } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Switch, RadioButton, Chips } from "../../../components"
import { color, spacing, styles } from "../../../theme"
import { ApiOrderSettingsConfig } from "../../../services/api/api-methods/api-order-setting-config";
import { useStores } from "../../../models";
import { PopupMessage } from "../../../components/popup-message/popup-message";

var newEntry = true

export const OrderSettingsScreen: Component = observer(function OrderSettingsScreen() {

  const  {orderSettingsDefaultStore, orderSettingsStore, loaderStore, domainStore} = useStores()
  const [showError, setShowError] = React.useState(false)

  var inHouseDeliveryOptions = [{option: "Yes", value: true},{option: "No", value: false}]
  const [defaultInHouseDeliveryOption, setDefaultInHouseDeliveryOption] = React.useState(inHouseDeliveryOptions[1]) 
  var inHouseDeliveryChargesOptions = [{option: "Platform Delivery Settings", value: true},{option: "Own Delivery Settings", value: false}]
  const [defaultInHouseDeliveryChargesOption, setDefaultInHouseDeliveryChargesOption] = React.useState(inHouseDeliveryChargesOptions[1]) 

  
  var applyAsList = [{option: "Amount"},{option: "Percentage"}]
  const [defaultApplyAs, setDefaultApplyAs] = React.useState(applyAsList[0]) 

  var applyOnList = [{option: "PerItem"},{option: "PerOrder"}]
  const [defaultApplyOn, setDefaultApplyOn] = React.useState(applyOnList[0]) 

  var coverageList = [{option: "For Entire Distance",value:"All"},{option: "Additional Cost on Longer Distance",value:"Limited"}]
  const [defaultCoverage, setDefaultCoverage] = React.useState(coverageList[1]) 


  const [showMessage, setShowMessage] = React.useState(false)
  const [deliverablePincodesList, setDeliverablePincodesList] = React.useState([])

  var isDeliveryToLimitedPincodesOptions = [{option: "Yes", value: false},{option: "No", value: true}]
  const [defaultIsDeliveryToLimitedPincodes, setDefaultIsDeliveryToLimitedPincodes] = React.useState({option: "Yes", value: false})
  const [deliverablePincodes, setDeliverablePincodes] = React.useState("")
  const [pincodeErrorText, setPincodeErrorText] = React.useState("")
  
  
  useEffect(()=>{
    loaderStore.hideLoader()
    orderSettingsStore.reset()
    getAllOrderSettings()
    getSupplierOrderSettings()
  }, [])


  const getAllOrderSettings = () => {
    const api = new ApiOrderSettingsConfig()
    api.getAllOrderSettings().then(res => {
      if(res.kind == "ok"){
        orderSettingsDefaultStore.create(res['data'])
        orderSettingsStore.createDefaultSettings(res['data'])
      }
    })
  }

  const getSupplierOrderSettings = () => {
      const api = new ApiOrderSettingsConfig()
      api.getSupplierOrderSettings().then(res => {

        if(res.kind == "ok"){

              res["data"] ? newEntry = false : true

              orderSettingsStore.create(res['data'])

              if(orderSettingsStore.inHouseDeliveryAvailable){

                setDefaultInHouseDeliveryOption(inHouseDeliveryOptions[0])
              
                if(!orderSettingsStore.inHouseDeliverySameAsPlatform){

                  setDefaultInHouseDeliveryChargesOption(inHouseDeliveryChargesOptions[1])
                  orderSettingsStore.priceApplied == "PerOrder" ? setDefaultApplyOn(applyOnList[1]) : setDefaultApplyOn(applyOnList[0])
                  orderSettingsStore.priceAppliedAs == "Percentage" ? setDefaultApplyAs(applyAsList[1]) : setDefaultApplyAs(applyAsList[0])
                  orderSettingsStore.coverage == "All" ? setDefaultCoverage(coverageList[0]) : setDefaultCoverage(coverageList[1])

                } else {
                  setDefaultInHouseDeliveryChargesOption(inHouseDeliveryChargesOptions[0])
                }
              } else {
                setDefaultInHouseDeliveryOption(inHouseDeliveryOptions[1])
              }

              setDeliverablePincodesList(orderSettingsStore.deliverablePincodes ? orderSettingsStore.deliverablePincodes : [])

              updateDefaultIsDeliveryToLimitedPincodes(orderSettingsStore.isDeliveryToLimitedPincodes)
              
        }

      })
  }


  const saveData = () => {

    if(orderSettingsStore.checkMandatory()){

      // check for isDeliveryToLimitedPincodes and if false reset DeliverablePincodes
      if(!orderSettingsStore.isDeliveryToLimitedPincodes){
        updatePinCodeList([])
      }

      loaderStore.showLoader()
      if(newEntry){
        addSupplierOrderSettings()
      } else{
        updateSupplierOrderSettings()
      }
    } else {
      setShowError(true)
      if(orderSettingsStore.isDeliveryToLimitedPincodes && (!orderSettingsStore.deliverablePincodes 
              || (orderSettingsStore.deliverablePincodes && orderSettingsStore.deliverablePincodes.length < 1))){
        setPincodeErrorText("Required")
      }
    }
  }


  const addSupplierOrderSettings = () => {

    const api = new ApiOrderSettingsConfig()
    const data = orderSettingsStore.getAddModel()
    api.addSupplierOrderSettings(data).then(res => {
      loaderStore.hideLoader()
      setShowMessage(true)
    })
  }
  
  
  
  const updateSupplierOrderSettings = () => {

    const api = new ApiOrderSettingsConfig()
    const data = orderSettingsStore.getUpdateModel()
    api.updateSupplierOrderSettings(data).then(res => {
      loaderStore.hideLoader()
      setShowMessage(true)
    })
  }

  const validatePinCode = (pincode) => {

    if(!deliverablePincodesList){
      setDeliverablePincodesList([])
    }
    var re = new RegExp("^[1-9][0-9]{5}$");
    var pincodeValid = re.test(pincode)
    if(pincodeValid){
      var index = deliverablePincodesList.indexOf(pincode)
      if(index==-1){
        setPincodeErrorText("")
        var sampleList = JSON.parse(JSON.stringify(deliverablePincodesList)) 
        sampleList.push(pincode)
        setDeliverablePincodesList(sampleList)
        updatePinCodeList(sampleList)
        setDeliverablePincodes("")
      }
      else{
        setPincodeErrorText("Duplicate Pincode")
      }
    }
    else{
      setPincodeErrorText("Invalid Pincode")
    }
  }
  
  const updatePinCodeList = (list) => {
    setDeliverablePincodesList(list)
    orderSettingsStore.updateDeliverablePincodes(list);

  }

  const updateDefaultIsDeliveryToLimitedPincodes = (defaultOption) => {
    isDeliveryToLimitedPincodesOptions.map((item, index) =>{
      if(defaultOption == item.value){
        setDefaultIsDeliveryToLimitedPincodes(item)
      }
    })
  }


  
  return (
        <Screen preset="fixedHeight" headerTitle="Order Settings" subTitle="Configure Order Settings" showHeader={true} 
                                                                          showBackButton={true} loader={loaderStore.loader}> 

          <View style={CONTAINER}>

              <PopupMessage 
                display={showMessage} 
                close={()=>{setShowMessage(false)}} 
                nextNavigationPath={{stack: 'configurationStack', screen: 'category'}}
                message="Successfully configured order settings"
                navigationMessage="Click next to configure category" 
                type="success"
              ></PopupMessage> 
              
              <View style={BOTTOM_CARD}>

                <ScrollView style={SCROLL_VIEW}>

                  <Switch value={orderSettingsStore.isVisibleToCustomer} onToggle={(value)=>orderSettingsStore.toggleIsVisible(value)} 
                          title={"Show " + domainStore.businessName + " to Customers"}
                  ></Switch>


                  <TextField required={true} showError={showError} defaultValue={orderSettingsStore.leadTime.toString()} 
                              label="Lead Time for Order (in Hrs)"  getText={text => orderSettingsStore.updateLeadTime(Number(text))} 
                              keyboardType="numeric">
                  </TextField> 


                  <Text style={{...LABEL_LG, ...MARGIN_HORIZONTAL}}>Enter the max and min order counts</Text>

                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexGrow:1, width: "50%"}}>
                          <TextField required={true} showError={showError} defaultValue={orderSettingsStore.minimumQuantity.toString()} 
                                      label="Minimum"  getText={text => orderSettingsStore.updateMinimumQuanitity(Number(text))} 
                                      keyboardType="numeric" titleStyle={LABEL_SM}>
                          </TextField> 
                    </View>
                    <View style={{flexGrow:1,  width: "50%"}}>
                        <TextField required={true} showError={showError} defaultValue={orderSettingsStore.maximumQuantity.toString()} 
                                      label="Maximum"  getText={text => orderSettingsStore.updateMaximumQuanitity(Number(text))} 
                                      keyboardType="numeric" titleStyle={LABEL_SM}>
                        </TextField> 
                    </View>
                  </View>

                  {/* 
                      <TextField defaultValue={orderSettingsStore.maxDaysForLongTimeOrder.toString()} label="Maximum Days of Long Orders"  
                                getText={text => orderSettingsStore.updateMaxDaysForLongTimeOrder(Number(text))} keyboardType="numeric">
                      </TextField>  
                  */}

                  <View style={{padding:10}}>
                    <View style={{width: '100%', height: 2, backgroundColor: color.background, marginVertical:10}}></View>
                  </View>

                  <Text preset="bold" style={{marginHorizontal: 8, marginVertical: 10}}>Delivery Detail</Text>

                  <RadioButton defaultValue={defaultInHouseDeliveryOption} items={inHouseDeliveryOptions} title="Do you have inhouse  delivery ?" 
                              getSelectedItem={(x)=>{orderSettingsStore.toggleInHouseDeliveryAvailability(x['value']); 
                                        setDefaultInHouseDeliveryChargesOption(inHouseDeliveryChargesOptions[0])}}
                  ></RadioButton>

                  { orderSettingsStore.inHouseDeliveryAvailable == true ?
                      <View>
                          <RadioButton defaultValue={defaultInHouseDeliveryChargesOption} items={inHouseDeliveryChargesOptions} 
                                        title="Setup your inhouse delivery charges ?" 
                                        getSelectedItem={(x)=> {orderSettingsStore.toggleInHouseDeliverySameAsPlatform(x['value']); 
                                        setDefaultApplyAs(applyAsList[0]); setDefaultApplyOn(applyOnList[0])}}
                          ></RadioButton>

                          { orderSettingsStore.inHouseDeliverySameAsPlatform == false ? 
                              <View>
                                  <RadioButton defaultValue={defaultApplyAs} items={applyAsList} title="Apply as" 
                                              getSelectedItem={(x)=> orderSettingsStore.updatePriceAppliedAs(x['option'])}
                                  ></RadioButton>
                                  
                                  <TextField defaultValue={orderSettingsStore.deliveryCharge.toString()} label="Delivery Amount"  
                                            getText={text => orderSettingsStore.updateDeliveryCharges(Number(text))} keyboardType="numeric">
                                  </TextField> 

                                  <RadioButton defaultValue={defaultApplyOn} items={applyOnList} title="Apply" 
                                            getSelectedItem={(x)=> orderSettingsStore.updatePriceApplied(x['option'])}
                                  ></RadioButton>
                                  
                                  <RadioButton defaultValue={defaultCoverage} items={coverageList} title="Distance" 
                                            getSelectedItem={(x)=> orderSettingsStore.updateCoverage(x['value'])}
                                  ></RadioButton>

                                  { orderSettingsStore.coverage == "Limited" ? 
                                      <View>
                                        <TextField defaultValue={orderSettingsStore.distanceLimit.toString()} 
                                                label="Additional Delivery Charge When Distance Greater than (KM)"  
                                                getText={text => orderSettingsStore.updateDistanceLimit(Number(text))} keyboardType="numeric">
                                        </TextField> 

                                        <TextField defaultValue={orderSettingsStore.additionalCost.toString()} 
                                                label="Additional Delivery Amount"  
                                                getText={text => orderSettingsStore.updateAdditionalCost(Number(text))} keyboardType="numeric">
                                        </TextField> 
                                      </View> : null
                                  }
                              </View> : null
                          }
                      </View> : null
                  }

                  <View style={{padding:10}}>
                    <View style={{width: '100%', height: 1, backgroundColor: color.line, marginVertical:10}}></View>
                  </View>

                  <View>

                      <TextField defaultValue={orderSettingsStore.minimumAmountForDelivery.toString()} label="Minimum Order Amount For Delivery"  
                                  getText={text => orderSettingsStore.updateMinimumAmountForDelivery(Number(text))} keyboardType="numeric">
                      </TextField> 

                      <TextField defaultValue={orderSettingsStore.deliveryBoundary.toString()} label="Maximum Delivery Boundary KM(s)"  
                                  getText={text => orderSettingsStore.updateDeliveryBoundary(Number(text))} keyboardType="numeric">
                      </TextField> 

                      <RadioButton defaultValue={defaultIsDeliveryToLimitedPincodes} items={isDeliveryToLimitedPincodesOptions} 
                                title="Do you support Delivery to all Pincodes ?" 
                                getSelectedItem={(x)=>{orderSettingsStore.updateIsDeliveryToLimitedPincodes(x['value']);}}
                      ></RadioButton>

                      { orderSettingsStore.isDeliveryToLimitedPincodes ? 
                            <Chips 
                              // required={true} 
                              errorText={pincodeErrorText} 
                              defaultValue={deliverablePincodes} 
                              chipsList={deliverablePincodesList ? deliverablePincodesList : []} 
                              title="Enter Pincode" 
                              getNewChip={item => {validatePinCode(item)}}
                              getNewChipList={list => {updatePinCodeList(list)}}
                            ></Chips>
                          : null
                      }

                  </View>

                  <View style={{padding:10}}>
                    <View style={{width: '100%', height: 1, backgroundColor: color.line, marginVertical:10}}></View>
                  </View>

                  <View>
                    <Text style={{padding: spacing.small, marginBottom: 5, fontWeight: 'bold'}}>Platform order settings details</Text>
                    <View style={{flexDirection: 'row', paddingHorizontal: spacing.small}}>
                        <Text>Delivery Charges : </Text>
                        <Text style={LABEL_LG}>{orderSettingsDefaultStore.chargeDetails.Amount} Rupess per order</Text>
                    </View>
                    {/* <View style={{flexDirection: 'row', paddingHorizontal: spacing.small}}>
                        <Text>Auto rejection time : </Text>
                        <Text style={LABEL_LG}>{orderSettingsDefaultStore.autoRejectionBufferTimeMinutes} Minutes</Text>
                    </View>
                    <View style={{flexDirection: 'row', paddingHorizontal: spacing.small}}>
                        <Text>Cancellation buffer time : </Text>
                        <Text style={LABEL_LG}>{orderSettingsDefaultStore.cancellationBufferTimeMinutes} Minutes</Text>
                    </View> */}

                  </View>
      

                  <View style={{marginBottom: Platform.OS == "web" ? 10: 30, marginTop:20, alignSelf: "center"}}>
                    <Button
                      preset="primary"
                      onPress={saveData}
                      text="Save"/>
                  </View>

                </ScrollView>

              </View>

          </View>

        </Screen>
  )
})




const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  maxHeight: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  bottom: 0,
}


const SCROLL_VIEW: ViewStyle = {
  padding: spacing.large,
}


const MARGIN_HORIZONTAL: ViewStyle = {
  marginHorizontal: 6,
  marginVertical: 10
}

const LABEL_LG: TextStyle = {
    fontSize: 14,
    color: color.dim,
    marginBottom: spacing.xSmall
}

const LABEL_SM: TextStyle = {
  fontSize: spacing.small,
  color: color.dim,
  marginBottom: spacing.xSmall
}
