import React, { FunctionComponent as Component, useEffect } from "react"
import { TextStyle, View, ViewStyle, ScrollView, TouchableOpacity } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, Icon, TextField } from "../../../components"
import { color } from "../../../theme"
import { OrderCard } from "../components/order-card/order-card";
import { ApiOrders } from "../../../services/api/api-methods/api-orders";
import { useStores } from "../../../models";
import { BottomCard } from "../../../components/bottom-card/bottom-card";
import { DatePickerComponent } from "../../../components/date-picker/date-picker";
import { Toast } from "../../../components/toast/toast";



export const SearchOrdersScreen: Component = observer(function SearchOrdersScreen() {

  const navigation = useNavigation()
  const detailsScreen = (id) => navigation.navigate('order_details', {orderId: id})

  const { orderListStore, domainStore } = useStores()

  const [showBottomCard, setShowBottomCard] = React.useState(false)
    
  const [showToast, setShowToast] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState()
  const [toastType, setToastType] = React.useState('success')
  const [searchText, setSearchText] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageLoader, setPageLoader] = React.useState(false);
  const [showLoadMore, setShowLoadMore] = React.useState(true);
  
  useEffect(()=>{
    // setSearchText(orderListStore.searchOrderText)
    searchOrders()
  }, [])

  const searchOrders = ()=> {
    setPageNumber(0);
    orderListStore.updateSearchOrderList([])
    getSearchOrders();
  }

  const loadMore = ()=> {
    setPageNumber(pageNumber+1);
    getSearchOrders();
  }

  const getSearchOrders = ()=> {
    let limit = pageSize;
    let offset = pageNumber*pageSize;
    var searchText = orderListStore.searchOrderText;
    if(searchText != ""){
      setPageLoader(true);
      setTimeout(() => {
        setPageLoader(false);
      }, 7000);
      const api = new ApiOrders()
      api.getSearchOrders(searchText,limit,offset).then(res => {
        setShowLoadMore(true)
        if(res['data'] ){
          if(res['data'].length < pageSize){
            setShowLoadMore(false)
          }
          orderListStore.updateSearchOrderList(orderListStore.searchOrderList.concat(res['data']))
        }
        setPageLoader(false);
      })
    }

  }

  
  return (
    <Screen preset="fixedHeight" headerTitle={"" + domainStore.inventoryName[0]} showHeader={true} showBackButton={true} loader={pageLoader}>
      <View style={SEARCH_CONTAINER}>
        <View style={{ flexGrow:1}}>
          <TextField 
            placeholder='Search'
            defaultValue={orderListStore.searchOrderText}  
            getText={text => orderListStore.updateSearchOrderText(text)}
            style={{ borderRadius: 20}}
          ></TextField>
        </View>
        <TouchableOpacity
          style={{...SEARCH_BUTTON, marginBottom:8, marginRight:10}}
          onPress={() => searchOrders()}
        >
          <Icon icon={'search'} ></Icon>
        </TouchableOpacity>
      </View>
      <View style={CONTAINER}>


          <View style={BOTTOM_CARD}>


              { orderListStore.searchOrderList.length ?
                <ScrollView>
                    { orderListStore.searchOrderList.map((order, index)=>
                      <OrderCard 
                          key={index} 
                          status="New Request" 
                          forDelivery={order.delivery} 
                          orderDetails={{id: order.id, name: order.userName, itemCount: order.items, amount: order.amount, 
                                            date: order.date, serviceDate: order.serviceDate, itemDetails: order.itemDetails}} 
                          onCardPress={()=>detailsScreen(order.id)}>
                      </OrderCard>
                    )}
                  { orderListStore.searchOrderList.length && showLoadMore ?
                    <View style={{flexDirection: 'row', alignSelf: 'center', marginVertical: 15}}>
                        <Button
                            preset="primary"
                            text="Load More"
                            onPress={()=>loadMore()}/> 
                    </View>
                    : null
                  } 
                </ScrollView> :
                <View style={{alignSelf: 'center', alignItems: 'center', flex: 1, justifyContent: 'center'}}>
                    <Icon icon={domainStore.imagePrefix + "_inventory"} style={{width: 60, height: 60}}></Icon>
                    <Text style={{padding: 20}}>{"No " + domainStore.inventoryName[0]}</Text>
                </View>
              }
          </View>
      </View>
    </Screen>
  )
})


const CONTAINER: TextStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}
const SEARCH_CONTAINER: ViewStyle = {
  width:'100%',
  marginBottom: 10,
  flexDirection: 'row',
  justifyContent: "center",
  flexWrap: 'wrap',
  alignItems: 'flex-end',
}

const BOTTOM_CARD: ViewStyle = {
  paddingTop: 10,
  width: "100%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  bottom: 0,
}

const SEARCH_BUTTON: ViewStyle = {
  backgroundColor: color.primary,
  height: 40,
  width: 40,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 20
}