import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TouchableOpacity, ImageStyle } from "react-native"
import { ChipsProps } from "./chips.props"
import { Icon } from "../icon/icon"
import { color } from "../../theme"
import { Text } from "../text/text"
import { TextField } from "../../components"



export const Chips: Component<ChipsProps> = props => {
  
  var {
    chipsList,
    title,
    errorText,
    getNewChip,
    getNewChipList,
    defaultValue
  } = props




 

  const [list, setList] = React.useState(chipsList);
  const [chipText, setChipText] = React.useState(defaultValue?defaultValue:0);
  const [defaultChipText, setDefaultChipText] = React.useState(defaultValue?defaultValue:"");
  const [refreshFlag, setRefreshFlag] = React.useState(1);

  useEffect(()=>{
    setList(chipsList)
    setDefaultChipText(defaultValue)
  }, [refreshFlag,defaultValue,chipsList])


  const addChip = (text) => {
    setDefaultChipText(text)
    getNewChip(text)
  }
  // const editChip = (text,index) => {
  //   setDefaultChipText(JSON.parse(JSON.stringify(text)))
  // }
  const deleteChip = (index) => {
    var newChipsList = JSON.parse(JSON.stringify(list)) 
    newChipsList.splice(index,1)
    setList(newChipsList)
    getNewChipList(newChipsList)
    setRefreshFlag(refreshFlag * -1)
  }

  return (
    <View>
      
          <View style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
            <View style={{flexGrow:1}}>
              <TextField errorText={errorText} defaultValue={defaultChipText} label={title} 
                      getText={text => {setChipText(Number(text))}} keyboardType="numeric"
                      onSubmit={event => addChip(chipText ? chipText : defaultChipText)}></TextField> 
            </View>
            {/* <TouchableOpacity onPress={()=>getNewChip(chipText)}>
              <View style={{backgroundColor:"#75bf72",marginTop:20,borderRadius:5}}>
                <Icon icon="success_icon" style={{height:40,width:40}} />
              </View>
            </TouchableOpacity> */}
            <TouchableOpacity onPress={()=> [addChip(chipText?chipText:defaultChipText)]}>
              <View style={ADD_BUTTON_CONTAINER}>
                <Icon icon="close" style={ADD_BUTTON} />
              </View>
            </TouchableOpacity>
          </View>

          <View style={{display:"flex",flexDirection:'row',flexWrap:'wrap',paddingHorizontal:4}}>
            {list && list.map((chip, index) => 

                <View key={index} style={{...BASE_VIEW, backgroundColor: color.transparent, borderColor:color.primary, borderWidth:1}}>
                  {/* <TouchableOpacity onPress={()=>editChip(chip,index)}> */}
                    <Text style={{fontSize: 17}}>
                      {chip}
                    </Text>

                  {/* </TouchableOpacity> */}
                  
                  <TouchableOpacity onPress={()=>deleteChip(index)}>
                    <Icon icon="close" style={{width: 12, height: 12, margin: 5}}/>
                  </TouchableOpacity>
                </View>
              )
            }    

          </View>
        
    </View>
  )
}



const BASE_VIEW: ViewStyle = {
  padding:6,
  margin:4,
  borderRadius: 4,
  display:"flex",
  flexDirection:"row",
  justifyContent: "center",
  alignItems: "center",
}

const ADD_BUTTON_CONTAINER: ViewStyle = {
  display:"flex", 
  justifyContent:"center", 
  alignItems:"center", 
  height:40,
  width:40,
  marginTop:20,
  borderRadius:5,
  borderColor:color.primary, 
  borderWidth:1
}
const ADD_BUTTON: ImageStyle = {
  height: 15,
  width: 15,
  margin: 5,
  transform:[{ rotate: '45deg'}]
}