import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle, TouchableOpacity, Image } from "react-native"
import { spacing, color, styles as mainStyles } from "../../theme"
import { DatePickerProps } from "./date-picker.props";
import { Text } from "../text/text"
import moment from 'moment';
import {Calendar} from 'react-native-calendars';
import { Modal, Card } from "@ui-kitten/components";
import { Ionicons } from "@expo/vector-icons";

// static styles
const ROOT: ViewStyle = {
  paddingHorizontal: spacing.xSmall,
  alignSelf: 'center',
}


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const DatePickerComponent: Component<DatePickerProps> = props => {
  const {
    title,
    getDate,
    initialDate,
    titleStyle,
    isSelected
  } = props


  const [date, setDate] = useState();
  const [selected, setSelected] = useState(true);
  const [selectedDate, setSelectedDate] = useState();

  useEffect(()=>{
    if(initialDate){
        var dateLoc = moment(initialDate, 'YYYY-MM-DD')
        setDate(dateLoc)
        var selectedDateObject = {}
        selectedDateObject[moment(initialDate).format('YYYY-MM-DD')] = {selected: true, color: color.primary, textColor: color.white}
        setSelectedDate(selectedDateObject) 
    } else {
        setDate(moment(new Date(),'YYYY-MM-DD'))
        var selectedDateObject = {}
        selectedDateObject[moment(new Date()).format('YYYY-MM-DD')] = {selected: true, color: color.primary, textColor: color.white}
        setSelectedDate(selectedDateObject) 
    }

  }, [initialDate])


  useEffect(()=>{
    setSelected(isSelected)
  }, [isSelected])

  const onTimeChange = (time) => {
    setDate(moment(time["dateString"]).format('MMM DD, YYYY'))
    getDate(moment(time["dateString"]).format('YYYY-MM-DD'))

    var selectedDateObject = {}
    selectedDateObject[moment(time["dateString"]).format('YYYY-MM-DD')] = {selected: true, color: color.primary, textColor: color.white}
    setSelectedDate(selectedDateObject) 

    setModalVisibility(false)
  };

  const [modalVisible, setModalVisibility] = useState(false);


  return (
    <View style={{ ...ROOT }}>
        
        { title ?
        <Text text={title}   style={{...mainStyles.label_lg, ...titleStyle}}/>: <View></View>
        }

      <TouchableOpacity onPress={()=> setModalVisibility(!modalVisible)} style={{ flexDirection: 'row', alignItems: "center", 
                              justifyContent: "center",  backgroundColor: !isSelected ? color.primary : color.background, 
                              width: 150, height: 40, borderRadius: 20}}>
        <Image source={ selected ? require('./calender.png') : require('./calender_white.png')} 
                style={{width: 20, height: 20, marginRight: 10}}>        
        </Image>
        <Text style={{textAlign: 'center', color: !isSelected ? color.white : color.primary}}>{moment(date).format('MMM DD, YYYY')}</Text>
      </TouchableOpacity>

      <Modal visible={modalVisible}
            backdropStyle={backdrop}
            onBackdropPress={() => setModalVisibility(false)}>

            <Card>

              <View style={{backgroundColor: color.transparent}}></View>

              <View style={{backgroundColor: color.white, borderTopStartRadius: 15, borderTopEndRadius: 15, width: "100%"}}>

                <View style={{borderRadius: 10, height: 15}}></View>

                <Calendar
                        markingType={'period'}
                        enableSwipeMonths={true}
                        onDayPress={(day) => onTimeChange(day)}
                        markedDates={selectedDate}
                        renderArrow={(direction) => (<Arrow dir={direction}/>)}
                />

              </View>

              </Card>

         </Modal>
         
    </View>
    
  )
}



const backdrop: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}



const Arrow = (val)=>{
  if(val["dir"] == "left"){
    return <View><Ionicons name="caret-back-outline" size={24} color="black" /></View>
  } else {
    return <View><Ionicons name="caret-forward-outline" size={24} color="black" /></View>
  }
}