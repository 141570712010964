import { GeneralApiProblem, getGeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { Api } from "../api"

const { GOOGLE_MAPS_API_KEY } = require("../../../config/env")
const { GOOGLE_MAPS_API_KEY_WEB } = require("../../../config/env")
const { PROXY_SERVER_BASE_URL } = require("../../../config/env")

type location_Res = { kind: "ok"; data: any } | GeneralApiProblem

export class ApiGoogleServices {

  async getReverseLocation(latitude, longitude): Promise<location_Res> {
    // make the api call

    const api = new Api()

    const url = 'https://maps.googleapis.com/maps/api/geocode/'

    const apiService = await api.requests(url)

    const apiUrl = `json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      // if (response.data['statusCode'] = 200) {
      // }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getReverseLocationforWEB(latitude, longitude): Promise<location_Res> {
    // make the api call

    const api = new Api()

    const url = 'https://maps.googleapis.com/maps/api/geocode/'

    const apiService = await api.requests(url)

    const apiUrl = `json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY_WEB}`

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      // if (response.data['statusCode'] = 200) {
      // }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getAutocompletePlaces(input): Promise<location_Res> {
    // make the api call

    const api = new Api()

    // const url = `https://maps.googleapis.com/maps/api/place/autocomplete/xml?`
    const apiService = await api.requests('')

    // const apiUrl = `input=Amoeba&types=establishment&location=37.76999,-122.44696&radius=500&key=${GOOGLE_MAPS_API_KEY}`

    // make the api call
    const response: ApiResponse<any> = await apiService.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${input}&types=geocode&key=${GOOGLE_MAPS_API_KEY}`)


    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else {
      // if (response.data['statusCode'] = 200) {
      // }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



}