import React, { FunctionComponent as Component } from "../../../../../node_modules/react"
import { View, ViewStyle, TextStyle, Text, TouchableOpacity, Dimensions } from "react-native"
import { ConfigCardProps } from "./config-card.props"
import { spacing, color } from "../../../../theme"
import { translate } from "../../../../i18n"
import { Icon } from "../../../../components/icon/icon";


// static styles
const ROOT: ViewStyle = {
    paddingHorizontal: spacing.medium,
    paddingTop: spacing.xSmall,
    paddingBottom: spacing.xSmall,
}

const CARD_STYLE: ViewStyle = { 
    flexDirection: 'row',
    backgroundColor: color.white,    
    width: Dimensions.get('window').width/1.3, 
    height: Dimensions.get('window').height/10,
    alignItems: "center",
    borderRadius: 20,
}

const CARD_TITLE: TextStyle = { 
    color: color.text,
    fontSize: spacing.medium,
    textAlign: 'center',
    marginRight: spacing.large,
    flexWrap: 'wrap'
}

const ICON_STYLE: ViewStyle ={
    marginLeft: spacing.large,
    marginRight: spacing.small
}


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const ConfigCard: Component<ConfigCardProps> = props => {
  const {
    cardText,
    icon,
    onCardPress,
    style,
    titleStyle
  } = props
  
  const cardtext = cardText || (cardText && translate(cardText)) || ""

  return (
    <View style={{ ...ROOT, ...style }}>
        <TouchableOpacity onPress={onCardPress}>
            <View style={{...CARD_STYLE}}>
                <View style={{...ICON_STYLE}}>
                    <Icon icon={icon} style={{width: Dimensions.get('window').height/20, height: Dimensions.get('window').height/20}}></Icon>
                </View>
                <View style={{flexGrow: 1}}>
                    <Text style= {{...CARD_TITLE, ...titleStyle}}>{cardtext}</Text>
                </View>
            </View>
        </TouchableOpacity>
    </View>
    
  )
}
