import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"
import { ApiConfig } from "./../api-config"

// models
import { registerBusiness_Model } from "../../models/signup-model"

import { save, load } from "../../../utils/storage";
import { Platform } from "react-native";



// User signup
type registerBusiness_Res = { kind: "ok"; data: registerBusiness_Model } | GeneralApiProblem


export class ApiSignup {

  config: ApiConfig


  async getRegisterOtp(phoneNumber) {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()


    const param = {
      phoneNumber: phoneNumber,
      userType: "Business"
    }

    const response: ApiResponse<any> = await apiService.post(url.getRegisterOtp, param)

    if (!response.ok) {
      // const problem = getGeneralApiProblem(response)
      // if (problem) return problem
    }
    else{

    }


    // transform the data into the format we are expecting
    try {

      var kind = "ok"
      if(!response.ok){
         kind = "bad-data"
      } else {
        kind = "ok"
      }


      return { kind: kind, data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async registerBusiness(param): Promise<registerBusiness_Res> {

      const api = new Api()
      const apiService = await api.setHeaderWOAuth()

      const response: ApiResponse<any> = await apiService.post(url.registerBusiness, param)

      if (!response.ok) {
        const problem = getGeneralApiProblem(response)

        if (problem) return response.data

      }
      else{

        save("businessId",  response.data['businessId'])
        save("businessTypeId", response.data['businessType'])  
        save("businessNumber", response.data['businessNumber'])          
        save("accessToken", response.data['authToken'])
        save("refreshToken", response.data['refreshToken'])
        save("deviceId", response.data['deviceId'])
      }
  
      // transform the data into the format we are expecting
      try {
        return { kind: "ok", data: response.data }
      } catch {
        return { kind: "bad-data" }
      }
  }


      


    // async insertBusinessInformation(): Promise<registerBusiness_Res> {
    //   // make the api call

    //   const api = new Api()
    //   const apiService = await api.setHeader()

    //   const url = api.generateUrl('insertBusinessInformation')
    //   // const param = business_information

    //   // const response = { 
    //   //   data : {
    //   //     kind : "ok"
    //   //   }
    //   // }

    //   // make the api call
    //   const response: ApiResponse<any> = await apiService.put(url,param)
    //   // the typical ways to die when calling an api
    //   if (!response.ok) {
    //     const problem = getGeneralApiProblem(response)
    //     if (problem) return problem
    //   }
    //   else{
    //     const data = response.data
    //     if(response.data['statusCode'] = 200){
    //         app_variables['status'] = data['status']
    //     }
    //   }
  
    //   // transform the data into the format we are expecting
    //   try {
    //     return { kind: "ok", data: response.data }
    //   } catch {
    //     return { kind: "bad-data" }
    //   }
    // }


  async emailOtp(email): Promise<registerBusiness_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    // const url = api.generateUrl('emailOtp')

    // make the api call
    const param = {
      "email": email
    }

    const response: ApiResponse<any> = await apiService.post(url.emailOtp, param)
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return response.data
    }
    else{
      const data = response.data
      // email_verify.otpReferenceId = data['otpReferenceId']
      // email_verify.status = data['status']

    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  
  async emailVerify(param): Promise<registerBusiness_Res> {

    const api = new Api()
    const apiService = await api.setHeader()


    const response: ApiResponse<any> = await apiService.post(url.emailVerify, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async checkUserEmail(email): Promise<registerBusiness_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const param = {
      email: email,
      userType: "Business"
    }

    const response: ApiResponse<any> = await apiService.post(url.checkUserEmail, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getAuthToken(param): Promise<registerBusiness_Res> {

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()


    const response: ApiResponse<any> = await apiService.post(url.getAuthToken, param)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async ownerImage(imageUrl:string): Promise<registerBusiness_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()

    let body = new FormData();
    if(Platform.OS == "web"){
      const base64Response = await fetch(imageUrl);
      const blob = await base64Response.blob();
      body.append('file', blob);
    } else{
      body.append('file', {uri: imageUrl, name: 'photo.png', type: 'image/png'});
    }
    const response: ApiResponse<any> = await apiService.post(url.avatar, body)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async uploadBankDocument(imageUrl:string): Promise<registerBusiness_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()

    let body = new FormData();
    if(Platform.OS == "web"){
      const base64Response = await fetch(imageUrl);
      const blob = await base64Response.blob();
      body.append('file', blob);
    } else{
      body.append('file', {uri: imageUrl, name: 'photo.png', type: 'image/png'});
    }


    const response: ApiResponse<any> = await apiService.post(url.uploadBankDocument, body)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }


    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }

  async getDocument(documentId) {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeaderWOAuth()
    const apiUrl = url.getDocument.replace("{documentId}", documentId)
    apiService.get(apiUrl)


  }     

}