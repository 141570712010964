import React, { FunctionComponent as Component, useCallback } from "react"
import { Text, View, ViewStyle, TouchableOpacity, StyleSheet, Alert, Linking } from "react-native"
import { observer } from "mobx-react-lite"
import { Screen, Icon } from "../../../components";
import { styles, color } from "../../../theme";
import { Ionicons } from "@expo/vector-icons";
import Constants from 'expo-constants';
const { T_AND_C_URL } = require("../../../config/env.js")




export const SupportScreen: Component = observer(function SupportScreen() {

const OpenURLButton = ({ url, children }) => {
   
  const handlePress = useCallback(async () => {
      // Checking if the link is supported for links with custom URL scheme.
    // const supported = await Linking.canOpenURL(url);

    // if (supported) {
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL(url);
    // } else {
    //   Alert.alert(`Invalid Reqest`);
    // }
    }, [url]);

    return (
        <TouchableOpacity  onPress={handlePress}>
            <Text style={{color: color.ananthamBlue}}>{children}</Text>
        </TouchableOpacity>
    );
};
  
  return (
    <Screen preset="fixedHeight" headerTitle="Help & Support" showHeader={true} showBackButton={true}>

            <View style={container}>


            <View  style={{marginTop: 30}}>
                <View style={{alignItems:'center', marginTop: 10, backgroundColor: color.black, borderRadius: 20, padding: 20}}>
                    <Icon icon={'ellam_icon'} style={{width: 60, height: 60}}></Icon>
                </View>
                <Text style={{textAlign: 'center', marginTop: 15, fontSize: 20, fontWeight: 'bold'}}>
                    Ellam
                </Text>
                <Text style={{...styles.label_sm}}>Ellam for Enterprises V {Constants.manifest.version}</Text>

            </View>

            </View>


            <View style={styleSheet.container}>
                <Text style={{fontSize: 18, marginTop: 10, marginBottom:20, fontWeight: 'bold'}}>Contact Us</Text>

                <View style={{flexDirection :'row', marginVertical: 5}}>
                    <Text>Email Us : </Text><OpenURLButton url={'mailto: info@ellam.in'}>info@ellam.in</OpenURLButton>
                </View>
                <View style={{flexDirection :'row', marginVertical: 5}}>
                    <Text>Contact Number : </Text><OpenURLButton url={'tel:+918086186688'}>+91 8086186688</OpenURLButton>
                </View>
            </View>

      <TouchableOpacity onPress={() => Linking.openURL(T_AND_C_URL)}
                style={[styleSheet.container, {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}]}>
              <Text style={{fontSize: 14, fontWeight: 'bold'}}>Legal, Terms & Conditions    </Text>
              <Ionicons name="ios-arrow-forward" size={24} color="black" />
            </TouchableOpacity>


    </Screen>
)
})

const container: ViewStyle = {
  backgroundColor: color.background,
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center"
}

const styleSheet = StyleSheet.create({
    container: { 
        backgroundColor: color.white,
        padding: 15,
        marginTop: 20,
        borderRadius: 10,
        marginHorizontal: 30
    },
    
  });
  
