import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TextStyle } from 'react-native';
import { color } from "../../theme";
import { useNavigation } from "@react-navigation/core";
import { Button, Icon, Text } from "..";
import { ToastProps } from "./toast.props";
import { Card , Modal} from "@ui-kitten/components";


const CENTER: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
}

const LIGHTTEXT: TextStyle = {
  color: color.dim,
  fontSize: 13,
  marginHorizontal: 7,
  marginVertical: 5
}

const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

const TITLE_PRIMARY: TextStyle = {
  width: "100%",
  color: color.black,
  fontSize: 28,
  textAlign: "center",
  fontWeight: "bold" 
}


export const Toast: Component<ToastProps> = props => {
  const {
    type,
    message,
    closeNavigationPath,
    display,
    close
  } = props


  const [modalVisible, setModalVisibility] = React.useState(false);

  useEffect(() => {
    setModalVisibility(display)
  }, [display]);


  // navigation
  const navigation = useNavigation()


  function closeNavigate() {
    if(closeNavigationPath){
      navigation.navigate(closeNavigationPath.stack, {screen: closeNavigationPath.screen})
    }
    close()
  }


  return (
 
      <View>

        <Modal visible={modalVisible}
          backdropStyle={BACKDROP}
          onBackdropPress={() => setModalVisibility(false)}
          style={{padding: 20}}>
          
          <Card>

              <View style={CENTER}>
                    { type == 'success' ? <Icon icon={"success_icon"} style={{width:80, height:80,  alignSelf: 'center'}}></Icon> : 
                      type == 'error' ? <Icon icon={"error_icon"} style={{width:80, height:80,  alignSelf: 'center'}}></Icon> : 
                      <Icon icon={"warning_icon"} style={{width:80, height:80,   alignSelf: 'center'}}></Icon>
                    }

                    <View style={{marginTop: 15, alignSelf: 'center'}}>

                      <Text style={[TITLE_PRIMARY, {textAlign: 'center', alignSelf: 'center'}]}>
                        {type[0].toUpperCase() + type.slice(1, type.length)}
                      </Text>

                      <Text style={[LIGHTTEXT, {textAlign: 'center'}]}>{message}</Text>
                    
                      <View style={[LIGHTTEXT, {flexDirection: 'row', marginTop: 5, alignSelf: 'center'}]}>
                        <Button
                          preset="primary"
                          style={{backgroundColor: type == 'success' ? color.green: type == 'error' ? color.red: color.ananthamOrange, margin: 10}}
                          text="Close"
                          onPress={closeNavigate}/>
                      </View>

                    </View>
              </View>
        
          </Card>
        </Modal>

    </View>
  );
};



