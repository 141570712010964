// It should be ten characters long.
// The first five characters should be any upper case alphabets.
// The next four-characters should be any number from 0 to 9.
// The last(tenth) character should be any upper case alphabet.
// It should not contains any white spaces.

export function panValidate(number: any){
    try {
        const reg = /[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}/;
        if (reg.test(number) === false || number.length != 10) {
          return false;
        } else {
          return true;
        }
    } catch {
      // not sure why this would fail... even reading the RN docs I'm unclear
      return null
    }
}
  