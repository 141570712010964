import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, StyleSheet, Alert, Image, TouchableOpacity, TextStyle } from "react-native"
import { spacing, color, styles } from "../../theme"
import { Text } from "../text/text"
import { DocumentUploadProps } from "./document-upload.props";
import * as ImagePicker from 'expo-image-picker';

// static styles
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}



/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const DocumentUpload: Component<DocumentUploadProps> = props => {
  const {
    defaultDocumentUrl,
    getDocument,
    required,
    showError,
    removeDocument
  } = props


  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const cross_arrow_white = require('../../../assets/times_white.png')

    
  useEffect(()=>{
      if(defaultDocumentUrl){
        setSelectedDocument(defaultDocumentUrl)
      }
  }, [])


  let openImagePickerAsync = async () => {
    let permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
        Alert.alert('Permission to access camera roll is required!');
        return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync();

    if (pickerResult.canceled === true) {
        return;
    }

    setSelectedDocument(pickerResult.assets[0].uri); 
    getDocument(pickerResult.assets[0].uri)
  };



  return (
    <View style={{ ...ROOT }}>
        
        {/* <Text style={{ color: color.ananthamBlue, paddingBottom: 15 }} onPress={openImagePickerAsync}>+ Upload Document</Text> */}


        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

          <View style={{flexDirection: 'row'}}>
          <Text style={{ color: color.ananthamBlue, paddingBottom: 15 }} onPress={openImagePickerAsync}>+ Upload Document</Text>
            { required ?
              <View>
                <Text style={{color: color.ananthamOrange}}> *</Text>
              </View> : <View></View>
            }
          </View>

          { required && showError &&  (selectedDocument == undefined || selectedDocument.length == 0)?
            <View>
              <Text style={{...label_error}}>Required</Text>
            </View> : <View></View>
          }

        </View>



        { selectedDocument ?    
            <View>
                <View style={{flexDirection: "row", justifyContent: "flex-start", marginBottom: 10}}>
                            <Image
                                source={{ uri: selectedDocument }}
                                style={styleSheet.thumbnail}
                            />
                            <TouchableOpacity style={styleSheet.close_btn} onPress={()=>{setSelectedDocument(null);removeDocument(true)}}>
                                <Image source={cross_arrow_white} style={{width:15, height: 15}}/>
                            </TouchableOpacity>
                </View>
            </View>
            : <View></View>
        }


    </View>
    

  )
}





  const styleSheet = StyleSheet.create({
    thumbnail: {
        width: "100%",
        height: 250,
        resizeMode: "cover",
        borderWidth: 1,
        borderColor: color.line
    },
    close_btn: {
        width: 25,
        height: 25,
        borderRadius: 15,
        backgroundColor: color.primary,
        marginLeft: -15,
        marginTop: -10,
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
    }
});

const label_error: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}