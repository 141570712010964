import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { WelcomeScreen, BusinessInformation, BusinessInformationVerification } from "../screens";


export type WelcomeParamList = {
    welcome: undefined
    businessinformation: undefined
    businessinformationverification: undefined
}

const Stack = createStackNavigator<WelcomeParamList>()
const config = {
    animation: 'spring',
    config: {
      stiffness: 850,
      damping: 100,
      mass: 3,
      overshootClamping: false,
      restDisplacementThreshold: 0.01,
      restSpeedThreshold: 0.01,
    },
};

export function WelcomeNavigator() {
    return (
        <Stack.Navigator
        screenOptions={{
            headerShown: false,
            gestureEnabled: true,
            gestureDirection: 'horizontal',
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            transitionSpec: {
              open: config,
              close: config
            }
        }}>

          <Stack.Screen name="welcome" component={WelcomeScreen} options={{title: "Welcome"}} />
          <Stack.Screen name="businessinformation" component={BusinessInformation} options={{title: "Business Information"}}/>
          <Stack.Screen name="businessinformationverification" component={BusinessInformationVerification} options={{title: "Business Information Verification"}}/>

    
        </Stack.Navigator>
      )
}

const exitRoutes = []
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
