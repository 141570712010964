import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, ViewStyle } from "react-native"
import { spacing, color } from "../../theme"
import { CalenderProps } from "./calender.props";
import { Calendar } from 'react-native-calendars';
import { Button } from "../button/button";
import moment from "moment";
import { Text } from "../text/text"
import { Card, Modal } from "@ui-kitten/components";
import { Ionicons } from "@expo/vector-icons";

// static styles
const ROOT: ViewStyle = {
  paddingHorizontal: spacing.xSmall,
}

const BACKDROP: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}


/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const Calender: Component<CalenderProps> = props => {
  const {
    display,
    cancel,
    confirm
  } = props


  const [modalVisible, setModalVisibility] = useState(false);

  const [markedDates, setMarkedDates] = useState({});

  const [isStartDate, setIsStartDate] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [minDate, setMinDate] = useState(null);


  useEffect(() => {
    setModalVisibility(display)
    // setMarkedDates({})
  }, [display]);


  function dateRange(day){

    var markedDatesList = markedDates

    if(!isStartDate && !isEndDate){
      setMarkedDates({})
      markedDatesList = {}
    }

    if(!isStartDate){
      setMarkedDates(null)
      setIsStartDate(true)
      setMinDate(day['dateString'])
      setStartDate(day['dateString'])
      setEndDate(null)
      markedDatesList[day['dateString']] = {startingDay: true, color: color.primary, textColor: 'white'}
      setMarkedDates(JSON.parse(JSON.stringify(markedDatesList)))
    } 

    if(isStartDate && !isEndDate){
      setEndDate(day['dateString'])
      setIsEndDate(true)

      var dates = enumerateDaysBetweenDates(startDate, day['dateString'])
      for(let i=0; i < dates.length; i++){
        markedDatesList[dates[i]] = {color: color.primary, textColor: 'white'}
      }

      markedDatesList[day['dateString']] = {endingDay: true, color: color.primary, textColor: 'white'}
      setMarkedDates(JSON.parse(JSON.stringify(markedDatesList)))

      setIsStartDate(false)
      setIsEndDate(false)
      setMinDate(null)
    }


  }

  var enumerateDaysBetweenDates = function(startDate, endDate) {
    var dates = [];

    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.toDate()).format("YYYY-MM-DD"))
    }

    return dates;
};


function confirmDates(){
  confirm({from: new Date(startDate), to: new Date(endDate)})
}


function cancelDate(){
  cancel(true)
}

  return (
    <View style={{ ...ROOT }}>          

        <Modal visible={modalVisible}
            backdropStyle={BACKDROP}
            onBackdropPress={() => setModalVisibility(false)}>

            <Card>

              <View style={{backgroundColor: color.transparent}}></View>

              <View style={{backgroundColor: color.white, borderTopStartRadius: 15, borderTopEndRadius: 15, width: "100%"}}>

                <View style={{borderRadius: 10, height: 15}}></View>

                    <Calendar
                            markingType={'period'}
                            enableSwipeMonths={true}
                            minDate={minDate}
                            maxDate={moment().format("YYYY-MM-DD")}
                            onDayPress={(day) => dateRange(day)}
                            markedDates={markedDates}
                            renderArrow={(direction) => (<Arrow dir={direction}/>)}
                    />


                    <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 15}}>
                      <Text style={{fontWeight: 'bold', color: color.grey}}>{startDate ? moment(startDate).format("DD MMM, YYYY") : "Pick Start Date"}</Text>
                      <Text style={{fontWeight: 'bold',  color: color.grey}}>  -  {endDate ? moment(endDate).format("DD MMM, YYYY") : "Pick End Date"}</Text>
                    </View>


                    <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 20}}>
                      <Button
                          preset="primary_sm"
                          style={{margin: 10, width: 150, height: 46, borderRadius: 23}}
                          text="Confirm"
                          onPress={()=> confirmDates()}>
                      </Button>

                      <Button
                          preset="secondary_sm"
                          style={{margin: 10, width: 150, height: 46, borderRadius: 23}}
                          text="Close"
                          onPress={()=>cancelDate()}>
                      </Button>
                    </View>

                </View>

              </Card>

         </Modal>

    </View>
    
  )
}


const Arrow = (val)=>{
  if(val["dir"] == "left"){
    return <View><Ionicons name="caret-back-outline" size={24} color="black" /></View>
  } else {
    return <View><Ionicons name="caret-forward-outline" size={24} color="black" /></View>
  }
}