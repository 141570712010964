import { GeneralApiProblem } from "../api-problem"
import { ApiResponse } from "apisauce"
import { getGeneralApiProblem } from "../api-problem"
import { url } from "../api-list"
import { Api } from "../api"

// models
import { getSupplierMenuCategoryAndItems_Model, getSupplierItems_Model, getSupplierItemsDetails_Model } from "../../models/configurations-model";
import { load } from "../../../utils/storage";
import { Platform } from "react-native";




type getSupplierMenuCategoryAndItems_Res = { kind: "ok", data: getSupplierMenuCategoryAndItems_Model } | GeneralApiProblem
type addSupplierInventory_Res = { kind: "ok", data: getSupplierMenuCategoryAndItems_Model } | GeneralApiProblem
type updateSupplierInventory_Res = { kind: "ok", data: getSupplierMenuCategoryAndItems_Model } | GeneralApiProblem
type getSupplierItems_Res = { kind: "ok", data: getSupplierItems_Model } | GeneralApiProblem
type getSupplierItemsDetails_Res = { kind: "ok", data: getSupplierItemsDetails_Model } | GeneralApiProblem


export class ApiInventoryConfig {

  async getSupplierMenuCategoryAndItems(): Promise<getSupplierMenuCategoryAndItems_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    const businessId = await load("businessId")

    const apiUrl = url.getSupplierMenuCategoryAndItems.replace('{businessId}', businessId)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
      }
    }

    // transform the data into the format we are expecting
    try {    

        var data = response.data
        var categoryResults = []
        var inventoryResults = {}

        data.map(x=>{

            categoryResults.push({id: x['id'], name: x['name']})

            inventoryResults[x['id']] = []
            x['menuItems'].map(item=>{
                item['icon'] = x['name'].toLowerCase()
                inventoryResults[x['id']].push(item)
            })
        })

      return { kind: "ok", data: {category: categoryResults, inventory: inventoryResults} }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async addSupplierInventory(inventory): Promise<addSupplierInventory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const businessId = await load("businessId")
    var apiUrl = url['addSupplierInventory'].replace('{businessId}', businessId)


    // make the api call
    const response: ApiResponse<any> = await apiService.post(apiUrl, inventory)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{

    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async updateSupplierInventory(inventory): Promise<updateSupplierInventory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const businessId = await load("businessId")
    var apiUrl = url['updateSupplierInventory'].replace('{businessId}', businessId)

    // make the api call

    const response: ApiResponse<any> = await apiService.put(apiUrl, inventory)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data = true){
      }
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSupplierItems(): Promise<getSupplierItems_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    const businessId = await load("businessId")
    const apiUrl = url.getSupplierItems.replace('{businessId}', businessId)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
      }
    }

    // transform the data into the format we are expecting
    try {    
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getSupplierItemsDetails(inventoryId): Promise<getSupplierItemsDetails_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    const businessId = await load("businessId")
    const apiUrl = url.getSupplierItemsDetails.replace('{businessInfoId}', businessId).replace('{inventoryId}', inventoryId)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      if(response.data['statusCode'] = 200){
      }
    }

    // transform the data into the format we are expecting
    try {    

        var results = response.data
        // delete results['statusCode']

      return { kind: "ok", data: results }
    } catch {
      return { kind: "bad-data" }
    }
  }



  // 
  
  async getMenuCategoryAndItems(domain): Promise<getSupplierMenuCategoryAndItems_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.getMenuCategoryAndItems.replace("{domain}", domain)

    const response: ApiResponse<any> = await apiService.get(apiUrl)
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {    
      
        var data = response.data
        var categoryResults = []
        var inventoryResults = {}

        data.map(x=>{

            categoryResults.push({id: x['id'], name: x['name']})

            inventoryResults[x['id']] = []
            x['menuItems'].map(item=>{
                item['icon'] = x['name'].toLowerCase()
                inventoryResults[x['id']].push(item)
            })
        })

      return { kind: "ok", data: {category: categoryResults, inventory: inventoryResults} }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async addInventory(inventory, domain): Promise<addSupplierInventory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()

    // domain = "workship"

    const apiUrl = url.addInventory.replace("{domain}", domain)

    const response: ApiResponse<any> = await apiService.post(apiUrl, inventory)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async updateInventory(inventory, domain): Promise<updateSupplierInventory_Res> {

    const api = new Api()
    const apiService = await api.setHeader()
    const apiUrl = url.updateInventory.replace('{inventoryId}', inventory.id).replace("{domain}", domain)
    
    const response: ApiResponse<any> = await apiService.put(apiUrl, inventory)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getItems(domain): Promise<getSupplierItems_Res> {

    const api = new Api()
    const apiService = await api.setHeader()


    const apiUrl = url.getItems.replace("{domain}", domain)

    const response: ApiResponse<any> = await apiService.get(apiUrl)

    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    try {    
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


  async getItemsDetails(inventoryId, domain): Promise<getSupplierItemsDetails_Res> {
    // make the api call

    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.getItemsDetails.replace('{inventoryId}', inventoryId).replace("{domain}", domain)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
      // if(response.data['statusCode'] = 200){
      // }
    }

    // transform the data into the format we are expecting
    try {    

        var results = response.data
        // delete results['statusCode']

      return { kind: "ok", data: results }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async getInventoriesLookup(domain) {
    
    // make the api call
    const api = new Api()
    const apiService = await api.setHeader()

    const apiUrl = url.getInventoriesLookup.replace("{domain}", domain)

    // make the api call
    const response: ApiResponse<any> = await apiService.get(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }
    else{
    }

    // transform the data into the format we are expecting
    try {    
        var results = response.data

      return { kind: "ok", data: results }
    } catch {
      return { kind: "bad-data" }
    }
  }



  async addInventoyImage(imageUrl, domainName) {
    // make the api call

    const api = new Api()
    const apiService = await api.setMultipartHeader()

    let body = new FormData();
    if(Platform.OS == "web"){
      const base64Response = await fetch(imageUrl);
      const blob = await base64Response.blob();
      body.append('file', blob);
    } else {
      body.append('file', { uri: imageUrl, name: 'photo.png', type: 'image/png' });
    }

    const response: ApiResponse<any> = await apiService.post(url.inventoryImage.replace("{domain}", domainName), body)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


    
  b64toBlob(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(onsuccess);
    };

    img.src = b64;
  }


  async deleteInventory(inventoryId, domain) {

    const api = new Api()
    const apiService = await api.setHeader()
    const apiUrl = url.deleteInventory.replace('{inventoryId}', inventoryId).replace("{domain}", domain)

    // make the api call
    const response: ApiResponse<any> = await apiService.delete(apiUrl)

    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) return problem
    }

    // transform the data into the format we are expecting
    try {
      return { kind: "ok", data: response.data }
    } catch {
      return { kind: "bad-data" }
    }
  }


}



