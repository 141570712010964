import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, Screen, DayPicker } from "../../../components"
import { color, spacing } from "../../../theme"
import { ApiOperatingDaysConfig } from "../../../services/api/api-methods/api-operaring-days-config";
import { useStores } from "../../../models/root-store/root-store-context";
import { PopupMessage } from "../../../components/popup-message/popup-message";



export const OperatingDaysScreen: Component = observer(function OperatingDaysScreen() {

    const  {operatingDaysStore, loaderStore} = useStores()

    const [showMessage, setShowMessage] = React.useState(false)


    useEffect(()=>{
        getSupplierOperatingDays()
    }, [])


    const getSupplierOperatingDays = () => {
        const api = new ApiOperatingDaysConfig()
        api.getSupplierOperatingDays().then(res => {
          if(res.kind == "ok"){
            'statusCode' in res['data'] ?  delete res['data']['statusCode'] : null
            operatingDaysStore.updateSupplierDays(res['data'])
          }
        })
    }

    const onChangeDay = (days) => {
      var selectedDays = []
      Object.keys(days).map((x)=>{
        if(days[x]==true){
          selectedDays.push(Number(operatingDaysStore.defaultDays[x]))
        }
      })
      operatingDaysStore.updateDaysId(selectedDays)
      operatingDaysStore.updateSupplierDays(days)
    }

    const updateOperatingDays = () => {
      loaderStore.showLoader()
      const api = new ApiOperatingDaysConfig()
      var days = operatingDaysStore.getAddModel()

      api.addSupplierOperatingDays(days).then(res => {
        loaderStore.hideLoader()
        setShowMessage(true)
      })
    }
    

    return (

      <Screen preset="fixedHeight" headerTitle="Configure Operating Days" showHeader={true} subTitle="Select your working days" showBackButton={true} loader={loaderStore.loader} >
        <View style={CONTAINER}>
    
          
          <PopupMessage 
                display={showMessage} 
                close={()=>setShowMessage(false)} 
                message="Successfully configured operating days"
                navigationMessage="Click next to configure order settings" 
                nextNavigationPath={{stack: 'configurationStack', screen: 'order_settings'}}
                type="success">    
          </PopupMessage> 
            
          <View style={BOTTOM_CARD}>

            <View style={{marginBottom: 50}}>
              <DayPicker type="large" days={JSON.parse(JSON.stringify(operatingDaysStore.supplierDays))} getDays={text => onChangeDay(text)}></DayPicker>
            </View>

            <View style={{width: 200, marginBottom: 30, position: 'absolute', bottom: 0}}>
                    <Button
                      preset="primary"
                      text="Save"
                      onPress={updateOperatingDays}/>
            </View>
            
          </View>
        </View>
      </Screen>
  )
})


const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  maxHeight: "90%",
  height: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  alignItems: 'center',
  justifyContent: 'center',
  padding: spacing.large,
  bottom: 0,
}
