import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, RefreshControl, ScrollView, Platform, Linking, TouchableOpacity } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { color } from "../../theme"
import { ApiBusinessServices } from "../../services/api"
import { HomeCard } from "./components/home-card/home-card";
import { Screen, TextField, Button, Icon } from "../../components";
import { ApiOrders } from "../../services/api/api-methods/api-orders";
import { useStores } from "../../models";
import { getNetworkState } from "../../utils/network"
import { initiateFirebaseDatabase } from "../../utils/firebase";
import { UpdatePopup } from "../../components/update-popup/update-popup";
import { clear, load } from "../../utils/storage";
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { PermissionPopupMessage } from "../../components/permission-popup-message/permission-popup-message"


export const DashboardScreen: Component = observer(function DashboardScreen() {

  const navigation = useNavigation()

  var store = useStores()
  const { orderListStore, networkStore, businessDetailsStore, userStore, domainStore } = useStores()
  const [versionMessage, showVersionMessage] =  React.useState(false)
  const [refreshing, setRefreshing] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const [showMessage, setShowMessage] = React.useState(false)

  useEffect(()=>{
    getAppTitles()
    checkNetworkState()
    checkAppVersion()
    getPendingOrders()
    getScheduledOrders()
    getCompletedOrders()
    getPendingApprovalOrders()
    getPendingPaymentOrders()
    getBusinessInfo()
    orderListStore.updateSearchOrderText("");
    ntificationPermissionCheck();

  }, [])


  const getAppTitles = async () => {
    var businessTypeId = await load("businessTypeId")
    domainStore.create(businessTypeId)
  }

  const getSearchOrders = ()=> {

    orderListStore.updateSearchOrderText(searchText)
    orderListStore.updateSearchOrderList([])
    navigateTo('search_orders')
    setSearchText("")
    // const api = new ApiOrders()
    // api.getSearchOrders(searchText).then(res => {
    //   orderListStore.createPendingList(res['data'])
    //   setRefreshing(false)
    // })
  }

  const getPendingOrders = ()=> {
    const api = new ApiOrders()
    api.getPendingOrders('pending').then(res => {
      if (res['kind'] !== 'ok') {
        signOut()
      }
      orderListStore.createPendingList(res['data'])
      setRefreshing(false)
    })
  }

  const getPendingApprovalOrders = ()=> {
    const api = new ApiOrders()
    api.getPendingOrders('pendingApproval').then(res => {
      if (res['kind'] !== 'ok') {
        signOut()
      }
      orderListStore.createPendingApprovalList(res['data'])
      setRefreshing(false)
    })
  }

  const getPendingPaymentOrders = ()=> {
    const api = new ApiOrders()
    api.getPendingOrders('pendingPayment').then(res => {
      if (res['kind'] !== 'ok') {
        signOut()
      }
      orderListStore.createPendingPaymentList(res['data'])
      setRefreshing(false)
    })
  }

  const getScheduledOrders = ()=> {
    const api = new ApiOrders()
    api.getPendingOrders('pendingDelivery').then(res => {
      if (res['kind'] !== 'ok') {
        signOut()
      }
      orderListStore.createConfirmedList(res['data'])
    })
  }

  const getCompletedOrders = ()=> {
    const api = new ApiOrders()
    api.getPendingOrders('completed').then(res => {
      if (res['kind'] !== 'ok') {
        signOut()
      }
      orderListStore.createCompletedList(res['data'])
    })
  }


  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    getPendingOrders()
    getPendingApprovalOrders()
    getPendingPaymentOrders()
  }, []);


  const getBusinessInfo  = ()=> {
    const api = new ApiBusinessServices()
    api.getBusinessInfo(domainStore.domainName[0]).then(res => {
      if(res.kind == "ok"){
        businessDetailsStore.createBusinessInfo(res['data'])
      }
    })
  }



  const checkAppVersion = ()=> {

    if(Platform.OS != 'web'){

      var dbConnection = initiateFirebaseDatabase()
      dbConnection.ref('supplier/').on('value', (snapshot) => {
  
        var appVersion = snapshot.val().version
        var forceUpdate = snapshot.val().force_update
  
        if(Constants.manifest.version == appVersion){
          showVersionMessage(false)
        } else {
            forceUpdate ? showVersionMessage(true): showVersionMessage(false)
        }
      });

    } else {
      showVersionMessage(false) 
    }

  }

  const signOut = async () => {
    userStore.setAccessToken(null)
    store.resetStore()
    await clear()
  }

  const navigateTo = (path) => {
    checkNetworkState()
    navigation.navigate('dashboardStack', {screen: path})
  } 

  const checkNetworkState = async () => {
    var netState = await getNetworkState()
    networkStore.create(netState)
  }


  const ntificationPermissionCheck = async () => {
    if (Platform.OS === 'android') {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      if (existingStatus !== 'granted') {
        setShowMessage(true)
      }
    }
  };
  
  return (
    <Screen preset="fixed" headerTitle="Dashboard" showBackButton={false}>

      <View style={{flex: 1}}>

            { versionMessage ? 
                <UpdatePopup
                  display={versionMessage} 
                  close={()=>showVersionMessage(false)} 
                  message={"There is a new version available. Please update the application to proceed."}
                  update={()=> { signOut(); Linking.openURL('https://play.google.com/store/apps/details?id=com.ellam.supplier')}}
                ></UpdatePopup> : null
            }

          <PermissionPopupMessage 
                display={showMessage} 
                close={()=>setShowMessage(false)} 
                action={()=>Linking.openSettings()} 
                actionText="Settings"
                message="Enable Notification"
                subMessage="Go to Settings and activate notifications to start receiving notifications." 
                >
          </PermissionPopupMessage> 

            <ScrollView
            contentContainerStyle={{flex: 1}}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }>
            <View style={ROOT}>
                <View style={CONTAINER}>
                  <View style={SEARCH_CONTAINER}>
                    <View style={{flexGrow:1}}>
                      <TextField 
                        placeholder='Search'
                        defaultValue={searchText}  
                        style={{ borderRadius: 20}}
                        getText={text => setSearchText(text)} 
                      ></TextField>
                    </View>
                    <TouchableOpacity
                      style={{...SEARCH_BUTTON, marginBottom:8, marginRight:10}}
                      onPress={() => getSearchOrders()}
                    >
                      <Icon icon={'search'} ></Icon>
                    </TouchableOpacity>
                  </View>
                  <View style={BUTTON_CONTAINER}>
                    <View style={{flexDirection:'row', justifyContent:'center', flexWrap:'wrap'}}>
                      
                      <HomeCard cardText={"Pending " + domainStore.inventoryName[0]} icon='pending_icon'
                                indicatorCount={orderListStore.pendingApprovalOrderList.length} 
                                onCardPress={()=> navigateTo('pending_orders')}></HomeCard>
                      <HomeCard cardText={"New " + domainStore.inventoryName[0]} icon={domainStore.imagePrefix + "_inventory"} 
                                indicatorCount={orderListStore.pendingOrderList.length} 
                                onCardPress={()=> navigateTo('new_orders')}></HomeCard>
                      <HomeCard cardText={"Scheduled " + domainStore.inventoryName[0]} icon='operating_days2' 
                                indicatorCount={orderListStore.acceptedOrderList.length} 
                                onCardPress={()=> navigateTo('scheduled_orders')}></HomeCard>
                      <HomeCard cardText="Reports" icon='reports' 
                                onCardPress={()=> navigateTo('reports')}></HomeCard>
                      {/* <HomeCard cardText="For Delivery" icon="for_delivery" indicatorCount={5} 
                                onCardPress={()=> navigateTo('for_delivery')}></HomeCard> */}
                      {/* <HomeCard cardText="Feedbacks" icon="feedbacks" indicatorCount={0} 
                                onCardPress={()=> navigateTo('feedbacks')}></HomeCard> */}
                    </View>
                  </View>
                </View>
            </View>  
          </ScrollView>

      </View> 

    </Screen>
  )
})

const ROOT: ViewStyle = {
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#EDF1F9",
}

const CONTAINER: ViewStyle = {
  flex: 1,
  width:'100%',
  flexDirection: 'column',
  justifyContent: "center",
  flexWrap: 'wrap',
}

const BUTTON_CONTAINER: ViewStyle = {
  flex: 1,
  flexDirection: 'column',
  justifyContent: "center",
  flexWrap: 'wrap',
  alignItems: 'center'
}

const SEARCH_BUTTON: ViewStyle = {
  backgroundColor: color.primary,
  height: 40,
  width: 40,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 20
}

const SEARCH_CONTAINER: ViewStyle = {
  paddingHorizontal: 10,
  width:'100%',
  marginTop: 20,
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'nowrap',
  alignItems: 'flex-end',
}