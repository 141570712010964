import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle, TextStyle } from "react-native"
import { spacing, styles, color } from "../../theme"
import { Text } from "../text/text"
import { TextFieldProps } from "./text-field.props"
import { Input } from "@ui-kitten/components";

// the base styling for the container
const ROOT: ViewStyle = {
  padding: spacing.xSmall
}

/**
 * A component which has a label and an input together.
 */
export const TextField: Component<TextFieldProps> = props => {
  const {
    placeholderTx,
    placeholder,
    labelTx,
    label,
    defaultValue,
    preset = "default",
    disabled,
    style,
    inputStyle: inputStyleOverride,
    forwardedRef,
    getText,
    keyboardType,
    titleStyle,
    required,
    showError,
    errorText,
    onSubmit,
    multiline = false,
    secureTextEntry = false,
    ...rest
  } = props
 
  const inputStyle: ViewStyle = {
    // backgroundColor: color.background,
    // width: '100%',
    // marginTop:1
  }

  const [value, setValue] = React.useState('');

  useEffect(()=>{
    setValue(defaultValue)
  }, [defaultValue])

  

  return (
    <View style={ROOT}>

      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>

        <View style={{flexDirection: 'row'}}>
          <Text  text={label}  style={{...label_lg, ...titleStyle}} />
          { required ?
            <View>
              <Text style={{color: color.ananthamOrange}}> *</Text>
            </View> : <View></View>
          }
        </View>

        { required && showError &&  (value == undefined || value == "0" || value.trim().length == 0)?
          <View>
            <Text style={{...label_error}}>Required</Text>
          </View> : <View></View>
        }

        { errorText?
          <View>
            <Text style={{...label_error}}>{errorText}</Text>
          </View> : 
          <View></View>
        }

      </View>

      <Input
        value={value}
        placeholder={placeholder}
        keyboardType={keyboardType}
        multiline={multiline}
        secureTextEntry={secureTextEntry}
        style={{...style, ...inputStyle}}
        onChangeText={nextValue => {setValue(nextValue);getText(nextValue)}}
        disabled={disabled}
        // onSubmitEditing={event => {onSubmit(event)}}
      />
    </View>
  )
}


const label_lg: TextStyle = {
  fontSize: spacing.medium,
  color: color.dim,
  marginBottom: spacing.xSmall
}


const label_error: TextStyle = {
  fontSize: spacing.small,
  color: color.ananthamOrange,
  marginBottom: spacing.xSmall
}