/**
 * This is the navigator you will modify to display the logged-in screens of your app.
 * You can use RootNavigator to also display an auth flow or other user flows.
 *
 * You'll likely spend most of your time in this file.
 */
import React from "react"
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack"
import { NewOrdersScreen, ScheduledOrdersScreen, SearchOrdersScreen, FeedbacksScreen, ForDeliveryScreen } from "../screens"
import { OrderDetailsScreen } from "../screens/dashboard-screen/order-details-screen/order-details-screen";
import { ReportsScreen } from "../screens/dashboard-screen/reports-screen/reports-screen";
import { PendingOrdersScreen } from "../screens/dashboard-screen/pending-orders-screen/pending-orders-screen";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type DashboardParamList = {
  // dashboard: undefined
  new_orders: undefined
  pending_orders: undefined
  search_orders: undefined
  for_delivery: undefined
  scheduled_orders: undefined
  feedbacks: undefined
  order_details: undefined
  reports: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createStackNavigator<DashboardParamList>()

const config = {
  animation: 'spring',
  config: {
    stiffness: 850,
    damping: 100,
    mass: 3,
    overshootClamping: false,
    restDisplacementThreshold: 0.01,
    restSpeedThreshold: 0.01,
  },
};

export function DashboardNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
          headerShown: false,
          gestureEnabled: true,
          // gestureDirection: 'horizontal',
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          transitionSpec: {
            open: config,
            close: config
          }
      }}
    >

      <Stack.Screen name="new_orders" component={NewOrdersScreen} options={{title: "New Orders"}}/>
      <Stack.Screen name="pending_orders" component={PendingOrdersScreen} options={{title: "Pending Orders"}}/>
      <Stack.Screen name="search_orders" component={SearchOrdersScreen} options={{title: "Search Orders"}}/>
      <Stack.Screen name="for_delivery" component={ForDeliveryScreen} options={{title: "For Delivery"}}/>
      <Stack.Screen name="scheduled_orders" component={ScheduledOrdersScreen} options={{title: "Scheduled Orders"}}/>
      <Stack.Screen name="feedbacks" component={FeedbacksScreen} options={{title: "Feedbacks"}}/>
      <Stack.Screen name="order_details" component={OrderDetailsScreen} options={{title: "Order Details"}}/>
      <Stack.Screen name="reports" component={ReportsScreen} options={{title: "Reports"}}/>

    </Stack.Navigator>
  )
}



/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = []
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
