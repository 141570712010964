import React, { FunctionComponent as Component, useEffect } from "react"
import { View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { color, spacing } from "../../../theme"
import { TextField, Switch, Screen, Button, TimePickerWidget } from "../../../components";
import { ScrollView } from "react-native-gesture-handler";
import { ApiCategoryConfig } from "../../../services/api/api-methods/api-category-config";
import { useStores } from "../../../models/root-store/root-store-context";
import { PopupMessage } from "../../../components/popup-message/popup-message";
import { TextFieldAndDropdown } from "../../../components/textfield-and-dropdown/textfield-and-dropdown";
import { useNavigation } from "@react-navigation/native";
import { CustomFieldsPopup } from "../components/custom-fields-popup/custom-fields-popup";
import CustomFields from "../components/custom-fields/custom-fields";


export const CategoryDetailScreen: Component = observer(function CategoryScreen(props) {

    const  {categoryViewStore, categoriesStore, loaderStore, categoryInventoryLookupModel, domainStore} = useStores()
    const navigation = useNavigation()

    var categoryData = props['route'].params
    const [showMessage, setShowMessage] = React.useState(false)
    const [showError, setShowError] = React.useState(false)
    const [newEntry, setNewEnty] = React.useState(false)
    const [popupMessage, setPopupMessage] = React.useState("")
    const [responseStatus, setResponseStatus] = React.useState(false)
    const [customField, setCustomField] = React.useState()
    const [showCustomFieldPopup, setCustomFieldPopup] = React.useState({show: false, mode: null})

    useEffect(()=>{

        if(categoryData['type'] == "update" && categoryData["item"]['id']){
            setNewEnty(false)
            getSupplierDetails(categoryData["item"]['id'])
        } else {
            setNewEnty(true)
            categoryViewStore.updateCategoryName(categoryData['groupName'])
            categoryViewStore.reset()
        }
    }, [])

    const getSupplierDetails = (inventoryGroupId) => {
        const api = new ApiCategoryConfig()
        api.getSupplierCategoryDetails(inventoryGroupId, domainStore.domainName[0]).then(res => {
            categoryViewStore.create(res['data'])
        })
    }

    const saveData = () =>{ 
        
        if(categoryViewStore.checkMandatory()){
            loaderStore.showLoader()
            if(newEntry){
                var addModel = categoryViewStore.getAddModel()
                addSupplierCategories(addModel)
            } else {
                var updateModel = categoryViewStore.getUpdateModel()
                updateSupplierCategories(updateModel)
            }
        } else {
            setShowError(true)
        }

    }

    const addSupplierCategories = (category)  => {
        const api = new ApiCategoryConfig()

        api.addSupplierCategory(category, domainStore.domainName[0]).then(res => {
            if(res.kind == "ok"){
                setResponseStatus(true)
                categoriesStore.addCategory(category, res['data'])
                setPopupMessage("Successfully configured the category")
                setShowMessage(true)
            } else {
                setResponseStatus(false)
                setPopupMessage("Failed to configure the category")
                setShowMessage(true)
            }
            loaderStore.hideLoader()
        })
    }
    
    const updateSupplierCategories = (category) => {

        const api = new ApiCategoryConfig()
        api.updateSupplierCategory(category, domainStore.domainName[0]).then(res => {
            loaderStore.hideLoader()

            if(res.kind == "ok"){
                setResponseStatus(true)
                setShowMessage(true)
                categoriesStore.updateCategory(category)
            } else {
                setResponseStatus(false)
                setPopupMessage("Failed to configure the category")
                setShowMessage(true)
            }
        })
    }

    const deleteCategory = () => {
        const api = new ApiCategoryConfig()
        api.deleteSupplierCategory(categoryViewStore.inventoryGroupId, domainStore.domainName[0]).then(res => {
            loaderStore.hideLoader()
            if(res.kind == "ok"){
                setResponseStatus(res.data)
                if(res.data) {
                    categoriesStore.deleteCategory(categoryViewStore.inventoryGroupId)
                    setPopupMessage("Successfully deleted the category")
                    setShowMessage(true)
                    navigation.navigate('configurationStack', {screen: 'category'})
                } else {
                    setPopupMessage("Failed to delete the category")
                    setShowMessage(true)
                }
            } else {
                setResponseStatus(false)
                setPopupMessage("Failed to delete the category")
                setShowMessage(true)
            }
        })
    }

    const updateCustomFields = (item, mode) => {
        if(mode == 'delete') {
          categoryViewStore.removeFieldFromCustomDetails(item)
        } else {
          setCustomField(item)
          setCustomFieldPopup({show: true, mode})
        }
    }

    return (
        <Screen preset="fixedHeight" headerTitle="Category Details" subTitle="Configure New Category" showHeader={true} showBackButton={true} loader={loaderStore.loader}>
            <View style={CONTAINER}>

                <PopupMessage 
                    display={showMessage} 
                    close={()=>setShowMessage(false)} 
                    message={popupMessage}
                    navigationMessage={"Click next to configure " + domainStore.inventoryName[4]}
                    nextNavigationPath={{stack: 'configurationStack', screen: 'inventory'}}
                    type={responseStatus ? "success": "error"}
                ></PopupMessage>


                <CustomFieldsPopup
                    mode={showCustomFieldPopup.mode}
                    display={showCustomFieldPopup.show}
                    close={()=>setCustomFieldPopup({show: false, mode: null})} 
                    save={(data, mode)=> {
                        if(mode == 'add'){
                            categoryViewStore.addFieldToCustomDetails(data)
                        } else {
                            categoryViewStore.editFieldToCustomDetails(data)
                        }
                    }}
                    customDetails={customField}
                ></CustomFieldsPopup>
            
                <View style={BOTTOM_CARD}>

                    <ScrollView style={SCROLL_VIEW}>
                
                        <TextFieldAndDropdown 
                            title="Category"
                            disabled={newEntry ? false: true } 
                            type={ newEntry ? "both" : "textfield"} required={true} 
                            showError={showError} defaultValue={categoryViewStore.categoryName}  
                            dropdownList={categoryInventoryLookupModel.categories}  
                            getSelectedItem={ item => {
                                if(typeof item == "string") { 
                                    categoryViewStore.updateCategoryName(item) 
                                } else {
                                    categoryViewStore.updateCategoryName(item['name']); 
                                    categoryViewStore.updateCustomDetails(item['customDetails'])
                                }
                            }}
                            onChangeType={ item=>{
                                if(!item){
                                    var customFields = []
                                    categoryViewStore.updateCustomDetails(customFields)
                                } else {
                                    categoryViewStore.updateCustomDetails([])
                                }
                            }}
                        ></TextFieldAndDropdown>

                        <Switch title="Show Category to Customer" value={categoryViewStore.showToCustomer} 
                            onToggle={(value)=>categoryViewStore.toggleShowToCustomer()}
                        ></Switch>

                        {  domainStore.businessName == "Open Kitchen"   ||  domainStore.businessName == "Temple" ?
                            <View>
                                <TimePickerWidget title="Delivery Start Time" initialTime={categoryViewStore.startTime}  
                                    getTime={time=> categoryViewStore.updateStartTime(time)}
                                ></TimePickerWidget>
                                <TimePickerWidget title="Delivery End Time" initialTime={categoryViewStore.endTime} 
                                    getTime={time=> categoryViewStore.updateEndTime(time)}
                                ></TimePickerWidget>
                            </View> : null
                        }

                        <TextField label="Category Capacity" required={true} showError={showError} 
                            getText={text => categoryViewStore.updateCapacity(Number(text))} keyboardType="numeric" 
                            defaultValue={categoryViewStore.capacity.toString()}>
                        </TextField>

                        <CustomFields style={{padding: spacing.small, marginTop: 20}}
                            customDetails={categoryViewStore.customDetails} 
                            action={(cf, mode)=> updateCustomFields(cf, mode)}
                        ></CustomFields>

                        <View style={{flexDirection:"row", marginTop:20, marginHorizontal:30, marginBottom: 20, alignSelf: "center"}}>
                            <Button
                                preset="primary_sm"
                                text="Save"
                                style={{marginRight: 10}}
                                onPress={saveData}/>

                            <Button
                                preset="reject_selected"
                                text="Delete"
                                style={{marginLeft: 10}}
                                onPress={deleteCategory}/>
                        </View>

                    </ScrollView>

                </View>
            </View>
        
        </Screen>
  )
})



const CONTAINER: ViewStyle = {
    flex: 1,
    backgroundColor: color.background,
    justifyContent: "space-between",
    alignItems: "center"
}

const BOTTOM_CARD: ViewStyle = {
    width: "100%",
    minHeight: "50%",
    maxHeight: "95%",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    backgroundColor: color.white,
    justifyContent: "space-around",
    bottom: 0,
}

const SCROLL_VIEW: ViewStyle = {
    padding: spacing.large
}