import * as firebase from 'firebase';


/**
 * Initiate Firebase Connection
 *
 * @param server The server which has these creds
 */
export function initiateFirebaseDatabase(){
    const firebaseConfig = {
      apiKey: "AIzaSyBG35GS1M52CQ60boTmdBwHeM-HtymmtfQ",
      authDomain: "anantham-b6af6.firebaseapp.com",
      databaseURL: "https://anantham-b6af6.firebaseio.com",
      projectId: "anantham-b6af6",
      storageBucket: "anantham-b6af6.appspot.com",
      messagingSenderId: "1069337666071",
      appId: "1:1069337666071:web:2506fee9ac409957ab3252",
      measurementId: "G-MQFYZ5EQYF"
    };
    
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    
    var database = firebase.database();

    return database
  
}