import React, { FunctionComponent as Component } from "react"
import { View, ViewStyle, ScrollView } from "react-native"
import { observer } from "mobx-react-lite"
import { Button, Text, Screen, TextField, Switch, Dropdown } from "../../../components"
import { color, spacing } from "../../../theme"
import { Toggle } from "react-powerplug";



export const TaxDetailsScreen: Component = observer(function TaxDetailsScreen() {

  const dropdown_list = [
    { name: 'SGST', id: 1},
    { name: 'CGST', id: 2},
    { name: 'VAT', id: 3},
    { name: 'Sales Tax', id: 4},
    { name: 'Flood Cees', id: 5},
    { name: 'Other Taxes', id: 6},
  ]

  const onChangeText = (text) => {
  }


  return (
      <Screen preset="fixed" headerTitle="Tax Details" showBackButton={true}>
          <View style={CONTAINER}>

              <View>
                  <Text style={{padding: spacing.medium, marginTop: 20}}>Customize your tax settings</Text>
              </View>
          
              <View style={BOTTOM_CARD}>

                  <ScrollView style={SCROLL_VIEW}>
                  
                      <Dropdown defaultValue={1} dropdownList={dropdown_list} title="Select a Tax" getSelectedItem={item => null}></Dropdown>

                  
                      <TextField label="Tax Percentage"  getText={text => onChangeText(text)} keyboardType="numeric"></TextField>

                      <Toggle initial={false}>
                        {({ on, toggle }) => <Switch value={on} onToggle={toggle} title="Apply this tax settings to order"/>}
                      </Toggle>
                      

                      <View style={{margin: 30, alignSelf: "center"}}>

                          <Button
                              preset="primary"
                              text="Save"/>

                      </View>

                  </ScrollView>

              </View>
          </View>
      
      </Screen>
  )
})



const CONTAINER: ViewStyle = {
  flex: 1,
  justifyContent: "space-between",
  alignItems: "center"
}


const BOTTOM_CARD: ViewStyle = {
  width: "100%",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
  backgroundColor: color.white,
  justifyContent: "space-around",
  bottom: 0,
}


const SCROLL_VIEW: ViewStyle = {
  padding: spacing.large,
}
