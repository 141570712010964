import { types } from "mobx-state-tree";


export const NetworkModel = types
.model()
.props({
    isConnected: types.optional(types.boolean, true),
    isInternetReachable: types.optional(types.boolean, true),
    type: types.optional(types.string, "")
})
.actions(self=>({
    create(data){
        self.isConnected = data.isConnected
        self.isInternetReachable = data.isInternetReachable
        self.type = data.type
    },
}))


