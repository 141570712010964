import React, { FunctionComponent as Component, useState, useEffect } from "react"
import { View, StyleSheet, Platform, ImageStyle, ViewStyle, TextStyle, Image, BackHandler } from 'react-native';
import { color, spacing } from "../../theme";
import { useNavigation } from "@react-navigation/core";
import { Button, Icon, Text } from "..";
import { BottomCardProps } from "./bottom-card.props";
import { Modal, Card } from "@ui-kitten/components";



export const BottomCard: Component<BottomCardProps> = props => {
  const {
    orderCount = 0,
    message,
    subMessage,
    display,
    close,
    proceed,
    errorMessage,
    showOrderCount = true
  } = props


  const [modalVisible, setModalVisibility] = React.useState(false);

  useEffect(() => {
    setModalVisibility(display)
  }, [display]);



  return (
    <View> 


        <Modal visible={modalVisible}
            backdropStyle={backdrop}
            onBackdropPress={() => setModalVisibility(false)}
            style={{padding: 20}}>

                <Card>

                    <Text style= {{textAlign: 'center', fontSize: 20, marginBottom: 10}}>
                        {message}
                    </Text>
                    <Text style= {{textAlign: 'center', fontSize: 14, marginBottom: 10, color: color.grey}}>
                        {subMessage}
                    </Text>
                    
                    { showOrderCount ?
                    <Text style= {{textAlign: 'center', fontSize: 20, marginVertical: 20}}>
                        Total Orders : {orderCount}
                    </Text> : null
                    }


                    <View style={{flexDirection: 'row', justifyContent: "center"}}>

                        <Button
                            style={[buttonSm, type1b, {margin: 10}]}
                            textStyle={type1}
                            text="Proceed"
                            onPress={proceed}/> 

                        <Button
                            style={[buttonSm, type2b, {margin: 10}]}
                            textStyle={type2}
                            text="Cancel"
                            onPress={()=>close()}/> 

                    </View>

                    { errorMessage ? 
                    <Text style= {{textAlign: 'center', fontSize: 12, color: color.red}}>
                        {errorMessage}
                    </Text> : null
                    }

                </Card>

        </Modal>
    </View>
  );
};






const backdrop: ViewStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}
  

  const buttonSm:  ViewStyle = {
    minWidth: 100,
    width: 150,
    height: 46,
    borderRadius: 23,
  } 
  
  const type1:  TextStyle = {
    color: color.white,
    fontSize: 17,
  } 

  const type1b:  ViewStyle = {
      backgroundColor: color.primary,
  }

  const type2:  TextStyle = {
      color: color.primary,
      fontSize: 17,
  } 

  const type2b:  ViewStyle = {
      backgroundColor: color.transparent,
      borderColor:color.primary,
      borderWidth:1
  }